import React, { Component } from 'react'
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const columns = [
    { id: 'customerName', label: 'Customer Name', minWidth: 170 },
    { id: 'orderCount', label: 'Order Count', minWidth: 100 },
    {
      id: 'totalSales',
      label: 'Total Sales',
      minWidth: 170,
      align: 'right',
    //   format: (value) => value.toLocaleString(),
    },

  ];

export default class Customers extends Component {

    state={
        page: 0,
        rowsPerPage: 25
    }

    handleChangePage = (event, newPage) => {
        console.log('change page', newPage)
        this.setState({page: newPage})
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: +event.target.value, page: 0})
    };

    componentDidMount(){
        this.getCustomerBreakdown(this.props.user.id)
        console.log('set state --->', this.state)
    }
    getCustomerBreakdown = (businessId) =>{
        this.setState({customerBreakdownLoading: true})

        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getCustomerBreakdownBQ',
            data: {
              businessId: businessId
            }
        })
        .then((response) =>{
            console.log('posted to cloud function', response)
            this.setState({customerBreakdownLoading: false, customerBreakdown: response.data})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({customerBreakdownLoading: false, customerBreakdown: null})
        })
    }

    render() {
        return (
            <div style={{marginTop:'7em'}}>
                
                <h3 style={{color:'black'}}>All Customers</h3>
                {this.state.customerBreakdown ?
                <Paper style={{marginLeft:'5em',marginRight:'5em', marginTop:'2em', marginBottom:'5em'}}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id}align={column.align} style={{ minWidth: column.minWidth, fontWeight:'bold' }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                    {this.state.customerBreakdown.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                        return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}> 
                                            {row.name? <TableCell component="th" scope="row">{row.name}</TableCell>:
                                            <TableCell component="th" scope="row">unavailable</TableCell>}                                       
                                            <TableCell align="left">{row.orders}</TableCell>
                                            <TableCell align="right">{row.total_sales.toFixed(2)}</TableCell>                                                                                   
                                        </TableRow>
                                    )})}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={this.state.customerBreakdown && this.state.customerBreakdown.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />

                    </Paper>
                    :
                    <p>
                        {this.state.customerBreakdownLoading ?
                        <CircularProgress color="secondary"/>:
                        <p>NO DATA</p>
                        }
                    </p>
            }
            </div>
        )
    }
}
