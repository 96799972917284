import React, { Component } from 'react'
import {Redirect} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import S3FileUpload from 'react-s3';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import moment from 'moment'
import Switch from '@material-ui/core/Switch';
import Backdrop from '@material-ui/core/Backdrop';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import firebase from '../Config/firebase'
const db = firebase.firestore();

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  
class Account extends Component {
    componentDidMount(){
        // console.log('loaded account page', this.props)

        this.setState({loading:true})
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              this.setState({businessId: user.uid})
              let businessRef = db.collection('businesses').doc(user.uid);
              businessRef.get()
                .then(doc => {
                  if (!doc.exists) {
                    console.log('No such document!');
                  } else {
                    this.setState({business: doc.data(), loading: false, status: doc.data().status, orderNotificationSound: doc.data().orderNotificationSound,  orderNotificationRepeat: doc.data().orderNotificationRepeat, tagLine:doc.data().tagLine})
                     console.log('business --->', this.state);

                    //  let test = JSON.stringify(this.state.business.openTime + ':00')
                    //  console.log(moment(JSON.stringify(this.state.business.openTime + ':00'), 'HH:mm').format('hh:mm a') )
                      console.log(moment("13:30", 'HH:mm').format('hh:mm a'))
                  }
                })
                .catch(err => {
                  console.log('Error getting document', err);
                });
            } else {
                console.log('NO USER');
            }
          });
    }

    state={
        business: '',
        updatedTagLine:false
    }

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value})
        // console.log(this.state)
    }

    handleStatus = (e) => {
        var user = firebase.auth().currentUser
        this.setState({[e.target.name]: e.target.value})
        console.log(this.state)
        let businessRef = db.collection('businesses').doc(user.uid);
        businessRef.update({status: e.target.value});
        
        this.setState({updateStatus: true})
        setTimeout(()=>{ 
            this.setState({updateStatus: false})
        }, 2500);
    }

    handleAutoPrint = (e) => {
        this.setState({[e.target.name]: e.target.value})
        console.log(this.state)
    }

    handleProfileImage = ({target}) =>{
        let imageName = 'update-' + JSON.stringify( Math.floor(1000 + Math.random() * 9000)) + '-profileImage.png'
        let newimageUrl = 'https://tango-pwa.s3.us-east-2.amazonaws.com/businesses/'+ this.state.businessId + '/' + imageName
        this.setState({loading: true})

        let image = new File([target.files[0]], imageName, {type: 'image/png'});

        const profileImageConfig = {
            bucketName: 'tango-pwa',
            dirName: 'businesses/' + this.state.businessId,
            region: 'us-east-2',
            accessKeyId: process.env.REACT_APP_AMAZON_S3_ACCESSKEYID,
            secretAccessKey: process.env.REACT_APP_AMAZON_S3_SECRETACCESSKEY
        }

        S3FileUpload.uploadFile(image, profileImageConfig).then((data) => {
            console.log('uploaded profile image to our s3 bucket!!', data)  
            this.setState({loading: false, uploadProfileImage: true}) 

            var businessRef = db.collection("businesses").doc(this.state.businessId);
            return businessRef.update({profileImageUrl: newimageUrl})
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
                this.setState({uploadProfileImage: false}) 
            });
        })
    }

    handleBannerImage = ({target}) =>{
        let imageName = 'update-' + JSON.stringify( Math.floor(1000 + Math.random() * 9000)) + '-bannerImage.png'
        let newimageUrl = 'https://tango-pwa.s3.us-east-2.amazonaws.com/businesses/'+ this.state.businessId + '/' + imageName
        this.setState({loading: true})

        let image = new File([target.files[0]], imageName, {type: 'image/png'});

        const bannerImageConfig = {
            bucketName: 'tango-pwa',
            dirName: 'businesses/' + this.state.businessId,
            region: 'us-east-2',
            accessKeyId: process.env.REACT_APP_AMAZON_S3_ACCESSKEYID,
            secretAccessKey: process.env.REACT_APP_AMAZON_S3_SECRETACCESSKEY
        }

        S3FileUpload.uploadFile(image, bannerImageConfig).then((data) => {
            console.log('uploaded banner image to our s3 bucket!!', data)         
            this.setState({loading: false, uploadBannerImage: true}) 
            var businessRef = db.collection("businesses").doc(this.state.businessId);
            return businessRef.update({bannerImageUrl: newimageUrl})
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
                this.setState({uploadBannerImage: false}) 
            });
    
        })
    }

    createStaff(staffEmail, staffPassword, print){
        this.setState({loadingStaff:true})
        // console.log('createStaff', staffEmail, staffPassword, print)
        
        if(!staffEmail || !staffPassword){
            this.setState({loadingStaff:false})
            return alert('pleas fill out all fields for the staff member')
        }
        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/createStaffAccount',
            data: {
              email: staffEmail,
              password: staffPassword,
              businessId: this.state.businessId,
              autoPrint: false,
              profileImageUrl: this.state.business.profileImageUrl,
              orderNotificationSound: true
            }
          }).then((response) =>{
            //   console.log('posted to cloud function', response)
              this.setState({staffCreated:true, staffEmail: null, staffPassword: null, loadingStaff:false})
              setTimeout(()=>{ 
                this.setState({staffCreated: false})
                window.location.reload()
            }, 3500);  
          })
    }

    resetEmail(newEmailAddress){
        this.setState({loading: true})
        var user = firebase.auth().currentUser;

        if(!newEmailAddress){
            this.setState({loading: false})
            return
        }
        
        user.updateEmail(newEmailAddress).then(()=> {
            console.log('reset email')
            let businessRef = db.collection('businesses').doc(user.uid);
            businessRef.update({email: newEmailAddress});
            this.setState({loading: false, updateEmail: true}) 
            setTimeout(()=>{ 
                this.setState({updateEmail: false})
            }, 2500);         
        }).catch((error)=>{
            console.log('error', error)
        });
    }

    sendResetPassword(emailAddress){
        this.setState({loading: true})
        var auth = firebase.auth();
        auth.sendPasswordResetEmail(emailAddress).then(()=> {
            this.setState({passwordSent: true, loading: false})
            setTimeout(()=>{ 
                this.setState({passwordSent: false})
            }, 2500);  
        })
        .catch((error) =>{
            console.log('error sending email', error)
        });
    }


    handleBarWaitTime = (e)=>{
        console.log('clicked set bar wait time', e.target.value)
        this.enableBarConfirmation(this.state.businessId, e.target.value)
    }
    enableBarConfirmation = (businessId, waitTime) =>{
        console.log('clicked enable bar', waitTime)

        if(!waitTime){
            this.setState({barMessageError:true})
            return
        }
        else{
            this.setState({barMessageError:false})

            var business = db.collection("businesses").doc(businessId);

            business.get().then((doc)=> {
                if (doc.exists) {
                    let newBar = {
                        name:'bar',
                        available: true,
                        message: 'Prep time is about ' + waitTime + ' minutes'
                    }
                    console.log("Document data:", doc.data());
                    let businessData =  doc.data()
                    for(let i = 0; i<businessData.orderMethods.length; i++){
                        if(businessData.orderMethods[i].name === 'bar'){
                            console.log('edit this bar', businessData.orderMethods[i])

                            business.update({
                                orderMethods: firebase.firestore.FieldValue.arrayRemove(businessData.orderMethods[i])
                            })
                            business.update({
                                orderMethods: firebase.firestore.FieldValue.arrayUnion(newBar)
                            });
                        }
                    }
                } else {
                    console.log("No such document!");
                }
            }).catch((error)=> {
                console.log("Error getting document:", error);
            });

            return business.update({
                "orderConfirmationMethod.bar": true,
                "orderConfirmationMethod.barMessage": 'Prep time is about ' + waitTime + ' minutes'
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });

        }
    }
    disableBarConfirmation = (businessId)=>{
        console.log('clicked disable bar', businessId)
        
        var business = db.collection("businesses").doc(businessId);

        business.get().then((doc)=> {
            if (doc.exists) {
                let newBar = {
                    name:'bar',
                    available: false,
                    message: null
                }
                console.log("Document data:", doc.data());
                let businessData =  doc.data()
                for(let i = 0; i<businessData.orderMethods.length; i++){
                    if(businessData.orderMethods[i].name === 'bar'){
                        business.update({
                            orderMethods: firebase.firestore.FieldValue.arrayRemove(businessData.orderMethods[i])
                        })
                        business.update({
                            orderMethods: firebase.firestore.FieldValue.arrayUnion(newBar)
                        });
                    }
                }
            } else {
                console.log("No such document!");
            }
        }).catch((error)=> {
            console.log("Error getting document:", error);
        });

            return business.update({
                "orderConfirmationMethod.bar": false
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
    }

    handleCarryOutWaitTime = (e)=>{
        console.log('clicked set carryout wait time', e.target.value)
        this.enableCarryOut(this.state.businessId,  e.target.value)
    }

    enableCarryOut = (businessId, waitTime) =>{
        console.log('clicked enable carryout')

            this.setState({carryOutMessageError:false})

            var business = db.collection("businesses").doc(businessId);

            business.get().then((doc)=> {
                if (doc.exists) {
                    let newCarryOut = {
                        name:'carryOut',
                        available: true,
                        message: 'Prep time is about ' + waitTime + ' minutes'
                    }
                    console.log("Document data:", doc.data());
                    let businessData =  doc.data()
                    for(let i = 0; i<businessData.orderMethods.length; i++){
                        if(businessData.orderMethods[i].name === 'carryOut'){
                            console.log('edit this bar', businessData.orderMethods[i])

                            business.update({
                                orderMethods: firebase.firestore.FieldValue.arrayRemove(businessData.orderMethods[i])
                            })
                            business.update({
                                orderMethods: firebase.firestore.FieldValue.arrayUnion(newCarryOut)
                            });
                        }
                    }
                } else {
                    console.log("No such document!");
                }
            }).catch((error)=> {
                console.log("Error getting document:", error);
            });

            return business.update({
                "orderConfirmationMethod.carryOut": true,
                "orderConfirmationMethod.carryOutMessage": 'Prep time is about ' + waitTime + ' minutes'
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
    }
    disableCarryOutConfirmation = (businessId)=>{
        console.log('clicked disable carryout', businessId)
        
        var business = db.collection("businesses").doc(businessId);
        business.get().then((doc)=> {
            if (doc.exists) {
                let newCarryOut = {
                    name:'carryOut',
                    available: false,
                    message: null
                }
                console.log("Document data:", doc.data());
                let businessData =  doc.data()
                for(let i = 0; i<businessData.orderMethods.length; i++){
                    if(businessData.orderMethods[i].name === 'carryOut'){
                        business.update({
                            orderMethods: firebase.firestore.FieldValue.arrayRemove(businessData.orderMethods[i])
                        })
                        business.update({
                            orderMethods: firebase.firestore.FieldValue.arrayUnion(newCarryOut)
                        });
                    }
                }
            } else {
                console.log("No such document!");
            }
        }).catch((error)=> {
            console.log("Error getting document:", error);
        });

            return business.update({
                "orderConfirmationMethod.carryOut": false
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
    } 

    handleDineInWaitTime = (e)=>{
        this.enableDineIn(this.state.businessId, e.target.value)
    }
    enableDineIn = (businessId, waitTime) =>{
        if(!waitTime){
            this.setState({dineInMessageError:true})
            return
        }
        else{
            this.setState({dineInMessageError:false})

            var business = db.collection("businesses").doc(businessId);

            business.get().then((doc)=> {
                if (doc.exists) {
                    let newDineIn = {
                        name:'dineIn',
                        available: true,
                        message: 'Prep time is about ' + waitTime + ' minutes'
                    }
                    console.log("Document data:", doc.data());
                    let businessData =  doc.data()
                    for(let i = 0; i<businessData.orderMethods.length; i++){
                        if(businessData.orderMethods[i].name === 'dineIn'){
                            console.log('edit this bar', businessData.orderMethods[i])

                            business.update({
                                orderMethods: firebase.firestore.FieldValue.arrayRemove(businessData.orderMethods[i])
                            })
                            business.update({
                                orderMethods: firebase.firestore.FieldValue.arrayUnion(newDineIn)
                            });
                        }
                    }
                } else {
                    console.log("No such document!");
                }
            }).catch((error)=> {
                console.log("Error getting document:", error);
            });

            return business.update({
                "orderConfirmationMethod.dineIn": true,
                "orderConfirmationMethod.dineInMessage": 'Prep time is about ' + waitTime + ' minutes'
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });

        }
    }
    disableDineInConfirmation = (businessId)=>{
        console.log('clicked disable dine in', businessId)
        
        var business = db.collection("businesses").doc(businessId);
        business.get().then((doc)=> {
            if (doc.exists) {
                let newdineIn = {
                    name:'dineIn',
                    available: false,
                    message: null
                }
                console.log("Document data:", doc.data());
                let businessData =  doc.data()
                for(let i = 0; i<businessData.orderMethods.length; i++){
                    if(businessData.orderMethods[i].name === 'dineIn'){
                        business.update({
                            orderMethods: firebase.firestore.FieldValue.arrayRemove(businessData.orderMethods[i])
                        })
                        business.update({
                            orderMethods: firebase.firestore.FieldValue.arrayUnion(newdineIn)
                        });
                    }
                }
            } else {
                console.log("No such document!");
            }
        }).catch((error)=> {
            console.log("Error getting document:", error);
        });

            return business.update({
                "orderConfirmationMethod.dineIn": false
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
    } 

    enablePostmatesDelivery = (businessId) =>{
        // console.log('clicked enable postmates', businessId, this.state.business)
        
        let orderConfirmationMethod = this.state.business.orderConfirmationMethod
        let orderMethods = this.state.business.orderMethods
        orderConfirmationMethod.delivery = true
        orderConfirmationMethod.deliveryMessage = 'Postmates delivery time is about 20 minutes.'

        for (let i=0; i<orderMethods.length; i++){
            console.log('update delivery method', orderMethods[i])
            if(orderMethods[i].name === 'delivery'){
                orderMethods[i].available = true
                orderMethods[i].message = 'Postmates delivery time is about 20 minutes.'
            }
        }
        // console.log('new delivery data', orderConfirmationMethod, orderMethods)
        var business = db.collection("businesses").doc(businessId);
        business.update({orderMethods: orderMethods, orderConfirmationMethod: orderConfirmationMethod})
        .then(()=> {
            console.log("Document successfully updated!");
            window.location.reload()
        })
        .catch((error)=> {
            console.error("Error updating document: ", error);
        });
    }

    enableCustomDelivery = (businessId) =>{
        // console.log('clicked enable postmates', businessId, this.state.business)
        
        let orderConfirmationMethod = this.state.business.orderConfirmationMethod
        let orderMethods = this.state.business.orderMethods
        orderConfirmationMethod.delivery = true
        orderConfirmationMethod.deliveryMessage = 'Delivery time is about 30 minutes.'

        for (let i=0; i<orderMethods.length; i++){
            console.log('update delivery method', orderMethods[i])
            if(orderMethods[i].name === 'delivery'){
                orderMethods[i].available = true
                orderMethods[i].message = 'Delivery time is about 30 minutes.'
            }
        }
        // console.log('new delivery data', orderConfirmationMethod, orderMethods)
        var business = db.collection("businesses").doc(businessId);
        business.update({orderMethods: orderMethods, orderConfirmationMethod: orderConfirmationMethod})
        .then(()=> {
            console.log("Document successfully updated!");
            window.location.reload()
        })
        .catch((error)=> {
            console.error("Error updating document: ", error);
        });
    }
    disableDeliveryConfirmation = (businessId)=>{
        
        var business = db.collection("businesses").doc(businessId);
        business.get().then((doc)=> {
            if (doc.exists) {
                let newDelivery = {
                    name:'delivery',
                    available: false,
                    message: null
                }
                let businessData =  doc.data()
                for(let i = 0; i<businessData.orderMethods.length; i++){
                    if(businessData.orderMethods[i].name === 'delivery'){
                        business.update({
                            orderMethods: firebase.firestore.FieldValue.arrayRemove(businessData.orderMethods[i])
                        })
                        business.update({
                            orderMethods: firebase.firestore.FieldValue.arrayUnion(newDelivery)
                        });
                    }
                }
            } else {
                console.log("No such document!");
            }
        }).catch((error)=> {
            console.log("Error getting document:", error);
        });

            return business.update({
                "orderConfirmationMethod.delivery": false
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
    } 


    handleOpen = (e) => {
        this.setState({openTime: e.target.value})
    }  
    handleClose = (e) => {
        this.setState({closeTime: e.target.value})
    }   
    openSetHours = (day)=>{
        console.log('clicked open set hours for', day)
        this.setState({setDay: day, setHoursDialog: true})
    }
    closeSetHoursDialog = () =>{
        console.log('clicked cancel')
        this.setState({setDay: null, setHoursDialog: false})
    }
    handleChangeOpenClose = (e) =>{
        console.log('set open or closed', e.target.checked)
        this.setState({[e.target.name]: e.target.checked})
    }
    saveHours = ()=>{
        if(!this.state.setDay){
            return alert('something went wrong')
        }
        let hoursData = {
            open: this.state.businessIsOpen,
            openTime: this.state.openTime,
            closeTime: this.state.closeTime
        }
        if(!this.state.businessIsOpen){
            hoursData.open = false
        }
        if(!this.state.openTime){
            hoursData.openTime = '9:00'
        }
        if(!this.state.closeTime){
            hoursData.closeTime = '17:00'
        }
      
        var businessRef = db.collection("businesses").doc(this.state.businessId);
        if(this.state.setDay === 'Sunday'){
            console.log('set hours', this.state.setDay, hoursData)
            this.setState({loadingSetHours: true})
            return businessRef.update({"hours.sunday": hoursData})
                .then(()=>{
                    console.log("Document successfully updated!");
                    window.location.reload()
                })
                .catch((error)=>{
                    console.error("Error updating document: ", error);
                });
        }
        if(this.state.setDay === 'Monday'){
            console.log('set hours', this.state.setDay, hoursData)
            this.setState({loadingSetHours: true})
            return businessRef.update({"hours.monday": hoursData})
            .then(()=>{
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=>{
                console.error("Error updating document: ", error);
            });
        }
        if(this.state.setDay === 'Tuesday'){
            console.log('set hours', this.state.setDay, hoursData)
            this.setState({loadingSetHours: true})
            return businessRef.update({"hours.tuesday": hoursData})
            .then(()=>{
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=>{
                console.error("Error updating document: ", error);
            });
        }
        if(this.state.setDay === 'Wednesday'){
            console.log('set hours', this.state.setDay, hoursData)
            this.setState({loadingSetHours: true})
            return businessRef.update({"hours.wednesday": hoursData})
            .then(()=>{
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=>{
                console.error("Error updating document: ", error);
            });
        }
        if(this.state.setDay === 'Thursday'){
            console.log('set hours', this.state.setDay, hoursData)
            this.setState({loadingSetHours: true})
            return businessRef.update({"hours.thursday": hoursData})
            .then(()=>{
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=>{
                console.error("Error updating document: ", error);
            });
        }
        if(this.state.setDay === 'Friday'){
            console.log('set hours', this.state.setDay, hoursData)
            this.setState({loadingSetHours: true})
            return businessRef.update({"hours.friday": hoursData})
            .then(()=>{
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=>{
                console.error("Error updating document: ", error);
            });
        }
        if(this.state.setDay === 'Saturday'){
            console.log('set hours', this.state.setDay, hoursData)
            this.setState({loadingSetHours: true})
            return businessRef.update({"hours.saturday": hoursData})
            .then(()=>{
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=>{
                console.error("Error updating document: ", error);
            });
        }       
    }

    blobToFile = (theBlob, fileName) => {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }


    printTicketThree = () => {
        var tCtx = document.getElementById('textCanvas').getContext('2d') //Hidden canvas
        let text = "Hello can you see me?";
        // TODO: Make this look like a receipt
        // tCtx.font = "14px Verdana";
        tCtx.canvas.width = tCtx.measureText(text).width;
        tCtx.fillText(text, 0, 10);

        // Get image as dataURL
        let out = tCtx.canvas.toDataURL();
        console.log(out);

        // Convert dataURL to Blob
        var data = atob( out.substring( "data:image/png;base64,".length ) ),
          asArray = new Uint8Array(data.length);
        for( var i = 0, len = data.length; i < len; ++i ) {
          asArray[i] = data.charCodeAt(i);
        }
        var blob = new Blob( [ asArray.buffer ], {type: "image/png"} );

        // Convert Blob to File
        let image = this.blobToFile(blob, "receiptImage.png");
        const profileImageConfig = {
            bucketName: 'tango-pwa',
            dirName: 'businesses/' + this.state.businessId + '/receipts/',
            region: 'us-east-2',
            accessKeyId: process.env.REACT_APP_AMAZON_S3_ACCESSKEYID,
            secretAccessKey: process.env.REACT_APP_AMAZON_S3_SECRETACCESSKEY
        }
       
        // Upload it to Amazon
        S3FileUpload.uploadFile(image, profileImageConfig).then((data) => {
            console.log('uploaded product image to our s3 bucket!', data)  
            let url = 'https://tango-pwa.s3.us-east-2.amazonaws.com/Group+25_24.png'
                    + this.state.businessId + '/receipts'
                    + '/receiptImage.png'
            console.log(blob);
            let commandsToPrint = "<BIG><BOLD><CENTER>Tango<BR>\n" +
                       "<IMAGE200X300>" + url + "<BR>"
             commandsToPrint += "<CUT>\n" + "<DRAWER>\n";
             console.log("Printing: ", commandsToPrint);
             var textEncoded = encodeURI(commandsToPrint);
             window["printNewReceipt"](textEncoded);
        })
       }


    printTicketTwo = () =>{
        var commandsToPrint =
          "<BIG><BOLD><CENTER>Tango<BR>\n" +
          "<CENTER>We are test printing_13.<BR>\n" +
          "<BOLD>1<BR>\n" +
          "<BOLD>2<BR>\n" +
          "<BOLD>3<BR>\n" +
          "<BOLD>4<BR>\n" +
          "<BOLD>5<BR>\n" +
          "<BOLD>6<BR>\n" +
          "<BOLD>7<BR>\n" +
          "<BOLD>8<BR>\n" +
          "<BOLD>9<BR>\n" +
          "<BOLD>10<BR>\n" +
          "<BOLD>11<BR>\n" +
          "<BOLD>12<BR>\n" +
          "<BOLD>13<BR>\n" +
          "<BOLD>14<BR>\n" +
          "<BOLD>15<BR>\n" +
          "<BOLD>16<BR>\n" +
          "<BOLD>17<BR>\n" +
          "<BOLD>18<BR>\n" +
          "<BOLD>19<BR>\n" +
          "<BOLD>19<BR>\n";
        // for (let i=0; i<4; i++) {
        //  commandsToPrint += String(i) + "<BR>\n"
        // }
        commandsToPrint += "<CUT>\n" + "<DRAWER>\n";
        console.log("Printing: ", commandsToPrint);
        var textEncoded = encodeURI(commandsToPrint);
        window["printNewReceipt"](textEncoded);
      }

    updateTagLine = () => {
    db.collection("businesses").doc(this.state.businessId).update({tagLine:this.state.tagLine}).then(()=>{
        this.setState({updatedTagLine:true})
        setTimeout(()=>{ 
            this.setState({updatedTagLine: false})
        }, 2500);
    })

    }

    handleChangeSoundAlert = (event) => {
        console.log('sound on?', event.target.checked)
        this.setState({loadingSoundNotification: true})

        if(event.target.checked){
            db.collection("businesses").doc(this.state.businessId).update({orderNotificationSound:true})
            .then(function() {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        }else{
            db.collection("businesses").doc(this.state.businessId).update({orderNotificationSound:false, orderNotificationRepeat: false})
            .then(function() {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        }
    };

    handleChangeRepeatSound = (event) => {
        console.log('repeat sound?', event.target.checked)
        this.setState({loadingSoundNotification: true})

        if(event.target.checked){
            db.collection("businesses").doc(this.state.businessId).update({orderNotificationSound:true, orderNotificationRepeat:true})
            .then(function() {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        }else{
            db.collection("businesses").doc(this.state.businessId).update({orderNotificationRepeat:false})
            .then(function() {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        }
    };
    

    render() {
        if(!this.props.user.admin) return <Redirect to='/' />
        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        return (
            <div style={{paddingTop: '5em'}}>

            {this.state.business &&
                <div>

                <Paper style={{marginLeft: '10%', marginRight: '10%', marginBottom:'2em', paddingLeft:'3em', paddingRight:'3em', paddingBottom:'3em'}}>
                    <div style={{textAlign:'left', marginTop:'3em', paddingTop:"3%", paddingBottom:".5%", fontWeight:"500", fontSize:"135%"}}>Account</div>
                    <div style={{paddingBottom:"3%"}}>
                        <hr/>
                    </div>

                        <p style={{textAlign: 'left'}}>{!this.state.business.published && this.state.business.stripeConnectId && <span style={{color:'orange', fontWeight:'bold'}}>Tango is reviewing your account an will contact when you are published.</span>}</p>   
                        <p style={{textAlign: 'left'}}>{!this.state.business.stripeConnectId && <span style={{color:'red', fontWeight:'bold'}}>Please link your bank account</span>}</p>

                        {!this.state.business.stripeConnectId && 
                        <div>
                            <h4>Connect your bank account to receive payments</h4>
                            <p>We use Stripe to connect to your bank and send you payments securely.</p>
                            <br/>
                            <Button variant="contained" node="a" href="https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://portal.paywithtango.com/confirmStripeConnect&client_id=ca_G3fEqI3Ew2T0UHTG9YjYMXch98HlpVPq">Connect With Stripe </Button> 
                        </div>   
                        }
                        <br/>

                        <FormControl style={{float:'left', width: '40%', marginTop:'1em', marginBottom:'1em'}}>
                            <InputLabel>Current Status</InputLabel>
                            <Select id="status" name="status" value={this.state.status} onChange={this.handleStatus}>
                                <MenuItem value={'Live'}>Live</MenuItem>
                                <MenuItem value={'Away'}>Away</MenuItem>
                            </Select>
                        </FormControl>

                        <br/><br/><br/><br/><br/><br/>
                                            {/* SET BUSINESS HOURS */}
                        <h3 style={{textAlign:'left'}}>{this.state.business.businessName} Hours</h3>

                        <Dialog onClose={this.closeSetHoursDialog} aria-labelledby="tablePolicy" open={this.state.setHoursDialog}>
                            <DialogTitle id="tablePolicy">Set New {this.state.setDay} Hours</DialogTitle>
                            {this.state.loading && <LinearProgress />}
                                <div style={{padding:'1em'}}>
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item>Closed</Grid>
                                        <Grid item>
                                        <Switch
                                            checked={this.state.businessIsOpen}
                                            onChange={this.handleChangeOpenClose}
                                            name="businessIsOpen"
                                        />
                                        </Grid>
                                            <Grid item>Open</Grid>
                                        </Grid>

                                    <InputLabel id="hoursStartLabel">Open Time</InputLabel>
                                    <Select style={{width:'80%'}}
                                        id="businessType"
                                        value={this.state.startTime}
                                        onChange={this.handleOpen}
                                        >
                                        <MenuItem value={'04:00'}>4:00am</MenuItem>
                                        <MenuItem value={'04:30'}>4:30am</MenuItem>
                                        <MenuItem value={'05:00'}>5:00am</MenuItem>
                                        <MenuItem value={'05:30'}>5:30am</MenuItem>
                                        <MenuItem value={'06:00'}>6:00am</MenuItem>
                                        <MenuItem value={'06:30'}>6:30am</MenuItem>
                                        <MenuItem value={'07:00'}>7:00am</MenuItem>
                                        <MenuItem value={'07:30'}>7:30am</MenuItem>
                                        <MenuItem value={'08:00'}>8:00am</MenuItem>
                                        <MenuItem value={'08:30'}>8:30am</MenuItem>
                                        <MenuItem value={'09:00'}>9:00am</MenuItem>
                                        <MenuItem value={'09:30'}>9:30am</MenuItem>
                                        <MenuItem value={'10:00'}>10:00am</MenuItem>
                                        <MenuItem value={'10:30'}>10:30am</MenuItem>
                                        <MenuItem value={'11:00'}>11:00am</MenuItem>
                                        <MenuItem value={'11:30'}>11:30am</MenuItem>
                                        <MenuItem value={'12:00'}>12:00pm</MenuItem>
                                        <MenuItem value={'12:30'}>12:30pm</MenuItem>
                                        <MenuItem value={'13:00'}>1:00pm</MenuItem>
                                        <MenuItem value={'13:30'}>1:30pm</MenuItem>
                                        <MenuItem value={'14:00'}>2:00pm</MenuItem>
                                        <MenuItem value={'14:30'}>2:30pm</MenuItem>
                                        <MenuItem value={'15:00'}>3:00pm</MenuItem>
                                        <MenuItem value={'15:30'}>3:30pm</MenuItem>
                                        <MenuItem value={'16:00'}>4:00pm</MenuItem>
                                        <MenuItem value={'16:30'}>4:30pm</MenuItem>
                                        <MenuItem value={'17:00'}>5:00pm</MenuItem>
                                        <MenuItem value={'17:30'}>5:30pm</MenuItem>
                                        <MenuItem value={'18:00'}>6:00pm</MenuItem>
                                        <MenuItem value={'18:30'}>6:30pm</MenuItem>
                                        <MenuItem value={'19:00'}>7:00pm</MenuItem>
                                        <MenuItem value={'19:30'}>7:30pm</MenuItem>
                                        <MenuItem value={'20:00'}>8:00pm</MenuItem>
                                        <MenuItem value={'20:30'}>8:30pm</MenuItem>
                                        <MenuItem value={'21:00'}>9:00pm</MenuItem>
                                        <MenuItem value={'21:30'}>9:30pm</MenuItem>
                                        <MenuItem value={'22:00'}>10:00pm</MenuItem>
                                        <MenuItem value={'22:30'}>10:30pm</MenuItem>
                                        <MenuItem value={'23:00'}>11:00pm</MenuItem> 
                                        <MenuItem value={'23:30'}>11:30pm</MenuItem>   
                                        <MenuItem value={'24:00'}>12:00am</MenuItem> 
                                        <MenuItem value={'00:30'}>12:30am</MenuItem>
                                        <MenuItem value={'01:00'}>1:00am</MenuItem> 
                                        <MenuItem value={'01:30'}>1:30am</MenuItem>
                                        <MenuItem value={'02:00'}>2:00am</MenuItem> 
                                        <MenuItem value={'02:30'}>2:30am</MenuItem>
                                        <MenuItem value={'03:00'}>3:00am</MenuItem> 
                                        <MenuItem value={'03:30'}>3:30am</MenuItem>
                                    </Select>     

                                    <br/><br/>           
                                    <InputLabel id="hoursStartLabel">Close Time</InputLabel>
                                    <Select style={{width:'80%'}}
                                        id="businessType"
                                        value={this.state.endTime}
                                        onChange={this.handleClose}
                                        >
                                        <MenuItem value={'04:00'}>4:00am</MenuItem>
                                        <MenuItem value={'04:30'}>4:30am</MenuItem>
                                        <MenuItem value={'05:00'}>5:00am</MenuItem>
                                        <MenuItem value={'05:30'}>5:30am</MenuItem>
                                        <MenuItem value={'06:00'}>6:00am</MenuItem>
                                        <MenuItem value={'06:30'}>6:30am</MenuItem>
                                        <MenuItem value={'07:00'}>7:00am</MenuItem>
                                        <MenuItem value={'07:30'}>7:30am</MenuItem>
                                        <MenuItem value={'08:00'}>8:00am</MenuItem>
                                        <MenuItem value={'08:30'}>8:30am</MenuItem>
                                        <MenuItem value={'09:00'}>9:00am</MenuItem>
                                        <MenuItem value={'09:30'}>9:30am</MenuItem>
                                        <MenuItem value={'10:00'}>10:00am</MenuItem>
                                        <MenuItem value={'10:30'}>10:30am</MenuItem>
                                        <MenuItem value={'11:00'}>11:00am</MenuItem>
                                        <MenuItem value={'11:30'}>11:30am</MenuItem>
                                        <MenuItem value={'12:00'}>12:00pm</MenuItem>
                                        <MenuItem value={'12:30'}>12:30pm</MenuItem>
                                        <MenuItem value={'13:00'}>1:00pm</MenuItem>
                                        <MenuItem value={'13:30'}>1:30pm</MenuItem>
                                        <MenuItem value={'14:00'}>2:00pm</MenuItem>
                                        <MenuItem value={'14:30'}>2:30pm</MenuItem>
                                        <MenuItem value={'15:00'}>3:00pm</MenuItem>
                                        <MenuItem value={'15:30'}>3:30pm</MenuItem>
                                        <MenuItem value={'16:00'}>4:00pm</MenuItem>
                                        <MenuItem value={'16:30'}>4:30pm</MenuItem>
                                        <MenuItem value={'17:00'}>5:00pm</MenuItem>
                                        <MenuItem value={'17:30'}>5:30pm</MenuItem>
                                        <MenuItem value={'18:00'}>6:00pm</MenuItem>
                                        <MenuItem value={'18:30'}>6:30pm</MenuItem>
                                        <MenuItem value={'19:00'}>7:00pm</MenuItem>
                                        <MenuItem value={'19:30'}>7:30pm</MenuItem>
                                        <MenuItem value={'20:00'}>8:00pm</MenuItem>
                                        <MenuItem value={'20:30'}>8:30pm</MenuItem>
                                        <MenuItem value={'21:00'}>9:00pm</MenuItem>
                                        <MenuItem value={'21:30'}>9:30pm</MenuItem>
                                        <MenuItem value={'22:00'}>10:00pm</MenuItem>
                                        <MenuItem value={'22:30'}>10:30pm</MenuItem>
                                        <MenuItem value={'23:00'}>11:00pm</MenuItem> 
                                        <MenuItem value={'23:30'}>11:30pm</MenuItem>   
                                        <MenuItem value={'24:00'}>12:00am</MenuItem> 
                                        <MenuItem value={'00:30'}>12:30am</MenuItem>
                                        <MenuItem value={'01:00'}>1:00am</MenuItem> 
                                        <MenuItem value={'01:30'}>1:30am</MenuItem>
                                        <MenuItem value={'02:00'}>2:00am</MenuItem> 
                                        <MenuItem value={'02:30'}>2:30am</MenuItem>
                                        <MenuItem value={'03:00'}>3:00am</MenuItem> 
                                        <MenuItem value={'03:30'}>3:30am</MenuItem>
                                    </Select>
                                 </div>
                                 {this.state.loadingSetHours && <LinearProgress/>}
                            <Button color='primary' onClick={()=>this.saveHours()}>Save Change</Button>
                        </Dialog>

                        <Grid container spacing={1}>
                            <Grid item xs={1.5}>
                                <Card style={{cursor:'pointer'}} variant="outlined" onClick={()=>this.openSetHours('Sunday')}>
                                <CardContent>
                                    <Typography color="textSecondary">Sunday</Typography>
                                    {this.state.business.hours.sunday.open ?
                                        <div><p>Open</p><span style={{fontSize:'.75em'}}>{moment(this.state.business.hours.sunday.openTime, 'HH:mm').format('hh:mm a') } - {moment(this.state.business.hours.sunday.closeTime, 'HH:mm').format('hh:mm a')}</span></div>
                                        :
                                        <div>Closed</div>
                                    }
                                </CardContent>
                                </Card>
                            </Grid> 
                            <Grid item xs={1.5}>
                                <Card style={{cursor:'pointer'}} variant="outlined" onClick={()=>this.openSetHours('Monday')}>
                                <CardContent>
                                    <Typography color="textSecondary">Monday</Typography>
                                    {this.state.business.hours.monday.open ?
                                        <div><p>Open</p><span style={{fontSize:'.75em'}}>{moment(this.state.business.hours.monday.openTime, 'HH:mm').format('hh:mm a') } - {moment(this.state.business.hours.monday.closeTime, 'HH:mm').format('hh:mm a')}</span></div>
                                        :
                                        <div>Closed</div>
                                    }
                                </CardContent>
                                </Card>
                            </Grid> 
                            <Grid item xs={1.5}>
                                <Card style={{cursor:'pointer'}} variant="outlined" onClick={()=>this.openSetHours('Tuesday')}>
                                <CardContent>
                                    <Typography color="textSecondary">Tuesday</Typography>
                                    {this.state.business.hours.tuesday.open ?
                                        <div><p>Open</p><span style={{fontSize:'.75em'}}>{moment(this.state.business.hours.tuesday.openTime, 'HH:mm').format('hh:mm a') } - {moment(this.state.business.hours.tuesday.closeTime, 'HH:mm').format('hh:mm a')}</span></div>
                                        :
                                        <div>Closed</div>
                                    }
                                </CardContent>
                                </Card>
                            </Grid> 
                            <Grid item xs={1.5}>
                                <Card style={{cursor:'pointer'}} variant="outlined" onClick={()=>this.openSetHours('Wednesday')}>
                                <CardContent>
                                    <Typography color="textSecondary">Wednesday</Typography>
                                    {this.state.business.hours.wednesday.open ?
                                        <div><p>Open</p><span style={{fontSize:'.75em'}}>{moment(this.state.business.hours.wednesday.openTime, 'HH:mm').format('hh:mm a') } - {moment(this.state.business.hours.wednesday.closeTime, 'HH:mm').format('hh:mm a')}</span></div>
                                        :
                                        <div>Closed</div>
                                    }
                                </CardContent>
                                </Card>
                            </Grid> 
                            <Grid item xs={1.5}>
                                <Card style={{cursor:'pointer'}} variant="outlined" onClick={()=>this.openSetHours('Thursday')}>
                                <CardContent>
                                    <Typography color="textSecondary">Thursday</Typography>
                                    {this.state.business.hours.thursday.open ?
                                        <div><p>Open</p><span style={{fontSize:'.75em'}}>{moment(this.state.business.hours.thursday.openTime, 'HH:mm').format('hh:mm a') } - {moment(this.state.business.hours.thursday.closeTime, 'HH:mm').format('hh:mm a')}</span></div>
                                        :
                                        <div>Closed</div>
                                    }
                                </CardContent>
                                </Card>
                            </Grid> 
                            <Grid item xs={1.5}>
                                <Card style={{cursor:'pointer'}} variant="outlined" onClick={()=>this.openSetHours('Friday')}>
                                <CardContent>
                                    <Typography color="textSecondary">Friday</Typography>
                                    {this.state.business.hours.friday.open ?
                                        <div><p>Open</p><span style={{fontSize:'.75em'}}>{moment(this.state.business.hours.friday.openTime, 'HH:mm').format('hh:mm a') } - {moment(this.state.business.hours.friday.closeTime, 'HH:mm').format('hh:mm a')}</span></div>
                                        :
                                        <div>Closed</div>
                                    }
                                </CardContent>
                                </Card>
                            </Grid> 
                            <Grid item xs={1.5}>
                                <Card style={{cursor:'pointer'}} variant="outlined" onClick={()=>this.openSetHours('Saturday')}>
                                <CardContent>
                                    <Typography color="textSecondary">Saturday</Typography>
                                    {this.state.business.hours.saturday.open ?
                                        <div><p>Open</p><span style={{fontSize:'.75em'}}>{moment(this.state.business.hours.saturday.openTime, 'HH:mm').format('hh:mm a') } - {moment(this.state.business.hours.saturday.closeTime, 'HH:mm').format('hh:mm a')}</span></div>
                                        :
                                        <div>Closed</div>
                                    }
                                </CardContent>
                                </Card>
                            </Grid> 
                        </Grid>
                    </Paper>

                    
                    <Paper style={{marginLeft: '10%', marginRight: '10%', marginBottom:'2em', paddingLeft:'3em', paddingRight:'3em', paddingBottom:'3em'}}>
                    <div style={{textAlign:'left', marginTop:'3em', paddingTop:"3%", paddingBottom:".5%", fontWeight:"500", fontSize:"135%"}}>Enable Different Order Types</div>
                    <div style={{paddingBottom:"3%"}}>
                        <hr/>
                    </div>
                    <Grid container spacing={2}> 
                    <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h6" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Dine In / Table Service</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%"}}>
                                        Customers who select this option will be prompted to enter a table number when placing an order. They will receive a confirmation text as soon as their order is placed and you will take the order to the table number listed on the order.
                                    </Typography>
                                </CardContent>
                                    {!this.state.business.orderConfirmationMethod.dineIn ?
                                    <div>
                                    <CardActions>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                                                <Button onClick={()=>this.enableDineIn(this.state.businessId, '15')} variant='contained' color="primary">Enable</Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>  
                                    </div>
                                    :
                                    <div>
                                    <CardActions>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                                                <Paper elevation={6} style={{marginLeft:'8%', marginRight:"8%", marginBottom:"5%"}}>
                                                <div style={{textAlign:"right", paddingTop:"2%", paddingRight:"2%"}}>
                                                    <Button variant='contained' style={{backgroundColor:"#48BC62", color:"white", width:"17%", fontSize:"75%", padding:".3%", cursor:"default"}}>Live</Button>
                                                </div>
                                                <div style={{paddingTop:"2%", paddingBottom:"4%", fontSize:"130%"}}>
                                                    Active Dine / Table Service Details
                                                </div>
                                                <div>
                                                    <div style={{fontStyle:'italic', paddingBottom:"1.5%", fontSize:"90%"}}>Dine In Message:</div>
                                                    <div> 
                                                        "{this.state.business.orderConfirmationMethod.dineInMessage}"
                                                    </div>
                                                </div>
                                                <div style={{paddingTop:"8%", paddingBottom:"8%"}}>
                                                    <InputLabel id="hoursStartLabel">Prep Time</InputLabel>
                                                    <Select style={{width:'40%'}}  id="dineInWaitTime"
                                                        value={this.state.dineInWaitTime} onChange={this.handleDineInWaitTime} >
                                                        <MenuItem value={'5'}>5 minutes</MenuItem>
                                                        <MenuItem value={'10'}>10 minutes</MenuItem>
                                                        <MenuItem value={'15'}>15 minutes</MenuItem>
                                                        <MenuItem value={'20'}>20 minutes</MenuItem>
                                                        <MenuItem value={'25'}>25 minutes</MenuItem>
                                                        <MenuItem value={'30'}>30 minutes</MenuItem>
                                                        <MenuItem value={'35'}>35 minutes</MenuItem>
                                                        <MenuItem value={'40'}>40 minutes</MenuItem>
                                                        <MenuItem value={'45'}>45 minutes</MenuItem>
                                                        <MenuItem value={'60'}>1 hour</MenuItem>
                                                    </Select>  
                                                </div>
                                                </Paper>

                                                <div style={{paddingTop:"3%"}}>
                                                    <Button onClick={()=>this.disableDineInConfirmation(this.state.businessId)} variant='contained' color="secondary">
                                                        Disable Dine In / Table Service
                                                    </Button>
                                                </div>
                                                
                                            </Grid>
                                        </Grid>

                                    </CardActions>    
                                    </div>
                                    }                         
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h6" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Carry Out</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%"}}>
                                        Customers who select this option will receive a text message to pick up their order at your location as soon as the order is placed. The message will include an estimated wait time.
                                    </Typography>
                                </CardContent>
                                    {!this.state.business.orderConfirmationMethod.carryOut ?
                                    <div>
                                    <CardActions>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                                                {/* when click enable pass '15' as hardcoded wait time - they can change wait time after it reloads */}
                                                <Button onClick={()=>this.enableCarryOut(this.state.businessId, '15')} variant='contained' color="primary">Enable</Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>  
                                    </div>
                                    :
                                    <div>
                                    <CardActions>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                                                <Paper elevation={6} style={{marginLeft:'8%', marginRight:"8%", marginBottom:"5%"}}>
                                                <div style={{textAlign:"right", paddingTop:"2%", paddingRight:"2%"}}>
                                                    <Button variant='contained' style={{backgroundColor:"#48BC62", color:"white", width:"17%", fontSize:"75%", padding:".3%", cursor:"default"}}>Live</Button>
                                                </div>
                                                <div style={{paddingTop:"2%", paddingBottom:"4%", fontSize:"130%"}}>
                                                    Active Carry Out Details
                                                </div>
                                                <div>
                                                    <div style={{fontStyle:'italic', paddingBottom:"1.5%", fontSize:"90%"}}>Carry Out Message:</div>
                                                    <div> 
                                                        "{this.state.business.orderConfirmationMethod.carryOutMessage}"
                                                    </div>
                                                </div>
                                                <div style={{paddingTop:"8%", paddingBottom:"8%"}}>
                                                    <InputLabel id="hoursStartLabel">Prep Time</InputLabel>
                                                    <Select style={{width:'40%'}}  id="carryoutWaittime"
                                                        value={this.state.carryOutWaitTime} onChange={this.handleCarryOutWaitTime} >
                                                        <MenuItem value={'5'}>5 minutes</MenuItem>
                                                        <MenuItem value={'10'}>10 minutes</MenuItem>
                                                        <MenuItem value={'15'}>15 minutes</MenuItem>
                                                        <MenuItem value={'20'}>20 minutes</MenuItem>
                                                        <MenuItem value={'25'}>25 minutes</MenuItem>
                                                        <MenuItem value={'30'}>30 minutes</MenuItem>
                                                        <MenuItem value={'35'}>35 minutes</MenuItem>
                                                        <MenuItem value={'40'}>40 minutes</MenuItem>
                                                        <MenuItem value={'45'}>45 minutes</MenuItem>
                                                        <MenuItem value={'60'}>1 hour</MenuItem>
                                                    </Select>  
                                                </div>
                                                </Paper>

                                                <div style={{paddingTop:"3%"}}>
                                                    <Button onClick={()=>this.disableCarryOutConfirmation(this.state.businessId)} variant='contained' color="secondary">Disable Carry Out</Button>
                                                </div>
                                                
                                            </Grid>
                                        </Grid>

                                    </CardActions>    
                                    </div>
                                    }                         
                            </Card>
                        </Grid>


                        <Grid item xs={6}>
                            <Card>
                                <CardContent style={{paddingBottom:'1em'}}>
                                    <Typography gutterBottom variant="h6" component="h6" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Bar or Tango Station</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%"}}>
                                        Customers who select this option will receive a text message to redeem their order at the bar or station as soon as the order is placed.
                                    </Typography>
                                </CardContent>
                                    {!this.state.business.orderConfirmationMethod.bar ?
                                    <div>
                                        <CardActions>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                                                    <Button onClick={()=>this.enableBarConfirmation(this.state.businessId, '15')} variant='contained' color="primary">Enable</Button>
                                                </Grid>
                                            </Grid>  
                                        </CardActions>
                                    </div>
                                    :
                                    <div>
                                        <CardActions>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                                                    <Paper elevation={6} style={{marginLeft:'8%', marginRight:"8%", marginBottom:"5%"}}>
                                                    <div style={{textAlign:"right", paddingTop:"2%", paddingRight:"2%"}}>
                                                        <Button variant='contained' style={{backgroundColor:"#48BC62", color:"white", width:"17%", fontSize:"75%", padding:".3%", cursor:"default"}}>Live</Button>
                                                    </div>
                                                    <div style={{paddingTop:"2%", paddingBottom:"4%", fontSize:"130%"}}>
                                                        Active Bar/Tango Station Details
                                                    </div>
                                                    <div>
                                                        <div style={{fontStyle:'italic', paddingBottom:"1.5%", fontSize:"90%"}}>Bar Message:</div>
                                                        <div> 
                                                            "{this.state.business.orderConfirmationMethod.barMessage}"
                                                        </div>
                                                    </div>
                                                    <div style={{paddingTop:"8%", paddingBottom:"8%"}}>
                                                        <InputLabel id="hoursStartLabel">Prep Time</InputLabel>
                                                        <Select style={{width:'80%'}}  id="barWaitTime"
                                                            value={this.state.barWaitTime} onChange={this.handleBarWaitTime} >
                                                            <MenuItem value={'5'}>5 minutes</MenuItem>
                                                            <MenuItem value={'10'}>10 minutes</MenuItem>
                                                            <MenuItem value={'15'}>15 minutes</MenuItem>
                                                            <MenuItem value={'20'}>20 minutes</MenuItem>
                                                            <MenuItem value={'25'}>25 minutes</MenuItem>
                                                            <MenuItem value={'30'}>30 minutes</MenuItem>
                                                            <MenuItem value={'35'}>35 minutes</MenuItem>
                                                            <MenuItem value={'40'}>40 minutes</MenuItem>
                                                            <MenuItem value={'45'}>45 minutes</MenuItem>
                                                            <MenuItem value={'60'}>1 hour</MenuItem>
                                                        </Select>  
                                                    </div>
                                                    </Paper>

                                                    <div style={{paddingTop:"3%"}}>
                                                        <Button onClick={()=>this.disableBarConfirmation(this.state.businessId)} variant='contained' color="secondary">
                                                            Disable Bar/Tango Station
                                                        </Button>
                                                    </div>
                                                    
                                                </Grid>
                                            </Grid>
                                        </CardActions>    
                                    </div>
                                    }
                            </Card>
                        </Grid>

                        <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h6" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Delivery</Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%"}}>
                                        Customers who select this option will receive a text message as soon as their order is placed. They will receive a second message when you mark the order as out for delivery.
                                    </Typography>
                                </CardContent>
                                    {!this.state.business.orderConfirmationMethod.delivery ?
                                    <div>
                                    <CardActions>
                                     
                                        <Grid container spacing={0}>
                                            {this.state.business.postmates ?
                                            <Grid item xs={12} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                                                <Button variant='contained' color='primary' onClick={()=>this.enablePostmatesDelivery(this.state.businessId)}>Enable Postmates Delivery</Button>
                                            </Grid>
                                            :
                                            this.state.business.customDelivery ?
                                            <Grid item xs={12} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                                                <Button variant='contained' color='primary' onClick={()=>this.enableCustomDelivery(this.state.businessId)}>Enable Delivery</Button>
                                            </Grid>
                                            :
                                            <Grid item xs={12} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                                                <Button variant='contained' disabled>Coming Soon</Button>
                                            </Grid>
                                            }
                                        </Grid>
                            
                                    </CardActions>  
                                    </div>
                                    :
                                    <div>
                                    <CardActions>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                                                <Paper elevation={6} style={{marginLeft:'8%', marginRight:"8%", marginBottom:"5%"}}>
                                                <div style={{textAlign:"right", paddingTop:"2%", paddingRight:"2%"}}>
                                                    <Button variant='contained' style={{backgroundColor:"#48BC62", color:"white", width:"17%", fontSize:"75%", padding:".3%", cursor:"default"}}>Live</Button>
                                                </div>
                                                <div style={{paddingTop:"2%", paddingBottom:"4%", fontSize:"130%"}}>
                                                    Active Delivery Details
                                                </div>
                                           

                                                <div style={{padding:'.5em'}}>
                                                    {this.state.business.postmates ?
                                                    <p>*Delivery time based on Postmates courrier availablity</p>
                                                    :
                                                    <p>{this.state.business.orderConfirmationMethod.deliveryMessage}</p>
                                                    }
                                                </div>

                                                </Paper>

                                                <div style={{paddingTop:"3%"}}>
                                                    <Button onClick={()=>this.disableDeliveryConfirmation(this.state.businessId)} variant='contained' color="secondary">
                                                        Disable Delivery
                                                    </Button>
                                                </div>
                                                
                                            </Grid>
                                        </Grid>

                                    </CardActions>    
                                    </div>
                                    }                         
                            </Card>
                        </Grid>
            
                    </Grid>
                </Paper>  

                <Paper style={{marginLeft: '10%', marginRight: '10%', marginBottom:'3em', paddingLeft:'3em', paddingRight:"3em", paddingBottom:"3em"}}>
                    <div style={{textAlign:'left', marginTop:'3em', paddingTop:"3%", paddingBottom:".5%", fontWeight:"500", fontSize:"135%"}}>Upload Business Images</div>
                    <div style={{paddingBottom:"3%"}}>
                        <hr/>
                    </div>
                    <br/>
                    <Grid container spacing={1}>
                        <Grid item xs={6} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                            <Paper elevation={4} style={{marginLeft:'8%', marginRight:"8%", marginBottom:"5%", borderRadius:"100%", width:"300px", height:"300px"}}>
                                {this.state.loading && <LinearProgress/>}
                                <img alt="Business" style={{width:"100%"}} src={this.state.business.profileImageUrl}/>
                                <br/>
                                <input onChange={this.handleProfileImage} accept="image/*" style={{ display: 'none'}} id="profileImage" type="file" />
                            </Paper>
                            <label htmlFor="profileImage">
                                    <Button variant="contained" component="span">Upload Profile Image</Button>
                            </label> 
                        </Grid>

                        <Grid item xs={6} style={{paddingBottom:'3%', paddingTop:"2%"}}>
                            <Paper elevation={4} style={{marginLeft:'8%', marginRight:"8%", marginBottom:"5%", width:"450px", height:"300px"}}>
                                {this.state.loading && <LinearProgress/>}
                                <img style={{borderRadius: '2px', width:'100%', height:"100%"}} src={this.state.business.bannerImageUrl} alt="Business" srcSet=""/>
                                <br/><br/>
                                <input onChange={this.handleBannerImage} accept="image/*" style={{ display: 'none'}} id="bannerImage" type="file" />
                            </Paper>
                            <label htmlFor="bannerImage">
                                    <Button variant="contained" component="span">Upload Banner Image </Button>
                            </label>  
                        </Grid>

                    </Grid>                   
                </Paper>

                <Paper style={{marginLeft: '10%', marginRight: '10%', marginBottom:'3em', paddingLeft:'3em', paddingRight:"3em", paddingBottom:"3em"}}>
                    <div style={{textAlign:'left', marginTop:'3em', paddingTop:"3%", paddingBottom:".5%", fontWeight:"500", fontSize:"135%"}}>Update Tagline</div>
                    <div style={{paddingBottom:"3%"}}>
                        <hr/>
                        {
                            this.state.tagLine && this.state.tagLine.length > 0 &&                        <font style={{fontStyle:"italic"}}>" {this.state.tagLine} "</font> 

                        }
                    </div>

                    <form noValidate autoComplete="off">
                        <TextField style={{width:"25%"}} onChange={this.handleChange} id="tagLine" label="your tagline" value={this.state.tagLine} />
                        <br/>
                    </form>
                    <br/>

                    <Button onClick={()=>this.updateTagLine(this.state.tagLine)} variant="contained">Update Tagline</Button>
                </Paper>
                
                <Paper style={{marginLeft: '10%', marginRight: '10%', marginBottom:'3em', paddingLeft:'3em', paddingRight:"3em", paddingBottom:"3em"}}>
                    <div style={{textAlign:'left', marginTop:'3em', paddingTop:"3%", paddingBottom:".5%", fontWeight:"500", fontSize:"135%"}}>Create Staff Accounts</div>
                    <div style={{paddingBottom:"3%"}}>
                        <hr/>
                    </div>
                    {this.state.loadingStaff &&
                    <CircularProgress color="secondary"/>
                    }
                    <br/>
                        <TextField  style={{width:"25%"}} onChange={this.handleChange} id="staffEmail" label="Staff Email" />
                        <br/><br/>
                        <TextField  style={{width:"25%"}} onChange={this.handleChange} id="staffPassword" label="Staff Password" type="password"/>
                        <br/>
                    <br/><br/>
                    <InputLabel>Auto print tickets when this staff is logged in?</InputLabel>
                    <Select style={{width:'20%'}} id="autoPrint" name="autoPrint" value={this.state.autoPrint} onChange={this.handleAutoPrint}>
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>
                    <br/><br/><br/>

                    <Button onClick={()=>this.createStaff(this.state.staffEmail, this.state.staffPassword, this.state.autoPrint)} variant="contained">Create Staff Login</Button>
                <br/><br/>
                {this.staffCreated && <p style={{color:'black'}}>Created new staff account!</p>}
                </Paper>

                <Paper style={{marginLeft: '10%', marginRight: '10%', marginBottom:'3em', paddingLeft:'3em', paddingRight:"3em", paddingBottom:"3em"}}>
                    <div style={{textAlign:'left', marginTop:'3em', paddingTop:"3%", paddingBottom:".5%", fontWeight:"500", fontSize:"135%"}}>Change Your Email</div>
                    <div style={{paddingBottom:"3%"}}>
                        <hr/>
                    </div>

                    <form noValidate autoComplete="off">
                        <TextField style={{width:"25%"}} onChange={this.handleChange} id="newEmail" label="New Email" />
                        <br/>
                    </form>
                    <br/>

                    <Button onClick={()=>this.resetEmail(this.state.newEmail)} variant="contained">Reset Email</Button>
                </Paper>

                <Paper style={{marginLeft: '10%', marginRight: '10%', marginBottom:'3em', paddingLeft:'3em', paddingRight:"3em", paddingBottom:"3em"}}>
                    <div style={{textAlign:'left', marginTop:'3em', paddingTop:"3%", paddingBottom:".5%", fontWeight:"500", fontSize:"135%"}}>Change Your Password</div>
                    <div style={{paddingBottom:"3%"}}>
                        <hr/>
                    </div>
                    <Button onClick={()=>this.sendResetPassword(this.state.business.email)} variant="contained">Send me a Password Reset Link</Button>
                </Paper>

                {/* TEST PRINT BUTTON */}
                <Paper style={{marginLeft: '10%', marginRight: '10%', marginBottom:'3em', paddingLeft:'3em', paddingRight:"3em", paddingBottom:"3em"}}>
                    <div style={{textAlign:'left', marginTop:'3em', paddingTop:"3%", paddingBottom:".5%", fontWeight:"500", fontSize:"135%"}}>Test Print</div>
                    <div style={{paddingBottom:"3%"}}>
                        <hr/>
                    </div>
                    <Button variant="contained" onClick={() => this.printTicketThree()}>Run a test print</Button>
                    <canvas id='textCanvas' height={20} style={{ display: 'none' }}></canvas>
                </Paper>

                {/* REPEAT SOUND BUTTON */}
                <Paper style={{marginLeft: '10%', marginRight: '10%', marginBottom:'3em', paddingLeft:'3em', paddingRight:"3em", paddingBottom:"3em"}}>
                    <div style={{textAlign:'left', marginTop:'3em', paddingTop:"3%", paddingBottom:".5%", fontWeight:"500", fontSize:"135%"}}>Sound Alerts</div>
                    <div style={{paddingBottom:"3%"}}>
                        <hr/>
                        {this.state.loadingSoundNotification && <LinearProgress />}
                    </div>

                    {/* {this.state.orderNotificationRepeat && */}
                    <FormControlLabel checked={this.state.orderNotificationSound}
                        control={<Switch onChange={this.handleChangeSoundAlert} name="soundAlert" />}
                        label="Alerts On New Order"
                    />
                    <br/><br/><br/>

                    <FormControlLabel checked={this.state.orderNotificationRepeat}
                        control={<Switch onChange={this.handleChangeRepeatSound} name="repeatSoundAlert" />}
                        label="Repeat Order Alerts"
                    />
                    {/* } */}
                    <canvas id='textCanvas' height={20} style={{ display: 'none' }}></canvas>
                </Paper>

                </div>
            }   

                 
                <Snackbar open={this.state.updatedTagLine} style={{width:'40%'}}>
                    <Alert style={{position:'fixed', bottom: '35%'}} severity="success">Updated Tagline!</Alert>
                </Snackbar>    
                
                <Snackbar open={this.state.staffCreated} style={{width:'40%'}}>
                    <Alert style={{position:'fixed', bottom: '35%'}} severity="success">Created new staff account! Please wait 1 minute to login as the staff.</Alert>
                </Snackbar>

                <Snackbar open={this.state.staffCreated} style={{width:'40%'}}>
                    <Alert style={{position:'fixed', bottom: '35%'}} severity="success">Created new staff account! Please wait 1 minute to login as the staff.</Alert>
                </Snackbar>
                <Snackbar open={this.state.updateStatus} style={{width:'50%'}}>
                    <Alert style={{position:'fixed', bottom: '30px'}} severity="success">Success! you've update your status to {this.state.status}</Alert>
                </Snackbar>   
                <Snackbar open={this.state.updateEmail} style={{width:'50%'}}>
                    <Alert style={{position:'fixed', bottom: '30px'}} severity="success">Success! you've updated your email</Alert>
                </Snackbar> 
                <Snackbar open={this.state.uploadProfileImage} style={{width:'50%'}}>
                    <Alert style={{position:'fixed', bottom: '30px'}} severity="success">Success! you've uploaded a new profile image</Alert>
                </Snackbar> 
                <Snackbar open={this.state.uploadBannerImage} style={{width:'50%'}}>
                    <Alert style={{position:'fixed', bottom: '30px'}} severity="success">Success! you've uploaded a new banner image</Alert>
                </Snackbar> 
                <Snackbar open={this.state.passwordSent} style={{width:'50%'}}>
                    <Alert style={{position:'fixed', bottom: '30px'}} severity="success">A reset password link has been emailed to you</Alert>
                </Snackbar> 
                <Snackbar open={this.state.changeTimeSuccess} style={{width:'50%'}}>
                    <Alert style={{position:'fixed', bottom: '30px'}} severity="success">Your hours have been updated</Alert>
                </Snackbar>                                                             
            </div>
        )
    }
}

export default Account
