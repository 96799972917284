import React, { Component } from 'react';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import DeleteForever from "@material-ui/icons/DeleteForever"
import { Calendar, momentLocalizer } from 'react-big-calendar'
import firebase from '../../Config/firebase'
import moment from 'moment'
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const db = firebase.firestore();
const localizer = momentLocalizer(moment)

class Parties extends Component {

    state = {
        today: new Date(),
        productsForPartyMenu:[],
        menusForParty: []
    }

    componentDidMount(){
        console.log('loaded parties page', this.props)
        var businessId;
        if(this.props.user.admin){
            businessId = this.props.user.id
            this.setState({businessId: this.props.user.id, profileImageUrl: this.props.user.data.profileImageUrl, bannerImageUrl: this.props.user.data.bannerImageUrl,  timezone: this.props.user.data.timezone})
        }else{
            //set business id if staff is viewing page??
        }
        this.setState({businessId: businessId})

        //load products on component mount - use to add to pary menus
        db.collection("products").where("businessId", "==", businessId).get()
        .then((querySnapshot) =>{
            let products = []
            querySnapshot.forEach((doc)=> {
                let product = doc.data()
                product.id = doc.id
                products.push(product)
            });
            //sort alphabetically
            products.sort((a, b) => (a.productName > b.productName) ? 1 : -1)
            this.setState({products: products})
        })
        .catch((error)=>{
            console.log("Error getting documents: ", error);
        });

        //load parties and party menus real time
        let parties = [];
        let partyMenus = []

        this.realTimeParties = db.collection("parties").where('businessId', '==', businessId)
        .onSnapshot((querySnapshot)=> {
            querySnapshot.forEach((doc)=>{
                let party = doc.data()
                party.id = doc.id
                party.start = new Date(moment(party.date.toDate()).format('MMMM DD, YYYY ' + party.startTime))
                party.end = new Date(moment(party.date.toDate()).format('MMMM DD, YYYY ' + party.endTime))
                party.date = party.date.toDate()
                party.date = `${party.date.getMonth()+1}/${party.date.getDate()}/${party.date.getFullYear()}`
                parties.push(party);
            });

            this.setState({parties: parties})
            //clear parties array for next time real time listener reloads (any time party added, deleted)
            parties = [];
        });

        this.realTimePartyMenus = db.collection("partyMenus").where('businessId', '==', businessId)
        .onSnapshot((querySnapshot)=> {
            querySnapshot.forEach((doc)=>{
                let partyMenu = doc.data()
                partyMenu.id = doc.id
                partyMenus.push(partyMenu);
            });

            this.setState({partyMenus: partyMenus})
            console.log('party menus', partyMenus)
            //clear parties array for next time real time listener reloads (any time party menu added, deleted)
            partyMenus = [];
        });
    }
    //"turn off real time listener when leave page"
    componentWillUnmount(){
        if(this.realTimeParties){
            this.realTimeParties() 
            console.log('unmount component and unsubscribe realtime parties lister')
        }  
        if(this.realTimePartyMenus){
            this.realTimePartyMenus()
            console.log('unmount component and unsubscribe realtime parties menus lister')
        }
    }
    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value})
        console.log(this.state)
    }
    handleDateChange = (date) => {
        if (isNaN(date.getTime())) {
            console.log('invalid date')
            return
        }
        this.setState({date: date})
    }
    handleSelectTime = (e) => {
        this.setState({[e.target.name]: e.target.value})
        console.log(this.state)
    }
    createParty = () =>{
        if(!this.state.name){
            return this.setState({noNameError: true})
        }else{
            this.setState({noNameError: false})
        }
        if(!this.state.entryCode){
            return this.setState({noEntryCodeError: true})
        }else{
            this.setState({noEntryCodeError: false})
        }
        if(this.state.maxItemCount < 10){
            return alert('Your maximum order count should be at least 10')
        }
        if(!this.state.maxItemCount){
            this.setState({maxItemCount: 100000})//if no max item count set it to 100k which is basically impossible to reach
        }
        if(!this.state.maxDollarAmount){
            this.setState({maxDollarAmount: 1000000})//if no max item count set it to 1million which is basically impossible to reach
        }
        if(!this.state.date || !this.state.startTime || !this.state.endTime){
            return alert ('please select a date, start and end times for your party')
        }
        this.setState({loading: true})
        db.collection("parties").add({
            businessId: this.state.businessId,
            name: this.state.name,
            date: this.state.date,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            profileImageUrl: this.state.profileImageUrl,
            bannerImageUrl: this.state.bannerImageUrl,
            timezone: this.state.timezone,
            itemCount: 0, //start at 0 when create party
            maxItemCount: JSON.parse(this.state.maxItemCount), //when item count = max item count, party is over
            dollarCount: 0, //start at 0 when create party
            maxDollarAmount: JSON.parse(this.state.maxDollarAmount), //when dollar count = max dollar amount, party is over
            entryCode: this.state.entryCode,
            enabled: true,
            guests: ['hardcodedFirstUser'] //when user enters correct code they are added to geusts array
        })
        .then((docRef)=> {
            console.log("Document written with ID: ", docRef.id);
            this.setState({loading: false, createPartyDialog: false})
        })
        .catch((error)=> {
            console.error("Error adding document: ", error);
            this.setState({loading: false})
            return alert('there was a problem creating your party')
        });
    }
    deleteParty = () =>{
        db.collection("parties").doc(this.state.selectedPartyToView.id).delete().then(()=> {
            console.log("Document successfully deleted!");
            this.setState({viewParty:false, deletePartyDialog: false})
        }).catch((error)=> {
            console.error("Error removing document: ", error);
            alert('there was a problem deleting this party')
        });
    }
    sortMenuProducts = (direction, index)=>{
        let productsForPartyMenu = [...this.state.productsForPartyMenu]
        if(direction === 'down' && index === (productsForPartyMenu.length-1)){
            return console.log('menu is already at bottom so dont adjust')
        }
        else if(direction === 'up' && index === 0){
            return console.log('menu is already at top so dont adjust')
        }else{
            if(direction === "up"){
                let existedMenu = productsForPartyMenu[index - 1]
                let currentMenu = productsForPartyMenu[index]
                productsForPartyMenu[index - 1] = currentMenu
                productsForPartyMenu[index] = existedMenu
            }else{
                let existedMenu = productsForPartyMenu[index + 1]
                let currentMenu = productsForPartyMenu[index]
                productsForPartyMenu[index + 1] = currentMenu
                productsForPartyMenu[index] = existedMenu
            }
            productsForPartyMenu.filter(e => e)

            this.setState({productsForPartyMenu})
        }
    }
    sortMenusForParty = (direction, index)=>{
        let menusForParty = [...this.state.menusForParty]
        if(direction === 'down' && index === (menusForParty.length-1)){
            return console.log('menu is already at bottom so dont adjust')
        }
        else if(direction === 'up' && index === 0){
            return console.log('menu is already at top so dont adjust')
        }else{
            if(direction === "up"){
                let existedMenu = menusForParty[index - 1]
                let currentMenu = menusForParty[index]
                menusForParty[index - 1] = currentMenu
                menusForParty[index] = existedMenu
            }else{
                let existedMenu = menusForParty[index + 1]
                let currentMenu = menusForParty[index]
                menusForParty[index + 1] = currentMenu
                menusForParty[index] = existedMenu
            }
            menusForParty.filter(e => e)
            this.setState({menusForParty})
        }
    }
    savePartyMenu = () =>{
        console.log('clicked party menu', this.state)
        this.setState({savingPartyMenu: true})
        if(!this.state.menuName){
            this.setState({savingPartyMenu: false})
            return alert('Please include a name for your party menu')
        }
        if(!this.state.productsForPartyMenu.length){
            this.setState({savingPartyMenu: false})
            return alert('Please add products to your menu')
        }
        if(!this.state.businessId){
            this.setState({savingPartyMenu: false})
            return alert('Something went wrong creating your menu')
        }
        
        return db.collection("partyMenus").add({
            businessId: this.state.businessId,
            name: this.state.menuName,
            products: this.state.productsForPartyMenu
        })
        .then((docRef) =>{
            this.setState({savingPartyMenu: false, createPartyMenuDialog: false, productsForPartyMenu: [], menuName: null})
        })
        .catch((error)=> {
            this.setState({savingPartyMenu: false})
            console.error("Error adding document: ", error);
            alert('There was a problem adding your party menu')
        });
    }
    deletePartyMenu = () =>{
        console.log('clicked delete party menu', this.state.clickedPartyMenu)
        if(!this.state.clickedPartyMenu){
            return alert ("There was a problem deleting your party menu")
        }
        db.collection("partyMenus").doc(this.state.clickedPartyMenu.id).delete().then(()=> {
            console.log("Document successfully deleted!");
            this.setState({deleteMenuDialog:false})
        }).catch((error)=> {
            console.error("Error removing document: ", error);
            alert('There was a problem deleting this party menu')
        });
    }
    updateParty = () =>{
        this.setState({updatingParty: true})
        console.log('clicked update party', this.state)

        if(!this.state.menusForParty.length){
            this.setState({updatingParty: false})
            return alert('Please add at least one menu to your party')
        }
        if(!this.state.businessId || !this.state.selectedPartyToView){
            this.setState({updatingParty: false})
            return alert('Something went wrong updating your party')
        }

        var partyToUpdate = db.collection("parties").doc(this.state.selectedPartyToView.id);
        return partyToUpdate.update({menus: this.state.menusForParty})
        .then(()=> {
            this.setState({updatingParty: false, partyUpdateSuccess: true, selectedPartyToView: null, menusForParty: [], productsForPartyMenu: []})
            console.log("Document successfully updated!");
        })
        .catch((error)=>{
            console.error("Error updating document: ", error);
            return alert('Something went wrong updating your party')
        });
    }
    selectPartyOnCalendar = (event) =>{
        this.setState({viewParty: true, selectedPartyToView: event})
        if(event.menus){
            this.setState({menusForParty: event.menus})
        }
    }
    enableParty = () =>{
        return db.collection("parties").doc(this.state.selectedPartyToView.id).update({enabled: true})
        .then(()=> {
            this.setState({enableDisableDialog:false, selectedPartyToView: null})
        })
        .catch((error)=> {
            console.error("Error updating document: ", error);
        });
    }
    disableParty = () =>{
        return db.collection("parties").doc(this.state.selectedPartyToView.id).update({enabled: false})
        .then(()=> {
            this.setState({enableDisableDialog:false, selectedPartyToView: null})
        })
        .catch((error)=> {
            console.error("Error updating document: ", error);
        });
    }

    render() {
        return (
            <div style={{marginTop:'5em', color: 'black'}}>

               <h3  style={{marginTop:'6em', marginRight:'.5em', marginLeft: '.5em'}}>My Party Menus</h3>
               <Button color='primary' variant='contained' onClick={()=>this.setState({createPartyMenuDialog: true})}>+ New Party Menu</Button>
                {this.state.partyMenus && !this.state.partyMenus.length ? 
                <p>Create your first party menu!</p>
                :
                this.state.partyMenus && this.state.partyMenus.length && 
                <Grid container spacing={2} style={{marginTop:'1em', marginLeft:'.5em', marginRight:'.5em'}}>
                        {this.state.partyMenus.map(partyMenu => 
                            <Grid item xs={12} sm={6} md={3}>
                            <Card key={partyMenu.id} style={{marginTop:'.5em', marginBottom: '.5em'}}>
                                <div>
                                    <p style={{fontWeight:'bold'}}>{partyMenu.name}</p>
                                </div>  
                                <div>
                                    {partyMenu.products[0] && <p>{partyMenu.products[0].productName}</p>}
                                    {partyMenu.products[1] && <p>{partyMenu.products[1].productName}</p>}
                                    {partyMenu.products[2] && <p>{partyMenu.products[2].productName}</p>}
                                    ...
                                </div>
                                <CardActions>
                                    <Button onClick={()=>this.setState({deleteMenuDialog:true, clickedPartyMenu: partyMenu})} size="small"><DeleteForever style={{color:"red"}}/></Button>
                                </CardActions>            
                            </Card>  
                            </Grid>
                        )}
                </Grid>
                }

                <Dialog onClose={()=>this.setState({deleteMenuDialog: false})} open={this.state.deleteMenuDialog}>     
                    <DialogTitle id="deltePartyTitle">Are You sure you want to delete this party menu?</DialogTitle>
                    <p style={{paddingLeft:'1em'}}>You will not be able to use this party menu once deleted</p>
                    
                    <DialogActions>
                        <Button variant='outlined' onClick={()=>this.setState({deleteMenuDialog: false})}>Cancel</Button>
                        <Button variant='outlined' color='primary' onClick={this.deletePartyMenu}>Yes</Button>
                    </DialogActions>
                </Dialog>


                <Dialog style={{padding:'1em'}} onClose={()=>this.setState({createPartyDialog: false})} aria-labelledby="createPartDialog" open={this.state.createPartyDialog} fullWidth={true} maxWidth = {'md'}>
                    <DialogTitle id="tablePolicy">Create a New Party</DialogTitle>

                    <div style={{padding:'1em'}}>
                        <TextField style={{width:'50%'}} error={this.state.noNameError} onChange={this.handleChange} id="name" label={"Party Name"}/>
                        <br/>
                        <TextField style={{width:'50%'}} error={this.state.noEntryCodeError} onChange={this.handleChange} id="entryCode" label={"Entry Code"}/>
                        <br/>
                        <TextField style={{width:'50%'}} type="number" onChange={this.handleChange} id="maxItemCount" label={"Maximum Item Count"}/>
                        <br/>
                        <TextField style={{width:'50%'}} type="number" onChange={this.handleChange} id="maxDollarAmount" label={"Maximum Dollar Amount"}/>
                        <br/><br/>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <h4 style={{textAlign:'left', marginLeft:'1em'}}>Choose Date</h4>
                                    <KeyboardDatePicker
                                        minDate={moment()}                                 
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Select Date"
                                        format="MM/dd/yyyy"
                                        value={this.state.placeholderDate}
                                        onChange={this.handleDateChange}
                                        KeyboardButtonProps={{'aria-label': 'change date',}}/>
                                    </MuiPickersUtilsProvider>
                        <br/><br/>
                        <InputLabel>Start Time</InputLabel>
                        <Select style={{width:'50%'}} name='startTime'  id="startTime"
                            value={this.state.startTime} onChange={this.handleSelectTime} >
                            <MenuItem value={'06:00'}>6:00 am</MenuItem>
                            <MenuItem value={'06:30'}>6:30 am</MenuItem>
                            <MenuItem value={'07:00'}>7:00 am</MenuItem>
                            <MenuItem value={'07:30'}>7:30 am</MenuItem>
                            <MenuItem value={'08:00'}>8:00 am</MenuItem>
                            <MenuItem value={'08:30'}>8:30 am</MenuItem>
                            <MenuItem value={'09:00'}>9:00 am</MenuItem>
                            <MenuItem value={'09:30'}>9:30 am</MenuItem>
                            <MenuItem value={'10:00'}>10:00 am</MenuItem>
                            <MenuItem value={'10:30'}>10:30 am</MenuItem>
                            <MenuItem value={'11:00'}>11:00 am</MenuItem>
                            <MenuItem value={'11:30'}>11:30 am</MenuItem>
                            <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                            <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                            <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                            <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                            <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                            <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                            <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                            <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                            <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                            <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                            <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                            <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                            <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                            <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                            <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                            <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                            <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                            <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                            <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                            <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                            <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                            <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                            <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                            <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                            <MenuItem value={'24:00'}>12:00 am</MenuItem>
                            <MenuItem value={'00:30'}>12:30 am</MenuItem>
                            <MenuItem value={'01:30'}>1:00 am</MenuItem>
                            <MenuItem value={'01:30'}>1:30 am</MenuItem>
                            <MenuItem value={'02:30'}>2:00 am</MenuItem>
                            <MenuItem value={'02:30'}>2:30 am</MenuItem>
                            <MenuItem value={'03:30'}>3:00 am</MenuItem>
                            <MenuItem value={'03:30'}>3:30 am</MenuItem>
                            <MenuItem value={'04:00'}>4:00 am</MenuItem>
                            <MenuItem value={'04:30'}>4:30 am</MenuItem>
                            <MenuItem value={'05:00'}>5:00 am</MenuItem>
                            <MenuItem value={'05:30'}>5:30 am</MenuItem>
                        </Select> 
                        <br/><br/>
                        <InputLabel>End Time</InputLabel>
                        <Select style={{width:'50%'}} name='endTime'  id="endTime"
                            value={this.state.endTime} onChange={this.handleSelectTime} >
                            <MenuItem value={'06:00'}>6:00 am</MenuItem>
                            <MenuItem value={'06:30'}>6:30 am</MenuItem>
                            <MenuItem value={'07:00'}>7:00 am</MenuItem>
                            <MenuItem value={'07:30'}>7:30 am</MenuItem>
                            <MenuItem value={'08:00'}>8:00 am</MenuItem>
                            <MenuItem value={'08:30'}>8:30 am</MenuItem>
                            <MenuItem value={'09:00'}>9:00 am</MenuItem>
                            <MenuItem value={'09:30'}>9:30 am</MenuItem>
                            <MenuItem value={'10:00'}>10:00 am</MenuItem>
                            <MenuItem value={'10:30'}>10:30 am</MenuItem>
                            <MenuItem value={'11:00'}>11:00 am</MenuItem>
                            <MenuItem value={'11:30'}>11:30 am</MenuItem>
                            <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                            <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                            <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                            <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                            <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                            <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                            <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                            <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                            <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                            <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                            <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                            <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                            <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                            <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                            <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                            <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                            <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                            <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                            <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                            <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                            <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                            <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                            <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                            <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                            <MenuItem value={'24:00'}>12:00 am</MenuItem>
                            <MenuItem value={'00:30'}>12:30 am</MenuItem>
                            <MenuItem value={'01:30'}>1:00 am</MenuItem>
                            <MenuItem value={'01:30'}>1:30 am</MenuItem>
                            <MenuItem value={'02:30'}>2:00 am</MenuItem>
                            <MenuItem value={'02:30'}>2:30 am</MenuItem>
                            <MenuItem value={'03:30'}>3:00 am</MenuItem>
                            <MenuItem value={'03:30'}>3:30 am</MenuItem>
                            <MenuItem value={'04:00'}>4:00 am</MenuItem>
                            <MenuItem value={'04:30'}>4:30 am</MenuItem>
                            <MenuItem value={'05:00'}>5:00 am</MenuItem>
                            <MenuItem value={'05:30'}>5:30 am</MenuItem>
                        </Select> 
                    </div>

                    <DialogActions>
                        {this.state.loading && <LinearProgress />}
                        <Button variant='outlined' color='secondary' onClick={()=>this.setState({createPartyDialog: false})}>Cancel</Button>
                        <Button variant='outlined' color='primary' onClick={this.createParty}>Create Party</Button>
                    </DialogActions> 
                </Dialog>            
            
                <Dialog style={{padding:'1em'}} onClose={()=>this.setState({createPartyMenuDialog: false, productsForPartyMenu: []})} aria-labelledby="partyMenuDialog" open={this.state.createPartyMenuDialog} fullWidth={true} maxWidth = {'md'}>
                    <DialogTitle id="partyMenuDialog">Create a New Menu for Parties</DialogTitle>

                    <DialogContent>
                    <DialogContentText>Click the arrow to move an existing acitve product to move it to your special party menu</DialogContentText>
                    
                    <TextField
                        autoFocus
                        margin="dense"
                        id="menuName"
                        label="Menu Name"
                        onChange={this.handleChange}
                        style={{marginBottom:'2em', width:'50%'}}
                    />

                    <Grid container spacing={3} style={{backgroundColor:'lightgrey'}}>
                        {/* all products on left */}
                        <Grid item xs={6}>
                            <h2>My Products</h2>
                            {this.state.products && this.state.products.map(product => {
                            if(!(this.state.productsForPartyMenu).includes(product) && product.productActive)
                                return (
                                    <Card key={product.id} style={{marginTop:'.5em', marginBottom: '.5em', padding:"20px", marginBottom:"10px", flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                        <div>
                                            <p style={{fontWeight:'bold'}}>{product.productName}</p>
                                            <p style={{fontStyle:'italic'}}>Category: {product.productCategory}</p>
                                        </div>              
                                        <div style={{flexDirection:"column",display:"flex", marginLeft:10}}>                                        
                                            <Button onClick={()=>{
                                                this.setState({productsForPartyMenu: [...this.state.productsForPartyMenu, product]})
                                            }}variant='outlined'><ArrowRightAltRoundedIcon/></Button>
                                        </div>
                                    </Card>  
                                )
                            })}
                        </Grid>
                        {/* Products added to bucket on right*/}
                        <Grid item xs={6}>
                            <h2>Products On <span style={{fontStyle: 'italic'}}>{this.state.menuName}</span></h2>
                            {this.state.productsForPartyMenu.map((product, index) =>{
                                    return (
                                        <Card key={product.id} style={{padding:"20px", marginBottom:"10px", flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                            <div>
                                                <p style={{fontWeight:'bold'}}>{product.productName}</p>
                                                <p style={{fontStyle:'italic'}}>Category: {product.productCategory}</p>
                                            </div>
                                            <div style={{flexDirection:"row",display:"flex", marginLeft:10}}>                                        
                                                <Button
                                                size="small"
                                                onClick={()=>{
                                                    let productsForPartyMenu = [...this.state.productsForPartyMenu]
                                                    productsForPartyMenu.splice(index, 1)
                                                    this.setState({productsForPartyMenu})
                                                }}>
                                                    <DeleteForever/>
                                                </Button>
                                                    <div style={{flexDirection:"column",display:"flex", marginLeft:10}}>                                        
                                                    <Button
                                                    size="small"
                                                    onClick={()=>{
                                                        this.sortMenuProducts('up', index)}}variant='outlined'>
                                                        <ArrowDropUpRoundedIcon/>
                                                    </Button>
                                                    <Button 
                                                    size="small"
                                                    onClick={()=>{
                                                        this.sortMenuProducts('down', index)}}variant='outlined'>
                                                        <ArrowDropDownRoundedIcon/>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Card>
                                    )                                            
                                } 
                            )}
                        </Grid>
                    </Grid>

                    </DialogContent>

                    <DialogActions>
                        <Button variant='outlined' onClick={()=>this.setState({createPartyMenuDialog: false,  productsForPartyMenu: []})} color="secondary">Cancel</Button>
                        <Button variant='outlined' onClick={this.savePartyMenu} color="primary">Save Party Menu</Button>
                        {this.state.savingPartyMenu && <LinearProgress />}
                    </DialogActions>
                </Dialog>

            <h3 style={{marginTop:'4em'}}>My Parties</h3>
            <Button color='primary' variant='contained' onClick={()=>this.setState({createPartyDialog: true})}>+ New Party</Button>
            {this.state.parties &&
                <Paper style={{margin: '3em', paddingTop:'.5em'}}>
                <Calendar
                        localizer={localizer}
                        views = {['month','week', 'day']}
                        events={this.state.parties}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView='week'
                        min={
                            new Date(
                                this.state.today.getFullYear(), 
                                this.state.today.getMonth(), 
                                this.state.today.getDate(), 
                                10
                            )
                        }
                        style={{ height: 650 }}
                        onSelectEvent={(event)=>this.selectPartyOnCalendar(event)}
                        // onSelectEvent={event => this.setState({viewParty: true, selectedPartyToView: event, menusForParty: event.menus})
                    // }
                    />  
                </Paper>             
            }
           
           {this.state.selectedPartyToView &&
            <Dialog onClose={()=>this.setState({viewParty: false, menusForParty: []})} aria-labelledby="tablePolicy" open={this.state.viewParty} fullWidth={true} maxWidth = {'md'}>
              
                <DialogTitle id="tablePolicy">Update Party: {this.state.selectedPartyToView.name}</DialogTitle>
                {this.state.selectedPartyToView.entryCode && <span style={{paddingLeft:'2em', fontStyle: 'italic', paddingBottom:'.5em'}}>Entry Code:  {this.state.selectedPartyToView.entryCode}</span>}
                {this.state.selectedPartyToView.maxItemCount && <span style={{paddingLeft:'2em', fontStyle: 'italic', paddingBottom:'.5em'}}>Maximum Item Count: {this.state.selectedPartyToView.maxItemCount}</span>}
                {this.state.selectedPartyToView.maxDollarAmount && <span style={{paddingLeft:'2em', fontStyle: 'italic', paddingBottom:'.5em'}}>Maximum Dollar Amount: ${this.state.selectedPartyToView.maxDollarAmount}</span>}

                <Button style={{width:'20%', marginLeft:'2em'}} onClick={()=>this.setState({enableDisableDialog: true})} variant='outlined'>Status: {this.state.selectedPartyToView.enabled ? <span style={{color:'green'}}>Enabled</span>: <span style={{color:'red'}}>Disabled</span>} </Button>
                <DialogContent>
                    <DialogContentText>Click the arrow to move a party menu to the party. Then click update party.</DialogContentText>
                    <Grid container spacing={3} style={{backgroundColor:'lightgrey'}}>
                        {/* all party menus on left */}
                        <Grid item xs={6}>
                            <h2>My Party Menus</h2>
                            {this.state.partyMenus && this.state.partyMenus.map(partyMenu =>
                                <Card key={partyMenu.id} style={{marginTop:'.5em', marginBottom: '.5em', padding:"20px", marginBottom:"10px", flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                    <div>
                                        <p style={{fontWeight:'bold'}}>{partyMenu.name}</p>
                                    </div>              
                                    <div style={{flexDirection:"column",display:"flex", marginLeft:10}}>                                        
                                        <Button onClick={()=>{
                                            this.setState({menusForParty: [...this.state.menusForParty, partyMenu]})
                                        }}variant='outlined'><ArrowRightAltRoundedIcon/></Button>
                                    </div>
                                </Card>  
                            )}
                        </Grid>
                         
                         
                         {/* Party Menus added to bucket on right*/}
                         <Grid item xs={6}>
                            <h2>Menus On Party:<span style={{fontStyle: 'italic'}}>"{this.state.selectedPartyToView.name}"</span></h2>
                            {this.state.menusForParty.map((menu,index) =>
                                
                                <Card key={menu.index} style={{padding:"20px", marginBottom:"10px", flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                    <div>
                                        <p style={{fontWeight:'bold'}}>{menu.name}</p>
                                    </div>


                                    <div style={{flexDirection:"row",display:"flex", marginLeft:10}}>                                        
                                        <Button
                                        size="small"
                                        onClick={()=>{
                                            let menusForParty = [...this.state.menusForParty]
                                            menusForParty.splice(index, 1)
                                            this.setState({menusForParty})
                                        }}>
                                            <DeleteForever/>
                                        </Button>
                                            <div style={{flexDirection:"column",display:"flex", marginLeft:10}}>                                        
                                            <Button
                                            size="small"
                                            onClick={()=>{
                                                this.sortMenusForParty('up', index)}}variant='outlined'>
                                                <ArrowDropUpRoundedIcon/>
                                            </Button>
                                            <Button 
                                            size="small"
                                            onClick={()=>{
                                                this.sortMenusForParty('down', index)}}variant='outlined'>
                                                <ArrowDropDownRoundedIcon/>
                                            </Button>
                                        </div>
                                    </div> 

                                </Card>                                          
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button size='sm' onClick={()=>this.setState({deletePartyDialog:true})}><DeleteForever style={{color:"red"}}/></Button>
                    <Button size='md' variant='outlined' onClick={()=>this.setState({viewParty:false, menusForParty: []})}>Cancel</Button>
                    <Button size='md' color='primary' variant='outlined' onClick={this.updateParty}>Update Party</Button>
                    {this.state.updatingParty && <LinearProgress />}
                </DialogActions>
                
            </Dialog>
            }

            <Dialog onClose={()=>this.setState({enableDisableDialog: false})} open={this.state.enableDisableDialog}>     
                <DialogTitle id="deltePartyTitle">Change Party Status</DialogTitle>
                <DialogActions>
                    <Button variant='outlined' onClick={this.enableParty}>Enable</Button>
                    <Button variant='outlined' onClick={this.disableParty}>Disable</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={()=>this.setState({deletePartyDialog: false})} open={this.state.deletePartyDialog}>     
                <DialogTitle id="deltePartyTitle">Are You sure you want to delete this party?</DialogTitle>
                <p style={{paddingLeft:'1em'}}>All it's data will not be recoverable once deleted</p>
                
                <DialogActions>
                    <Button variant='outlined' onClick={()=>this.setState({deletePartyDialog: false})}>Cancel</Button>
                    <Button variant='outlined' color='primary' onClick={this.deleteParty}>Yes</Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }
}

export default Parties;