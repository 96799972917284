import React, { useState } from 'react';
import firebase from '../Config/firebase'
import {Button,TextField} from '@material-ui/core';
import {Redirect} from 'react-router-dom';
import {NavLink} from 'react-router-dom';

import logo from '../Assets/TangoHorizontal_No_Background.png'
const db = firebase.firestore();

export default function Login() {

    var [email, setEmail] = useState(null);
    var [password, setPassword] = useState(null);
    var [loginSuccess, setLoginSuccess] = useState(false);
    var [loginError, setLoginError] = useState(false);
    var [loading, setLoading] = useState(false);

    const logIn=()=>{
        // console.log('logging in', email, password)
        if(!email){
            return
        }
        if(!password){
            return 
        }

        setLoading(true)
        firebase.auth().signInWithEmailAndPassword(email,password)
        .then((user)=>{
            // console.log('logged in success!', user)
            setLoginSuccess(true)
            setLoading(false)

        }).catch((error)=>{
            console.log('logged in error!', error)
            setLoading(false)
            setLoginError(error.message)
        });

    }
    if(loginSuccess) return <Redirect to='/' />
    return (
        <div style={{paddingTop:'12em',backgroundColor:'white',paddingBottom:'20em',}}>
        <img src={logo} alt="" srcSet="" style={{width:'40%', paddingBottom:'5%'}}/>

            <div>
                <TextField required onChange={e => setEmail(e.target.value)} value={email} id="email" label="email" defaultValue={email} style={{width: '20em', paddingBottom:'1%'}}/>
                <br/>
                <TextField required onChange={e => setPassword(e.target.value)} value={password} id="password"label="password" type="password" autoComplete="current-password" style={{width: '20em', paddingBottom:'1%'}}/>
                <br/>
                <Button onClick={logIn} style={{backgroundColor: 'green', fontWeight:'bold', color:'white', marginTop:'2em'}} variant="contained">Log In</Button>   
                {loginError && <h5 style={{color:'red'}}>{loginError}</h5>}
            </div>
                <br/><br/>
                <NavLink className='' to='./signUp' style={{textDecoration:'none', color:'black'}}>Sign Up</NavLink>


                
        </div>

    )
}
