import React, { Component } from 'react'
import {Redirect} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from  'moment'
import {NavLink} from 'react-router-dom';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import firebase from '../Config/firebase'
import axios from 'axios';
import NumberFormat from 'react-number-format';
import RVStyles from 'react-vis-styles';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  LabelSeries
} from 'react-vis';



const db = firebase.firestore();

const customerTableColumns = [
    { id: 'customerName', label: 'Customer Name', minWidth: 100 },
    { id: 'orderCount', label: 'Order Count', minWidth: 100 },
    {
      id: 'totalSales',
      label: 'Total Sales',
      minWidth: 100,
      align: 'right',
    },
];
const productTableColumns = [
    { id: 'productName', label: 'Product Name', minWidth: 100 },
    { id: 'orderCount', label: 'Order Count', minWidth: 100 },
    {
      id: 'totalSales',
      label: 'Total Sales',
      minWidth: 100,
      align: 'right',
    },
];
const productTypeTableColumns = [
    { id: 'productType', label: 'Product Type', minWidth: 100 },
    {
      id: 'totalSales',
      label: 'Total Sales',
      minWidth: 100,
      align: 'right',
    },
];
class Analytics extends Component {

    state={
        selectedTimeframe: 'Today',//hardcode today for page load display,
				useCanvas: false,
				currentWeekSales: {
                        total_sales_per_week: 0,
                        week: parseInt(moment().format('W'))
                    }

    }
    componentDidMount(){
				console.log("info: ", this.props.user.id);
				this.getWeeklySales(this.props.user.id);
        console.log('loaded page', this.props)


        //when page loads fetch data for today
        let todayStart = new Date(moment().startOf('day').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
        let todayEnd = new Date(moment().endOf('day').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
        // this.getSalesAndOrders(todayStart, todayEnd)
        this.getOrderCount(moment(todayStart).utc().format('YYYY-MM-DD HH:mm:ss'), moment(todayEnd).utc().format('YYYY-MM-DD HH:mm:ss'))
        this.getCustomSalesTips(moment(todayStart).utc().format('YYYY-MM-DD HH:mm:ss'), moment(todayEnd).utc().format('YYYY-MM-DD HH:mm:ss'))
        this.getTopCustomers(moment(todayStart).utc().format('YYYY-MM-DD HH:mm:ss'), moment(todayEnd).utc().format('YYYY-MM-DD HH:mm:ss'))
        this.getTopProducts(moment(todayStart).utc().format('YYYY-MM-DD HH:mm:ss'), moment(todayEnd).utc().format('YYYY-MM-DD HH:mm:ss'))
        this.getProductTypeSales(moment(todayStart).utc().format('YYYY-MM-DD HH:mm:ss'), moment(todayEnd).utc().format('YYYY-MM-DD HH:mm:ss'))
    }

		getDateOfWeek = (w, y) => {
	    let d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
		 	return new Date(y, 0, d);
    }
		
		getWeeklySales = (businessId) =>{
        this.setState({weeklySalesLoading: true})

        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getWeeklySalesBQ',
            data: {
              businessId: businessId
            }
        })
        .then((response) =>{
            console.log('posted to cloud function', response)

            //hardcode 0 sales per wekk
            let weeklySalesChartData = [
                {x: 1, y: 0},
                {x: 2, y: 0},
                {x: 3, y: 0},
                {x: 4, y: 0},
                {x: 5, y: 0},
                {x: 6, y: 0},
                {x: 7, y: 0},
                {x: 8, y: 0},
                {x: 9, y: 0},
                {x: 10, y: 0},
                {x: 11, y: 0},
                {x: 12, y: 0},
                {x: 13, y: 0},
                {x: 14, y: 0},
                {x: 15, y: 0},
                {x: 16, y: 0},
                {x: 17, y: 0},
                {x: 18, y: 0},
                {x: 19, y: 0},
                {x: 20, y: 0},
                {x: 21, y: 0},
                {x: 22, y: 0},
                {x: 23, y: 0},
                {x: 24, y: 0},
                {x: 25, y: 0},
                {x: 26, y: 0},
                {x: 27, y: 0},
                {x: 28, y: 0},
                {x: 29, y: 0},
                {x: 30, y: 0},
                {x: 31, y: 0},
                {x: 32, y: 0},
                {x: 33, y: 0},
                {x: 34, y: 0},
                {x: 35, y: 0},
                {x: 36, y: 0},
                {x: 37, y: 0},
                {x: 38, y: 0},
                {x: 39, y: 0},
                {x: 40, y: 0},
                {x: 41, y: 0},
                {x: 42, y: 0},
                {x: 43, y: 0},
                {x: 44, y: 0},
                {x: 45, y: 0},
                {x: 46, y: 0},
                {x: 47, y: 0},
                {x: 48, y: 0},
                {x: 49, y: 0},
                {x: 50, y: 0},
                {x: 51, y: 0},
                {x: 52, y: 0},
            ];
            //splice weeklySalesChartData with sales data if it is greater than 0 
            for(let i = 0; i<response.data.length; i++){
                if(response.data[i].total_sales_per_week > 0){
                    let plotItem = {x: response.data[i].week, y: response.data[i].total_sales_per_week.toFixed(2)}
                    weeklySalesChartData.splice(response.data[i].week-1, 1, plotItem)
					console.log("print: ", weeklySalesChartData);
                }
               
                if(parseInt(moment().format('W')) -1 === response.data[i].week){
                    console.log('found this weeks item', response.data[i].week)
                    console.log(response.data[i])
                    this.setState({currentWeekSales: response.data[i] })
                }else{
                    let noData ={
                        total_sales_per_week: 0,
                        week: parseInt(moment().format('W'))
                    }

                    this.setState({currentWeekSales: noData})
                }
    
            }
            this.setState({weeklySalesLoading: false, weeklySales: response.data, weeklySalesChartData: weeklySalesChartData}, () => {
					// Convert week to date
					let dateSalesChartData = [];
					for (let i=0; i<this.state.weeklySalesChartData.length; i++) {
							dateSalesChartData.push({x: this.getDateOfWeek(this.state.weeklySalesChartData[i]['x'], 2020).toDateString().replace(" 2020", "").replace("Wed ", ""), y: parseFloat(this.state.weeklySalesChartData[i]['y'])})
					}
					this.setState({dateSalesChartData: dateSalesChartData})
			})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({weeklySalesLoading: false, weeklySales: null})
        })
    }

    handleChangeTimeframe = (e) => {
        console.log('change timeframe', e.target.value)
        this.setState({selectedTimeframe: e.target.value})

        //pass selected timeframe into functions that fetch data
        if(e.target.value === 'Today'){
            let start = new Date(moment().startOf('day').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
            let end = new Date(moment().endOf('day').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));            
            // this.getSalesAndOrders(start, end)
            this.getOrderCount(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getCustomSalesTips(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopCustomers(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopProducts(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getProductTypeSales(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
        }
        if(e.target.value === 'This Week'){
            let start = new Date(moment().startOf('week').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
            let end = new Date(moment().endOf('week').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss')); 
            // this.getSalesAndOrders(start, end)
            this.getOrderCount(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getCustomSalesTips(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopCustomers(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopProducts(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getProductTypeSales(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
        }
        if(e.target.value === 'This Month'){
            let start = new Date(moment().startOf('month').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
            let end = new Date(moment().endOf('month').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss')); 
            // this.getSalesAndOrders(start, end)
            this.getOrderCount(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getCustomSalesTips(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopCustomers(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopProducts(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getProductTypeSales(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
        }
        if(e.target.value === 'This Quarter'){
            let start = new Date(moment().startOf('quarter').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
            let end = new Date(moment().endOf('quarter').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss')); 
            // this.getSalesAndOrders(start, end)
            this.getOrderCount(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getCustomSalesTips(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopCustomers(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopProducts(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getProductTypeSales(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
        }
        if(e.target.value === 'This Year'){
            let start = new Date(moment().startOf('year').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
            let end = new Date(moment().endOf('year').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss')); 
            // this.getSalesAndOrders(start, end)
            this.getOrderCount(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getCustomSalesTips(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopCustomers(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopProducts(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getProductTypeSales(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
        }
        if(e.target.value === 'All'){
            let start = new Date(moment().subtract(30, 'years').format('YYYY-MM-DD HH:mm:ss'));
            let end = new Date(moment().endOf('day').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));  
            // this.getSalesAndOrders(start, end)
            this.getOrderCount(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getCustomSalesTips(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopCustomers(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getTopProducts(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
            this.getProductTypeSales(moment(start).utc().format('YYYY-MM-DD HH:mm:ss'), moment(end).utc().format('YYYY-MM-DD HH:mm:ss'))
        }
    };

    getTopCustomers = (start, end) =>{
        this.setState({topCustomersLoading: true})
        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getTopCustomersBQ',
            data: {
              businessId: this.props.user.id,
              startQuery: start,
              endQuery: end
            }
        })
        .then((response) =>{
            console.log('posted to top customers cloud function!', response.data)
            this.setState({topCustomersLoading: false, topCustomers: response.data})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({topCustomersLoading: false, topCustomers: null})
        })
    }

    getTopProducts = (start, end) =>{
        this.setState({topProductsLoading: true})
        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getTopProductsBQ',
            data: {
              businessId: this.props.user.id,
              startQuery: start,
              endQuery: end
            }
        })
        .then((response) =>{
            console.log('posted to top products cloud function!', response.data)
            this.setState({topProductsLoading: false, topProducts: response.data})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({topProductsLoading: false, topProducts: null})
        })
    }
    getProductTypeSales = (start, end) =>{
        this.setState({productTypeSalesLoading: true})
        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getProductTypeSalesBQ',
            data: {
              businessId: this.props.user.id,
              startQuery: start,
              endQuery: end
            }
        })
        .then((response) =>{
            console.log('posted to product type cloud function!', response.data)
            this.setState({productTypeSalesLoading: false, productTypeSales: response.data})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({productTypeSalesLoading: false, productTypeSales: null})
        })
    }

    getCustomSalesTips = (start, end) =>{
        this.setState({customSalesAndTipsLoading: true})

        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getCustomReportBQ',
            data: {
              start: start,
              end: end,
              businessId: this.props.user.id
            }
        })
        .then((response) =>{
            console.log('posted to GET SALES, TIPS, TAXES', response)
            this.setState({customSalesAndTipsLoading: false, customSalesAndTips: response.data})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({customSalesAndTipsLoading: false, customSalesAndTips: null})
        })
    }

    getOrderCount = (start, end) =>{
        this.setState({orderCountLoadingTrue: true})

        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getOrderCount',
            data: {
              start: start,
              end: end,
              businessId: this.props.user.id
            }
        })
        .then((response) =>{
            console.log('posted to order count', response)
            this.setState({orderCountLoadingTrue: false, orderCount: response.data})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({orderCountLoadingTrue: false, orderCount: null})
        })
    }

    render() {
				const {useCanvas} = this.state;
				const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;
        if(!this.props.user.admin) return <Redirect to='/' />
        return (
            <React.Fragment>
            <Paper style={{paddingLeft: '5em', paddingRight:'5em', paddingTop:'2em', paddingBottom:'3em', marginTop:'6em', marginBottom:'5em', marginLeft: '4em', marginRight:'4em', backgroundColor:'white', color:'black'}}>
            <h2>Analytics</h2>
            <div style={{float:'left'}}>
            {/* <InputLabel id="timeframeSelect">Timeframe</InputLabel> */}
            <Select labelId="timeframeSelect"
            id="timeframe"
            value={this.state.selectedTimeframe}
            onChange={this.handleChangeTimeframe}
            style={{width:'10em'}}
            >
            <MenuItem value={'Today'}>Today</MenuItem>
            <MenuItem value={'This Week'}>This Week</MenuItem>
            <MenuItem value={'This Month'}>This Month</MenuItem>
            <MenuItem value={'This Quarter'}>This Quarter</MenuItem>
            <MenuItem value={'This Year'}>This Year</MenuItem>
            <MenuItem value={'All'}>All</MenuItem>
            </Select>
            </div>

            <br/><br/>
        
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom style={{paddingBottom:"2%"}}>Total Sales</Typography>
                            {this.state.customSalesAndTips && this.state.customSalesAndTips[0] ? 
                                <div>
                                    <div style={{fontSize:"120%", fontWeight:"500", paddingBottom:"2%"}}>
                                        <span>Sales: </span>
                                        <NumberFormat value={(this.state.customSalesAndTips[0].total_sales - this.state.customSalesAndTips[0].total_tips - this.state.customSalesAndTips[0].taxes).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </div>
                                    <div style={{fontSize:"105%", fontWeight:"500", paddingBottom:"2%"}}>
                                        <span>Taxes: </span>
                                        <NumberFormat value={this.state.customSalesAndTips[0].taxes.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </div>
                                    <div style={{fontSize:"105%", fontWeight:"500", paddingBottom:"4%"}}>
                                        <span>Tips: </span> 
                                        <NumberFormat value={this.state.customSalesAndTips[0].total_tips.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </div>
                                    <div style={{fontSize:"135%", fontWeight:"500", paddingBottom:"1.5%"}}>
                                        <span>Revenue: </span>
                                        <NumberFormat value={this.state.customSalesAndTips[0].total_sales.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </div>
                                </div> 
                            : <h3>$0</h3>
                            }
                        </CardContent>
                        
                        <CardActions>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom style={{paddingBottom:"2%"}}>Total Orders</Typography> 
                            {this.state.orderCount && this.state.orderCount[0]? 
                            <div style={{fontSize:"135%", fontWeight:"500", paddingBottom:"1.5%"}}>
                                <NumberFormat value={this.state.orderCount[0].order_count} displayType={'text'} thousandSeparator={true} />
                            </div>
                            : <h3>0</h3>}
                        </CardContent>
                        
                        <CardActions>
                        </CardActions>
                    </Card>
                </Grid> 

                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>Sales by Product Type</Typography>
                            {this.state.productTypeSalesLoading ?
                            <p>Loading...</p>:
                            <span>
                                {this.state.productTypeSales && this.state.productTypeSales.length ? 
                                
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {productTypeTableColumns.map((column) => (
                                            <TableCell key={column.id}align={column.align} style={{ minWidth: column.minWidth, fontWeight:'bold' }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {this.state.productTypeSales.map((productType, index) => (
                                            <TableRow key={index}>                                        
                                                {productType.product_type ? <TableCell component="th" scope="row">{productType.product_type}</TableCell>:
                                                <TableCell component="th" scope="row">unavailable</TableCell>}

                                                {productType.total_sales ? 
                                                <TableCell component="th" scope="row" align="right">
                                                    <NumberFormat style={{fontSize:"130%", fontWeight:"500"}} value={productType.total_sales.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </TableCell>:
                                                <TableCell component="th" scope="row" align="right">unavailable</TableCell>}

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <p>No Data for {this.state.selectedTimeframe}</p>
                                }
                            </span>
                            }       

                        </CardContent>
                        
                        <CardActions>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={6}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>Top Earning Customers</Typography> 
                            <p style={{fontStyle: 'italic', fontSize:'10px'}}>*Total Sales include tips</p>
                            {this.state.topCustomersLoading ?
                            <p>Loading...</p>:
                            <span>
                                {this.state.topCustomers && this.state.topCustomers.length ? 
                                
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {customerTableColumns.map((column) => (
                                            <TableCell key={column.id}align={column.align} style={{ minWidth: column.minWidth, fontWeight:'bold' }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.topCustomers.map((customer) => (
                                            <TableRow key={customer.user_id}>                                        
                                                {customer.user_name? <TableCell component="th" scope="row">{customer.user_name}</TableCell>:
                                                <TableCell component="th" scope="row">unavailable</TableCell>}

                                                <TableCell align="left">{customer.orders}</TableCell>
                                                <TableCell align="right">
                                                    <NumberFormat style={{fontSize:"130%", fontWeight:"500"}} value={customer.total_sales.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <p>No Data for {this.state.selectedTimeframe}</p>
                                }
                            </span>
                            }   
                        </CardContent>
                        
                        <CardActions>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={6}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>Top Earning Products</Typography>  
                            {this.state.topProductsLoading ?
                            <p>Loading...</p>:
                            <span>
                                {this.state.topProducts && this.state.topProducts.length ? 
                                
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {productTableColumns.map((column) => (
                                            <TableCell key={column.id}align={column.align} style={{ minWidth: column.minWidth, fontWeight:'bold' }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.topProducts.map((product) => (
                                            <TableRow key={product.product_id}>                                        
                                                {product.product_name ? <TableCell component="th" scope="row">{product.product_name}</TableCell>:
                                                <TableCell component="th" scope="row">unavailable</TableCell>}

                                                <TableCell align="left">{product.orders}</TableCell>

                                                {product.total_sales ? <TableCell component="th" scope="row" align="right">
                                                    <NumberFormat style={{fontSize:"130%", fontWeight:"500"}} value={product.total_sales.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </TableCell>:
                                                <TableCell component="th" scope="row" align="right">unavailable</TableCell>}

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <p style={{paddingTop:"2%"}}>No Data for {this.state.selectedTimeframe}</p>
                                }
                            </span>
                            }                           
                            </CardContent>
                        
                        <CardActions>
                        </CardActions>
                    </Card>
                </Grid>

            </Grid>

            <br/><br/>
            <p style={{fontStyle: 'italic', fontSize:'10px'}}>*Weeks starting on Sundays at 4am</p>
            <p style={{fontStyle: 'italic', fontSize:'10px'}}>*Days starting at 4am</p>

            </Paper>
            <div style={{marginLeft: '4em', marginRight:'4em', marginBottom:'10em'}}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                {/* <Card style={{paddingLeft: '5em', paddingRight:'5em', paddingTop:'2em', paddingBottom:'3em', marginTop:'6em', marginBottom:'5em', marginLeft: '4em', marginRight:'4em', backgroundColor:'white', color:'black'}}> */}
                    <div style={{fontSize:"130%", fontWeight:"500", paddingTop:"1%"}}>
                            Weekly Sales Performance (Full Year) 
                        </div>
                        <div style={{fontSize:"105%", fontWeight:"500", paddingBottom:"2%", paddingTop:"1%"}}>
                            Current Week:  
                            <NumberFormat value={this.state.currentWeekSales.total_sales_per_week.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </div>
                        <RVStyles />
                        <XYPlot width={window.innerWidth * 0.9} height={window.innerHeight * 0.4} margin={{left: 50}} xType="ordinal">
                        <VerticalGridLines/>
                        <HorizontalGridLines />
                        <XAxis title="Week #" tickLabelAngle={-45} />
                        <YAxis title="Sales $" />
                        <BarSeries /*data={this.state.weeklysaleschartdata}*/ data={this.state.dateSalesChartData} />
                                {/* console.log("Other Data: ", this.state.weeklySalesChartData) */}
                                {/* console.log("Data: ", this.state.dateSalesChartData) */}
                        </XYPlot>
                    </CardContent>
			    </Card>
            </Grid>
            </div>
            </React.Fragment>
        )
    }
}

export default Analytics
