import React, { Component } from 'react'

export default class NotFound extends Component {
    render() {
        return (
            <div style={{textAlign: 'center', margin:'6em', color:'black'}}>
                <h3>404</h3>
                <p>We're sorry, but the page you requested in not available.</p>
            </div>
        )
    }
}
