import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AppBar} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import {Favorite, Subscriptions, OndemandVideo,
LocalLibrary, Photo,TrendingUp, MusicNote, LocationOn, 
Description, Home, LocalCafe, School, LocalBar, Loyalty, BusinessCenter, Storefront, Restore, LocalGroceryStore} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Login from './Auth/Login'
import firebase from './Config/firebase'
const db = firebase.firestore();


class Landing extends Component {

    useStyles = makeStyles(theme => ({
        root: {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          overflow: 'hidden',
        },
    }));

    componentDidMount(){
        console.log('loaded landing page', this.props)
    }



    render() {

        return (
            <div>
                <Login/>
            </div>
        )
    }
}

export default Landing