import React, { Component } from 'react'
import Button from '@material-ui/core/Button';

import axios from 'axios';
import firebase from '../Config/firebase'
import logo from '../Assets/TangoappStoreIcon.jpg'
const db = firebase.firestore();

const getStripeDetails = (someConfirmationCode) =>{
    console.log(firebase.auth().currentUser.uid, someConfirmationCode)
    axios({
        method: 'post',
        url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/saveStripeConnectDetails',
        data: {
            businessId: firebase.auth().currentUser.uid,
            code: someConfirmationCode,
        }
    })
    .then((response) =>{
          console.log('posted to cloud function', response)
    })
}

export default class ConfirmStripeLanding extends Component {

    componentDidMount(){
   
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                var url = window.location.href
                console.log(user)
                console.log(url.includes("code="))
                if (url.includes("code=") === true){
                    var StripeCode = url.split('code=').pop();
                    console.log( 'includes code in url', StripeCode );
                    getStripeDetails(StripeCode)
                }
                // getStripeDetails('ac_H6zmpSxoezsmEab43cu3KgyH7QVsNtQh')
              } else {
                  console.log('NO USER');
              }
        })
      }

    render() {
        return (
            <div>
                <div style ={{margin: '10%', color:'black'}}>
                <h3>Thanks For Connecting With Stripe!</h3>
                <br/>
                <h5>You will now receive direct payouts when you sell your products with Tango</h5>
                <br/><br/>
                 <Button variant="contained" node="a" href="./">Home</Button>
                </div>

                {/* <img style={{width:'10%',  borderRadius:'3px', position:'fixed', bottom:'0'}} src={logo} alt="" srcSet=""/> */}
            </div>
        )
    }
}

