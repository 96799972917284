import React, { Component } from 'react'
import firebase from '../../Config/firebase'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Delete';
import CardMedia from '@material-ui/core/CardMedia';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import Button from '@material-ui/core/Button';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import RemoveShoppingCartRoundedIcon from '@material-ui/icons/RemoveShoppingCartRounded';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import S3FileUpload from 'react-s3';
import TextField from '@material-ui/core/TextField';
import SwapVertRoundedIcon from '@material-ui/icons/SwapVertRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import {NavLink} from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import CreateMenu from './CreateMenu'
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import FastfoodRoundedIcon from '@material-ui/icons/FastfoodRounded';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import DeleteForever from "@material-ui/icons/DeleteForever"
import SnoozeRoundedIcon from '@material-ui/icons/SnoozeRounded';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
const db = firebase.firestore();

export default class EditProducts extends Component {
    state ={
        showConfirmation:false,
        menus:[],
        menuProducts:[]
    }

    componentDidMount(){
        this.setState({loading:true})
        db.collection("products").where("businessId", "==", this.props.user.id)
        .get()
        .then((querySnapshot) =>{
            let productsArray = []

            querySnapshot.forEach((doc)=> {
                let product = doc.data()
                product.id = doc.id
                productsArray.push(product)
                // console.log('found product set array', productsArray)
            });
            productsArray.sort((a, b) => (a.productName > b.productName) ? 1 : -1)
            this.setState({loading:false, products: productsArray})
        })
        .catch((error)=>{
            console.log("Error getting documents: ", error);
        });

        if(this.props.user.data.menus){
            let menus = this.props.user.data.menus
            //sort menus on page load
            menus.sort((a, b)=> {
                if(a.index && b.index)
                return a.index - b.index
            });
            this.setState({
                menus
            })
        }
        
    }

    openOptions = (product, value) =>{
        console.log('edit this', product, value)
        this.setState({open:true, selectedProduct: product, fieldToEdit: value})
    }
    handleClose = () =>{
        this.setState({
            menuProducts:[],
            selectedMenu:null,  
            openSortMenuDialog: false,
            openAddProductsDialog: false,
            open:false, 
            selectedProduct: null, 
            fieldToEdit: null,
            editMenu: false, 
            newMenuName:null,
            mondayStart:null, mondayEnd:null,
            tuesdayStart:null,tuesdayEnd:null,
            wednesdayStart:null, wednesdayEnd:null,
            thursdayStart:null, thursdayEnd:null,
            fridayStart:null, fridayEnd:null,
            saturdayStart:null, saturdayEnd:null,
            sundayStart:null, sundayEnd:null,
        })
    }

    deleteProduct = (id) =>{
        var businessRef = db.collection("businesses").doc(this.props.user.id);
        let menus = this.props.user.data.menus
        console.log('clicked delete product', id)

        return db.collection("products").doc(id).delete()
        .then(()=>{
            //after delete item from menus
            for (let i = 0; i<menus.length; i++){
                let products = menus[i].products
                for(let j = 0; j< products.length; j++){
                    // console.log('product?', products[j])
                    if(id === products[j].id){
                        console.log('found match', products[j])
                        menus[i].products.splice(j, 1)
                    }
                }
            }
            console.log("Document successfully deleted!");
            businessRef.update({menus: menus})
            .then(()=> {window.location.reload()})
            .catch((error)=> {
                console.error("Error updating document: ", error);
                this.setState({updateProductLoading: false})
                alert('something went wrong')
            });
            this.setState({open:false, selectedProduct: null})
        }).catch((error)=>{
            this.setState({open:false, selectedProduct: null})
            console.error("Error removing document: ", error);
        });
    }

    setInactive = (id) =>{
        console.log('clicked setInactive product', id)
        var product = db.collection("products").doc(id);
        var businessRef = db.collection("businesses").doc(this.props.user.id);
        let menus = this.props.user.data.menus

        return product.update({productActive: false})
        .then(()=> {
            console.log("Document successfully updated!");
            //remove from menus after set to inactive
            for (let i = 0; i<menus.length; i++){
                if(menus[i].products && menus[i].products.length){
                    let products = menus[i].products
                    for(let j = 0; j< products.length; j++){
                        // console.log('product?', products[j])
                        if(id === products[j].id){
                            console.log('found match', products[j])
                            menus[i].products[j].productActive = false
                        }
                    }
                }
            }
            console.log('changed product to inacte on menu?', menus)
            businessRef.update({menus: menus})
            .then(()=> {window.location.reload()})
            .catch((error)=> {
                console.error("Error updating document: ", error);
                this.setState({updateProductLoading: false})
                alert('something went wrong')
            });
            this.setState({open:false, selectedProduct: null})
        })
        .catch((error)=> {
            this.setState({open:false, selectedProduct: null})
            console.error("Error updating document: ", error);
        });
    }

    setActive = (id) =>{
        console.log('clicked set active product', id)
        var product = db.collection("products").doc(id);
        var businessRef = db.collection("businesses").doc(this.props.user.id);
        let menus = this.props.user.data.menus

        return product.update({productActive: true})
        .then(()=> {
            console.log("Document successfully updated!");
            //remove from menus after set to inactive
            for (let i = 0; i<menus.length; i++){
                if(menus[i].products && menus[i].products.length){
                    let products = menus[i].products
                    for(let j = 0; j< products.length; j++){
                        // console.log('product?', products[j])
                        if(id === products[j].id){
                            console.log('found match', products[j])
                            menus[i].products[j].productActive = true
                        }
                    }
                }
            }
            
            businessRef.update({menus: menus})
            .then(()=> {window.location.reload()})
            .catch((error)=> {
                console.error("Error updating document: ", error);
                this.setState({updateProductLoading: false})
                alert('something went wrong')
            });
            this.setState({open:false, selectedProduct: null})
        })
        .catch((error)=> {
            this.setState({open:false, selectedProduct: null})
            console.error("Error updating document: ", error);
        });
    }
  

    /////////////////////
    // updateProductIndex = () =>{
    //     console.log('clicked update product', this.state)
    //     let newIndex = parseInt(this.state.newProductIndex)
    //     console.log('clicked update product', this.state.selectedProduct.id, newIndex)

    //     return db.collection("products").doc(this.state.selectedProduct.id)
    //     .update({index: newIndex})
    //     .then(()=> {
    //         console.log('success')
    //         this.setState({open: false})
    //         // window.location.reload()
    //     })
    //     .catch((error)=> {
    //         console.error("Error updating document: ", error);
    //         this.setState({updateProductLoading: false})
    //         alert('something went wrong')
    //     });
    // }
    /////////////////////

    updateProduct = () => {
        var businessRef = db.collection("businesses").doc(this.props.user.id);
        let menus = this.props.user.data.menus
        console.log('clicked update product', this.state, menus)
        this.setState({updateProductLoading: true})

        if(this.state.fieldToEdit === 'name'){  
            return db.collection("products").doc(this.state.selectedProduct.id)
            .update({productName: this.state.newProductName})
            .then(()=> {

                for (let i = 0; i<menus.length; i++){
                    if(menus[i].products && menus[i].products.length){
                        let products = menus[i].products
                        for(let j = 0; j< products.length; j++){
                            // console.log('product?', products[j])
                            if(this.state.selectedProduct.id === products[j].id){
                                console.log('found match', products[j])
                                menus[i].products[j].productName = this.state.newProductName
                            }
                        }
                    }
                }
                businessRef.update({menus: menus})
                .then(()=> {window.location.reload()})
                .catch((error)=> {
                    console.error("Error updating document: ", error);
                    this.setState({updateProductLoading: false})
                    alert('something went wrong')
                });
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
                this.setState({updateProductLoading: false})
                alert('something went wrong')
            });
        }

        if(this.state.fieldToEdit === 'description'){
            return db.collection("products").doc(this.state.selectedProduct.id)
            .update({productDescription: this.state.newProductDescription})
            .then(()=> {
                for (let i = 0; i<menus.length; i++){
                    if(menus[i].products && menus[i].products.length){
                        let products = menus[i].products
                        for(let j = 0; j< products.length; j++){
                            // console.log('product?', products[j])
                            if(this.state.selectedProduct.id === products[j].id){
                                console.log('found match', products[j])
                                menus[i].products[j].productDescription = this.state.newProductDescription
                            }
                        }
                    }
                }
                businessRef.update({menus: menus})
                .then(()=> {window.location.reload()})
                .catch((error)=> {
                    console.error("Error updating document: ", error);
                    this.setState({updateProductLoading: false})
                    alert('something went wrong')
                });
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
                this.setState({updateProductLoading: false})
                alert('something went wrong')
            });
        }
        if(this.state.fieldToEdit === 'category'){
            return db.collection("products").doc(this.state.selectedProduct.id)
            .update({productCategory: this.state.newProductCategory})
            .then(()=> {
                for (let i = 0; i<menus.length; i++){
                    if(menus[i].products && menus[i].products.length){
                        for(let j = 0; j<menus[i].products.length; j++){
                            console.log('product?', menus[i].products[j])
                            if(this.state.selectedProduct.id === menus[i].products[j].id){
                                console.log('found match', menus[i].products[j])
                                menus[i].products[j].productCategory = this.state.newProductCategory
                            }
                        }
                    }
                }
                businessRef.update({menus: menus})
                .then(()=> {window.location.reload()})
                .catch((error)=> {
                    console.error("Error updating document: ", error);
                    this.setState({updateProductLoading: false})
                    alert('something went wrong')
                });
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
                this.setState({updateProductLoading: false})
                alert('something went wrong')
            });     
        }
        if(this.state.fieldToEdit === 'price'){
            return db.collection("products").doc(this.state.selectedProduct.id)
            .update({productPrice: parseFloat(this.state.newProductPrice)})
            .then(()=> {
                for (let i = 0; i<menus.length; i++){
                    if(menus[i].products && menus[i].products.length){
                        let products = menus[i].products
                        for(let j = 0; j< products.length; j++){
                            // console.log('product?', products[j])
                            if(this.state.selectedProduct.id === products[j].id){
                                console.log('found match', products[j])
                                menus[i].products[j].productPrice =  parseFloat(this.state.newProductPrice)
                            }
                        }
                    }
                }
                businessRef.update({menus: menus})
                .then(()=> {window.location.reload()})
                .catch((error)=> {
                    console.error("Error updating document: ", error);
                    this.setState({updateProductLoading: false})
                    alert('something went wrong')
                });
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
                this.setState({updateProductLoading: false})
                alert('something went wrong')
            });
        }
    }
    handleChange = (e) =>{
        this.setState({[e.target.id]: e.target.value})
    }

    handleSelectTime = (e) => {
        this.setState({[e.target.name]: e.target.value})
        console.log(this.state)
    }

    //no longer saving menus this way
    // saveProductToMenu = (menu) =>{
    //     this.setState({updateProductLoading: true})
    //     console.log('save product to menu', menu, this.state.selectedProduct)

    //     var productRef = db.collection("products").doc(this.state.selectedProduct.id);
    //     productRef.update({  menu: menu })
    //     .then((docRef) =>{
    //         console.log("updated ");
    //         window.location.reload()
    //     })
    //     .catch((error)=> {
    //         console.error("Error update document: ");
    //         this.setState({updateProductLoading:false})
    //     });
    // }

    deleteMenu = (menu)=>{
        this.setState({updateProductLoading: true})
        console.log('delete menu', menu, this.props.user.data.menus)
        for(let i = 0; i < this.props.user.data.menus.length; i++){
            if (menu.name === this.props.user.data.menus[i].name){
                console.log('found menu to delete', this.props.user.data.menus[i])
                this.props.user.data.menus.splice(i, 1)
                break;
            }
        }

        var businessRef = db.collection("businesses").doc(menu.businessId);
        businessRef.update({menus: this.props.user.data.menus})
        .then(()=> {window.location.reload()})
        .catch((error)=> {
            console.error("Error updating document: ", error);
            this.setState({updateProductLoading: false})
            alert('something went wrong')
        });
    }

    updateMenu = (menu) =>{
        this.setState({updateProductLoading: true})

        console.log('selected menu', this.state.selectedMenu)
        console.log('old menus', this.state.menus)
        console.log('clicked save update menu', menu, this.state.menuIndex)
        let newMenu = {
            name: menu.name,
            hours: menu.hours,
            businessId: menu.businessId,
            index: menu.index,
            products:menu.products?menu.products:[]
        }
        if(this.state.newMenuName){
            newMenu.name = this.state.newMenuName
        }
        if(this.state.sundayStart && this.state.sundayEnd){
            newMenu.hours.sunday.start = this.state.sundayStart
            newMenu.hours.sunday.end = this.state.sundayEnd
        }
        if(this.state.mondayStart && this.state.mondayEnd){
            newMenu.hours.monday.start = this.state.mondayStart
            newMenu.hours.monday.end = this.state.mondayEnd
        }
        if(this.state.tuesdayStart && this.state.tuesdayEnd){
            newMenu.hours.tuesday.start = this.state.tuesdayStart
            newMenu.hours.tuesday.end = this.state.tuesdayEnd
        }
        if(this.state.wednesdayStart && this.state.wednesdayEnd){
            newMenu.hours.wednesday.start = this.state.wednesdayStart
            newMenu.hours.wednesday.end = this.state.wednesdayEnd
        }
        if(this.state.thursdayStart && this.state.thursdayEnd){
            newMenu.hours.thursday.start = this.state.thursdayStart
            newMenu.hours.thursday.end = this.state.thursdayEnd
        }
        if(this.state.fridayStart && this.state.fridayEnd){
            newMenu.hours.friday.start = this.state.fridayStart
            newMenu.hours.friday.end = this.state.fridayEnd
        }
        if(this.state.saturdayStart && this.state.saturdayEnd){
            newMenu.hours.saturday.start = this.state.saturdayStart
            newMenu.hours.saturday.end = this.state.saturdayEnd
        }

        let newMenus = [...this.state.menus]
        newMenus[menu.index] = newMenu

        var businessRef = db.collection("businesses").doc(menu.businessId);
        
        //then add new updated menu
        businessRef.update({menus: newMenus})
        .then(()=> {
            console.log('updated menus')
            window.location.reload()
        })
        .catch((error)=> {
            console.error("Error updating document: ", error);
            this.setState({updateProductLoading: false})
            alert('something went wrong')
        });
    }

    changePhoto = ({target})=>{
        // return console.log('change this image', this.state.selectedProductImage)
        let imageName = 'update-' + JSON.stringify( Math.floor(1000 + Math.random() * 9000)) + '-productImage.png'
        let newimageUrl = 'https://tango-pwa.s3.us-east-2.amazonaws.com/businesses/'+ this.props.user.id +
        '/products/' + this.state.selectedProductImage + '/' + imageName
        
        console.log('???', imageName, newimageUrl)

        this.setState({loadingImageUpload: true})
        let image = new File([target.files[0]], imageName, {type: 'image/png'});
        console.log('image upload', image)

        const productImageConfig = {
            bucketName: 'tango-pwa',
            dirName: 'businesses/' + this.props.user.id + '/products/' + this.state.selectedProductImage,
            region: 'us-east-2',
            accessKeyId: process.env.REACT_APP_AMAZON_S3_ACCESSKEYID,
            secretAccessKey: process.env.REACT_APP_AMAZON_S3_SECRETACCESSKEY
        }

        S3FileUpload.uploadFile(image, productImageConfig).then((data) => {            
            var productRef = db.collection("products").doc(this.state.selectedProductImage);

            return productRef.update({productImageUrl: newimageUrl})
            .then(()=> {

                //after upload image - update each menu with new product image
                for (let i = 0; i<this.props.user.data.menus.length; i++){
                    if(this.props.user.data.menus[i].products && this.props.user.data.menus[i].products.length){
                        let products = this.props.user.data.menus[i].products
                        for(let j = 0; j< products.length; j++){
                            // console.log('product?', products[j])
                            if(this.state.selectedProductImage === products[j].id){
                                console.log('found match', products[j])
                                this.props.user.data.menus[i].products[j].productImageUrl = newimageUrl
                            }
                        }
                    }
                }
                console.log("Document successfully deleted!");
                var businessRef = db.collection("businesses").doc(this.props.user.id);
                businessRef.update({menus: this.props.user.data.menus})
                .then(()=> {window.location.reload()})
                .catch((error)=> {
                    console.error("Error updating document: ", error);
                    this.setState({updateProductLoading: false})
                    alert('something went wrong')
                });
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
                this.setState({loadingImageUpload: false}) 
                alert('something went wrong')
            });
        })
    }

    handleAvailablity = (day, status) =>{
        let selectedMenu = {...this.state.selectedMenu}
        selectedMenu.hours[day]['available'] = status
        if(!status){
            selectedMenu.hours[day]['start'] = "00:00"
            selectedMenu.hours[day]['end'] = "24:00"   
            switch(day){
                case "sunday":
                this.setState({
                    sundayStart:"00:00",
                    sundayEnd:"24:00"
                })
                case "monday":
                this.setState({
                    mondayStart:"00:00",
                    mondayEnd:"24:00"
                })
                case "tuesday":
                this.setState({
                    tuesdayStart:"00:00",
                    tuesdayEnd:"24:00"
                })
                case "wednesday":
                this.setState({
                    wednesdayStart:"00:00",
                    wednesdayEnd:"24:00"
                })
                case "thursday":
                this.setState({
                    thursdayStart:"00:00",
                    thursdayEnd:"24:00"
                })
                case "friday":
                this.setState({
                    fridayStart:"00:00",
                    fridayEnd:"24:00"
                })
                case "saturday":
                this.setState({
                    saturdayStart:"00:00",
                    saturdayEnd:"24:00"
                })
            }
        }
        this.setState({selectedMenu})   
    }

    sortMenu = (direction, index)=>{
        let menus = [...this.state.menus]
        if(direction === 'down' && index === (this.state.menus.length-1)){
            return console.log('menu is already at bottom so dont adjust')
        }
        else if(direction === 'up' && index === 0){
            return console.log('menu is already at top so dont adjust')
        }else{
            if(direction === "up"){
                let existedMenu = menus[index - 1]
                let currentMenu = menus[index]
                menus[index - 1] = {...currentMenu, index: index-1}
                menus[index] = {...existedMenu, index}
            }else{
                let existedMenu = menus[index + 1]
                let currentMenu = menus[index]
                menus[index + 1] = {...currentMenu, index:index+1}
                menus[index] = {...existedMenu, index}
            }
            this.setState({menus})
        }
    }

    sortMenuProducts = (direction, index)=>{
        let menuProducts = [...this.state.menuProducts]
        if(direction === 'down' && index === (menuProducts.length-1)){
            return console.log('menu is already at bottom so dont adjust')
        }
        else if(direction === 'up' && index === 0){
            return console.log('menu is already at top so dont adjust')
        }else{
            if(direction === "up"){
                let existedMenu = menuProducts[index - 1]
                let currentMenu = menuProducts[index]
                menuProducts[index - 1] = currentMenu
                menuProducts[index] = existedMenu
            }else{
                let existedMenu = menuProducts[index + 1]
                let currentMenu = menuProducts[index]
                menuProducts[index + 1] = currentMenu
                menuProducts[index] = existedMenu
            }
            menuProducts.filter(e => e)

            this.setState({menuProducts})
        }
    }
    saveMenuProductsOrder = () => {
        this.setState({updateProductLoading: true})

        const {menus, selectedMenu, menuProducts} = this.state
        const selectedMenuIndex =  menus.findIndex((menu)=> menu.name == selectedMenu.name)
        menus[selectedMenuIndex]["products"] =  menuProducts
        this.setState({
            menus
        })
        var businessRef = db.collection("businesses").doc(menus[0].businessId);

        //then add new updated menu
        businessRef.update({menus})
        .then(()=> {
            console.log('add new menu')
            window.location.reload()
        })
        .catch((error)=> {
            console.error("Error updating document: ", error);
            this.setState({updateProductLoading: false})
            alert('something went wrong')
        });
    }
    saveMenuOrder = () =>{
        this.setState({loadingSaveMenuOrder: true})

        const {menus} = this.state
        var businessRef = db.collection("businesses").doc(menus[0].businessId);

        //then add new updated menu
        businessRef.update({menus})
        .then(()=> {
            console.log('add new menu')
            window.location.reload()
        })
        .catch((error)=> {
            console.error("Error updating document: ", error);
            this.setState({loadingSaveMenuOrder: false})
            alert('something went wrong')
        });
    }
    render() {
        if(this.state.loading) return <div style={{marginTop:'10em'}}><CircularProgress color="secondary"/></div>

        return (
            <div style={{paddingTop: '5em', marginBottom:'3em'}}>
                
                <div style={{marginRight:'3em'}}>
                    <NavLink className='' to='./products' style={{textDecoration:'none', float:'right'}}>
                        <Button variant='contained' color="primary" endIcon={<ArrowForwardRoundedIcon />}>
                            Create a new product
                        </Button>
                    </NavLink>
                </div>

                <div style={{marginTop:'5em', marginLeft: '4em', marginRight:'4em', marginBottom:'10em', color:'black'}}>

                    {this.state.selectedMenu &&
                    <Dialog onClose={this.handleClose} aria-labelledby="customer" open={this.state.editMenu} style={{padding:'1em'}} id="edit-menu-dialog">
                        <DialogTitle id="customer">Edit Menu</DialogTitle>
                            <div style={{padding:'1em'}}>
                                <div>
                                    <InputLabel htmlFor="newMenuName">Menu Name</InputLabel>
                                    <Input id="newMenuName" onChange={this.handleChange} value={this.state.newMenuName}/>
                                </div>

                                <div>
                                    <p>*Please select both start and end times for all days. Leaving a time unselected will result in the menu saving as always available for that day of the week.</p>
                                    <Grid container spacing={1}>
                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}} color="textSecondary">Sunday</Typography>

                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='sundayStart'  id="sundayStart"
                                                    value={this.state.sundayStart} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='sundayEnd'  id="sundayEnd"
                                                    value={this.state.sundayEnd} onChange={this.handleSelectTime} >
                                                     <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.selectedMenu.hours.sunday.available}
                                                    onChange={(e)=>{this.handleAvailablity("sunday",e.target.checked)}}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />        
                                        </CardContent>
                                        </Card>
                                    </Grid> 

                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Monday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='mondayStart' id="mondayStart"
                                                    value={this.state.mondayStart} onChange={this.handleSelectTime} >
                                                   <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='mondayEnd' id="mondayEnd"
                                                    value={this.state.mondayEnd} onChange={this.handleSelectTime} >
                                                     <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.selectedMenu.hours.monday.available}
                                                    onChange={(e)=>{this.handleAvailablity("monday",e.target.checked)}}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                        </CardContent>
                                        </Card>
                                    </Grid> 
                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Tuesday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='tuesdayStart' id="tuesdayStart"
                                                    value={this.state.tuesdayStart} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                    <Select style={{width:'100%'}} name='tuesdayEnd' id="tuesdayEnd"
                                                    value={this.state.tuesdayEnd} onChange={this.handleSelectTime} >
                                                     <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.selectedMenu.hours.tuesday.available}
                                                    onChange={(e)=>{this.handleAvailablity("tuesday",e.target.checked)}}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                        </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Wednesday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='wednesdayStart' id="wednesdayStart"
                                                    value={this.state.wednesdayStart} onChange={this.handleSelectTime} >
                                                     <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='wednesdayEnd' id="wednesdayEnd"
                                                    value={this.state.wednesdayEnd} onChange={this.handleSelectTime} >
                                                     <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.selectedMenu.hours.wednesday.available}
                                                    onChange={(e)=>{this.handleAvailablity("wednesday",e.target.checked)}}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                        </CardContent>
                                        </Card>
                                    </Grid> 

                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Thursday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='thursdayStart' id="thursdayStart"
                                                    value={this.state.thursdayStart} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='thursdayEnd' id="thursdayEnd"
                                                    value={this.state.thursdayEnd} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.selectedMenu.hours.thursday.available}
                                                    onChange={(e)=>{this.handleAvailablity("thursday",e.target.checked)}}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                        </CardContent>
                                        </Card>
                                    </Grid> 

                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Friday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='fridayStart' id="fridayStart"
                                                    value={this.state.fridayStart} onChange={this.handleSelectTime} >
                                                     <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='fridayEnd' id="fridayEnd"
                                                    value={this.state.fridayEnd} onChange={this.handleSelectTime} >
                                                       <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.selectedMenu.hours.friday.available}
                                                    onChange={(e)=>{this.handleAvailablity("friday",e.target.checked)}}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                        </CardContent>
                                        </Card>
                                    </Grid> 

                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Saturday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='saturdayStart' id="saturdayStart"
                                                    value={this.state.saturdayStart} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='saturdayEnd' id="saturdayEnd"
                                                    value={this.state.saturdayEnd} onChange={this.handleSelectTime} >
                                                <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select>
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.selectedMenu.hours.saturday.available}
                                                    onChange={(e)=>{this.handleAvailablity("saturday",e.target.checked)}}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            /> 
                                            </CardContent>
                                            </Card>
                                        </Grid> 
                                    </Grid>
                                </div>
                                {this.state.updateProductLoading && <LinearProgress/>}
                            </div>
                            <br/>

                        <DialogActions >
                            <Button variant='contained' onClick={()=>this.updateMenu(this.state.selectedMenu)} color="primary">Save Update</Button>
                            <Button style={{marginLeft:'2em'}} variant='contained' onClick={()=>this.deleteMenu(this.state.selectedMenu)} color="secondary">Delete Menu</Button>
                        </DialogActions>

                    </Dialog>
                    }

                    {/* sort menu dialog */}
                    {this.state.menus &&
                    <Dialog onClose={this.handleClose} open={this.state.openSortMenuDialog} style={{padding:'1em'}}>
                        <DialogTitle>
                            Sort Menus
                            {this.state.loadingSaveMenuOrder && <LinearProgress/>}
                        </DialogTitle>
                        <DialogContent>
                        {this.state.menus.map((menu, index) => (
                            <Card key={index} style={{padding:"20px", marginBottom:"10px", flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            {menu.name}
                                <div style={{flexDirection:"column",display:"flex", marginLeft:10}}>                                        
                                    <Button
                                    size="small"
                                    onClick={()=>this.sortMenu('up', index)}variant='outlined'><ArrowDropUpRoundedIcon/></Button>
                                    <Button onClick={()=>this.sortMenu('down', index)}variant='outlined'><ArrowDropDownRoundedIcon/></Button>
                                </div>
                            </Card>
                        ))}
                        </DialogContent>
                        <DialogActions >
                            <Button 
                            size="small"
                            variant='contained' onClick={()=>this.saveMenuOrder()} color="primary">Save Menu Order</Button>
                        </DialogActions>
                    </Dialog>
                    }

                    {/* add products dialog */}
                    {this.state.products &&
                    <Dialog onClose={this.handleClose} open={this.state.openAddProductsDialog} style={{padding:'1em'}} fullWidth={true} maxWidth = {'md'}>
                        <DialogTitle>
                            Add Products to {this.state.selectedMenu? this.state.selectedMenu.name:""}
                            <span style={{fontSize:'.8em',fontStyle:'italic', float:'right'}}>*Products marked as unavailable will not be listed</span>
                            {this.state.updateProductLoading && <LinearProgress/>}    
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3} style={{backgroundColor:'lightgrey'}}>
                                <Grid item xs={6}>
                                    <h2>My Products</h2>
                                    {this.state.selectedMenu && this.state.products.map((product, index) => {
                                    if(!(this.state.menuProducts).includes(product) && product.productActive)
                                        return (
                                            <Card key={index} style={{backgroundColor: !this.state.selectedMenu.products || (this.state.selectedMenu.products && !this.state.selectedMenu.products.some(selectedMenuProduct => selectedMenuProduct.id === product.id))? 'white' : 'transparent', padding:"20px", marginBottom:"10px", flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                <div>
                                                    <p style={{fontWeight:'bold'}}>{product.productName}</p>
                                                    <p style={{fontStyle:'italic'}}>Category: {product.productCategory}</p>
                                                </div>              
                                                <div style={{flexDirection:"column",display:"flex", marginLeft:10}}>                                        
                                                    <Button onClick={()=>{
                                                        this.setState({menuProducts: [...this.state.menuProducts, product]})
                                                    }}variant='outlined' disabled={this.state.selectedMenu.products && this.state.selectedMenu.products.some(selectedMenuProduct => selectedMenuProduct.id === product.id)}><ArrowRightAltRoundedIcon/></Button>
                                                </div>
                                            </Card>  
                                        )
                                    })}
                                </Grid>
                                <Grid item xs={6}>
                                    <h2>Products On {this.state.selectedMenu? this.state.selectedMenu.name:""}</h2>
                                    {this.state.menuProducts.map((product, index) =>{
                                            return (
                                                <Card key={index} style={{padding:"20px", marginBottom:"10px", flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                    <div>
                                                        <p style={{fontWeight:'bold'}}>{product.productName}</p>
                                                        <p style={{fontStyle:'italic'}}>Category: {product.productCategory}</p>
                                                    </div>
                                                    <div style={{flexDirection:"row",display:"flex", marginLeft:10}}>                                        
                                                        <Button
                                                        size="small"
                                                        onClick={()=>{
                                                            let menuProducts = [...this.state.menuProducts]
                                                            menuProducts.splice(index, 1)
                                                            this.setState({menuProducts})
                                                        }}>
                                                            <DeleteForever/>
                                                        </Button>
                                                         <div style={{flexDirection:"column",display:"flex", marginLeft:10}}>                                        
                                                            <Button
                                                            size="small"
                                                            onClick={()=>{
                                                                this.sortMenuProducts('up', index)}}variant='outlined'>
                                                                <ArrowDropUpRoundedIcon/>
                                                            </Button>
                                                            <Button 
                                                            size="small"
                                                            onClick={()=>{
                                                                this.sortMenuProducts('down', index)}}variant='outlined'>
                                                                <ArrowDropDownRoundedIcon/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )                                            
                                        } 
                                    )}
                                </Grid>
                            </Grid>
                            </DialogContent>
                            
                        <DialogActions style={{textAlign: 'right'}}>
                            <br/>
                            <Button size="small" variant='contained' onClick={this.saveMenuProductsOrder} color="primary">Save Products To Menu</Button>
                        </DialogActions>
                    </Dialog>
                    }

                    <Card style={{textAlign:'left', padding:'1em', width:'40%'}}>
                        {this.props.user.data.menus &&
                            <div>
                                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                <h4>My Menus</h4>
                                <SwapVertRoundedIcon  onClick={()=>this.setState({openSortMenuDialog: true})} style={{cursor:'pointer'}} color="primary"/>

                                </div>
                                
                                {this.state.menus.map((menu, index) => (
                                    <Card key={index} style={{margin: '1em', padding:'1em', display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}} >
                                        <span style={{flex:0.7}}>{menu.name}</span>
                                        <div style={{flex:0.3, display:"flex", flexDirection:"row", alignItems:"center"}}> 
                                        <EditRoundedIcon style={{cursor:'pointer'}} onClick={()=>{
                                            this.setState({
                                                editMenu: true,
                                                selectedMenu:menu, 
                                                menuIndex: index, 
                                                newMenuName:menu.name,
                                                mondayStart:menu.hours.monday.start,mondayEnd:menu.hours.monday.end,
                                                tuesdayStart:menu.hours.tuesday.start,tuesdayEnd:menu.hours.tuesday.end,
                                                wednesdayStart:menu.hours.wednesday.start,wednesdayEnd:menu.hours.wednesday.end,
                                                thursdayStart:menu.hours.thursday.start,thursdayEnd:menu.hours.thursday.end,
                                                fridayStart:menu.hours.friday.start,fridayEnd:menu.hours.friday.end,
                                                saturdayStart:menu.hours.saturday.start,saturdayEnd:menu.hours.saturday.end,
                                                sundayStart:menu.hours.sunday.start,sundayEnd:menu.hours.sunday.end,
                                            })
                                        }}/>
                                        <div style={{display:"flex",cursor:'pointer', flexDirection:"row", alignItems:"center", marginLeft:"20px"}} onClick={()=>{
                                            this.setState({openAddProductsDialog: true,selectedMenu:menu,menuProducts:menu.products ? menu.products:[]})
                                        }}
                                        >
                                        <FastfoodRoundedIcon  />    
                                        <span style={{color:"grey",fontSize: "12px", marginLeft: "8px", marginTop: "3px"}}>{menu.products? menu.products.length : 0} items</span>
                                        </div>
                                       
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        }
                         <br/>
                         <CreateMenu size='sm' business={this.props.user}/>
                    </Card>

                    <h2 style={{marginTop:'3em', marginBottom:'1.5em', color:"black"}}>My Products</h2>
                    {this.state.loadingImageUpload && <LinearProgress/>}

                    {this.state.selectedProduct &&
                    <Dialog onClose={this.handleClose} aria-labelledby="customer" open={this.state.open}>
                        {this.state.updateProductLoading && <LinearProgress/>}

                        <DialogTitle id="customer">Edit {this.state.selectedProduct.productName}</DialogTitle>
                        <div style={{paddingBottom:'2em', paddingLeft: '2em', paddingRight: '2em'}}>
                            
                      
                            {/* {this.state.fieldToEdit === 'index' && 
                                    <span>
                                    <p>Name: {this.state.selectedProduct.productName}</p>
                                    <TextField onChange={this.handleChange} type='number' id="newProductIndex" label="new index..." />
                                    <br/><br/>
                                    <br/>
                                    <Button variant='contained' size='small' color='primary' onClick={this.updateProductIndex}>Save Index</Button>
                                </span>
                            } */}

                            {this.state.fieldToEdit === 'name' && 
                                    <span>
                                    <p>Name: {this.state.selectedProduct.productName}</p>
                                    <TextField onChange={this.handleChange} id="newProductName" label="new name..." />
                                    <br/><br/>
                                    {this.state.updateProductLoading && <LinearProgress/>}
                                    <br/>
                                    <Button variant='contained' size='small' color='primary' onClick={this.updateProduct}>Save New Name</Button>
                                </span>
                            }
                            {this.state.fieldToEdit === 'description' && 
                                <span>
                                    <p>Description: {this.state.selectedProduct.productDescription}</p>
                                    <TextField onChange={this.handleChange} id="newProductDescription" label="new description..." />
                                    <br/><br/>
                                    {this.state.updateProductLoading && <LinearProgress/>}
                                    <br/>
                                    <Button variant='contained' size='small' color='primary' onClick={this.updateProduct}>Save New Description</Button>
                                </span>
                            }
                            {this.state.fieldToEdit === 'category' && 
                                <span>
                                    <p>Category: {this.state.selectedProduct.productCategory}</p>               
                                    <TextField onChange={this.handleChange} id="newProductCategory" label="new category..." />
                                    <br/><br/>
                                    {this.state.updateProductLoading && <LinearProgress/>}
                                    <br/>
                                    <Button variant='contained' size='small' color='primary' onClick={this.updateProduct}>Save New Category</Button>
                                </span>
                            }
                            {this.state.fieldToEdit === 'price' && 
                                <span>
                                    <p>Price: ${this.state.selectedProduct.productPrice.toFixed(2)}</p>    
                                    <TextField onChange={this.handleChange} id="newProductPrice" label="new price..." />
                                    <br/><br/>
                                    {this.state.updateProductLoading && <LinearProgress/>}
                                    <br/>
                                    <Button variant='contained' size='small' color='primary' onClick={this.updateProduct}>Save New Price</Button>
                                </span>
                            }

                            {this.state.fieldToEdit === 'hours' && 
                                <span>
                                    <p>Hours:</p>    
                                    <br/><br/>
                                    {this.state.updateProductLoading && <LinearProgress/>}
                                    <br/>
                                    <Button variant='contained' size='small' color='primary' onClick={this.updateProduct}>Save New Product Hours</Button>
                                </span>
                            }

                            {this.state.fieldToEdit === 'delete' &&
                            <div>
                                {this.state.selectedProduct.productActive ?
                                <Button onClick={()=>this.setInactive(this.state.selectedProduct.id)} startIcon={<RemoveShoppingCartRoundedIcon/>} variant="contained" size="small" style={{backgroundColor:'#385CAD', color:'white'}}>Mark as unavailable</Button>
                                :
                                <Button onClick={()=>this.setActive(this.state.selectedProduct.id)} startIcon={<ShoppingCartRoundedIcon/>} variant="contained" size="small" style={{backgroundColor:'green', color:'white'}}>Mark as available</Button>
                                }
                                <br/><br/>
                                <Button onClick={()=>this.deleteProduct(this.state.selectedProduct.id)} startIcon={<DeleteIcon/>} variant="contained" size="small" style={{backgroundColor:'tomato', color:'white'}}>Delete</Button>
                            </div>
                            }
                        </div>
                    </Dialog>
                    }
                    

                    {this.state.products &&
                        <Grid container spacing={1} >
                    
                        {this.state.products.map((product,i) => 
                            <Grid item xs={12} sm={6} md={3} key={product.id} >
                                <Card style={{padding:'1em'}}>
                                    <Typography gutterBottom variant="h5" component="h2"> <span style={{cursor:'pointer'}} onClick={()=>this.openOptions(product, "name")} >{product.productName} </span>{!product.productActive && <SnoozeRoundedIcon color='secondary' style={{float:'right'}}/> } </Typography>
                                    {product.menu &&
                                        <p>Menu: {product.menu.name}</p>
                                    }
                                    <div style={{position:"relative"}}>
                                    <img style={{borderRadius:'3px', width:'50%'}} src={product.productImageUrl} alt="" srcSet=""/>
                                    {
                                       product.productImageUrl && product.productImageUrl.length > 0 && <HighlightOffTwoToneIcon 
                                       onClick={()=>{
                                            this.setState({showConfirmation:true, selectedProductIndex:i})
                                       }}
                                       style={{
                                           position: "absolute",
                                           right: "50px",
                                           top: "-12px",
                                           color: "red",
                                           cursor:"pointer"
                                       }}/>
                                    }
                                    </div>
                                    <br/>
                                    
                                    <br/>
                                    {product.productDescription && product.productDescription.length > 1 ?
                                        <Typography style={{cursor:'pointer'}} onClick={()=>this.openOptions(product, "description")} variant="body2" color="textSecondary" component="p">{product.productDescription}</Typography>
                                        :
                                        <p onClick={()=>this.openOptions(product, "description")} style={{cursor:'pointer'}}>Add a product description</p>
                                    }
                                    <br/>

                                    {product.productCategory && product.productCategory.length > 1 ?
                                        <Typography style={{cursor:'pointer'}} onClick={()=>this.openOptions(product, "category")} variant="body2" color="textSecondary" component="p">{product.productCategory}</Typography>
                                        :
                                        <p onClick={()=>this.openOptions(product, "category")} style={{cursor:'pointer'}}>Add a product category</p>
                                    }

                                    {/* {product.productOptions.map((option, index) => ( 
                                    <Chip variant="outlined" label={<span style={{color:'grey'}}> {option.option}: {selectedOption.selectedOption}</span>} key={index}/> 
                                    ))}     */}
                                    <CardActions>
                                        <IconButton onClick={()=>this.openOptions(product, "delete")}  aria-label="product options" component="span">
                                        <MoreHorizIcon />
                                        </IconButton>
                                    
                                        <input onChange={this.changePhoto} style={{display:'none'}} accept="image/*" id="product-image-file" type="file" />
                                        <label htmlFor="product-image-file">
                                            <IconButton onClick={()=>this.setState({selectedProductImage: product.id})} color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                            </IconButton>
                                        </label>
                                        
                                        <IconButton style={{color:'black', fontSize:'1em'}} onClick={()=>this.openOptions(product, "price")} aria-label="product options" component="span">
                                            $ {product.productPrice.toFixed(2)}
                                        </IconButton>

                                        {/* <IconButton style={{color:'black', fontSize:'1em'}} onClick={()=>this.openOptions(product, "hours")} aria-label="product options" component="span">
                                            <AccessTimeRoundedIcon style={{float:'right'}}/>
                                        </IconButton>
                                         */}
                                        {/* <Button style={{color:'black', fontSize:'1em'}} onClick={()=>this.openOptions(product, "index")} aria-label="product options" component="span">
                                           save new index
                                        </Button> */}

                                    </CardActions>
                                </Card> 
                            </Grid>
                        )}
                        </Grid>
                    }
                    <Dialog open={this.state.showConfirmation} onClose={()=>{this.setState({showConfirmation:false, selectedProductIndex:null})}}
                        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Remove Image"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">are you sure you want to delete this product image?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={()=>this.setState({showConfirmation:false})} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={()=>{
                            const products = [...this.state.products]
                            const selectedProduct = products[this.state.selectedProductIndex]  
                            db.collection("products").doc(selectedProduct.id).update({productImageUrl:null}).then(()=>{
                                selectedProduct['productImageUrl']= null
                                this.setState({products,selectedProductIndex:null, showConfirmation:false})
                            })
                        }} color="primary" autoFocus>
                            Agree
                        </Button>
                        </DialogActions>
                    </Dialog>
                    {this.state.loadingImageUpload && <LinearProgress/>}
                </div>
            </div>
        )
    }
}

