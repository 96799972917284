import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { fade, makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import {Menu, Home, Info, Add, Help, Settings, ExitToApp, AccountBox,Fastfood, InsertChart, MonetizationOn, Payment,Loyalty, AssignmentTurnedIn, FeaturedVideo} from '@material-ui/icons';
import StoreRoundedIcon from '@material-ui/icons/StoreRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import logo from '../Assets/TangoIcon_Curved.png'
import logoText from '../Assets/TangoHorizontal_No_Background.png'
import {Tab, Tabs} from '@material-ui/core'
import {Link} from 'react-router-dom';
import firebase from '../Config/firebase'
import LinearProgress from '@material-ui/core/LinearProgress';
import whitelogo from '../Assets/TangoHorizontal_No_Background.png'
import CakeRoundedIcon from '@material-ui/icons/CakeRounded';
const db = firebase.firestore();


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', 
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
}));
  
  

export default function BusinessNavbar(props) {
    // console.log('business signed in', props)

    const classes = useStyles();

    const [business, setbusiness] = React.useState(props.user);
    // console.log('set var', business)
    // const [open, setOpen, value ] = React.useState(false);

    const handleChange = ()=>{
        console.log('change tab')
    }  

    const [state, setState] = React.useState({
        left: false,
        loading: false,
        test: 'hl'
    });

    const toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }
        setState({ ...state, [side]: open });
    };

    const firebaseLogout = () =>{ 
      setState({loading: true})
      firebase.auth().signOut().then(()=> {
          console.log('signed out user!')
          window.location.href = '/';
        })
      .catch((error) => {
          console.log(error)
      });    
    }

  const sideList = side => (
    <div style={{width: '16em'}} className={classes.list} role="presentation" onClick={toggleDrawer(side, false)} onKeyDown={toggleDrawer(side, false)}>
      
      <List>
          <img style={{marginLeft: '6%', marginTop: "3%"}} width='50%' src= {logoText} alt="" srcSet=""/>
      </List>
    
      <Divider />
      
      <List>   
      
      {/* for admin / renders landing page */}
      {props.user.admin && props.user.userType === 'business' &&
        <Link style={{textDecoration: 'none', color: 'black'}} to='/'>
        <ListItem button>
            <ListItemIcon><Home /></ListItemIcon>
            <ListItemText primary={'Home'}/>
        </ListItem>
        </Link>
      }

      {/* for staff / renders orders */}
        { props.user.userType === 'staff' &&
        <Link style={{textDecoration: 'none', color: 'black'}} to='/'>
        <ListItem button>
            <ListItemIcon><Payment/></ListItemIcon>
            <ListItemText primary={'Orders'}/>
        </ListItem>
        </Link>
      }

        {props.user.admin && props.user.userType === 'business' &&
          <Link style={{textDecoration: 'none', color: 'black'}} to='/orders' user={business}>
          <ListItem button>
              <ListItemIcon><Payment /></ListItemIcon>
              <ListItemText primary={'Orders'} />
          </ListItem>
          </Link>
        }

        {props.user.admin && props.user.userType === 'business' &&
          <Link style={{textDecoration: 'none', color: 'black'}} to='/dailyReporting' user={business}>
          <ListItem button>
              <ListItemIcon><AssignmentTurnedIn /></ListItemIcon>
              <ListItemText primary={'Daily Reporting'} />
          </ListItem>
          </Link>
        }


        {props.user.admin && props.user.userType === 'business' &&
          <Link style={{textDecoration: 'none', color: 'black'}} to='/reservations' user={business}>
          <ListItem button>
              <ListItemIcon><TodayRoundedIcon /></ListItemIcon>
              <ListItemText primary={'Reservations'} />
          </ListItem>
          </Link>
        }

        {props.user.userType === 'business' ||  props.user.userType === 'staff' &&
          <Link style={{textDecoration: 'none', color: 'black'}} to='/dailyReporting' user={business}>
          <ListItem button>
              <ListItemIcon><MonetizationOn /></ListItemIcon>
              <ListItemText primary={'Daily Reporting'} />
          </ListItem>
          </Link>
        }
        
        {props.user.admin && props.user.userType === 'business' &&
          <Link style={{textDecoration: 'none', color: 'black'}} to='/editProducts' user={business}>
          <ListItem button>
              <ListItemIcon><Fastfood /></ListItemIcon>
              <ListItemText primary={'Products'} />
          </ListItem>
          </Link>
        } 

        {props.user.admin && props.user.userType === 'business' &&
          <Link style={{textDecoration: 'none', color: 'black'}} to='/analytics' user={business}>
          <ListItem button>
              <ListItemIcon><InsertChart /></ListItemIcon>
              <ListItemText primary={'Analytics'} />
          </ListItem>
          </Link>
        }

        {/* {props.user.admin && props.user.userType === 'business' &&
          <Link style={{textDecoration: 'none', color: 'black'}} to='/customers'>
          <ListItem button>
              <ListItemIcon><PeopleAltRoundedIcon /></ListItemIcon>
              <ListItemText primary={'Customers'} />
          </ListItem>
          </Link>
        } */}

        {/* {props.user.admin && props.user.userType === 'business' &&
          <Link style={{textDecoration: 'none', color: 'black'}} to='/deals' user={business}>
          <ListItem button>
              <ListItemIcon><Loyalty /></ListItemIcon>
              <ListItemText primary={'Deals'} />
          </ListItem>
          </Link>
        } */}

        <Link style={{textDecoration: 'none', color: 'black'}} to='/parties' user={business}>
        <ListItem button>
            <ListItemIcon><CakeRoundedIcon /></ListItemIcon>
            <ListItemText primary={'Parties'} />
        </ListItem>
        </Link>
      
        <Link style={{textDecoration: 'none', color: 'black'}} to='/partyOrders' user={business}>
        <ListItem button>
            <ListItemIcon><CakeRoundedIcon /></ListItemIcon>
            <ListItemText primary={'Party Orders'} />
        </ListItem>
        </Link>
        

        {props.user.admin && props.user.userType === 'business' &&
          <Link style={{textDecoration: 'none', color: 'black'}} to='/account' user={business}>
          <ListItem button>
              <ListItemIcon><Settings /></ListItemIcon>
              <ListItemText primary={'Account Settings'} />
          </ListItem>
          </Link>
        }

      {props.user.admin && props.user.userType === 'business' &&
          <Link style={{textDecoration: 'none', color: 'black'}} to='/adminHelp' user={business}>
          <ListItem button>
              <ListItemIcon><FeaturedVideo /></ListItemIcon>
              <ListItemText primary={'How To Guides'} />
          </ListItem>
          </Link>
        }


      </List>

      <Divider />
      <List>   


        <ListItem button onClick={firebaseLogout}>
            <ListItemIcon><ExitToApp /></ListItemIcon>
            <ListItemText primary={'Logout'} />
        </ListItem>

        {/* {loading && <LinearProgress style={{paddinTop: '1em', paddingBottom: '1em'}}/>} */}

        {/* <ListItem style={{position: 'fixed', bottom: '0'}}>
          <ListItemText style={{color:'grey'}} primary={props.user.email} />
        </ListItem> */}

      </List>


    </div>
  );
  
    return (
      <div className={classes.root}>
        <AppBar style={{height: '3.2em', backgroundColor: 'white', color: 'black'}} position="fixed">
            <Toolbar>


          {/* <ListItemIcon style={{marginBottom: '1%'}}><Menu style={{color: 'white'}} onClick={toggleDrawer('left', true)} edge="start" className={classes.menuButton} src={logo} alt="Logo" aria-label="open drawer"/></ListItemIcon> */}

            {/* <div style={{marginBottom: '1%', cursor:'pointer'}}>
                <img onClick={toggleDrawer('left', true)} className={classes.menuButton} src={logo} alt="Logo" width='40em' height='40em' aria-label="open drawer"/>
            </div> */}

            {business && <Avatar onClick={toggleDrawer('left', true)} style={{marginBottom: '1%', cursor:'pointer'}} alt="B" src={business.data.profileImageUrl} />}

            <SwipeableDrawer open={state.left} onClose={toggleDrawer('left', false)} onOpen={toggleDrawer('left', true)}>
            {sideList('left')}
            </SwipeableDrawer>

            <div style={{marginBottom: '1%', marginLeft:'1em'}}>
             <p>{props.user.data.businessName}</p> 
            </div>
           
           <div style={{width:'15%', position:'absolute',  right:'0'}}>
            <img style={{width:'80%'}}src={whitelogo} alt="" srcSet=""/>        
           </div>

            {/* <div style={{position:'absolute',  right:'0', width:'30%', marginBottom:'1%'}} className={classes.search} centered>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase placeholder="Search orders..." classes={{root: classes.inputRoot, input: classes.inputInput,}} inputProps={{ 'aria-label': 'search' }}/>
            </div> */}
          

          </Toolbar>
        </AppBar>

      </div>
    );
  }
