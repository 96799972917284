import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import {Redirect} from 'react-router-dom';
import moment from  'moment'
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';

class DailyReporting extends Component {

    state = {
    }
    
    componentDidMount(){
        let todayStart = moment().startOf('day').add(4, 'hours').utc().format('YYYY-MM-DD HH:mm:ss')
        let tomorrowsStart = moment().add(1, 'day').startOf('day').add(4, 'hours').utc().format('YYYY-MM-DD HH:mm:ss')
        console.log(todayStart, 'tomorrow', tomorrowsStart)
        this.setState({todayStart: todayStart, selectedStartDate: todayStart, selectedEndDate: tomorrowsStart, selectedStartTime: todayStart, selectedEndTime: tomorrowsStart})
        //if staff account
        if(!this.props.user.admin){
            this.setState({businessId: this.props.user.data.businessId})
            this.getTodaySalesTips(this.props.user.data.businessId, todayStart)
        }
        //if admin account
        else{
            this.setState({businessId: this.props.user.id})
            this.getTodaySalesTips(this.props.user.id, todayStart)
            this.getTodayProducts(this.props.user.id, todayStart)
        }
    }

    getTodaySalesTips = (businessId, timestamp) =>{
        this.setState({todaySalesAndTipsLoading: true})
        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getDailyReportBQ',
            data: {
              startTime: timestamp,
              businessId: businessId
            }
        })
        .then((response) =>{
            console.log('posted to cloud function', response)
            this.setState({todaySalesAndTipsLoading: false, todaySalesAndTips: response.data})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({todaySalesAndTipsLoading: false, todaySalesAndTips: null})
        })
    }

    groupProductOptions = (data) => {
      const result = []
      const unique = []
      for (let i=0; i<data.length; i++) {
        if (unique.includes(
              data[i]['product_name'] +
              data[i]['product_option_one'] +
              data[i]['product_options_two']) ||
            unique.includes(
              data[i]['product_name'] +
              data[i]['product_options_two'] +
              data[i]['product_option_one'])) {
          for (let j=0; j<result.length; j++) {
            if (result[j]['product_name'] === data[i]['product_name'] &&
               ((result[j]['product_option_one'] === data[i]['product_option_one'] &&
                 result[j]['product_options_two'] === data[i]['product_options_two']) ||
                (result[j]['product_option_one'] === data[i]['product_options_two'] &&
                 result[j]['product_options_two'] === data[i]['product_option_one']))) {
              result[j]['count'] += data[i]['count']
              break
            }
          }
        } else {
          result.push(data[i])
          unique.push(
              data[i]['product_name'] +
              data[i]['product_option_one'] +
              data[i]['product_options_two'])
        }
      }

      return result
    }

    getTodayProducts = (businessId, timestamp) =>{
        this.setState({todaySalesAndTipsLoading: true})

        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getDailyProductsBQ',
            data: {
              startTime: timestamp,
              businessId: businessId
            }
        })
        .then((response) =>{
            console.log('posted to cloud function', response)
            this.setState({todayProductsLoading: false, todayProducts: this.groupProductOptions(response.data)})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({todayProductsLoading: false, todayProducts: null})
        })
    }

    getCustomSalesTips = (businessId, start, end) =>{
        this.setState({customSalesAndTipsLoading: true})

        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getCustomReportBQ',
            data: {
              start: start,
              end: end,
              businessId: businessId
            }
        })
        .then((response) =>{
            console.log('posted to cloud function', response)
            this.setState({customSalesAndTipsLoading: false, customSalesAndTips: response.data})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({customSalesAndTipsLoading: false, customSalesAndTips: null})
        })
    }


    getCustomProductBreakdown = (businessId, start, end) =>{
        this.setState({customSalesAndTipsLoading: true})
        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getCustomProductBreakdownBQ',
            data: {
              start: start,
              end: end,
              businessId: businessId
            }
        })
        .then((response) =>{
            console.log('posted to product breakdown cloud function', response)
            this.setState({customProductsLoading: false, customProductBreakdown: this.groupProductOptions(response.data)})
        })
        .catch(error=>{
            console.log('error posting to cloud function')
            this.setState({customProductsLoading: false, customProductBreakdown: null})
        })
    }


    handleStartDateChange = (date) => {
        this.setState({selectedStartDate: date})
    };
    handleEndDateChange = (date) => {
        this.setState({selectedEndDate: date})
    };
    handleStartTimeChange = (date) => {
        this.setState({selectedStartTime: date})
    };
    handleEndTimeChange = (date) => {
        this.setState({selectedEndTime: date})
    };

    getCustomReport = (startDate, startTime, endDate, endTime) =>{
        console.log('get custom report dates--->', startDate, endDate, 'times -->', startTime, endTime)
        if(!startDate || !startTime || !endDate || !endTime){
            this.setState({dateTimeError:true})
            return setTimeout(()=>{ 
              this.setState({dateTimeError: false})
            }, 2500);      
        }

        startDate = moment(startDate).format('YYYY-MM-DD')
        endDate = moment(endDate).format('YYYY-MM-DD')
        startTime = moment(startTime).format('HH:mm:ss')
        endTime = moment(endTime).format('HH:mm:ss')
        let startQuery = startDate + ' ' + startTime
        let endQuery = endDate + ' ' + endTime

        // console.log( 'what this -->', startQuery, endQuery)
        startQuery =  moment(startQuery).utc().format('YYYY-MM-DD HH:mm:ss')
        endQuery = moment(endQuery).utc().format('YYYY-MM-DD HH:mm:ss')
        // console.log( 'what this -->', startQuery, endQuery)

        this.setState({selectedCustomReport: true})
        this.getCustomSalesTips(this.state.businessId, startQuery, endQuery)
        this.getCustomProductBreakdown(this.state.businessId, startQuery, endQuery)
    }

    handleCloseModal = () => {
        this.setState({clickedViewProducts:false, clickedViewCustomProductBreakdown: false});
    };
    render() {
        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }
        return (
            <div style={{marginTop: '7em', marginLeft: '4em', marginRight:'4em', marginBottom:'10em'}}>
             <Paper style={{padding:'1em'}}>
                <h2>Today {moment().format('MM/DD/YYYY')}</h2>
                <p style={{fontStyle: 'italic'}}>Since 4am</p>
                <br/>
                {this.state.todaySalesAndTipsLoading ?
                    <div><LinearProgress style={{margin: '1em'}}/></div>
                    :
                    <div>
                    {this.state.todaySalesAndTips && this.state.todaySalesAndTips.length ?
                            <div>
                                <h4>Sales: ${((this.state.todaySalesAndTips[0].total_sales - this.state.todaySalesAndTips[0].total_tips) - this.state.todaySalesAndTips[0].taxes).toFixed(2) }</h4>
                                <h4>Taxes:  ${this.state.todaySalesAndTips[0].taxes.toFixed(2)}</h4>
                                <h4>Tips:  ${this.state.todaySalesAndTips[0].total_tips.toFixed(2)}</h4>
                                <h4>Revenue: ${this.state.todaySalesAndTips[0].total_sales.toFixed(2)}</h4>
                            </div>
                            :
                            <div>
                                <h5>Sales: NO DATA</h5>
                                <h5>Tips: NO DATA</h5>
                            </div>   
                    }

                    <Dialog onClose={this.handleCloseModal} aria-labelledby="customer" open={this.state.clickedViewProducts}>
                        <DialogTitle id="customer">{moment().format('MM/DD/YYYY')} Product Breakdown</DialogTitle>
                        {this.state.todayProducts && this.state.todayProducts.map((product, index) => (
                            <p style={{margin:'1em'}} key={index}>{product.product_name}: {product.product_option_one && <Chip variant="outlined" label={<span style={{color:'grey'}}>{product.product_option_one}</span>}/>} {product.product_options_two && <Chip variant="outlined" label={<span style={{color:'grey'}}>{product.product_options_two}</span>}/>} {product.product_option_three && <Chip variant="outlined" label={<span style={{color:'grey'}}>{product.product_option_three}</span>}/>} {product.count}</p>                        
                        ))}
                    </Dialog>

                    {this.state.todayProducts &&
                    <Button color='primary' variant='contained' onClick={()=>this.setState({clickedViewProducts:true})}>View Product Breakdown</Button>
                    }
                    <br/><br/>
                    <Button variant='contained' onClick={()=>this.getTodaySalesTips(this.state.businessId, this.state.todayStart)}>Refresh</Button>
                    
                    </div>
                }
             </Paper>

             <br/><br/>

             <Paper style={{padding:'1em'}}>
                <h2>Generate Custom Report</h2>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Start Date"
                    format="MM/dd/yyyy"
                    value={this.state.selectedStartDate}
                    onChange={this.handleStartDateChange}
                    KeyboardButtonProps={{'aria-label': 'change date',}}
                    style={{margin:'1em'}}
                    
                />
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Start Time"
                    value={this.state.selectedStartTime}
                    onChange={this.handleStartTimeChange}
                    KeyboardButtonProps={{'aria-label': 'change time',}}
                    style={{margin:'1em'}}
                />
                <br/>
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="End Date"
                    format="MM/dd/yyyy"
                    value={this.state.selectedEndDate}
                    onChange={this.handleEndDateChange}
                    KeyboardButtonProps={{'aria-label': 'change date',}}
                    style={{margin:'1em'}}
                />
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="End Time"
                    value={this.state.selectedEndTime}
                    onChange={this.handleEndTimeChange}
                    KeyboardButtonProps={{ 'aria-label': 'change time',}}
                    style={{margin:'1em'}}
                />
                </MuiPickersUtilsProvider>

                {!this.state.selectedCustomReport ?
                    <div>
                        {/* <h5>Select Dates and Times</h5> */}
                    </div>
                    :
                    <div>
                        {this.state.customSalesAndTipsLoading ?
                            <div><LinearProgress style={{margin: '1em'}}/></div>
                            :
                            <div style={{marginTop:'1em'}}>
                            {this.state.customSalesAndTips && this.state.customSalesAndTips.length ?
                                    <div>
                                        <h4>Sales: ${(this.state.customSalesAndTips[0].total_sales - this.state.customSalesAndTips[0].total_tips - this.state.customSalesAndTips[0].taxes).toFixed(2)}</h4>
                                        <h4>Taxes:  ${this.state.customSalesAndTips[0].taxes.toFixed(2)}</h4>
                                        <h4>Tips:  ${this.state.customSalesAndTips[0].total_tips.toFixed(2)}</h4>
                                        <h4>Revenue: ${this.state.customSalesAndTips[0].total_sales.toFixed(2)}</h4>
                                    </div>
                                    :
                                    <div>
                                        <h5>Sales: NO DATA</h5>
                                        <h5>Tips: NO DATA</h5>
                                    </div>   
                            }
                            </div>
                        }
                    </div>
                }

                    <Dialog onClose={this.handleCloseModal} aria-labelledby="customer" open={this.state.clickedViewCustomProductBreakdown}>
                        <DialogTitle id="customer">Product Breakdown</DialogTitle>
                        {this.state.customProductBreakdown && this.state.customProductBreakdown.map((product, index) => (
                            <p style={{margin:'1em'}} key={index}>{product.product_name} {product.product_option_one && <Chip variant="outlined" label={<span style={{color:'grey'}}>{product.product_option_one}</span>}/>} {product.product_options_two && <Chip variant="outlined" label={<span style={{color:'grey'}}>{product.product_options_two}</span>}/>}- {product.count}</p>                        
                        ))}
                    </Dialog>

                <br/><br/>
                {this.state.customProductBreakdown &&
                    <Button color='primary' variant='contained' onClick={()=>this.setState({clickedViewCustomProductBreakdown:true})}>View Product Breakdown</Button>
                }
                <br/><br/>
                <Button variant='contained' onClick={()=>this.getCustomReport(this.state.selectedStartDate, this.state.selectedStartTime, this.state.selectedEndDate, this.state.selectedEndTime)}>Create Report</Button>


             </Paper>


                <Snackbar open={this.state.dateTimeError} style={{width:'50%', margin: 'auto'}}>
                    <Alert style={{position:'fixed', bottom: '30px'}} severity="error">please choose all date and time options</Alert>
                </Snackbar>   
            </div>
        )
    }
}

export default DailyReporting
