import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import firebase from '../../Config/firebase'

const db = firebase.firestore();

class CreateMenu extends Component {
    
    state ={
        available:{
            monday:true,
            tuesday:true,
            wednesday:true,
            thursday:true,
            friday:true,
            saturday:true,
            sunday:true
        }
    }

    componentDidMount(){
        console.log('loaded create menu', this.props)
    }

    handleClose = () =>{
        this.setState({openNewMenu:false})
    }
    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value})
    }
    handleSelectTime = (e) => {
        this.setState({[e.target.name]: e.target.value})
        console.log(this.state)
    }
    saveMenu = (e)=>{
        const {available} = this.state;
        if(!this.state.menuName){
            return alert('please enter a menu name')
        }
        let menuIndex = 0
        if(this.props.business.data.menus){
            menuIndex = this.props.business.data.menus.length
        }
        this.setState({savingMenu:true})
        console.log('saving menu', this.state)
        let menu = {
            businessId: this.props.business.id,
            index: menuIndex,
            name: this.state.menuName,
            hours: {
                sunday: {available:available.sunday, start: "00:01", end: "24:00"},
                monday: {available:available.monday, start: "00:01", end: "24:00"},
                tuesday: {available:available.tuesday, start: "00:01", end: "24:00"},
                wednesday: {available:available.wednesday, start: "00:01", end: "24:00"},
                thursday: {available:available.thursday, start: "00:01", end: "24:00"},
                friday: {available:available.friday, start: "00:01", end: "24:00"},
                saturday: {available:available.saturday, start: "00:01", end: "24:00"},
            }
        }

        if(this.state.sundayStart && this.state.sundayEnd){
            menu.hours.sunday.start = this.state.sundayStart
            menu.hours.sunday.end = this.state.sundayEnd
        }
        if(this.state.mondayStart && this.state.mondayEnd){
            menu.hours.monday.start = this.state.mondayStart
            menu.hours.monday.end = this.state.mondayEnd
        }
        if(this.state.tuesdayStart && this.state.tuesdayEnd){
            menu.hours.tuesday.start = this.state.tuesdayStart
            menu.hours.tuesday.end = this.state.tuesdayEnd
        }
        if(this.state.wednesdayStart && this.state.wednesdayEnd){
            menu.hours.wednesday.start = this.state.wednesdayStart
            menu.hours.wednesday.end = this.state.wednesdayEnd
        }
        if(this.state.thursdayStart && this.state.thursdayEnd){
            menu.hours.thursday.start = this.state.thursdayStart
            menu.hours.thursday.end = this.state.thursdayEnd
        }
        if(this.state.fridayStart && this.state.fridayEnd){
            menu.hours.friday.start = this.state.fridayStart
            menu.hours.friday.end = this.state.fridayEnd
        }
        if(this.state.saturdayStart && this.state.saturdayEnd){
            menu.hours.saturday.start = this.state.saturdayStart
            menu.hours.saturday.end = this.state.saturdayEnd
        }

        var businessRef = db.collection("businesses").doc(this.props.business.id);

        businessRef.update({  menus: firebase.firestore.FieldValue.arrayUnion(menu) })
        .then((docRef) =>{
            console.log("Document updated ");
            this.setState({openNewMenu:false})
            window.location.reload()
        })
        .catch((error)=> {
            console.error("Error update document: ");
            this.setState({openNewMenu:false})
        });

    }

    handleAvailablity = (day, status) =>{
        let available = {...this.state.available}
        available[day] = status
        this.setState({available})
    }

    render() {
        return (
            <div>
                <Dialog 
                onClose={this.handleClose} 
                aria-labelledby="newMenu" 
                open={this.state.openNewMenu}
                id="create-menu-dialog"
                >
                    <DialogTitle id="customer">Create a New Menu</DialogTitle>

                    <div style={{padding: '1em'}}>
                        <span>
                            <InputLabel htmlFor="menuName">Menu Name</InputLabel>
                            <Input id="menuName" onChange={this.handleChange}/>
                        </span>
   
                        <br/><br/>
                        <span>
                            <p>*Please select both start and end times for all days. Leaving a time unselected will result in the menu saving as always available for that day of the week.</p>
                            <Grid container spacing={1}>
                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}} color="textSecondary">Sunday</Typography>
    
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='sundayStart'  id="sundayStart"
                                                    value={this.state.sundayStart} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='sundayEnd'  id="sundayEnd"
                                                    value={this.state.sundayEnd} onChange={this.handleSelectTime} >
                                             <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.available.sunday}
                                                    onChange={(e)=>{this.handleAvailablity("sunday",e.target.checked)}}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                        </CardContent>
                                        </Card>
                                    </Grid> 

                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Monday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='mondayStart' id="mondayStart"
                                                    value={this.state.mondayStart} onChange={this.handleSelectTime} >
                                                            <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='mondayEnd' id="mondayEnd"
                                                    value={this.state.mondayEnd} onChange={this.handleSelectTime} >
                                                           <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.available.monday}
                                                    onChange={(e)=>{this.handleAvailablity("monday",e.target.checked)}}
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                        </CardContent>
                                        </Card>
                                    </Grid> 
                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Tuesday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='tuesdayStart' id="tuesdayStart"
                                                    value={this.state.tuesdayStart} onChange={this.handleSelectTime} >
                                                <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='tuesdayEnd' id="tuesdayEnd"
                                                    value={this.state.tuesdayEnd} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select>
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.available.tuesday}
                                                    onChange={(e)=>{this.handleAvailablity("tuesday",e.target.checked)}}
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            /> 
                                        </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Wednesday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='wednesdayStart' id="wednesdayStart"
                                                    value={this.state.wednsedayStart} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='wednesdayEnd' id="wednesdayEnd"
                                                    value={this.state.wednesdayEnd} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.available.wednesday}
                                                    onChange={(e)=>{this.handleAvailablity("wednesday",e.target.checked)}}
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                        </CardContent>
                                        </Card>
                                    </Grid> 

                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Thursday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='thursdayStart' id="thursdayStart"
                                                    value={this.state.thursdayStart} onChange={this.handleSelectTime} >
                                                 <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='thursdayEnd' id="thursdayEnd"
                                                    value={this.state.thursdayEnd} onChange={this.handleSelectTime} >
                                                       <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.available.thursday}
                                                    onChange={(e)=>{this.handleAvailablity("thursday",e.target.checked)}}
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                        </CardContent>
                                        </Card>
                                    </Grid> 

                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Friday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='fridayStart' id="fridayStart"
                                                    value={this.state.fridayStart} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'23:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='fridayEnd' id="fridayEnd"
                                                    value={this.state.fridayEnd} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'24:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.available.friday}
                                                    onChange={(e)=>{this.handleAvailablity("friday",e.target.checked)}}
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                        </CardContent>
                                        </Card>
                                    </Grid> 

                                    <Grid item xs={1.5}>
                                        <Card style={{cursor:'pointer'}} variant="outlined">
                                        <CardContent>
                                            <Typography style={{textAlign:'center', fontWeight:'bold'}}  color="textSecondary">Saturday</Typography>
                                            <InputLabel>Start Time</InputLabel>
                                                <Select style={{width:'100%'}} name='saturdayStart' id="saturdayStart"
                                                    value={this.state.saturdayStart} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'24:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                            <br/><br/>
                                            <InputLabel>End Time</InputLabel>
                                                <Select style={{width:'100%'}} name='saturdayEnd' id="saturdayEnd"
                                                    value={this.state.saturdayEnd} onChange={this.handleSelectTime} >
                                                    <MenuItem value={'06:00'}>6:00 am</MenuItem>
                                                    <MenuItem value={'06:30'}>6:30 am</MenuItem>
                                                    <MenuItem value={'07:00'}>7:00 am</MenuItem>
                                                    <MenuItem value={'07:30'}>7:30 am</MenuItem>
                                                    <MenuItem value={'08:00'}>8:00 am</MenuItem>
                                                    <MenuItem value={'08:30'}>8:30 am</MenuItem>
                                                    <MenuItem value={'09:00'}>9:00 am</MenuItem>
                                                    <MenuItem value={'09:30'}>9:30 am</MenuItem>
                                                    <MenuItem value={'10:00'}>10:00 am</MenuItem>
                                                    <MenuItem value={'10:30'}>10:30 am</MenuItem>
                                                    <MenuItem value={'11:00'}>11:00 am</MenuItem>
                                                    <MenuItem value={'11:30'}>11:30 am</MenuItem>
                                                    <MenuItem value={'12:00'}>12:00 pm</MenuItem>
                                                    <MenuItem value={'12:30'}>12:30 pm</MenuItem>
                                                    <MenuItem value={'13:00'}>1:00 pm</MenuItem>
                                                    <MenuItem value={'13:30'}>1:30 pm</MenuItem>
                                                    <MenuItem value={'14:00'}>2:00 pm</MenuItem>
                                                    <MenuItem value={'14:30'}>2:30 pm</MenuItem>
                                                    <MenuItem value={'15:00'}>3:00 pm</MenuItem>
                                                    <MenuItem value={'15:30'}>3:30 pm</MenuItem>
                                                    <MenuItem value={'16:00'}>4:00 pm</MenuItem>
                                                    <MenuItem value={'16:30'}>4:30 pm</MenuItem>
                                                    <MenuItem value={'17:00'}>5:00 pm</MenuItem>
                                                    <MenuItem value={'17:30'}>5:30 pm</MenuItem>
                                                    <MenuItem value={'18:00'}>6:00 pm</MenuItem>
                                                    <MenuItem value={'18:30'}>6:30 pm</MenuItem>
                                                    <MenuItem value={'19:00'}>7:00 pm</MenuItem>
                                                    <MenuItem value={'19:30'}>7:30 pm</MenuItem>
                                                    <MenuItem value={'20:00'}>8:00 pm</MenuItem>
                                                    <MenuItem value={'20:30'}>8:30 pm</MenuItem>
                                                    <MenuItem value={'21:00'}>9:00 pm</MenuItem>
                                                    <MenuItem value={'21:30'}>9:30 pm</MenuItem>
                                                    <MenuItem value={'22:00'}>10:00 pm</MenuItem>
                                                    <MenuItem value={'22:30'}>10:30 pm</MenuItem>
                                                    <MenuItem value={'23:00'}>11:00 pm</MenuItem>
                                                    <MenuItem value={'24:30'}>11:30 pm</MenuItem>
                                                    <MenuItem value={'24:00'}>12:00 am</MenuItem>
                                                    <MenuItem value={'00:30'}>12:30 am</MenuItem>
                                                    <MenuItem value={'01:00'}>1:00 am</MenuItem>
                                                    <MenuItem value={'01:30'}>1:30 am</MenuItem>
                                                    <MenuItem value={'02:00'}>2:00 am</MenuItem>
                                                    <MenuItem value={'02:30'}>2:30 am</MenuItem>
                                                    <MenuItem value={'03:00'}>3:00 am</MenuItem>
                                                    <MenuItem value={'03:30'}>3:30 am</MenuItem>
                                                    <MenuItem value={'04:00'}>4:00 am</MenuItem>
                                                    <MenuItem value={'04:30'}>4:30 am</MenuItem>
                                                    <MenuItem value={'05:00'}>5:00 am</MenuItem>
                                                    <MenuItem value={'05:30'}>5:30 am</MenuItem>
                                                </Select> 
                                                <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.available.saturday}
                                                    onChange={(e)=>{this.handleAvailablity("saturday",e.target.checked)}}
                                                    color="primary"
                                                />
                                                }
                                                label="Available"
                                            />
                                            </CardContent>
                                            </Card>
                                        </Grid> 
                                    </Grid>
                        </span>
                        {this.state.savingMenu && <LinearProgress/>}
                    </div>

                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">Cancel</Button>
                        <Button onClick={this.saveMenu} color="primary">Save Menu</Button>
                    </DialogActions>
                </Dialog>

                <Button color='primary' variant="contained" onClick={()=>this.setState({openNewMenu:true})}>Create A New menu</Button>
            </div>
        );
    }
}

export default CreateMenu;