import React, { Component } from 'react'
import {Redirect} from 'react-router-dom';

class Deals extends Component {
    componentDidMount(){
        console.log('loaded page', this.props)
    }
    render() {
        if(!this.props.user.admin) return <Redirect to='/' />
        return (
            <div style={{paddingTop: '15em'}}>
                This feature is coming soon!
            </div>
        )
    }
}

export default Deals