import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import {TextField} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import firebase from '../Config/firebase'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import style from 'react-big-calendar/lib/css/react-big-calendar.css';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import tangoLogo from '../Assets/TangoLogo.png'
import reservationImage from '../Assets/reservation.png'
import confirmImage from '../Assets/confirmReservation.png'

import moment from 'moment'
const db = firebase.firestore();

const localizer = momentLocalizer(moment)

export default class Reservations extends Component {
    state = {
        today: new Date()
    }

    componentDidMount(){
        // console.log('Loaded Reservations Page', this.props.user.id, this.props)

        if(!this.props.user.data.reservationUser && this.props.user.data.reservationPolicy){
            this.setState({uploadNewReservationUser: true})
        }

        if(this.props.user.data.reservationUser){
            this.setState({reservationPolicy: this.props.user.data.reservationPolicy})
        }else{
            this.setState({reservationPolicy: false})
        }
        let todayStart = new Date(moment().startOf('day').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
        let yesterdayStart = moment(todayStart).subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss')
        yesterdayStart = Date(yesterdayStart)


        let tables = [];
        this.setState({
            userId: this.props.user.id
        }, () => {
            db.collection("businesses").doc(this.state.userId)
              .get()
              .then((querySnapshot) => {
                  if(querySnapshot.data().reservationPolicy){
                    let tables = querySnapshot.data().reservationPolicy.tables
                    this.setState({ tablePolicy: tables, reservationPolicy:querySnapshot.data().reservationPolicy});
                  }else{
                    this.setState({ tablePolicy: null });
                  }

            });
        })
        

        let reservations = []
        db.collection("reservations").where("businessId", "==", this.props.user.id)
        // .where("date", ">=", yesterdayStart)
        .get()
        .then((querySnapshot)=> {
            querySnapshot.forEach((doc)=> {
                let reservation = doc.data()
                
                //ignore already cancelled
                if (reservation.cancelled){
                    return
                }

                reservation.id = doc.id
                reservation.date = reservation.date.toDate()
                reservation.date = `${reservation.date.getMonth()+1}/${reservation.date.getDate()}/${reservation.date.getFullYear()}`
                
                //use these fields to render in calendar
                reservation.allDay = false
                reservation.title = reservation.userName
                reservation.start = new Date(moment(reservation.date).format('MMMM DD, YYYY') + ' ' + moment(reservation.time, "h:mm:ss A").format("HH:mm:ss"))
                reservation.end = new Date(moment(reservation.date).format('MMMM DD, YYYY') + ' ' + moment(reservation.time, "h:mm:ss A").add(this.props.user.data.reservationPolicy.reservationDuration,'hours').format("HH:mm:ss"))

                reservations.push(reservation)
            });
            this.setState({reservations: reservations})
            console.log(this.state)
        })
        .catch((error)=> {
            console.log("Error getting documents: ", error);
        });

    }
    openReservationPolicy = (item) =>{
        console.log('clciked reservation policy', item)
        if(item === 'cancellationFee'){
            this.setState({editPolicy: 'cancellationFee'})
        }
        if(item === 'cancelTimeFrame'){
            this.setState({editPolicy: 'cancelTimeFrame'})
        }
        if(item === 'maxOccupancy'){
            this.setState({editPolicy: 'maxOccupancy'})
        }
        if(item === 'maxReservations'){
            this.setState({editPolicy: 'maxReservations'})
        }
        if(item === 'mergeTables'){
            this.setState({editPolicy: 'mergeTables'})
        }
        if(item === 'maxReservationDuration'){
            this.setState({editPolicy: 'maxReservationDuration'})
        }
        this.setState({openReservationPolicy: true})
    }
    closeReservationPolicy = () => {
        this.setState({openReservationPolicy: false, editPolicy: null})
    }
    handleChange = (e)=>{
        console.log('handle input', this.state)
        this.setState({[e.target.id]: e.target.value})
    } 
    handleMergeTables = (e)=>{
        console.log('handle input', e.target.value)
        if(e.target.value === 'yes'){
            this.setState({mergeTables: true})
        }
        if(e.target.value === 'no'){
            this.setState({mergeTables: false})
        }
    } 
    updateReservationPolicy = () => {
        console.log('clicked update reservation policy for', this.state.editPolicy)
        
        if(this.state.editPolicy === 'cancellationFee' && !this.state.cancellationFee){
            return this.setState({cancellationFeeError: true})
        }
        if(this.state.editPolicy === 'cancelTimeFrame' && !this.state.cancelTimeFrame){
            return this.setState({cancelTimeFrameError: true})
        }
        if(this.state.editPolicy === 'maxOccupancy' && !this.state.maxOccupancy){
            return this.setState({maxOccupancyError: true})
        }
        if(this.state.editPolicy === 'maxReservations' && !this.state.maxReservations){
            return this.setState({maxReservationsError: true})
        }
        if(this.state.editPolicy === 'mergeTables' && this.state.mergeTables === undefined){
            return this.setState({mergeTablesError: true})
        }
        if(this.state.editPolicy === 'maxReservationDuration' && !this.state.maxReservationDuration){
            return this.setState({maxReservationDurationError: true})
        }

        var businessRef = db.collection("businesses").doc(this.props.user.id);

        this.setState({loading:true})

        if(this.state.editPolicy === 'cancellationFee'){
            businessRef.update({
                "reservationPolicy.cancellationFee": parseInt(this.state.cancellationFee)
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
        }
        if(this.state.editPolicy === 'cancelTimeFrame'){
            businessRef.update({
                "reservationPolicy.cancellationTimeFrame":  parseInt(this.state.cancelTimeFrame)
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
        }
        if(this.state.editPolicy === 'maxOccupancy'){
            businessRef.update({
                "reservationPolicy.maxOccupancy":  parseInt(this.state.maxOccupancy)
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
        }
        if(this.state.editPolicy === 'maxReservations'){
            businessRef.update({
                "reservationPolicy.maxReservations":  parseInt(this.state.maxReservations)
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
        }
        if(this.state.editPolicy === 'mergeTables'){
            businessRef.update({
                "reservationPolicy.mergeTables": this.state.mergeTables
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
        }
        if(this.state.editPolicy === 'maxReservationDuration'){
            businessRef.update({
                "reservationPolicy.reservationDuration": parseInt(this.state.maxReservationDuration)
            })
            .then(()=> {
                console.log("Document successfully updated!");
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
        }
    }
 
    onReservationClick(event) {
        this.setState({openReservation: true, selectedReservationToView: event})
        console.log(event) //Shows the event details provided while booking
    }
    handleCloseReservation = ()=>{
        this.setState({closeReservation: false, selectedReservationToView: null})
    }
    cancelReservationNoFee = () =>{
        console.log('clicked cancel reservatinon no fee', this.state.selectedReservationToView)
        this.setState({loading: true, disableCancelButton: true})
        
        db.collection("reservations").doc(this.state.selectedReservationToView.id).delete().then(()=> {
            console.log("Document successfully deleted!");
            window.location.reload()
        }).catch((error)=>{
            console.error("Error removing document: ", error);
            this.setState({loading: false, disableCancelButton: false})
        });
    }
    cancelReservation = () =>{
        console.log('clicked cancel reservatinon', this.state.selectedReservationToView)

        this.setState({loading: true, disableCancelButton: true})
        var reservation = db.collection("reservations").doc(this.state.selectedReservationToView.id);

        return reservation.update({
            cancelled: true,
            applyFee: true
        })
        .then(()=> {
            console.log("Document successfully updated!");
            window.location.reload()
        })
        .catch((error)=>{
            console.error("Error updating document: ", error);
            this.setState({loading: false, disableCancelButton: false})
        });

    }

    getStarted = () =>{
        this.setState({clickedGetStarted: true})
    }
    submitNewReservationPolicy = ()=>{
        console.log('clicked get save new res policy', this.state)

        let tables = []

        if(this.state.mergeTables === undefined){
            console.log('why is this mergeTables')
            return this.setState({mergeTablesError: true})
        }
        else{
            this.setState({mergeTablesError: false})
        }
        if(!this.state.cancellationFee){
            console.log('why is this cancellationFee')
            return this.setState({cancellationFeeError: true})
        }else{
            this.setState({cancellationFeeError: false})
        }
        if(!this.state.cancelTimeFrame){
            console.log('why is this cancelTimeFrame')
            return this.setState({cancelTimeFrameError: true})
        }
        else{
            this.setState({cancelTimeFrameError: false})
        }
        if(!this.state.maxOccupancy){
            console.log('why is this maxOccupancy')
            return this.setState({maxOccupancyError: true})
        }
        else{
            this.setState({maxOccupancyError: false})
        }
        if(!this.state.maxReservations){
            console.log('why is this maxReservations')
            return this.setState({maxReservationsError: true})
        }
        else{
            this.setState({maxReservationsError: false})
        }
        if(!this.state.maxReservationDuration){
            console.log('why is this maxReservationDuration')
            return this.setState({maxReservationDurationError: true})
        } else{
            this.setState({maxReservationDurationError: false})
        }

        if(this.state.twoPersonTables){
            console.log(this.state.twoPersonTables)
            let table = {tables: parseInt(this.state.twoPersonTables), numberOfPeople: 2}
            tables.push(table)
        }
        if(this.state.threePersonTables){
            console.log(this.state.threePersonTables)
            let table = {tables: parseInt(this.state.threePersonTables), numberOfPeople: 3}
            tables.push(table)
        }
        if(this.state.fourPersonTables){
            console.log(this.state.fourPersonTables)
            let table = {tables: parseInt(this.state.fourPersonTables), numberOfPeople: 4}
            tables.push(table)
        }
        if(this.state.fivePersonTables){
            console.log(this.state.fivePersonTables)
            let table = {tables: parseInt(this.state.fivePersonTables), numberOfPeople: 5}
            tables.push(table)
        }
        if(this.state.sixPersonTables){
            console.log(this.state.sixPersonTables)
            let table = {tables: parseInt(this.state.sixPersonTables), numberOfPeople: 6}
            tables.push(table)
        }
        if(this.state.sevenPersonTables){
            console.log(this.state.sevenPersonTables)
            let table = {tables: parseInt(this.state.sevenPersonTables), numberOfPeople: 7}
            tables.push(table)
        }
        if(this.state.eightPersonTables){
            console.log(this.state.eightPersonTables)
            let table = {tables: parseInt(this.state.eightPersonTables), numberOfPeople: 8}
            tables.push(table)
        }
        if(this.state.tenPersonTables){
            console.log(this.state.tenPersonTables)
            let table = {tables: parseInt(this.state.tenPersonTables), numberOfPeople: 10}
            tables.push(table)
        }
        if(this.state.twelvePersonTables){
            console.log(this.state.twelvePersonTables)
            let table = {tables: parseInt(this.state.twelvePersonTables), numberOfPeople: 12}
            tables.push(table)
        }
        if(!tables.length){
            alert('Please select at least one table size setting')
            return
        }

        let reservationPolicy = {
            enabled: true,
            chargeCancellationFee: true,
            cancellationFee: parseInt(this.state.cancellationFee),
            maxOccupancy: parseInt(this.state.maxOccupancy),
            maxReservations: parseInt(this.state.maxReservations),
            reservationDuration: parseInt(this.state.maxReservationDuration),
            cancellationTimeFrame: parseInt(this.state.cancelTimeFrame),
            tables: tables,
            mergeTables: this.state.mergeTables
        }
        this.setState({uploadingNewReservationPolicy: true})

        console.log('running', reservationPolicy)
        var businessRef = db.collection("businesses").doc(this.props.user.id);

        businessRef.update({"reservationPolicy": reservationPolicy})
        .then(()=> {
            console.log("Document successfully updated!");
            this.setState({uploadingNewReservationPolicy: false, uploadNewReservationUser:true})
        })
        .catch((error)=> {
            console.error("Error updating document: ", error);
        });

    }

    openTablePolicy = (table) => {
        this.setState({tableToUpdate: table, newTableSettings: {tables: null, numberOfPeople: null}, openTablePolicy: true });
    }

    handleTableChange = (event) => {
        console.log('table policy: ', this.state.tablePolicy);
        // Update this.state.tablePolicy according to numberOfTables or tables
        if (event.target.id === "numberOfTables") {
            // number of tables
            this.setState({
                newTableSettings: {
                    ...this.state.newTableSettings,
                    tables: parseInt(event.target.value)
                }
            });
        } else if (event.target.id === "tableSize") {
            // number of people
            this.setState({
                newTableSettings: {
                    ...this.state.newTableSettings,
                    numberOfPeople: parseInt(event.target.value)
                }
            });
        }
    }

    closeTablePolicy = () => {
        this.setState({ tableToUpdate: null, newTableSettings: {tables: null, numberOfPeople: null}, openTablePolicy: false })
    }

    saveTablePolicy = (event) => {
        if (this.state.tableToUpdate !== null) {
            if (this.state.newTableSettings.tables !== null && 
                this.state.newTableSettings.numberOfPeople !== null) {
                // Send to firebase
                // let tables = [];
                // db.collection("businesses").doc(this.state.userId)
                //     .update({

                //     })
               
                let newTablePolicy = [];
                for (let i=0; i<this.state.tablePolicy.length; i++) {
                    if (this.state.tablePolicy[i].tables === this.state.tableToUpdate.tables && 
                        this.state.tablePolicy[i].numberOfPeople === this.state.tableToUpdate.numberOfPeople) {
                        newTablePolicy.push(this.state.newTableSettings)
                    } else {
                        newTablePolicy.push(this.state.tablePolicy[i])
                    }
                }

                this.setState({
                    tablePolicy: newTablePolicy,
                    tableToUpdate: null,
                    openTablePolicy: false,
                    newTableSettings: {tables: null, numberOfPeople: null}
                }, () =>  {
                    let businessRef = db.collection("businesses").doc(this.state.userId);
                    
                    this.setState({loading:true})

                    businessRef.update({
                        "reservationPolicy.tables": this.state.tablePolicy
                    })
                    .then(()=> {
                        console.log("Document successfully updated!");
                        window.location.reload()
                    })
                    .catch((error)=> {
                        console.error("Error updating document: ", error);
                    });
                });
            } else { this.closeTablePolicy(); }
        } else { this.closeTablePolicy(); }
    }

    render() {

        if(this.state.uploadNewReservationUser){
            return (
                <div style={{marginTop:'6em', marginLeft:'2em', marginRight: '2em', marginBottom:'5em', color:'black'}}>
                    <Paper style={{padding:'1em'}}>
                    <img src={tangoLogo} style={{width:'4em'}} alt="" srcSet=""/>
                        <h4>Success, you've set up your reservation policy</h4>
                        <p>A Tango rep will be in touch shortly</p>
                    </Paper>
                </div>
            )
        }
        return (
        <div style={{marginTop:'6em', marginLeft:'2em', marginRight: '2em', marginBottom:'5em', color:'black'}}>
            {this.state.tableToUpdate && 
                <Dialog onClose={this.closeTablePolicy} aria-labelledby="tablePolicy" open={this.state.openTablePolicy}>
                    <DialogTitle id="tablePolicy">Edit Table Policy</DialogTitle>

                    <div style={{padding:'1em'}}>
                        <TextField style={{width:'90%'}} error={this.state.TableError} onChange={this.handleTableChange} id="numberOfTables" label={"Number of Tables (" + this.state.tableToUpdate.tables + ")"}  type="number" InputProps={{ inputProps: { min: 1} }}/>
                        <TextField style={{width:'90%'}} error={this.state.TableError} onChange={this.handleTableChange} id="tableSize" label={"Table Size (" + this.state.tableToUpdate.numberOfPeople + ")" } type="number" InputProps={{ inputProps: { min: 1} }}/>
                    </div>

                    {this.state.loading && <LinearProgress />}
                    <Button color='secondary' onClick={this.closeTablePolicy}>Cancel</Button>
                    <Button color='primary' onClick={this.saveTablePolicy}>Save Change</Button>
                </Dialog>
            }

            <Paper style={{paddingTop: "1%", marginLeft:'5em', marginRight:'5em'}}>
                    <h4>Table Management</h4>
                    {this.state.tablePolicy &&
                    <Grid container spacing={3}>
                    {this.state.tablePolicy.map((i, j) => {
                        return (
                        <Grid key={j} item xs={4}>
                            <Button key={j} onClick={()=>this.openTablePolicy(i)}>
                                <EditRoundedIcon style={{width:'.75em', paddingRight:"1%"}}/> 
                                {i.tables === 1 ? <div> {i.tables} table</div> : <div> {i.tables} tables</div> } of {i.numberOfPeople} people
                            </Button>
                        </Grid>
                        )
                    })}
                    </Grid>
                    }
                </Paper>


    {/* This is the signup flow for clients that are signed up with digital ordering but not reservations */}
            {!this.state.reservationPolicy ?
            <div style={{width:'60%', margin:'auto'}}>
                {!this.state.clickedGetStarted ?
                <Paper style={{padding:'1em'}}>
                <img src={tangoLogo} style={{width:'4em'}} alt="" srcSet=""/>
                <p>Get started with reservations for free</p>
                    <Card variant="outlined">
                        <CardContent>
                            <img src={reservationImage} style={{width:'65%'}} alt="" srcSet=""/>             
                            <img src={confirmImage} style={{width:'9em', borderRadius:'3px', boxShadow: '0 0 10px rgba(0,0,0,0.6)'}} alt="" srcSet=""/>
                        </CardContent>
                    </Card>

                <p style={{paddingTop:'1em'}}>A flexible and reliable reservation system built to help grow your business</p>
                <Button color='primary' variant='contained' onClick={this.getStarted}>get started for free</Button>
                </Paper> 
                :
                <Paper style={{padding:'1em'}}>
                    <Paper elevation={3} style={{color:'black', padding:'1em', margin:'1em'}}>
                            <p>How many tables of each size do you have?</p>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                <TextField onChange={this.handleChange} id="twoPersonTables" label="2 Person Tables" type="number"  InputProps={{inputProps: { min: 0  } }}/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                <TextField onChange={this.handleChange} id="threePersonTables" label="3 Person Tables" type="number" InputProps={{inputProps: { min: 0  } }}/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                <TextField onChange={this.handleChange} id="fourPersonTables" label="4 Person Tables" type="number" InputProps={{inputProps: { min: 0  } }}/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                <TextField onChange={this.handleChange} id="fivePersonTables" label="5 Person Tables" type="number" InputProps={{inputProps: { min: 0  } }}/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                <TextField onChange={this.handleChange} id="sixPersonTables" label="6 Person Tables" type="number" InputProps={{inputProps: { min: 0  } }}/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                <TextField onChange={this.handleChange} id="sevenPersonTables" label="7 Person Tables" type="number" InputProps={{inputProps: { min: 0  } }}/> 
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                <TextField onChange={this.handleChange} id="eightPersonTables" label="8 Person Tables" type="number" InputProps={{inputProps: { min: 0  } }}/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                <TextField onChange={this.handleChange} id="tenPersonTables" label="10 Person Tables" type="number" InputProps={{inputProps: { min: 0  } }}/> 
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                <TextField onChange={this.handleChange} id="twelvePersonTables" label="12 Person Tables" type="number" InputProps={{inputProps: { min: 0  } }}/>
                                </Grid>
                            </Grid>
                        </Paper>

                        <br/>
                        <InputLabel id="mergetTableInput">Are you willing to move/merge tables to accommodate reservations?</InputLabel>
                        <Select style={{width:'80%'}}
                        labelId="mergetTableInput" id="mergeTables"
                        error={this.state.mergeTablesError} value={this.state.mergeTables}
                        onChange={this.handleMergeTables}>
                        <MenuItem value={'yes'}>Yes</MenuItem>
                        <MenuItem value={'no'}>No</MenuItem>
                        </Select>

                        <TextField style={{width:'90%'}} error={this.state.cancellationFeeError} onChange={this.handleChange}id="cancellationFee" label="Cancellation Fee"  type="number" InputProps={{ inputProps: { min: 1} }}/>
                        <TextField style={{width:'90%'}} error={this.state.cancelTimeFrameError} onChange={this.handleChange}id="cancelTimeFrame" label="Cancellation Time Frame (in hours)"  type="number" InputProps={{ inputProps: { min: 1} }}/>
                        <TextField style={{width:'90%'}} error={this.state.maxOccupancyError} onChange={this.handleChange}id="maxOccupancy" label="Max occupancy"  type="number" InputProps={{ inputProps: { min: 1} }}/>
                        <TextField style={{width:'90%'}} error={this.state.maxReservationsError} onChange={this.handleChange}id="maxReservations" label="Max Reservations at one time"  type="number" InputProps={{ inputProps: { min: 1} }}/>
                        <TextField style={{width:'90%'}} error={this.state.maxReservationDurationError} onChange={this.handleChange} id="maxReservationDuration" label="Max Reservation Duration"  type="number" InputProps={{ inputProps: { min: 1} }}/>

                    <br/><br/>
                    {this.state.uploadingNewReservationPolicy &&  <LinearProgress />}
                    <Button variant='contained' style={{margin:'1em', backgroundColor:'#ff3333', color:'white'}} onClick={()=>this.setState({clickedGetStarted:false})}>Cancel</Button>
                    <Button color='primary' variant='contained' style={{margin:'1em'}} onClick={this.submitNewReservationPolicy}>Save Reservation Policy</Button>
                </Paper>
              }
            </div> 
            :
            <div style={{marginTop:'5em', marginLeft:'4em', marginRight: '4em', marginBottom:'5em'}}>
        
            <Dialog onClose={this.closeReservationPolicy} aria-labelledby="reservationPolicy" open={this.state.openReservationPolicy}>
                <DialogTitle id="reservationPolicy">Edit Reservation Policy</DialogTitle>
        
                <div style={{padding:'1em'}}>
                    {this.state.editPolicy === 'cancellationFee' &&
                    <TextField style={{width:'90%'}} error={this.state.cancellationFeeError} onChange={this.handleChange}id="cancellationFee" label="Cancellation Fee"  type="number" InputProps={{ inputProps: { min: 1} }}/>
                    }
                    {this.state.editPolicy === 'cancelTimeFrame' &&
                    <TextField style={{width:'90%'}} error={this.state.cancelTimeFrameError} onChange={this.handleChange}id="cancelTimeFrame" label="Cancellation Time Frame (in hours)"  type="number" InputProps={{ inputProps: { min: 1} }}/>
                    }
                    {this.state.editPolicy === 'maxOccupancy' &&
                    <TextField style={{width:'90%'}} error={this.state.maxOccupancyError} onChange={this.handleChange}id="maxOccupancy" label="Max occupancy"  type="number" InputProps={{ inputProps: { min: 1} }}/>
                    }
                    {this.state.editPolicy === 'maxReservations' &&
                    <TextField style={{width:'90%'}} error={this.state.maxReservationsError} onChange={this.handleChange}id="maxReservations" label="Max Reservations at one time"  type="number" InputProps={{ inputProps: { min: 1} }}/>
                    }
                
                    {this.state.editPolicy === 'mergeTables' &&
                    <span>
                        <InputLabel id="mergetTableInput">Are you willing to move/merge tables to accommodate reservations?</InputLabel>
                        <Select style={{width:'90%'}}
                        labelId="mergetTableInput"
                        id="mergeTables"
                        error={this.state.mergeTablesError}
                        value={this.state.mergeTables}
                        onChange={this.handleMergeTables}
                        >
                        <MenuItem value={'yes'}>Yes</MenuItem>
                        <MenuItem value={'no'}>No</MenuItem>
                        </Select> 
                        </span>                       
                    }

                    {this.state.editPolicy === 'maxReservationDuration' &&
                    <TextField style={{width:'90%'}} error={this.state.maxReservationDurationError} onChange={this.handleChange} id="maxReservationDuration" label="Max Reservation Duration"  type="number" InputProps={{ inputProps: { min: 1} }}/>
                    }
                </div>
                {this.state.loading && <LinearProgress />}
                <Button color='secondary' onClick={this.closeReservationPolicy}>Cancel</Button>
                <Button color='primary' onClick={this.updateReservationPolicy}>Save Change</Button>

            </Dialog>


        {/* Current reservation policy */}
            <Paper style={{paddingTop:'1%', marginBottom:'3em', marginLeft:'5em', marginRight:'5em'}}>
                <h4>Reservation Policy</h4>
                {this.state.reservationPolicy &&
                <Grid container spacing={3}>
                    <Grid item xs={5}>
                        <Button style={{width:"90%"}} onClick={()=>this.openReservationPolicy('cancellationFee')}>
                            <EditRoundedIcon style={{width:'.75em', paddingRight:"3%"}}/> 
                            <div>
                                <div style={{fontSize:"90%", color:"#212121"}}>
                                    Cancellation Fee: 
                                </div>
                                <div style={{paddingTop:"1%", fontWeight:"bold"}}>
                                    ${this.state.reservationPolicy.cancellationFee}
                                </div>
                            </div>
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button onClick={()=>this.openReservationPolicy('cancelTimeFrame')}>
                            <EditRoundedIcon style={{width:'.75em', paddingRight:"1%"}}/> 
                            <div>
                                <div style={{fontSize:"80%", color:"#212121"}}>
                                    If a users cancels within this many hours of their reservation they will be charged:  
                                </div>
                                <div style={{paddingTop:"1%", fontWeight:"bold"}}>
                                    {this.state.reservationPolicy.cancellationTimeFrame} hours
                                </div>
                            </div>
                        </Button>
                    </Grid>
                    {/* <Grid item xs={4}>
                        <Button style={{width:"90%"}} onClick={()=>this.openReservationPolicy('maxOccupancy')}>
                            <EditRoundedIcon style={{width:'.75em', paddingRight:"3%"}}/> 
                            <div>
                                <div style={{fontSize:"90%", color:"#212121"}}>
                                    Maximum Occupancy:
                                </div>
                                <div style={{paddingTop:"1%", fontWeight:"bold"}}>
                                    {this.state.reservationPolicy.maxOccupancy}
                                </div>
                            </div>
                        </Button>
                    </Grid> */}
                    <Grid item xs={4}>
                        <Button style={{width:"90%"}} onClick={()=>this.openReservationPolicy('maxReservations')}>
                            <EditRoundedIcon style={{width:'.75em', paddingRight:"1%"}}/> 
                            <div>
                                <div style={{fontSize:"90%", color:"#212121"}}>
                                    Maximum reservation size:
                                </div>
                                <div style={{paddingTop:"1%", fontWeight:"bold"}}>
                                    {this.state.reservationPolicy.maxReservations} people
                                </div>
                            </div>
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button onClick={()=>this.openReservationPolicy('mergeTables')}>
                            <EditRoundedIcon style={{width:'.75em', paddingRight:"1%"}}/> 
                            <div>
                                <div style={{fontSize:"90%", color:"#212121"}}>
                                    Am I willing to move/merge tables to accomodate reservations? 
                                </div>
                                <div style={{paddingTop:"1%", fontWeight:"bold"}}>
                                    {this.state.reservationPolicy.mergeTables?  <div>Yes</div>:<div> No</div> }
                                </div>
                            </div>
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button style={{width:"80%"}} onClick={()=>this.openReservationPolicy('maxReservationDuration')}>
                            <EditRoundedIcon style={{width:'.75em', paddingRight:"1%"}}/> 
                            <div>
                                <div style={{fontSize:"90%", color:"#212121"}}>
                                    Maximum Reservation Duration:
                                </div>
                                <div style={{paddingTop:"1%", fontWeight:"bold"}}>
                                    {this.state.reservationPolicy.reservationDuration} hours
                                </div>
                            </div>
                            
                        </Button>
                    </Grid>
            
                </Grid>
                }
            </Paper>

            <Paper style={{paddingTop:'1em', marginBottom:'2em'}}>

            {this.state.reservations &&
            <Calendar
                localizer={localizer}
                views = {['month','week', 'day']}
                events={this.state.reservations}
                startAccessor="start"
                endAccessor="end"
                defaultView='day'
                min={
                    new Date(
                        this.state.today.getFullYear(), 
                        this.state.today.getMonth(), 
                        this.state.today.getDate(), 
                        10
                    )
                }
                style={{ height: 700 }}
                onSelectEvent={event => this.onReservationClick(event)}
            />               

            }
            </Paper>
    
            {this.state.selectedReservationToView &&
            <Dialog onClose={this.handleCloseReservation} aria-labelledby="reservationModal" open={this.state.openReservation}>
                <DialogTitle id="reservationModal">Reservation</DialogTitle>

                <span style={{marginLeft:'2em', marginRight:'2em', marginBottom:'2em'}}>
                <p>Name: {this.state.selectedReservationToView.userName}</p>
                <p>Phone: {this.state.selectedReservationToView.userPhone}</p>
                <p>Date: {this.state.selectedReservationToView.date}</p>
                <p>Time: {this.state.selectedReservationToView.time}</p>
                <p>Party Size: {this.state.selectedReservationToView.numberOfPeople}</p>
                
                {this.state.selectedReservationToView.tablesBooked &&
                <p style={{textAlign:'center'}}>Tables: {this.state.selectedReservationToView.tablesBooked.map((table, index) => (
                    <p key={index}>{table} person table</p>
                ))}</p>
                }

                <br/>
                {this.state.loading && <LinearProgress />}
                {this.state.selectedReservationToView.cancellationFee ?
                <div>
                    <Button disabled={this.state.disableCancelButton} variant='outlined' color='secondary' onClick={this.cancelReservation}>Cancel Reservation (Apply Fee)</Button>
                    <br/><br/>
                    <Button disabled={this.state.disableCancelButton} variant='outlined' onClick={this.cancelReservationNoFee}>Cancel Reservation (No Fee)</Button>
                </div>
                :
                <div>
                    <Button disabled={this.state.disableCancelButton} variant='outlined' onClick={this.cancelReservationNoFee}>Cancel Reservation (No Fee)</Button>
                </div>
                }
           
                </span>
            </Dialog>
            }
              
            </div>
        }
            
        </div>

        )
    }
}
