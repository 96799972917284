import React from "react"
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

export default function ProductOptions(props) {

    console.log('clicked add product options', props)
    return (
        props.productOptions.map((val, idx)=> {
        let productOptionsId = `optionName-${idx}`, optionSelectMultipleId = `chooseMultiple-${idx}`, 

        optionOneNameId = `optionOneName-${idx}`, optionOneCostId = `optionOneCost-${idx}`,
        optionTwoNameId = `optionTwoName-${idx}`, optionTwoCostId = `optionTwoCost-${idx}`, 
        optionThreeNameId = `optionThreeName-${idx}`, optionThreeCostId = `optionThreeCost-${idx}`,
        optionFourNameId = `optionFourName-${idx}`, optionFourCostId = `optionFourCost-${idx}`,
        optionFiveNameId = `optionFiveName-${idx}`, optionFiveCostId = `optionFiveCost-${idx}`,
        optionSixNameId = `optionSixName-${idx}`, optionSixCostId = `optionSixCost-${idx}`,
        optionSevenNameId = `optionSevenName-${idx}`, optionSevenCostId = `optionSevenCost-${idx}`,
        optionEightNameId = `optionEightName-${idx}`, optionEightCostId = `optionEightCost-${idx}`,
        optionNineNameId = `optionNineName-${idx}`, optionNineCostId = `optionNineCost-${idx}`

        return (
            <div key={idx}>
            
            <div className='row' style={{borderRadius: '10px', border: '1px solid grey', padding:'1em', margin:'2em'}}>
                {/* creat options here */}
                <div style={{marginTop: '.5em'}}>
                    <input id="optionName" className="optionName" placeholder="Option Name" style={{width: '60%'}} data-id={idx} id={productOptionsId} value={props.productOptions[idx].optionName}/>
                </div>

                <div style={{marginTop: '.5em'}}>
                    <select id="chooseMultiple" className="chooseMultiple" name={optionSelectMultipleId} data-id={idx} id={optionSelectMultipleId} value={props.productOptions[idx].chooseMultiple} required>
                        <option value="">Can a user select more than one?</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select>
                </div>

                <div style={{padding:'.5em'}}>
                    <label htmlFor="optionOneName">Option 1 Name: </label>
                    <input name={optionOneNameId} data-id={idx} id={optionOneNameId} value={props.productOptions[idx].optionOneName} type="text" className="optionOneName"/>
                    <label htmlFor="optionOneCost">Additional Cost: </label>
                    <input name={optionOneCostId} data-id={idx} id={optionOneCostId} value={props.productOptions[idx].optionOneCost} type="text" className="optionOneCost"/>
                </div>

                <div style={{padding:'.5em'}}>
                    <label htmlFor="optionOneName">Option 2 Name: </label>
                    <input name={optionTwoNameId} data-id={idx} id={optionTwoNameId} value={props.productOptions[idx].optionTwoName} type="text" className="optionTwoName"/>
                    <label htmlFor="optionTwoCost">Additional Cost: </label>
                    <input name={optionTwoCostId} data-id={idx} id={optionTwoCostId} value={props.productOptions[idx].optionTwoCost} type="text" className="optionTwoCost"/>
                </div>

                <div style={{padding:'.5em'}}>
                    <label htmlFor="optionOneName">Option 3 Name: </label>
                    <input name={optionThreeNameId} data-id={idx} id={optionThreeNameId} value={props.productOptions[idx].optionThreeName} type="text" className="optionThreeName"/>
                    <label htmlFor="optionThreeCost">Additional Cost: </label>
                    <input name={optionThreeCostId} data-id={idx} id={optionThreeCostId} value={props.productOptions[idx].optionThreeCost} type="text" className="optionThreeCost"/>
                </div>
  
                <div style={{padding:'.5em'}}>
                    <label htmlFor="optionFourName">Option 4 Name: </label>
                    <input name={optionFourNameId} data-id={idx} id={optionFourNameId} value={props.productOptions[idx].optionFourName} type="text" className="optionFourName"/>
                    <label htmlFor="optionFourCost">Additional Cost: </label>
                    <input name={optionFourCostId} data-id={idx} id={optionFourCostId} value={props.productOptions[idx].optionFourCost} type="text" className="optionFourCost"/>
                </div>              

                <div style={{padding:'.5em'}}>
                    <label htmlFor="optionFiveName">Option 5 Name: </label>
                    <input name={optionFiveNameId} data-id={idx} id={optionFiveNameId} value={props.productOptions[idx].optionFiveName} type="text" className="optionFiveName"/>
                    <label htmlFor="optionFiveCost">Additional Cost: </label>
                    <input name={optionFiveCostId} data-id={idx} id={optionFiveCostId} value={props.productOptions[idx].optionFiveCost} type="text" className="optionFiveCost"/>
                </div>

                <div style={{padding:'.5em'}}>
                    <label htmlFor="optionSixName">Option 6 Name: </label>
                    <input name={optionSixNameId} data-id={idx} id={optionSixNameId} value={props.productOptions[idx].optionSixName} type="text" className="optionSixName"/>
                    <label htmlFor="optionSixCost">Additional Cost: </label>
                    <input name={optionSixCostId} data-id={idx} id={optionSixCostId} value={props.productOptions[idx].optionSixCost} type="text" className="optionSixCost"/>
                </div>

                <div style={{padding:'.5em'}}>
                    <label htmlFor="optionSevenName">Option 7 Name: </label>
                    <input name={optionSevenNameId} data-id={idx} id={optionSevenNameId} value={props.productOptions[idx].optionSevenName} type="text" className="optionSevenName"/>
                    <label htmlFor="optionSevenCost">Additional Cost: </label>
                    <input name={optionSevenCostId} data-id={idx} id={optionSevenCostId} value={props.productOptions[idx].optionSevenCost} type="text" className="optionSevenCost"/>
                </div>

                <div style={{padding:'.5em'}}>
                    <label htmlFor="optionEightName">Option 8 Name: </label>
                    <input name={optionEightNameId} data-id={idx} id={optionEightNameId} value={props.productOptions[idx].optionEightName} type="text" className="optionEightName"/>
                    <label htmlFor="optionEightCost">Additional Cost: </label>
                    <input name={optionEightCostId} data-id={idx} id={optionEightCostId} value={props.productOptions[idx].optionEightCost} type="text" className="optionEightCost"/>
                </div>

                <div style={{padding:'.5em'}}>
                    <label htmlFor="optionNineName">Option 9 Name: </label>
                    <input name={optionNineNameId} data-id={idx} id={optionNineNameId} value={props.productOptions[idx].optionNinetName} type="text" className="optionNineName"/>
                    <label htmlFor="optionNineCost">Additional Cost: </label>
                    <input name={optionNineCostId} data-id={idx} id={optionNineCostId} value={props.productOptions[idx].optionNineCost} type="text" className="optionNineCost"/>
                </div>

            </div>

            </div>
        )
        })
    )
}


