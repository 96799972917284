import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import {Redirect} from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import ProductOptions from "./ProductOptions"
import firebase from '../../Config/firebase'
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {NavLink} from 'react-router-dom';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import S3FileUpload from 'react-s3';
import { catchClause } from '@babel/types';
const db = firebase.firestore();

class Products extends Component {
    state={
        loading: false,
        productOptions: [],
    }

    useStyles = makeStyles((theme) => ({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
        },
        selectEmpty: {
          marginTop: theme.spacing(2),
        },
      }));


    componentDidMount(){
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
               console.log('USER', user);
              this.setState({businessId: user.uid})
            } else {
                console.log('NO USER');
                this.setState({businessId: false})
            }
        });
    }

    addProductOptions = (e) => {
        e.preventDefault()
        this.setState({clickedAddProductOptions: true})
        this.setState((prevState) => ({
          productOptions: [...prevState.productOptions, {}],
        }));
    }
    handleProductType = (e) => {
        this.setState({[e.target.name]: e.target.value})
        console.log('set input', this.state)
    }; 

    handleChange = (e) => {
        if (["optionName", "chooseMultiple",
        "optionOneName", "optionOneCost", 
        "optionTwoName", "optionTwoCost",
        "optionThreeName", "optionThreeCost",
        "optionFourName", "optionFourCost",
        "optionFiveName", "optionFiveCost",
        "optionSixName", "optionSixCost",
        "optionSevenName", "optionSevenCost",
        "optionEightName", "optionEightCost",
        "optionNineName", "optionNineCost"].includes(e.target.className) ) {
          // console.log('setting product options', e.target.value)
          let productOptions = [...this.state.productOptions]
          console.log(this.state.productOptions)
    
          productOptions[e.target.dataset.id][e.target.className] = e.target.value
  
          this.setState({ productOptions }, () => console.log(this.state))
    
        }
        else{
            this.setState({[e.target.id]: e.target.value})
        }
    }

    handleProductImage = ({target}) =>{
        console.log('select image')
        this.setState({selectedImage: target.files[0]})
    }

    createProduct = () =>{
        console.log('clicked create product', this.state)
        this.setState({loading: true})
        
        //error handling for undefined
        if(!this.state.productType || !this.state.productCategory || !((this.state.productCategory).trim()).length || !this.state.productName || !this.state.productPrice){
            this.setState({loading: false})
            return alert('Please fill out all product details')
        }
        // loop through product options to format for db
        if(this.state.productOptions.length > 0){
            for(let i = 0; i < this.state.productOptions.length; i++){
                this.state.productOptions[i].options = []

                if(this.state.productOptions[i].optionOneName){
                    if(!this.state.productOptions[i].optionOneCost){
                        this.state.productOptions[i].optionOneCost = 0.0
                    }
                    let option = {
                        name: this.state.productOptions[i].optionOneName, 
                        cost: JSON.parse(this.state.productOptions[i].optionOneCost), 
                    }
                    this.state.productOptions[i].options.push(option)
                }
                if(this.state.productOptions[i].optionTwoName){
                    if(!this.state.productOptions[i].optionTwoCost){
                        this.state.productOptions[i].optionTwoCost = 0.0
                    }
                    let option = {
                        name: this.state.productOptions[i].optionTwoName, 
                        cost: JSON.parse(this.state.productOptions[i].optionTwoCost), 
                    }
                    this.state.productOptions[i].options.push(option)
                }
                if(this.state.productOptions[i].optionThreeName){
                    if(!this.state.productOptions[i].optionThreeCost){
                        this.state.productOptions[i].optionThreeCost = 0.0
                    }
                    let option = {
                        name: this.state.productOptions[i].optionThreeName, 
                        cost: JSON.parse(this.state.productOptions[i].optionThreeCost), 
                    }
                    this.state.productOptions[i].options.push(option)
                }
                if(this.state.productOptions[i].optionFourName){
                    if(!this.state.productOptions[i].optionFourCost){
                        this.state.productOptions[i].optionFourCost = 0.0
                    }
                    let option = {
                        name: this.state.productOptions[i].optionFourName, 
                        cost: JSON.parse(this.state.productOptions[i].optionFourCost), 
                    }
                    this.state.productOptions[i].options.push(option)
                }
                if(this.state.productOptions[i].optionFiveName){
                    if(!this.state.productOptions[i].optionFiveCost){
                        this.state.productOptions[i].optionFiveCost = 0.0
                    }
                    let option = {
                        name: this.state.productOptions[i].optionFiveName, 
                        cost: JSON.parse(this.state.productOptions[i].optionFiveCost), 
                    }
                    this.state.productOptions[i].options.push(option)
                }
                if(this.state.productOptions[i].optionSixName){
                    if(!this.state.productOptions[i].optionSixCost){
                        this.state.productOptions[i].optionSixCost = 0.0
                    }
                    let option = {
                        name: this.state.productOptions[i].optionSixName, 
                        cost: JSON.parse(this.state.productOptions[i].optionSixCost), 
                    }
                    this.state.productOptions[i].options.push(option)
                }
                if(this.state.productOptions[i].optionSevenName){
                    if(!this.state.productOptions[i].optionSevenCost){
                        this.state.productOptions[i].optionSixCost = 0.0
                    }
                    let option = {
                        name: this.state.productOptions[i].optionSevenName, 
                        cost: JSON.parse(this.state.productOptions[i].optionSevenCost), 
                    }
                    this.state.productOptions[i].options.push(option)
                }
                if(this.state.productOptions[i].optionEightName){
                    if(!this.state.productOptions[i].optionEightCost){
                        this.state.productOptions[i].optionEightCost = 0.0
                    }
                    let option = {
                        name: this.state.productOptions[i].optionEightName, 
                        cost: JSON.parse(this.state.productOptions[i].optionEightCost), 
                    }
                    this.state.productOptions[i].options.push(option)
                }
                if(this.state.productOptions[i].optionNineName){
                    if(!this.state.productOptions[i].optionNineCost){
                        this.state.productOptions[i].optionNineCost = 0.0
                    }
                    let option = {
                        name: this.state.productOptions[i].optionNineName, 
                        cost: JSON.parse(this.state.productOptions[i].optionNineCost), 
                    }
                    this.state.productOptions[i].options.push(option)
                }
            }

            for(let i = 0; i < this.state.productOptions.length; i++){
                if(!this.state.productOptions[i].chooseMultiple){
                    this.setState({loading: false})
                    return alert("Please select if a user can select multiple of the product option. eg. select 'no' for choose your size and 'yes' for choose multiple sides.")
                }
                this.state.productOptions[i] = {
                    optionName: this.state.productOptions[i].optionName,
                    chooseMultiple: JSON.parse(this.state.productOptions[i].chooseMultiple),
                    options: this.state.productOptions[i].options
                }
            }
            console.log('product options',this.state.productOptions)
        }
        else{
            this.state.productOptions = null 
        }

        if(this.state.productType === 'Beer' || this.state.productType === 'Liquor' || this.state.productType === 'Seltzer' || this.state.productType === 'Wine'){
            var alcohol = true
        }else{
            var alcohol = false
        }

        //remove last character if its a period (avoids double period in pwa)
        let description = this.state.productDescription
        if(description.slice(-1) === '.'){
            description = description.slice(0, -1); 
        }
        // Add a new product
        db.collection('products').add({
            businessId: this.props.user.id,
            businessName: this.props.user.data.businessName,
            businessProfileImageUrl: this.props.user.data.profileImageUrl,
            productCategory: this.state.productCategory,
            productType: this.state.productType,
            productName: this.state.productName,
            productDescription: description,
            productPrice: parseFloat(this.state.productPrice),
            productOptions: this.state.productOptions,
            productActive: true,
            productImageUrl: null,
            stripeConnectId: null,
            alcohol: alcohol,
            productTax: null
        }).then(ref => {
            // console.log('Added document with ID: ', ref.id);

            //if selected image upload to s3
            if(this.state.selectedImage){
                let image = new File([this.state.selectedImage], 'productImage.png', {type: 'image/png'});
                const profileImageConfig = {
                    bucketName: 'tango-pwa',
                    dirName: 'businesses/' + this.state.businessId + '/products/' + ref.id,
                    region: 'us-east-2',
                    accessKeyId: process.env.REACT_APP_AMAZON_S3_ACCESSKEYID,
                    secretAccessKey: process.env.REACT_APP_AMAZON_S3_SECRETACCESSKEY
                }
        
                S3FileUpload.uploadFile(image, profileImageConfig).then((data) => {
                    console.log('uploaded product image to our s3 bucket!', data)  
                    //after success upload image, update firestor doc
                    let productRef = db.collection('products').doc(ref.id);
                    productRef.update({productImageUrl: 'https://tango-pwa.s3.us-east-2.amazonaws.com/businesses/' + this.state.businessId + '/products/' + ref.id + '/productImage.png'});    

                    this.setState({uploadProductSuccess:true})
                    setTimeout(()=>{ 
                        this.setState({uploadProductSuccess: false})
                        window.location.reload()
                    }, 2000);                 
                })
                .catch((error)=> {
                    console.log("Error uploading to s3", error); 
                    this.setState({loading:false})                               
                });
            }else{
                //no image - use upload photo success to flash success alert
                this.setState({uploadProductSuccess:true})
                setTimeout(()=>{ 
                    this.setState({uploadProductSuccess: false})
                    window.location.reload()
                }, 2000);        
            }

        })
        .catch((error)=> {
            console.log("Error", error);   
            this.setState({loading:false})                                                            
        });
   
    }

    render() {        
        if(!this.props.user) return <Redirect to='/' />        
        const {loading, productOptions} = this.state

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        return (
            <div style={{paddingTop: '5em', marginBottom:'3em'}}>
            
            <div style={{marginLeft:'3em'}}>
                <NavLink className='' to='./editProducts' style={{textDecoration:'none', float:'left'}}>
                    <Button variant='contained' color='primary' startIcon={<ArrowBackRoundedIcon />} style={{color:'white'}}>Back to My Products</Button>
                </NavLink>
            </div>
            
            <Paper style={{marginLeft: '5em', marginRight:'5em', marginTop:'4em', padding:'2em'}} elevation={3}>
                <h2>Create a new product</h2>
                <hr/>

                {/* choose product type */}
                <div style={{width:'100%'}}>
                <FormControl style={{width: '50%', marginTop: '4em'}} className={this.useStyles.formControl}>
                    <InputLabel>Product Type</InputLabel>
                    <Select name="productType" id="productType" onChange={this.handleProductType}>
                        <MenuItem value={'Beer'}>Beer</MenuItem>
                        <MenuItem value={'Beverage'}>Beverage (Non Alcoholic)</MenuItem>
                        <MenuItem value={'Coffee / Tea'}>Coffee / Tea</MenuItem>
                        <MenuItem value={'Special'}>Custom</MenuItem>
                        <MenuItem value={'Food'}>Food</MenuItem>
                        <MenuItem value={'Liquor'}>Liquor Products</MenuItem>
                        <MenuItem value={'Merchandise'}>Merchandise</MenuItem>
                        <MenuItem value={'Seltzer'}>Seltzer</MenuItem>
                        <MenuItem value={'Wine'}>Wine</MenuItem>
                    </Select> 
                </FormControl>
                </div>


                {/* all other product inputs */}
                {this.state.productType &&
                <div>
                    <div style={{marginTop: '4em'}}>
                        <InputLabel htmlFor="productCategory">Product Category</InputLabel>
                        <Input style={{width: '70%'}} id="productCategory" onChange={this.handleChange}/>
                    </div>
                    <div style={{marginTop: '4em'}}>
                        <InputLabel htmlFor="productName">Product Name</InputLabel>
                        <Input style={{width: '70%'}} id="productName" onChange={this.handleChange}/>
                    </div>
                    <div style={{marginTop: '4em'}}>
                        <InputLabel htmlFor="productDescription">Product Description</InputLabel>
                        <Input style={{width: '70%'}} id="productDescription" onChange={this.handleChange}/>
                    </div>
                    <div style={{marginTop: '4em'}}>
                        <InputLabel htmlFor="productPrice">Product Price</InputLabel>
                        <Input style={{width: '70%'}} id="productPrice" onChange={this.handleChange} startAdornment={<InputAdornment position="start">$</InputAdornment>}/>
                    </div>

                    <div onChange={this.handleChange} style={{marginTop: '2em'}}>
                    {this.state.clickedAddProductOptions === true &&   
                        <ProductOptions productOptions={productOptions}/>
                    }
                    </div>

                    <Button component="span" onClick={this.addProductOptions}>+ Add a Product Option</Button>
                   
                     {/* upload image */}
                    <div style={{marginTop:'2em'}}>
                        <input onChange={this.handleProductImage} accept="image/*" style={{ display: 'none'}} id="productImage" type="file" />
                        <label htmlFor="productImage">
                            <Button color='primary' variant='outlined' component="span">Choose Product Image </Button>
                        </label> 
                        {this.state.selectedImage && <p style={{color:'green'}}>Image Selected</p>}
                    </div>
           

                    {/* Button */}
                    <div className="input-field" style={{paddingBottom:"10%", paddingTop:"5%"}}>
                        <Button onClick={this.createProduct} disabled={this.state.loading === true} className="btn" style={{width:"40%", backgroundColor:"#385CAD",fontWeight:"bold", color:"white"}}>Create New Product</Button>
                    </div>   
                    {this.state.loading && <LinearProgress/>}                
                </div>
                }
    
                <Snackbar open={this.state.uploadProductSuccess} style={{width:'50%', margin: 'auto'}}>
                    <Alert style={{position:'fixed', bottom: '40%'}} severity="success">Success! Your product is visible to Tango users</Alert>
                </Snackbar> 
            </Paper>
            </div>
        )
    }
}

export default Products
