import React, { Component } from 'react'
import {Redirect 
	// ,withRouter
} from 'react-router-dom';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import MuiAlert from '@material-ui/lab/Alert';
import moment from  'moment'
import PartyOrdersTableView from './PartyOrdersTableView'
import PartyOrdersRestaurantView from './PartyOrdersRestaurantView'
import {logo} from '../../Assets/TangoHorizontal_No_Background.png'
import firebase from '../../Config/firebase'

const db = firebase.firestore();

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  
class PartyOrders extends Component {

    state ={
        foundOrdersInSearch: [], //set if find search
        ordersFormat: 'tableView',
    }

    componentDidMount() {
        console.log('loaded PARTY ORDERS- just render table view', this.props)
        this.setState({business: this.props.user})
    }

    viewOrderFormat = (format) =>{
        console.log('clicked view order format', format)
        this.setState({ordersFormat: format})
    }
    render() {
        if(!this.props.user.admin) return <Redirect to='/' />

        return (
            <div style={{paddingTop: '5em', paddingBottom:'3em', marginLeft: '2em', marginRight:'2em', marginBottom:'10em'}}>

                <ToggleButtonGroup>
                    <ToggleButton value="tableView" onClick={()=>this.viewOrderFormat('tableView')}>
                      <FormatListBulletedRoundedIcon style={{color: this.state.ordersFormat === 'tableView' ? 'black' : 'grey'}}/>
                    </ToggleButton>
                    <ToggleButton value="restaurantView" onClick={()=>this.viewOrderFormat('restaurantView')}>
                      <ReceiptRoundedIcon style={{color: this.state.ordersFormat === 'restaurantView' ? 'black' : 'grey'}}/>
                    </ToggleButton>
                </ToggleButtonGroup>

                {this.state.ordersFormat === 'tableView' &&
                    <PartyOrdersTableView business={this.props} />
                }
                {this.state.ordersFormat === 'restaurantView' &&
                    <PartyOrdersRestaurantView business={this.props} />
                }
            </div>
        )
    }
}

export default PartyOrders; // withRouter(Orders);
