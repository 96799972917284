import React, { Component } from 'react'
import {Redirect} from 'react-router-dom';
import Orders from './Orders'

class StaffLanding extends Component {
    componentDidMount(){
        console.log('LOADED STAFF LANDING', this.props)
    }
    render() {
        return (
            <div>
                <Orders user={this.props.user}/>
            </div>
        )
    }
}

export default StaffLanding