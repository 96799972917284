import React, { Component } from 'react'
import {Redirect} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from  'moment'
import momentTZ from 'moment-timezone'
import {NavLink} from 'react-router-dom';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import SupervisedUserCircleRoundedIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import ShoppingBasketRoundedIcon from '@material-ui/icons/ShoppingBasketRounded';
import firebase from '../Config/firebase'
import axios from 'axios';
import NumberFormat from 'react-number-format';
import RVStyles from 'react-vis-styles';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  LabelSeries,
	Hint
} from 'react-vis';

const db = firebase.firestore();

class BusinessLanding extends Component {

    state = {
        useCanvas: false, //react vis
		weekdaySalesData: []
    }

    componentDidMount(){
        this.getWeeklySales(this.props.user.id)
        let todayStart = new Date(moment().startOf('day').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
        let todayOrders = []
        let todaySales = []
        let todayCustomers = [] 
        let todayProducts = []
        // console.log('loaded page', this.props)
        db.collection("orders").where("businessId", "==", this.props.user.id)
        .where("timestampCreated", ">=", todayStart)
        .get()
        .then((querySnapshot)=> {
            querySnapshot.forEach((doc)=>{
                if(doc.data().cancelled){
                    return
                }
                console.log(doc.id, " => ", doc.data());
                todayOrders.push(doc.data())
                if(parseFloat(doc.data().totalCost)){
                    todaySales.push(doc.data().totalCost)
                }
                todayCustomers.indexOf(doc.data().userId) === -1 ? todayCustomers.push(doc.data().userId) : console.log("customer already listed");
                for(let i = 0; i<doc.data().products.length; i++){
                    todayProducts.indexOf(doc.data().products[i].productName) === -1 ? todayProducts.push(doc.data().products[i].productName) : console.log("product already listed");
                }
            });
            this.setState({todayOrders:todayOrders, todaySales: todaySales.reduce((a, b) => a + b, 0), todayCustomers: todayCustomers, todayProducts:todayProducts })
        })
        .catch((error)=> {
            console.log("Error getting documents: ", error);
        });
    }

    getDateOfWeek = (w, y) => {
	    let d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
		return new Date(y, 0, d);
    }

    getWeeklySales = (businessId) =>{
        this.setState({weeklySalesLoading: true})

		let offset = moment().utcOffset(); 
        let startOfWeek = moment().utc().utcOffset(offset).endOf('day').add(-8, 'days')
        let endOfWeek = moment().utc().endOf('day')
        let timezone = momentTZ.tz.guess()
        // console.log("Start of week: ", startOfWeek);
        // console.log("End of week: ", endOfWeek);
        // console.log('timezone', momentTZ.tz.guess())
    
        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/getSalesByWeekday',
            data: {
                timezone: timezone,
                start: startOfWeek,
                end: endOfWeek, 
                businessId: businessId
            }
        })
        .then((response) =>{
            // console.log('posted to cloud function', response)
            response.data.sort( (a, b)=> {
                return moment(a.converted_date.value, 'YYYYMMDD') - moment(b.converted_date.value, 'YYYYMMDD');
            })
            .map((i, j) => {
                if (moment(i.converted_date.value, 'YYYYMMDD').format('YYYYMMDD') !== moment().format('YYYYMMDD')) {
                    return this.setState({weekdaySalesData: [...this.state.weekdaySalesData, 
                    {x: moment(i.converted_date.value, 'YYYYMMDD').format('dddd'), y: i.total_sales}]})
                } 
                else {
                    return this.setState({weekdaySalesData: [...this.state.weekdaySalesData, {x: "Today", y: i.total_sales}]})
                }          
            })
            // If Today doesn't have any sales yet
            let days = this.state.weekdaySalesData.map(i => i.x);
            if (!days.includes("Today")) {
                this.setState({
                    weekdaySalesData: [...this.state.weekdaySalesData,
                    {x: "Today", y: 0}]})
            }
        })
        .catch(error=>{
            console.log('error posting to cloud function', error)
        })
    }

    render() {
        
        if(!this.props.user.admin) return <Redirect to='/' />
        const {useCanvas} = this.state;
        const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;

        return (
            <div style={{marginTop: '6em', marginLeft: '4em', marginRight:'4em', marginBottom:'10em'}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent>
                        <MonetizationOnRoundedIcon style={{color: '#D40054', fontSize: 80, marginLeft:'11%', marginTop:'30px', position:'absolute'}} />
                            <Typography color="textSecondary" variant="h6" gutterBottom>Today's Total Sales</Typography>
                            {this.state.todaySales &&
                                <NumberFormat style={{fontSize:"130%", fontWeight:"500"}} value={this.state.todaySales.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            }  
                        </CardContent>
                        
                        <CardActions>
                            <NavLink to='./dailyReporting' style={{textDecoration:'none', margin:'auto'}}><Button variant='outlined'  style={{color:'black'}}endIcon={<ArrowForwardRoundedIcon />}>Daily Reporting</Button></NavLink>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent>
                        <SupervisedUserCircleRoundedIcon style={{color: '#D40054', fontSize: 80, marginLeft:'11%', marginTop:'30px', position:'absolute'}} />

                            <Typography color="textSecondary" variant="h6" gutterBottom>Today's Customers</Typography>
                            {this.state.todayCustomers &&
                                <NumberFormat style={{fontSize:"130%", fontWeight:"500"}} value={this.state.todayCustomers.length} displayType={'text'} thousandSeparator={true} />
                            }
                        </CardContent>
                        
                        <CardActions>
                            <NavLink to='./customers' style={{textDecoration:'none', margin: 'auto'}}><Button variant='outlined' style={{color:'black'}}endIcon={<ArrowForwardRoundedIcon />}>Customers</Button></NavLink>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent>
                        <ReceiptRoundedIcon style={{color: '#D40054', fontSize: 80, marginLeft:'11%', marginTop:'30px', position:'absolute'}} />

                            <Typography color="textSecondary" variant="h6" gutterBottom>Today's Orders</Typography>
                            {this.state.todayOrders && 
                                <NumberFormat style={{fontSize:"130%", fontWeight:"500"}} value={this.state.todayOrders.length} displayType={'text'} thousandSeparator={true} />
                            }
                        </CardContent>
                        
                        <CardActions>
                            <NavLink to='./orders' style={{textDecoration:'none', margin: 'auto'}}><Button variant='outlined'  style={{color:'black'}}endIcon={<ArrowForwardRoundedIcon />}>View Orders</Button></NavLink>
                        </CardActions>
                    </Card>
                </Grid> 
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent >
                            <ShoppingBasketRoundedIcon style={{color: '#D40054', fontSize: 80, marginLeft:'11%', marginTop:'30px', position:'absolute'}} />
                            <Typography color="textSecondary" variant="h6" gutterBottom>Products Sold Today</Typography>
                            <div style={{overflow: 'auto', height: '5em'}}>
                            {this.state.todayProducts && this.state.todayProducts.map(productName => ( 
                                <Typography variant="h6">{productName}</Typography>
                            ))}
                            </div>
                        </CardContent>
                        
                        <CardActions>
                            <NavLink to='./editProducts' style={{textDecoration:'none', margin: 'auto'}}><Button variant='outlined'  style={{color:'black'}}endIcon={<ArrowForwardRoundedIcon />}>Manage Products</Button></NavLink>
                        </CardActions>
                    </Card>
                </Grid>


                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" variant="h6" gutterBottom>7-Day Sales Performance</Typography>
                                {!this.state.weekdaySalesData || this.state.weekdaySalesData.length === 0 ? 
                                    <p>no data</p> :
                                    <span>
                                        {this.state.weekdaySalesData.length > 0 ?
                                        <div style={{ marginRight: 50 }}>
					    				<RVStyles />
                                            <XYPlot width={window.innerWidth * 0.9} height={window.innerHeight * 0.4} margin={{left: 100}} xType="ordinal">
                                                <VerticalGridLines/>
                                                <HorizontalGridLines />
                                                <XAxis title="" 
													// tickFormat={(d) => moment(d, 'YYYYMMDD') === moment().format('YYYYMMDD') ? 'Today' : moment(d, 'YYYYMMDD').format("dddd")}	
													tickLabelAngle={0} 
													/>
                                                <YAxis title="Sales $" />
                                                <BarSeries 
																									data={this.state.weekdaySalesData} 
												 													onNearestX={(value, { index }) => this.setState({ hoverValue: value, index })}
																									onSeriesMouseOut={() => this.setState({ hoverValue: null })}	
													/>
																								{this.state.hoverValue && 
																									<Hint value={this.state.hoverValue}>
																										<div background="#404040" style={{ color: "#404040", backgroundColor: "#fff", borderColor: "#000", borderRadius: 5, padding: 5 }}>
																											<div>Total Sales:</div>
																											<NumberFormat style={{fontSize:"130%", fontWeight:"500"}} value={this.state.hoverValue.y.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
																										</div>
																									</Hint>
																								}

                                            </XYPlot>
                                        </div>
                                        :
                                        <p>Loading...</p>
										}
                                    </span>
                                }
                     
                        </CardContent>
                        
                        <CardActions>
                            <NavLink to={{
								pathname: './analytics',
								user: this.props.user
                                }}	
                                style={{textDecoration:'none',  margin: 'auto'}}><Button variant='outlined'  style={{color:'black', marginBottom:"10%"}}endIcon={<ArrowForwardRoundedIcon />}>Analytics</Button></NavLink>
                        </CardActions>
                    </Card>
                </Grid>        
            </Grid>

            </div>
        )
    }
}

export default BusinessLanding
