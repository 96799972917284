import React, { Component } from 'react'
import {Redirect} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {NavLink} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import CreateNewProduct from './helpVideos/create_new_product.mp4';
import DeleteProduct from './helpVideos/delete_or_unavailable.mp4';
import ChangePrice from './helpVideos/change_product_price.mp4';
import AddPhoto from './helpVideos/adding_a_photo.mp4';
import EditProduct from './helpVideos/edit_a_product.mp4';
import EodReport from './helpVideos/run_EOD_report.mp4';
import CustomReport from './helpVideos/run_custom_report.mp4';
import FulfillOrder from './helpVideos/fulfill_order.mp4';



class AdminHelp extends Component {
    state={
        CreateNewProductRaised: false,
        DeleteProductRaised: false,
        ChangePriceRaised: false,
        AddPhotoRaised: false,
        EditProductRaised: false,
        EodReportRaised: false,
        CustomReportRaised: false,
        FulfillOrderRaised: false
      }
    
      ToggleCreateNewProductRaised = () => this.setState({CreateNewProductRaised:!this.state.CreateNewProductRaised});
      ToggleDeleteProductRaised = () => this.setState({DeleteProductRaised:!this.state.DeleteProductRaised});
      ToggleChangePriceRaised = () => this.setState({ChangePriceRaised:!this.state.ChangePriceRaised});
      ToggleAddPhotoRaised = () => this.setState({AddPhotoRaised:!this.state.AddPhotoRaised});
      ToggleEditProductRaised = () => this.setState({EditProductRaised:!this.state.EditProductRaised});
      ToggleEodReportRaised = () => this.setState({EodReportRaised:!this.state.EodReportRaised});
      ToggleCustomReportRaised = () => this.setState({CustomReportRaised:!this.state.CustomReportRaised});
      ToggleFulfillOrderRaised = () => this.setState({FulfillOrderRaised:!this.state.FulfillOrderRaised});


// Super inneficient code, handling all of the different dialog opens/closes seperately...but it works for now

    openCreateNewProduct = () =>{
        // console.log('edit this', product, value)
        this.setState({openCreateNewProduct:true})
    }

    handleCreateNewProductClose = () =>{
        this.setState({openCreateNewProduct:false})
    }


    openAddPhoto = () =>{
        // console.log('edit this', product, value)
        this.setState({openAddPhoto:true})
    }

    handleAddPhotoClose = () =>{
        this.setState({openAddPhoto:false})
    }

    openChangePrice = () =>{
        // console.log('edit this', product, value)
        this.setState({openChangePrice:true})
    }

    handleChangePriceClose = () =>{
        this.setState({openChangePrice:false})
    }

    openDeleteProduct = () =>{
        // console.log('edit this', product, value)
        this.setState({openDeleteProduct:true})
    }

    handleDeleteProductClose = () =>{
        this.setState({openDeleteProduct:false})
    }

    openEditProduct = () =>{
        // console.log('edit this', product, value)
        this.setState({openEditProduct:true})
    }

    handleEditProductClose = () =>{
        this.setState({openEditProduct:false})
    }

    openFulfillOrder = () =>{
        // console.log('edit this', product, value)
        this.setState({openFulfillOrder:true})
    }

    handleFulfillOrderClose = () =>{
        this.setState({openFulfillOrder:false})
    }

    openCustomReport = () =>{
        // console.log('edit this', product, value)
        this.setState({openCustomReport:true})
    }

    handleCustomReportClose = () =>{
        this.setState({openCustomReport:false})
    }

    openEodReport = () =>{
        // console.log('edit this', product, value)
        this.setState({openEodReport:true})
    }

    handleEodReportClose = () =>{
        this.setState({openEodReport:false})
    }

// END super inefficient function writing


    componentDidMount(){
        console.log('loaded page', this.props)
    }
    render() {
        if(!this.props.user.admin) return <Redirect to='/' />
        return (
            <div style={{marginTop:'5em', marginLeft: '4em', marginRight:'4em', marginBottom:'10em', color:'black'}}>
                <h2 style={{marginTop:'3em', color:"black", fontSize:"200%"}}>
                    How To Guides
                </h2>
                <p style={{color:"#3C4257", fontSize:"120%"}}>Click into the thumbnails below to find guidance on all aspects of working with the Tango admin portal.</p>
                <div style={{marginTop:"4em"}}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={6} md={3} >
                            <Card 
                                style={{padding:'2em', cursor:'pointer'}}
                                onMouseOver={this.ToggleCreateNewProductRaised} 
                                onMouseOut={this.ToggleCreateNewProductRaised} 
                                raised={this.state.CreateNewProductRaised}
                                onClick={()=>this.openCreateNewProduct()}
                            >
                                {this.props.children}
                                <Typography gutterBottom variant="h6" component="h3" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%", textAlign:"left"}}>
                                    Creating new products 
                                </Typography>       
                                <br/>
                                <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%", textAlign:"left"}}>
                                     Use the "Create New Producs" button in the products page to create any new products. 
                                </Typography>
                                <br/>
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#3D4EAC", fontSize:"70%", textAlign:"left"}}> Click this card for more info <ArrowForwardRoundedIcon style={{fontSize:"90%"}}/> </Typography>
                                </div>
                            </Card> 
                        </Grid>
                        <Dialog onClose={this.handleCreateNewProductClose} aria-labelledby="customer" open={this.state.openCreateNewProduct} maxWidth="md">
                            <DialogTitle style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Creating new products</DialogTitle>
                            <div style={{paddingBottom:'2em', paddingLeft: '2em', paddingRight: '2em'}}>
                                <video width="750" height="500" controls autoplay="true" >
                                    <source src={CreateNewProduct} type="video/mp4"/>
                                </video>
                            </div>
                        </Dialog>


                        <Grid item xs={12} sm={6} md={3} >
                            <Card 
                                style={{padding:'2em', cursor:'pointer'}}
                                onMouseOver={this.ToggleDeleteProductRaised} 
                                onMouseOut={this.ToggleDeleteProductRaised} 
                                raised={this.state.DeleteProductRaised}
                                onClick={()=>this.openDeleteProduct()}
                            >
                                <Typography gutterBottom variant="h6" component="h3" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%", textAlign:"left"}}>
                                    Delete a product or mark it as unavailable.
                                </Typography>       
                                <br/>
                                <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%", textAlign:"left"}}>
                                    Delete products permanently or mark them as temporarily unavailable.                                
                                </Typography>
                                <br/>
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#3D4EAC", fontSize:"70%", textAlign:"left"}}> Click this card for more info <ArrowForwardRoundedIcon style={{fontSize:"90%"}}/> </Typography>
                                </div>
                            </Card> 
                        </Grid>
                        <Dialog onClose={this.handleDeleteProductClose} aria-labelledby="customer" open={this.state.openDeleteProduct} maxWidth="md">
                            <DialogTitle style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Delete a product or mark it as unavailable.</DialogTitle>
                            <div style={{paddingBottom:'2em', paddingLeft: '2em', paddingRight: '2em'}}>
                                <video width="750" height="500" controls autoplay="true" >
                                    <source src={DeleteProduct} type="video/mp4"/>
                                </video>
                            </div>
                        </Dialog>


                        <Grid item xs={12} sm={6} md={3} >
                            <Card 
                                style={{padding:'2em', cursor:'pointer'}}
                                onMouseOver={this.ToggleChangePriceRaised} 
                                onMouseOut={this.ToggleChangePriceRaised} 
                                raised={this.state.ChangePriceRaised}
                                onClick={()=>this.openChangePrice()}
                            >
                                <Typography gutterBottom variant="h6" component="h3" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%", textAlign:"left"}}>
                                    Changing the price of a product
                                </Typography>       
                                <br/>
                                <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%", textAlign:"left"}}>
                                    Easily update the price of products in your menu.
                                </Typography>
                                <br/>
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#3D4EAC", fontSize:"70%", textAlign:"left"}}> Click this card for more info <ArrowForwardRoundedIcon style={{fontSize:"90%"}}/> </Typography>
                                </div>
                            </Card> 
                        </Grid>
                        <Dialog onClose={this.handleChangePriceClose} aria-labelledby="customer" open={this.state.openChangePrice} maxWidth="md">
                            <DialogTitle style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Changing the price of a product</DialogTitle>
                            <div style={{paddingBottom:'2em', paddingLeft: '2em', paddingRight: '2em'}}>
                                <video width="750" height="500" controls autoplay="true" >
                                    <source src={ChangePrice} type="video/mp4"/>
                                </video>
                            </div>
                        </Dialog>


                        <Grid item xs={12} sm={6} md={3} >
                            <Card 
                                style={{padding:'2em', cursor:'pointer'}}
                                onMouseOver={this.ToggleAddPhotoRaised} 
                                onMouseOut={this.ToggleAddPhotoRaised} 
                                raised={this.state.AddPhotoRaised}
                                onClick={()=>this.openAddPhoto()}
                            >
                                <Typography gutterBottom variant="h6" component="h3" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%", textAlign:"left"}}>
                                    Updating product photos
                                </Typography>       
                                <br/>
                                <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%", textAlign:"left"}}>
                                    Upload high quality photos to your products, customers love them!
                                </Typography>
                                <br/>
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#3D4EAC", fontSize:"70%", textAlign:"left"}}> Click this card for more info <ArrowForwardRoundedIcon style={{fontSize:"90%"}}/> </Typography>
                                </div>
                            </Card> 
                        </Grid>
                        <Dialog onClose={this.handleAddPhotoClose} aria-labelledby="customer" open={this.state.openAddPhoto} maxWidth="md">
                            <DialogTitle style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Updating product photos</DialogTitle>
                            <div style={{paddingBottom:'2em', paddingLeft: '2em', paddingRight: '2em'}}>
                                <video width="750" height="500" controls autoplay="true" >
                                    <source src={AddPhoto} type="video/mp4"/>
                                </video>
                            </div>
                        </Dialog>


                        <Grid item xs={12} sm={6} md={3} >
                            <Card 
                                style={{padding:'2em', cursor:'pointer'}}
                                onMouseOver={this.ToggleEditProductRaised} 
                                onMouseOut={this.ToggleEditProductRaised} 
                                raised={this.state.EditProductRaised}
                                onClick={()=>this.openEditProduct()}
                            >
                                <Typography gutterBottom variant="h6" component="h3" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%", textAlign:"left"}}>
                                    Editing a product description or category
                                </Typography>       
                                <br/>
                                <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%", textAlign:"left"}}>
                                    Product descriptions are a great way to highlight what makes you special.
                                </Typography>
                                <br/>
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#3D4EAC", fontSize:"70%", textAlign:"left"}}> Click this card for more info <ArrowForwardRoundedIcon style={{fontSize:"90%"}}/> </Typography>
                                </div>
                            </Card> 
                        </Grid>
                        <Dialog onClose={this.handleEditProductClose} aria-labelledby="customer" open={this.state.openEditProduct} maxWidth="md">
                            <DialogTitle style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Editing a product description or category</DialogTitle>
                            <div style={{paddingBottom:'2em', paddingLeft: '2em', paddingRight: '2em'}}>
                                <video width="750" height="500" controls autoplay="true" >
                                    <source src={EditProduct} type="video/mp4"/>
                                </video>
                            </div>
                        </Dialog>


                        <Grid item xs={12} sm={6} md={3} >
                            <Card 
                                style={{padding:'2em', cursor:'pointer'}}
                                onMouseOver={this.ToggleEodReportRaised} 
                                onMouseOut={this.ToggleEodReportRaised} 
                                raised={this.state.EodReportRaised}
                                onClick={()=>this.openEodReport()}
                            >
                                <Typography gutterBottom variant="h6" component="h3" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%", textAlign:"left"}}>
                                    Viewing the end-of-day report
                                </Typography>       
                                <br/>
                                <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%", textAlign:"left"}}>
                                    View the end-of-day report to reconcile inventory into your POS. 
                                </Typography>
                                <br/>
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#3D4EAC", fontSize:"70%", textAlign:"left"}}> Click this card for more info <ArrowForwardRoundedIcon style={{fontSize:"90%"}}/> </Typography>
                                </div>
                            </Card> 
                        </Grid>
                        <Dialog onClose={this.handleEodReportClose} aria-labelledby="customer" open={this.state.openEodReport} maxWidth="md">
                            <DialogTitle style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Viewing the end-of-day report</DialogTitle>
                            <div style={{paddingBottom:'2em', paddingLeft: '2em', paddingRight: '2em'}}>
                                <video width="750" height="500" controls autoplay="true" >
                                    <source src={EodReport} type="video/mp4"/>
                                </video>
                            </div>
                        </Dialog>


                        <Grid item xs={12} sm={6} md={3} >
                            <Card 
                                style={{padding:'2em', cursor:'pointer'}}
                                onMouseOver={this.ToggleCustomReportRaised} 
                                onMouseOut={this.ToggleCustomReportRaised} 
                                raised={this.state.CustomReportRaised}
                                onClick={()=>this.openCustomReport()}
                            >
                                <Typography gutterBottom variant="h6" component="h3" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%", textAlign:"left"}}>
                                    Running custom reports
                                </Typography>       
                                <br/>
                                <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%", textAlign:"left"}}>
                                    Run custom reports at your liesure to see business performance over time.
                                </Typography>
                                <br/>
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#3D4EAC", fontSize:"70%", textAlign:"left"}}> Click this card for more info <ArrowForwardRoundedIcon style={{fontSize:"90%"}}/> </Typography>
                                </div>
                            </Card> 
                        </Grid>
                        <Dialog onClose={this.handleCustomReportClose} aria-labelledby="customer" open={this.state.openCustomReport} maxWidth="md">
                            <DialogTitle style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Running custom reports</DialogTitle>
                            <div style={{paddingBottom:'2em', paddingLeft: '2em', paddingRight: '2em'}}>
                                <video width="750" height="500" controls autoplay="true" >
                                    <source src={CustomReport} type="video/mp4"/>
                                </video>
                            </div>
                        </Dialog>


                        <Grid item xs={12} sm={6} md={3} >
                            <Card 
                                style={{padding:'2em', cursor:'pointer'}}
                                onMouseOver={this.ToggleFulfillOrderRaised} 
                                onMouseOut={this.ToggleFulfillOrderRaised} 
                                raised={this.state.FulfillOrderRaised}
                                onClick={()=>this.openFulfillOrder()}
                            >
                                <Typography gutterBottom variant="h6" component="h3" style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%", textAlign:"left"}}>
                                    Fulfilling Orders
                                </Typography>       
                                <br/>
                                <Typography variant="body2" color="textSecondary" component="p" style={{color:"#4F566D", fontSize:"100%", textAlign:"left"}}>
                                    Fulfilling orders is as simple as seeing the order come in and clicking the "Fulfill" button.
                                </Typography>
                                <br/>
                                <div>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{color:"#3D4EAC", fontSize:"70%", textAlign:"left"}}> Click this card for more info <ArrowForwardRoundedIcon style={{fontSize:"90%"}}/> </Typography>
                                </div>
                            </Card> 
                        </Grid>
                        <Dialog onClose={this.handleFulfillOrderClose} aria-labelledby="customer" open={this.state.openFulfillOrder} maxWidth="md">
                            <DialogTitle style={{color:"#3D4EAC", fontWeight:"500", fontSize:"120%"}}>Fulfilling Orders</DialogTitle>
                            <div style={{paddingBottom:'2em', paddingLeft: '2em', paddingRight: '2em'}}>
                                <video width="750" height="500" controls autoplay="true" >
                                    <source src={FulfillOrder} type="video/mp4"/>
                                </video>
                            </div>
                        </Dialog>


                    </Grid>
                </div>
            </div>
        )
    }
}

export default AdminHelp