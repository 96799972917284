import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import moment from  'moment'
import Avatar from '@material-ui/core/Avatar';
import BlockIcon from '@material-ui/icons/Block';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import soundfile from '../../Assets/alert.mp3'
import axios from 'axios';
import { isEqual } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import styles from '../Orders/animations.module.css'

import firebase from '../../Config/firebase'
const db = firebase.firestore();

const columns = [
    { 
        id: 'customerName', 
        label: 'Name', 
        minWidth: 120,
        align: 'center',
    },
    {
        id: 'items',
        label: 'Items',
        minWidth: 80,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'tableNumber',
        label: 'Table Number',
        minWidth: 80,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'time',
        label: 'Time',
        minWidth: 120,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'total',
        label: 'Total ($)',
        minWidth: 120,
        align: 'center',
        format: (value) => value.toFixed(2),
    },
    { 
        id: 'fulfilled', 
        label: 'Order Status', 
        minWidth: 70,
        align: 'center'
      },
  ];

export default class PartyOrdersTableView extends Component {

    state={
        page: 0,
        rowsPerPage: 25, 
        repeatAlert: false
    }

    componentDidMount(){
        console.log('loaded table view ORDERS -->', this.props.business.user.data)
        let audio = new Audio(soundfile);
        //load from yesterday (so business can see where they left off)
        let yesterdayStart = new Date(moment().subtract(1, 'day').startOf('day').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
        let orders = []
        let unfulfilledOrders = []
        let otherOrders = [] 

        //rerenders page with ordres based on ANY update to order
        this.realTimeOrders = db.collection("orders").where('businessId', '==', this.props.business.user.id)
        .where("timestampCreated", ">=", yesterdayStart)
        .orderBy('timestampCreated', 'desc')
        .onSnapshot((querySnapshot)=> {
            if(querySnapshot.size === 0){
                return
            }else {
               
                querySnapshot.forEach(doc => {
                    let order = doc.data()
                    if(!order.partyOrder){
                        return console.log('dont show normal order in party view')
                    }
                    order.id = doc.id
                    order.timestampForDisplay = order.timestampCreated.toDate()
                    order.timestampForDisplay = `${order.timestampForDisplay.getMonth()+1}/${order.timestampForDisplay.getDate()}/${order.timestampForDisplay.getFullYear()} ${order.timestampForDisplay.getHours()}:${order.timestampForDisplay.getMinutes()} `
                    order.timestampForDisplay = moment(order.timestampForDisplay).format('hh:mm a')

                    //calc all tax in order
                    let taxInOrder = []
                    let subTotalInOrder = []
                    for (let i=0; i < order.products.length; i ++){
                        taxInOrder.push(order.products[i].taxApplied)
                        subTotalInOrder.push(order.products[i].productPrice)
                    }
                    order.taxInOrder = taxInOrder.reduce((a, b) => a + b, 0)
                    order.subTotal = subTotalInOrder.reduce((a, b) => a + b, 0)
                    //sort with food at top (make sure use all the potential types in create product)
                    let productType = {
                        "Food": 1, 
                        "Beer": 2,
                        'Wine':3,
                        "Beverage": 4,
                        'Coffee / Tea': 5,
                        'Custom':6,    
                        'Liquor':7,
                        'Merchandise':8,
                        'Seltzer':9   
                    }
                    order.products.sort((a, b)=> {
                        if(a.productType && b.productType){
                            return productType[a.productType] - productType[b.productType]
                        }
                    })

                    if(!order.fulfilled && !order.cancelled){
                        unfulfilledOrders.push(order) 
                    }else{
                        otherOrders.push(order)
                    }
                })

                orders = unfulfilledOrders.concat(otherOrders) // concat everything else at bottom - this keeps timestamp order
                this.setState({orders: orders})
                
                //if business wants repeat sound alert
                //start with no repeat
                if(this.state.repeatAlert){
                    console.log('turn off sound alert interval', this.state.repeatAlert)
                    clearInterval(this.state.repeatAlert); // clear the repeat sound alert
                    this.setState({repeatAlert: false})
                }
                if(orders.length && this.props.business.user.data.orderNotificationRepeat){
                    if (orders[0].fulfilled || orders[0].cancelled || orders[0].processing || orders[0].ready || orders[0].printedTicket){
                        console.log('top order has been addressed')
                    }else{
                        console.log('top order has not been addressed')
                        this.state.repeatAlert = setInterval(() => console.log("repeat sound!!!!: ", audio.play()), 10000); //after first sound ring every 10 seconds 
                    }
                }
          
                if(orders.length){
                    if (orders[0].fulfilled || orders[0].cancelled || orders[0].processing || orders[0].ready || orders[0].printedTicket){
                        this.setState({newOrder: false})
                    }
                }
                //set order arrays back to empty for new orders coming in
                orders = [] 
                unfulfilledOrders = []
                otherOrders = [] 
            }
        })


 
        //if new order added play audio once and set current party
        if(this.props.business.user.data.orderNotificationSound){
            this.newOrdersCheck = db.collection("orders").where('businessId', '==', this.props.business.user.id)
            .where("timestampCreated", ">=", yesterdayStart)
            .orderBy('timestampCreated', 'desc')
            .onSnapshot((snapshot)=> {
                snapshot.docChanges().forEach((change)=>{
                    if (change.type === "added") {
                        // console.log('new order to table view', change.doc.data())
                        let order = change.doc.data()
                        if (order.fulfilled || order.cancelled || order.processing || order.ready || !order.partyOrder){
                            console.log('do nothing because not a new party order')
                        }else{
                            this.setState({newOrder: true})
                            console.log("New order so make sound and show bell!!!!: ", audio.play());

                            var docRef = db.collection("parties").doc(order.partyId);
                                docRef.get().then((doc)=> {
                                    if (doc.exists) {
                                        console.log("Document data:", doc.data());
                                        let currentParty = doc.data()
                                        currentParty.id = doc.id
                                        this.setState({currentParty: currentParty})
                                    } else {
                                        // doc.data() will be undefined in this case
                                        console.log("No such document!");
                                    }
                                }).catch((error)=>{
                                    console.log("Error getting document:", error);
                                });
                        }
                    }
                });
            });
        }

    }

    //navigate away from page will stop listening for updates
    componentWillUnmount(){
        console.log('turn off sound alert interval', this.state.repeatAlert)
        if(this.state.repeatAlert){
            console.log('turn off sound alert interval', this.state.repeatAlert)
            clearInterval(this.state.repeatAlert); // clear the repeat sound alert
        }

        if(this.realTimeOrders){
            this.realTimeOrders() 
            console.log('unmount component and unsubscribe realtime lister for display orders, table view')
        }
        //maybe don't unmount this so new order alert always happens even if on different html 
        if(this.newOrdersCheck){
            this.realTimeOrders() 
            console.log('unmount check new orders and unsubscribe realtime lister for sound alerts, table view')
        }
    }

    fulfilled = (orderId) => {
        // console.log('clicked fulfill order', orderId)
        let orderRef = db.collection("orders").doc(orderId)
        orderRef.update({ processing:false, ready:false, fulfilled:true, cancelled:false })
        .then(()=> {
            console.log("Document successfully updated!");
        })
        .catch((error)=>{
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
        });
    };

    //used in print ticket to show items
    concatOrder = (products) => {
      let out = {};
      for (let i=0; i<products.length; i++) {
        // Product is not in out
        if (Object.keys(out).includes(products[i].productName)) {
          let opt = products[i].selectedOptions;
          let inst = products[i].productInstructions || null;
          let index = -1;
          for (let j=0; j<out[products[i].productName].length; j++) {
            if (out[products[i].productName][j]) {
              if (isEqual(out[products[i].productName][j][0], opt) && isEqual(out[products[i].productName][j][2], inst)) {
                index = j;
              }
            }
          }
          // Product is in out but doesn't have the same product options
          if (index !== -1) {
            out[products[i].productName][index][1].total += 1;
            out[products[i].productName][index][1].price  += products[i].productPrice;
            if (products[i].discount) {
              out[products[i].productName][index][1].discountedPrice += products[i].productPrice * (1 - products[i].discount);
            } else {
              out[products[i].productName][index][1].discountedPrice += products[i].productPrice;
            }
          } else {
            if (products[i].discount) {
              out[products[i].productName].push([products[i].selectedOptions, {total: 1, price: products[i].productPrice, discountedPrice: products[i].productPrice * (1 - products[i].discount)}, products[i].productInstructions || null]);
            } else {
              out[products[i].productName].push([products[i].selectedOptions, {total: 1, price: products[i].productPrice, discountedPrice: products[i].productPrice}, products[i].productInstructions || null]);

            }
          }
        } else {
          if (products[i].discount) {
            out[products[i].productName] = [[products[i].selectedOptions, {total: 1, price: products[i].productPrice, discountedPrice: products[i].productPrice * (1 - products[i].discount)}, products[i].productInstructions || null]];
          } else {
            out[products[i].productName] = [[products[i].selectedOptions, {total: 1, price: products[i].productPrice, discountedPrice: products[i].productPrice}, products[i].productInstructions || null]];
          }
        }
      }
      return out;
    }

    printTicket = (order) =>{
        var orderRef = db.collection("orders").doc(order.id);
        var commandsToPrint =
          "<BIG><BOLD><CENTER>" + order.businessName + "<BR>\n" +
          "<BIG><BOLD><CENTER>" + order.selectedOrderConfirmationMethod + "<BR>\n" +
          "<LINE>" + "<BR>\n" +
          "<CENTER><UNDERLINE>Customer Info: "+ "<BR>\n" +
          "<CENTER>" + order.username + "<BR>\n" +
          "<CENTER>"+ order.userPhone + "<BR>\n";

        if (order.selectedOrderConfirmationMethod === "dineIn") {
          commandsToPrint += 
          "<BOLD><LEFT>Table Number: " +"#"+order.tableNumber + "<BR>\n"+
          "<BR>" +
          "<MEDIUM1><LEFT>Order Details:" + "<BR>\n";
        }

        if (order.selectedOrderConfirmationMethod === "delivery") {
          commandsToPrint += 
          "<BOLD><CENTER>Delivery Address: " + order.userAddress + "<BR>\n" +
          "<BR>" +
          "<MEDIUM1><LEFT>Order Details:" + "<BR>\n";
        }

        if (order.selectedOrderConfirmationMethod === "carryOut") {
          commandsToPrint += 
          "<MEDIUM1><LEFT>Order Details:" + "<BR>\n";
        }

        // if (order.instructionsForBusiness) {
        //     commandsToPrint += 
        //     "<LINE>" + "<BR>\n" + 
        //     "<LEFT>Instructions: " + order.instructionsForBusiness + "<BR>\n"; 
        // } 

        // Sort order based on productName and selectedOptions
        order.products.sort(function(a, b) {
          let nameA = a.productName.toUpperCase();
          let nameB = b.productName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          let selectedOptionsA = a.selectedOptions;
          let selectedOptionsB = b.selectedOptions;
          if (selectedOptionsA && selectedOptionsB) {
            if (selectedOptionsA.length < selectedOptionsB.length) {
              return -1;
            }
            if (selectedOptionsA.length > selectedOptionsB.length) {
              return 1;
            }

            for (let i=0; i<selectedOptionsA.length; i++) {
              let priceA = selectedOptionsA[i].additionalCost;
              let priceB = selectedOptionsB[i].additionalCost;
              if (priceA < priceB) {
                return -1;
              }
              if (priceA > priceB) {
                return 1;
              }
              let sampleOptionA = selectedOptionsA[i].selectedOption;
              let sampleOptionB = selectedOptionsB[i].selectedOption;
              if (sampleOptionA < sampleOptionB) {
                return -1;
              }
              if (sampleOptionA > sampleOptionB) {
                return 1;
              }
            }
          } else if (selectedOptionsA || selectedOptionsB) {
            if (selectedOptionsA && !selectedOptionsB) {
              return 1;
            }
            if (!selectedOptionsA && selectedOptionsB) {
              return -1;
            }
          }

          // names must be equal
          return 0;
        })

        let concatProducts = this.concatOrder(order.products);

        for (let i=0; i<order.products.length; i++) {
          let productName = order.products[i].productName;
          let selectedOptions = order.products[i].selectedOptions;
          let inst = order.products[i].productInstructions || null;
          if (Object.keys(concatProducts).includes(productName)) {
            for (let j=0; j<concatProducts[productName].length; j++) {
              if (isEqual(concatProducts[productName][j][0], selectedOptions) && isEqual(concatProducts[productName][j][2], inst)) {
                let total = concatProducts[productName][j][1].total;
                if (total > 1) {
                  commandsToPrint += "<LEFT>" + total + "X " + productName + "<BR>\n";
                  i += (total - 1)
                } else {
                  commandsToPrint += "<LEFT>" + total + "X " + productName + "<BR>\n";
                }
              }
            }
          } else {
            commandsToPrint += productName + "<BR>\n";
          }
          if (selectedOptions) {
            for(let k = 0; k<selectedOptions.length; k++) {
              commandsToPrint += "     >> " + selectedOptions[k].selectedOption + "<BR>\n";
            }
          }
            if (inst) {
              commandsToPrint += "     Instruction: " + inst + "<BR>\n";
            }  
        }

        commandsToPrint += 
            "<LINE>" + "<BR>\n" +
            "<LEFT>Total Cost: $" + order.totalCost.toFixed(2) + "<BR>\n" +
            "<LEFT>Tip Included: $" + order.tip.toFixed(2) + "<BR>\n";

        commandsToPrint += 
            "<LINE>" + "<BR>\n" +
            "<LEFT>Time Received: " + order.timestampCreated.toDate() + "<BR>\n" +
            "<LEFT>Order Number: " + order.orderNumber + "<BR>\n" +
            "<BR\n>";
            
        commandsToPrint += 
            "<CUT>\n" + "<DRAWER>\n";

        console.log("Printing: ", commandsToPrint);

        var textEncoded = encodeURI(commandsToPrint);
        window["printNewReceipt"](textEncoded);

        return orderRef.update({printedTicket: true})
        .then(()=> {
            console.log("updated doc to printed!");
            // this.setState({clickedOrderOptions:false, clickedOrder: null})
        })
        .catch((error)=>{
            console.error("Error updating document: ", error);
            // this.setState({clickedOrderOptions:false, clickedOrder: null})
        });

        // for (let i=0; i<order.products.length; i++) {
        //   let productName = order.products[i].productName;
        //   let selectedOptions = order.products[i].selectedOptions;
        //   if (Object.keys(concatProducts).includes(productName)) {
        //     for (let j=0; j<concatProducts[productName].length; j++) {
        //       if (isEqual(concatProducts[productName][j][0], selectedOptions)) {
        //         let total = concatProducts[productName][j][1].total;
        //         if (total > 1) {
        //           commandsToPrint += total + "X " + productName + "<BR>\n";
        //           i += (total - 1)
        //         } else {
        //           commandsToPrint += productName + "<BR>\n";
        //         }
        //       }
        //     }
        //   } else {
        //     commandsToPrint += productName + "<BR>\n";
        //   }
        //   if (selectedOptions) {
        //       for(let k = 0; k<selectedOptions.length; k++) {
        //         commandsToPrint += "     >>" + selectedOptions[k].selectedOption + "<BR>\n";
        //       }
        //   }
        // }

        // commandsToPrint += 
        //     "<LINE>" + "<BR>\n" +
        //     "<LEFT>Time Received: " + order.timestampCreated.toDate() + "<BR>\n" +
        //     "<LEFT>Order Number: " + order.orderNumber + "<BR>\n" +
        //     "<BR\n>";
        //     
        // commandsToPrint += 
        //     "<CUT>\n" + "<DRAWER>\n";
        // 
        // console.log("Printing: ", commandsToPrint);

        // var textEncoded = encodeURI(commandsToPrint);
        // window["printNewReceipt"](textEncoded);

        // return orderRef.update({printedTicket: true})
        // .then(()=> {
        //     console.log("updated doc to printed!");
        //     this.setState({clickedOrderOptions:false, clickedOrder: null})
        // })
        // .catch((error)=>{
        //     console.error("Error updating document: ", error);
        //     this.setState({clickedOrderOptions:false, clickedOrder: null})
        // });
    }


    viewOrderDetails = (order)=>{
        console.log('view order', order)
        this.setState({clickedOrder: order, clickedViewOrder: true, })
    }

    cancelOrder = (order) => {
        // console.log('clicked cancel order', order)
        let orderRef = db.collection("orders").doc(order.id)
        orderRef.update({ processing:false, ready:false, fulfilled:false, cancelled:true })
        this.setState({clickedOrderOptions:false, clickedOrder: null, clickedCancel:false})
    };
    outForDelivery = (order) => {
        console.log('clicked order out for delivery', order)
        let orderRef = db.collection("orders").doc(order.id)
        orderRef.update({ outForDelivery: true })
        this.setState({clickedOrderOptions:false, clickedOrder: null})
    };

    viewCustomer = (order)=>{
        this.setState({clickedViewCustomer: true, clickedOrder: order})
        // console.log('clicked view customer', order)
    }
    handleChangePage = (event, newPage) => {
        console.log('change page', newPage)
        this.setState({page: newPage})
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: +event.target.value, page: 0})
    };
    //close modal - set open flags as false
    handleClose = () =>{
        this.setState({
            clickedOrderOptions: false, 
            clickedViewCustomer:false, 
            clickedViewOrder: false, 
            clickedOrder: null, 
            clickedCancel:false
        })
    }

    compDiscountRemoveItem = (item, updateMethod) => {
        console.log(updateMethod, 'to this item', item)
        if(updateMethod === 'remove' && this.state.clickedOrder.products.length === 1){
            return alert ('Order has 1 item. Please cancel order for proper analytics.')
        }
        this.setState({loadingCompDisountRemove: true})

        axios({
            method: 'post',
            url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/compDiscountRemoveItem',
            data: {
              orderId: this.state.clickedOrder.id,
              businessId: this.state.businessId,
              orderTotalCost: this.state.clickedOrder.totalCost,
              updateMethod: updateMethod,
              product: item,
              taxInOrder: this.state.clickedOrder.taxInOrder
            }
        }).then((response) =>{
            console.log('posted to cloud function to comp, discount or remove', response)
            //close modal on response
            this.setState({clickedViewOrder: false, clickedOrder: null, loadingCompDisountRemove: false}) 
        })
        .catch((error)=>{
            console.error("Error posting to cloud function ", error);
            alert('something went wrong with your request')
            this.setState({clickedViewOrder: false, clickedOrder: null, loadingCompDisountRemove: false})  
        });
    }
    enableParty = () =>{
        console.log('clicked enable party', this.state.currentParty)
            return db.collection("parties").doc(this.state.currentParty.id).update({enabled: true})
            .then(()=> {
                console.log("Document successfully updated!");
                this.setState({openPartyDialog:false})
                window.location.reload()
            })
            .catch((error)=> {
                console.error("Error updating document: ", error);
            });
    }
    disableParty = () =>{
        console.log('clicked disable party', this.state.currentParty)
        return db.collection("parties").doc(this.state.currentParty.id).update({enabled: false})
        .then(()=> {
            console.log("Document successfully updated!");
            this.setState({openPartyDialog:false})
            window.location.reload()
        })
        .catch((error)=> {
            console.error("Error updating document: ", error);
        });
    }
    render() {
        return (
            <div style={{marginTop: '1em', marginLeft:'2em', marginRight:'2em'}}>

                {/* click username */}
                <Dialog onClose={this.handleClose} open={this.state.clickedViewCustomer}>
                    <DialogTitle id="order-statuse">Customer Info</DialogTitle>
                        {this.state.clickedOrder &&
                        <List style={{padding:'1em'}}>
                            <p>Name: {this.state.clickedOrder.username}</p>
                            <p>Phone: {this.state.clickedOrder.userPhone}</p>
                            <p>Last 4 Card # {this.state.clickedOrder.last4}</p>
                        </List>
                        }
                </Dialog>

                {/* click view order */}
                {this.state.clickedOrder &&
                <Dialog style={{textAlign: 'center'}} fullWidth={true} maxWidth={'lg'} onClose={this.handleClose} open={this.state.clickedViewOrder}>
                    <DialogTitle id="order-statuse">Order #{this.state.clickedOrder.orderNumber} Details</DialogTitle>
                        <div style={{padding:'1em'}}>
                            <Grid container spacing={1}>
                                {this.state.clickedOrder.products.map((product, index) => (
                                    <div key={index}>            
                                    <Grid item xs={12}>
                                        <Card style={{padding:'1em', margin:'1em'}} raised='true'>
                                            <div component="h2" style={{fontWeight:"Bold", paddingBottom:'.5em'}}> {product.productName} </div>
                                            {product.comped &&<span style={{fontStyle:'italic', color:'red'}}>*Product Comped</span>}
                                            <div style={{paddingBottom:"5%"}}>
                                                {product.selectedOptions && product.selectedOptions.map((selectedOption, index) => ( 
                                                    <span>
                                                        <Chip variant="outlined" label={<span style={{color:'grey', fontSize: '15px'}} > {selectedOption.option}: {selectedOption.selectedOption}</span>} key={index}/> 
                                                        <br/>
                                                    </span>                                  
                                                ))}
                                                <p style={{padding:'1em'}}>Price: ${product.productPrice.toFixed(2)}</p>
                                                {!product.comped &&<Button disabled={this.state.loadingCompDisountRemove}onClick={() => this.compDiscountRemoveItem(product, 'comp')} variant='contained' style={{margin:'1em', backgroundColor: this.state.loadingCompDisountRemove?'grey':'green', color: 'white'}}>Comp Item</Button>}
                                                <br/>
                                                {/* <Button disabled={this.state.loadingCompDisountRemove} onClick={() => this.compDiscountRemoveItem(product, 'discount')} variant='contained' style={{margin:'1em' backgroundColor: this.state.loadingCompDisountRemove?'grey':'blue', color:'white'}}>Discount Item (%)</Button> */}
                                                <br/><br/>
                                                <Button disabled={this.state.loadingCompDisountRemove} onClick={() => this.compDiscountRemoveItem(product, 'remove')} variant='contained' style={{margin:'1em', backgroundColor: this.state.loadingCompDisountRemove?'grey':'#ff3333', color:'white'}}>Remove Item from order</Button>
                                            </div> 
                                        </Card>
                                    </Grid>
                                    </div>
                                ))}
                            </Grid>
                            <hr/>
                            <span style={{fontWeight: 'bold', margin:'2em'}}>
                                <p>Subtotal: ${(this.state.clickedOrder.totalCost - this.state.clickedOrder.tip - this.state.clickedOrder.taxInOrder).toFixed(2)}</p>
                                <p>Tip: ${this.state.clickedOrder.tip.toFixed(2)}</p>
                                <p>Tax: ${this.state.clickedOrder.taxInOrder.toFixed(2)}</p>
                                <p>Total: ${this.state.clickedOrder.totalCost.toFixed(2)}</p>
                            </span>
                            
                            {this.state.loadingCompDisountRemove && <LinearProgress/>}  
                        </div>
                </Dialog>
                }
                {/* click elipses */}
                <Dialog onClose={this.handleClose} aria-labelledby="order-status" open={this.state.clickedOrderOptions}>
                    <DialogTitle id="order-statuse">Order Options</DialogTitle>
                        <List>
                            <ListItem button onClick={() => this.printTicket(this.state.clickedOrder)}>
                                <ListItemAvatar>
                                    <Avatar> <PrintRoundedIcon/></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Print Ticket" />
                            </ListItem>
                            <br/>

                            {this.state.clickedOrder && this.state.clickedOrder.selectedOrderConfirmationMethod === 'delivery' &&
                            <ListItem button onClick={() => this.outForDelivery(this.state.clickedOrder)}>
                                <ListItemAvatar>
                                    <Avatar> <DirectionsBikeIcon/></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Out For Delivery" />
                            </ListItem>
                            }
                            <br/>
                            <ListItem button onClick={() => this.setState({clickedCancel:true})}>
                                <ListItemAvatar>
                                    <Avatar> <BlockIcon/></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Cancel Order" />
                            </ListItem>
                        </List>
                </Dialog>

                {/* are you sure cancel? */}
                <Dialog onClose={this.handleClose} open={this.state.clickedCancel}>
                    <DialogTitle id="order-statuse">Are you sure you want to cancel?</DialogTitle>
                    <p style={{padding:'1em'}}>The user will not be charged and will be notified their order is cancelled</p> 
                    <DialogActions>
                    <Button style={{marginRight:'1em'}} onClick={this.handleClose} color="primary" variant='contained'> No</Button>
                    <Button style={{marginLeft:'1em'}} onClick={() => this.cancelOrder(this.state.clickedOrder)} variant='contained' color="primary" autoFocus>
                        Yes, Cancel
                    </Button>
                    </DialogActions> 
                </Dialog>

                {this.state.currentParty &&
                <Dialog onClose={()=>this.setState({openPartyDialog:false})} open={this.state.openPartyDialog}>
                    <DialogTitle id="order-statuse">Current Party: {this.state.currentParty.name}</DialogTitle>
                    <span style={{padding:'.5em'}}>
                        <p>Entry Code: {this.state.currentParty.entryCode}</p> 
                        <br/>
                        <p>Current Item Count: {this.state.currentParty.itemCount}</p>
                        <p>Max Item Count: {this.state.currentParty.maxItemCount}</p> 
                        <br/>
                        <p>Current Dollar Count: ${this.state.currentParty.dollarCount.toFixed(2)}</p>
                        <p>Max Dollar Amount: ${this.state.currentParty.maxDollarAmount.toFixed(2)}</p> 
                        <br/>
                    </span>
                    <DialogActions>
                    {/* <Button style={{marginRight:'1em'}} onClick={()=>this.setState({openPartyDialog:false})} color="primary" variant='contained'> No</Button> */}
                    {!this.state.currentParty.enabled && <Button style={{marginLeft:'1em'}} variant='contained' color="primary" autoFocus onClick={this.enableParty}>Enable Party</Button>}
                    {this.state.currentParty.enabled && <Button style={{marginLeft:'1em'}} variant='contained' color="secondary" autoFocus onClick={this.disableParty}>Disable Party</Button>}
                    </DialogActions> 
                </Dialog>
                }
                
                <span>
                {this.state.currentParty &&
                    <div style={{float:'left'}}>
                    <span style={{color:'black', padding:'.5em'}}><span>🎉</span> Party Orders</span>
                    <Button variant='outlined' color='primary' style={{padding:'.5em'}} onClick={()=>this.setState({openPartyDialog:true})}>
                        <span style={{color:'black'}}><span style={{fontWeight:'bold'}}>{this.state.currentParty.itemCount}/{this.state.currentParty.maxItemCount}</span> items sold</span>
                    </Button>
                    </div>
                }
                </span>
                {this.state.newOrder &&
                    <span>
                        <NotificationsActiveRoundedIcon className={styles.notificationBell} color="secondary" fontSize="large" />
                    </span>
                }
                <br/><br/><br/>
                <Paper elevation={3}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{fontWeight:'bold', minWidth: column.minWidth}} >
                                    {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>

                            {this.state.orders &&
                                <TableBody>
                                    {this.state.orders.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                        let p = row.products;
                                        return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{backgroundColor: row.fulfilled || row.cancelled || row.outForDelivery  ? '#F0F0F0' : 'transparent'}}>                                        
                                            <TableCell style={{fontSize: '1.25em'}} align="center" component="th" scope="row">
                                                <span style={{ cursor:'pointer'}} onClick={()=>this.viewCustomer(row)}>{row.username}</span>
                                                <br/><br/>
                                                {row.customerArrived && <span style={{color:'red', fontWeight:'bold'}}>*CUSTOMER ARRIVED*</span> }
                                            </TableCell>
                                            <TableCell align="left">
                                                <Card style={{padding:'1em'}} onClick={()=>this.viewOrderDetails(row)} raised='true' style={{padding:'.5em', backgroundColor: row.fulfilled || row.cancelled || row.outForDelivery  ? '#F0F0F0' : 'transparent'}}>
                                                    {p.map((product, index) => (
                                                        <div variant="outlined" key={index} style={{ cursor:'pointer', marginLeft:'1%', marginRight:'1%'}}>
                                                            <span style={{fontSize: '15px'}}>
                                                                <div component="h2" style={{fontWeight:"Bold"}}> {product.productName}</div>
                                                        {(product.productInstructions || null) &&
                                                            <><span style={{ fontSize: '15px' }}>
                                                            Instructions: {product.productInstructions}
                                                            </span><br /></>
                                                        }
                                                                {product.comped &&<span style={{fontStyle:'italic', color:'red'}}>*Product Comped</span>}
                                                                <div style={{paddingBottom:"5%"}}>
                                                                    {product.selectedOptions && product.selectedOptions.map((selectedOption, index) => ( 
                                                                        <Chip variant="outlined" label={<span style={{color:'grey', fontSize: '15px'}} > {selectedOption.option}: {selectedOption.selectedOption}</span>} key={index}/> 
                                                                        ))}
                                                                </div>                        
                                                            </span>
                                                        </div>
                                                    ))}
                                                    {row.instructionsForBusiness &&
                                                        <div>
                                                            <span style={{color:'red'}}>Instructions: </span><span style={{fontStyle:'italic'}}>{row.instructionsForBusiness}</span>
                                                        </div>
                                                    }
                                                </Card>
                                            </TableCell>
                                            <TableCell align="center" style={{fontSize: '1.25em'}}> {row.selectedOrderConfirmationMethod === 'dineIn' && <span>{row.tableNumber} </span>}{row.selectedOrderConfirmationMethod === 'carryOut' && <span>Carry Out</span>} {row.selectedOrderConfirmationMethod === 'bar' && <span>Bar</span>}
                                            {row.selectedOrderConfirmationMethod === 'delivery' && <span style={{fontSize:'.9em'}}><span style={{color:'red'}}>Delivery: </span>{row.userAddress}</span>}
                                            </TableCell>                                
                                            <TableCell align="center" style={{fontSize: '1.25em'}}>{row.timestampForDisplay}</TableCell>
                                            <TableCell align="center" style={{fontSize: '1.25em'}}>${row.totalCost.toFixed(2)}</TableCell>
                                            <TableCell align="right">
                                                <span style={{display: "inline"}}>
                                                    {row.cancelled ?
                                                        <Button disabled variant="contained" color="default">Cancelled</Button> 
                                                        :
                                                        <div>
                                                            {row.selectedOrderConfirmationMethod === 'delivery' ?
                                                            <span>
                                                               {row.fulfilled || row.outForDelivery ? 
                                                               <Button disabled variant="contained" color="default">Fulfilled</Button> 
                                                               : 
                                                               <Button variant="contained" color="primary" onClick={()=>this.outForDelivery(row)}>Out For Delivery</Button>          
                                                               }
                                                           </span> 
                                                           :
                                                            <span>
                                                                {row.fulfilled ? 
                                                                <Button disabled variant="contained" color="default">Fulfilled</Button> 
                                                                : 
                                                                <Button variant="contained" color="primary" onClick={()=>this.fulfilled(row.id)}>Fulfill</Button>          
                                                                }
                                                            </span> 
                                                            }
                                                        </div>
                                                    }
                                                    <IconButton onClick={()=>this.setState({clickedOrder:row, clickedOrderOptions:true})} style={{marginLeft:'.5em'}} aria-label="clickOptions"> <MoreVertIcon /></IconButton> 
                                                </span>                                           
                                            </TableCell>
                                                
                                            {/* <TableCell align="center"><Button onClick={()=>this.viewOrderDetails(row.id)}>View</Button></TableCell> */}
                                        </TableRow>
                                    )})}
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={this.state.orders && this.state.orders.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>

            </div>
        )
    }
}
