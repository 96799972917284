import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {TextField, Paper, Box} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Redirect} from 'react-router-dom';
import PlacesAutocomplete, {geocodeByAddress,getLatLng,} from 'react-places-autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {MuiPickersUtilsProvider,} from '@material-ui/pickers';
import { DatePicker } from "@material-ui/pickers";
import firebase from '../Config/firebase'
const db = firebase.firestore();


export default class SingUpBusiness extends Component {

    state = {
        activeStep: 0,
        phone: null,
        disabled: false,
        stripeId: null,
        address: null,
        shippingAddress: null,
        lat: null,
        lon: null,
        googlePlacesId: null,
        businessDomain: null
    }

    componentDidMount(){
        // console.log('LOADED SIGN UP PAGE')
    }

    useStyles = makeStyles(theme => ({
        root: {width: '100%',},
        backButton: {
          marginRight: theme.spacing(1),
        },
        skipButton: {
            marginRight: theme.spacing(1),
          },
        instructions: {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        },
    }));
      
    getSteps() {
        return ['General', 'Details', 'Password'];
    }


    handleBack = () => {
        this.setState({activeStep: this.state.activeStep - 1})
    };
    handleReset = () => {
        this.setState({activeStep: 0})
    };
    handleChangeAddress = (address) => {
        // console.log('in change address', address)
        this.setState({ address});
    };

    handleSelectAddress = async address => {
        console.log('selecting adrress', address)
        const results = await geocodeByAddress(address);
        const coords = await getLatLng(results[0]);
        this.setState({ 
          googlePlacesId: results[0].place_id,
          address: results[0].formatted_address,
          lat: coords.lat,
          lon: coords.lng
        });
    }; 

    handleChange = (e) => {
        this.setState({[e.target.id]: e.target.value})
        // console.log(this.state);
    }
    handleBusinessType = (e) => {
        this.setState({businessType: e.target.value})
        // console.log(this.state);
    }
    handleNext = () => {
        
        console.log('clicked next')

        if(document.getElementById("firstName")){
            if(!this.state.firstName){
                this.setState({wrongFirstNameFormat: true})
                return
            }
            document.getElementById("firstName").value = "";
        }
        if(document.getElementById("lastName")){
            if(!this.state.lastName){
                this.setState({wrongLastNameFormat: true})
                return
            }
            document.getElementById("lastName").value = "";
        }
        if(document.getElementById("businessName")){
            if(!this.state.businessName){
                this.setState({wrongBusinessNameFormat: true})
                return
            }
            document.getElementById("businessName").value = "";
        }
        if(document.getElementById("businessDomain")){
            // this.setState({step: 'businessDomain'})
            // if(!this.state.businessDomain){
            //     this.setState({wrongBusinessDomainFormat: true})
            //     return
            // }
            // document.getElementById("businessDomain").value = "";
        }

        if(document.getElementById("email")){
            if(!this.state.email){
                this.setState({wrongEmailFormat: true})
                return
            }
            document.getElementById("email").value = "";
        }

        //validate input values for each field when click next then clear input
        if(document.getElementById("phone")){
            var phoneCheck = /^\d{10}$/;
            if(!this.state.phone){
                this.setState({wrongPhoneFormat: true})
                return
            }
            if(this.state.phone.match(phoneCheck)){ 
                this.setState({wrongPhoneFormat: false})
            }
            else
            {
                console.log('phone number is not valid')
                this.setState({wrongPhoneFormat: true})
                return
            }
            document.getElementById("phone").value = "";
        }


        if(document.getElementById("address")){
            this.setState({step: 'contact'})
            if(!this.state.address || !this.state.googlePlacesId){
                this.setState({wrongAddressFormat: true})
                return
            }
            else{
                this.setState({wrongAddressFormat: false})
                document.getElementById("address").value = "";
            }
        }

        if(document.getElementById("password") || document.getElementById("passwordConfirm")){
            this.setState({step:'password'})
            var passwordValidate = /^[a-zA-Z0-9]{6,}$/;
            if(!this.state.password || !this.state.password.match(passwordValidate)){
                this.setState({wrongPasswordFormat: true})
                return
            }
            else{
                this.setState({wrongPasswordFormat: false})
            }

            if(!this.state.passwordConfirm || this.state.passwordConfirm !== this.state.password){
                console.log('passwords dont match')
                this.setState({wrongPasswordConfirmFormat: true})
                return 
            }
            else{
                this.setState({wrongPasswordConfirmFormat: false})
            }

            if(this.state.password.match(passwordValidate)) { 
                this.setState({step: 'complete', loading: true})
                document.getElementById("password").value = "";

                    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
                    .then(res=>{
                        console.log('signed up business!', res)
                        
                        res.user.sendEmailVerification().then(()=>{
                            console.log("email verification sent to user");
                            this.setState({emailSent: true})
                        })
                        .catch((error)=> {
                            console.log('email confirm error')
                        })
    
                        let orderConfirmationMethod =
                        {
                            carryOut: false,
                            carryOutMessage: null,
                            delivery: false,
                            deliveryMessage: null, 
                            dineIn: true,
                            dineInMessage: 'Prep time is about 20 minutes',
                            bar: false,
                            barMessage: null
                        }
                        let orderMethods = [
                            {name: 'dineIn', available: true, message: 'Prep time is about 20 minutes'},
                            {name: 'carryOut', available: false, message: null},
                            {name: 'delivery', available: false, message: null},
                            {name: 'bar', available: false, message: null}       
                        ]
                        let hours = {
                            sunday : {
                                open: false,
                                openTime: '9:00',
                                closeTime:'17:00'
                            },
                            monday : {
                                open: true,
                                openTime: '9:00',
                                closeTime:'17:00'
                            },
                            tuesday : {
                                open: true,
                                openTime: '9:00',
                                closeTime:'17:00'
                            },
                            wednesday : {
                                open: true,
                                openTime: '9:00',
                                closeTime:'17:00'
                            },
                            thursday : {
                                open: true,
                                openTime: '9:00',
                                closeTime:'17:00'
                            },
                            friday : {
                                open: true,
                                openTime: '9:00',
                                closeTime:'17:00'
                            },
                            saturday : {
                                open: false,
                                openTime: '9:00',
                                closeTime:'17:00'
                            }
                        }

                        let deliverectItegration = false
                        if(this.state.businessType === 'restaurant (Deliverect)'){
                            deliverectItegration = true
                        }
    
                        db.collection('businesses').doc(res.user.uid).set({
                            email: res.user.email,
                            businessType: this.state.businessType,
                            businessName: this.state.businessName,
                            businessDomain: this.state.businessDomain,
                            firstName: this.state.firstName,
                            lastName: this.state.lastName,
                            signUpDate: res.user.metadata.creationTime,
                            phone: this.state.phone,
                            address: this.state.address,
                            billingAddress: this.state.address,
                            orderNotificationSound: true,
                            hours: hours,
                            lat: this.state.lat,
                            lon: this.state.lon,
                            published: false,
                            disabled: false,
                            stripeConnectId: null,
                            googlePlacesId: this.state.googlePlacesId,
                            bannerImageUrl: 'https://tango-pwa.s3.us-east-2.amazonaws.com/businesses/' + res.user.uid + '/bannerImage.png',
                            profileImageUrl: 'https://tango-pwa.s3.us-east-2.amazonaws.com/businesses/' + res.user.uid + '/profileImage.png',
                            orderConfirmationMethod: orderConfirmationMethod,
                            orderMethods: orderMethods,
                            searchTags: [this.state.businessName.toLowerCase()],
                            tangoUrl: null,
                            deliverect: deliverectItegration
                        }) 
                        .then(()=>{
                            this.setState({signUpSuccess: true, loading:false})
                        })       
                        .catch((error)=> {
                            console.log('📛 error add user to firestore', error)
                            return error
                        });
                    })
                    .catch(err => {
                        console.log('📛 Error creating auth account', err);
                        this.setState({loading:false, signUpUserError:true, errorMessage: err.message})
                    })
                

            }
            else{ 
                console.log('password not at least six')
                this.setState({wrongPasswordFormat: true})
                return
            }
        }


        console.log('clicked next -->', this.state)
        this.setState({activeStep: this.state.activeStep + 1})
    };

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
            return <span>
                {/* <span role="img" aria-label="phone">📱</span>Business phone<br/> */}
                <TextField style={{width:'30%'}} error={this.state.wrongFirstNameFormat} onChange={this.handleChange} id="firstName" label="first name" />
                <br/>
                <TextField style={{width:'30%'}} error={this.state.wrongLastNameFormat} onChange={this.handleChange}  id="lastName" label="last name" />
                <br/>
                <TextField style={{width:'30%'}} error={this.state.wrongBusinessNameFormat} onChange={this.handleChange} id="businessName" label="business name" />
                <br/>
                <TextField style={{width:'30%'}} onChange={this.handleChange}id="businessDomain" label="business web site (if applicable)" />
                <br/><br/><br/>

                <InputLabel id="businessTypeLabel">Business Type</InputLabel>
                <Select style={{width:'30%'}}
                    // labelId="Business-Type"
                    id="businessType"
                    value={this.state.businessType}
                    onChange={this.handleBusinessType}
                    >
                    <MenuItem value={'bar'}>Bar or Brewery</MenuItem>
                    <MenuItem value={'entertainment'}>Entertainment or Sports Venue</MenuItem>
                    <MenuItem value={'coffee'}>Coffee</MenuItem>
                    <MenuItem value={'foodTruck'}>Food Truck</MenuItem>
                    {/* <MenuItem value={'chainRestaurant'}>Restaurant (Chain / Multiple Locations)</MenuItem> */}
                    <MenuItem value={'restaurant'}>Restaurant (Single Location)</MenuItem>
                    <MenuItem value={'restaurant (Deliverect)'}>Business with POS Integration (Deliverect)</MenuItem>
                    {/* <MenuItem value={'diningServices'}>College Dining Services</MenuItem> */}
                    </Select>
                    <br/><br/>
                </span>;
            
            case 1:
            return <span>
                <TextField style={{width:'30%'}} error={this.state.wrongEmailFormat} onChange={this.handleChange} id="email" label="email" />
                <br/>
                <TextField style={{width:'30%'}} error={this.state.wrongPhoneFormat} onChange={this.handleChange} id="phone" label="phone" />
                <br/>

                <PlacesAutocomplete value={this.state.address} onChange={this.handleChangeAddress} onSelect={this.handleSelectAddress}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                        <TextField error={this.state.wrongAddressFormat} id="address" label="select address" style={{width:'30%', backgroundColor:'white', borderRadius: '5px', margin:'1em 1em 1em 1em', color: 'black'}} {...getInputProps({className: 'location-search-input'})}/>
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer', color:'black' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer', color:'black' };
                                return (
                                  <div {...getSuggestionItemProps(suggestion, {className, style})} >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                </span>;
            
            case 2:
            return <span>
                <span role="img" aria-label="lock">🔒</span>Create a secure password<br/>
                <TextField style={{width:'30%'}} error={this.state.wrongPasswordFormat} onChange={this.handleChange}id="password" label="password"  type="password"/>
                <br/>
                <TextField style={{width:'30%'}} error={this.state.wrongPasswordConfirmFormat} onChange={this.handleChange} type="password" id="passwordConfirm" label="password confirm" /></span>;          
            
            default:
            return 'Unknown stepIndex';
        }
    }

    render() {
        // if(this.state.signedUpUserSuccess) return <Redirect to='/' />

        const {activeStep} = this.state
        const steps = this.getSteps();
        return (
            <Paper elevation={3} className={this.useStyles.root} style={{paddingTop:'10em', paddingBottom:'50em'}}>
                {!this.state.emailSent &&
                <span>
                    <Box sfontFamily="Work Sans, Sans-Serif" fontWeight="fontWeightBold" fontSize="h6.fontSize">Create a business account</Box>
                    {!this.state.errorMessage &&
                    <Stepper  activeStep={activeStep} alternativeLabel>
                        {steps.map(label => (
                        <Step key={label}>
                            <StepLabel><span>{label}</span></StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                    }
                    {this.state.loading && <LinearProgress />}
                    {this.state.errorMessage && 
                    <div>
                        <Typography className={this.useStyles.instructions}>Oops!</Typography> 
                        <p>{this.state.errorMessage}</p> 
                    </div> 
                    }
                </span>    
                }
  
                <div>
                    {activeStep === steps.length ? (
                    <div>
                        {this.state.emailSent &&
                        <div>
                            <span role="img" aria-label="success">🎉</span>
                            <Typography className={this.useStyles.instructions}>Success!</Typography>
                            <span role="img" aria-label="success">🎉</span>
                            <p>We sent you an email to verify your new account</p>
                            <p>One of our reps will reach out soon</p>
                            <Button href="./">Home</Button>
                        </div>
                        }
                    </div>
                    ) : (
                    <div>
                        <Typography className={this.useStyles.instructions}>{this.getStepContent(activeStep)}</Typography>
                        
                        <div>
                        <br/>
                        <Button disabled={activeStep === 0} onClick={this.handleBack}>Back</Button>
                        <Button variant="contained" onClick={this.handleNext}>
                            {activeStep === steps.length - 1 ? 'Join' : 'Next'}
                        </Button>
                        <br/><br/>
                        
                        {/* skip button */}
                        {/* {activeStep !== steps.length - 1 &&
                        <button style={{border:'none', cursor:'pointer', backgroundColor:'transparent', color: 'steelblue'}} variant="contained" onClick={this.handleSkip}> {activeStep === steps.length - 1 ? 'Finish' : 'Skip'}
                        </button>
                        } */}
                        </div>
                        
                    </div>
                    )}
                </div>

            </Paper>
        )
    }
}
