import React, { Component } from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import './App.css';
import firebase from './Config/firebase'
import BusinessNavbar from './Admin/BusinessNavBar'
import BusinessLanding from './Admin/BusinessLanding'
import BusinessAccount from './Admin/Account'
import Analytics from './Admin/Analytics'
import ConfirmStripeConnect from './Admin/ConfirmStripeLanding'
import DailyReporting from './Admin/DailyReporting'
import Customers from './Admin/Customers'
import Reservations from './Admin/Reservations'
import Deals from './Admin/Deals'
import Orders from './Admin/Orders/Orders'
import CreateProducts from './Admin/Products/CreateProducts'
import AdminHelp from './Admin/AdminHelp'
import EditProducts from './Admin/Products/EditProducts'
import NotFound from './NotFound'
import Landing from './Landing'
import Login from './Auth/Login'
import SignUpBusiness from './Auth/SignUpBusiness'
import StaffLanding from './Staff/StaffLanding'
import Parties from './Admin/Parties/Parties'
import PartyOrders from './Admin/Parties/PartyOrders'

const db = firebase.firestore();

class App extends Component {
  
  
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      user: null,
      userType: null
    }
  }

  componentDidMount(){
    this.setState({loading: true})

    //**check if user is authenticated
    firebase.auth().onAuthStateChanged((user)=> {
      
      //if no user 
      if(!user){
        console.log('LOADED APP NO USER', this.state)
        this.setState({loading: false, userType: 'anonymousUser'})
        return
      }

      else {
        // console.log('LOADED APP WITH USER')
        var returnedUser = {
          id: user.uid,
          email: user.email,
          emailVerified: user.emailVerified,
          admin: false,
          userType: ''
        } 
        this.setState({isAuthenticated: true, user: returnedUser, loading: false})


        //**check if authenticated person is admin or staff
        firebase.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
          
          // if user is admin then they are a business
          if (!!idTokenResult.claims.admin) {
            
            console.log('USER IS ADMIN', idTokenResult.claims)      
              let businessRef = db.collection('businesses').doc(returnedUser.id);
              businessRef.get()
                .then(doc => {
                  if (!doc.exists) {
                    console.log('No such document!');
                  } else {
                    returnedUser.data = doc.data()
                    returnedUser.admin = idTokenResult.claims.admin
                    returnedUser.userType = idTokenResult.claims.userType
                    this.setState({user: returnedUser, admin:true, loading: false, userType: 'admin'})
                    console.log(this.state)
                  }
                })
                .catch(err => {
                  console.log('Error getting document', err);
                });
          } 
          
          //else they are STAFF OR user
          else {
            let userRef = db.collection('staff').doc(returnedUser.id);
            userRef.get()
              .then(doc => {
                if (!doc.exists) {
                  // console.log('USER TRYING TO LOG IN IS NOT ADMIN OR STAFF SO LOG THEM OUT');

                  firebase.auth().signOut().then(()=> {
                    console.log('signed out user!')
                    window.location.href = '/';
                  })
                  
                } else {
                  // console.log('USER TRYING IS STAFF');
                  returnedUser.data = doc.data()
                  returnedUser.admin = false
                  returnedUser.userType = 'staff'
                  this.setState({user: returnedUser, admin:false, loading: false, userType: 'staff'})
                  // console.log(this.state)
                }
              })
              .catch(err => {
                console.log('Error getting document', err);
              });
          }

        })
        .catch((error) => {
          console.log(error);
        });

      }  
    });//end check auth state

  }

  render(){
    return (
        <div className="App" style={{color: 'white'}}>
            {!this.state.userType ? <LinearProgress />:
            <BrowserRouter>
            
              {/* nav bars */}
              {this.state.isAuthenticated && this.state.userType === 'admin' && <BusinessNavbar user={this.state.user}/> }
              {this.state.isAuthenticated && this.state.userType === 'staff' && <BusinessNavbar user={this.state.user}/> }

              <Switch>

                {/* admin pages */}
                {this.state.userType === 'admin' && this.state.user.userType === 'business' &&<Route exact path='/' render={(props) => <BusinessLanding {...props} user={this.state.user} />}/>}

                {/* staff landing */}
                {this.state.userType === 'staff' && <Route exact path='/' render={(props) => <StaffLanding {...props} user={this.state.user} />}/>}
                
                {/* other */}
                {this.state.userType === 'anonymousUser' && <Route exact path='/' render={(props) => <Landing {...props} />}/>}

                {/* no user routes */}
                <Route path='/logIn'component={Login} />
                <Route path='/signUp'component={SignUpBusiness} />

                {/* business routes */}
                <Route path='/account' render={(props) => <BusinessAccount {...props} user={this.state.user} />}/>
                <Route path='/confirmStripeConnect' component={ConfirmStripeConnect} />
                <Route path='/analytics' render={(props) => <Analytics {...props} user={this.state.user} />}/>
                <Route path='/dailyReporting' render={(props) => <DailyReporting {...props} user={this.state.user} />}/>
                <Route path='/deals' render={(props) => <Deals {...props} user={this.state.user} />}/>
                <Route path='/orders' render={(props) => <Orders {...props} user={this.state.user} />}/>
                <Route path='/reservations' render={(props) => <Reservations {...props} user={this.state.user} />}/>
                <Route path='/parties' render={(props) => <Parties {...props} user={this.state.user} />}/>
                <Route path='/partyOrders' render={(props) => <PartyOrders {...props} user={this.state.user} />}/>
                <Route path='/products' render={(props) => <CreateProducts {...props} user={this.state.user} />}/>
                <Route path='/editProducts' render={(props) => <EditProducts {...props} user={this.state.user} />}/>
                <Route path='/customers' render={(props) => <Customers {...props} user={this.state.user} />}/>
                <Route path='/adminHelp' render={(props) => <AdminHelp {...props} user={this.state.user} />}/>

                
                <Route exact path="/notFound" component ={NotFound}/>
                <Redirect to="/notFound"/>
               
              </Switch>


            </BrowserRouter>
            }
        </div>

    );
  }
}

export default App;
