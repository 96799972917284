import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import moment from  'moment'
import Avatar from '@material-ui/core/Avatar';
import BlockIcon from '@material-ui/icons/Block';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import soundfile from '../Assets/alert.mp3'
import { isEqual } from 'lodash';
import axios from 'axios';

// TO DO:

import firebase from '../Config/firebase'
const db = firebase.firestore();

const columns = [
    { 
        id: 'customerName', 
        label: 'Name', 
        minWidth: 120,
        align: 'center',
    },
    {
        id: 'items',
        label: 'Items',
        minWidth: 80,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'tableNumber',
        label: 'Table Number',
        minWidth: 80,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'time',
        label: 'Time',
        minWidth: 120,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    // {
    //     id: 'total',
    //     label: 'Total ($)',
    //     minWidth: 120,
    //     align: 'center',
    //     format: (value) => value.toFixed(2),
    // },
    { 
        id: 'fulfilled', 
        label: 'Order Status', 
        minWidth: 70,
        align: 'center'
      },
  ];
  
//not using
// function autoPrintTicket(order){
//     console.log('print this -->', order)

//     let orderType = 'Dine In'
//     let tableNumber = 'not applicable'
//     if(order.selectedOrderConfirmationMethod === 'carryOut'){
//         orderType = 'Carry Out'
//     }
//     if(order.selectedOrderConfirmationMethod === 'bar'){
//         orderType = 'Bar'
//     }
//     if(order.tableNumber){
//         tableNumber = order.tableNumber
//     }

//     var ticket = window.open('', 'my div', 'height=400,width=600');
//     ticket.document.write('<html><head><title>TANGO TICKET</title>');
//     ticket.document.write('</head><body >');
//         ticket.document.write('<br/> TANGO ORDER - ', order.businessName);
//         ticket.document.write('<br/>ORDER #', order.orderNumber);
//         ticket.document.write('<br/>CUSTOMER NAME: ', order.username);
//         ticket.document.write('<br/>ORDER TYPE: ', orderType);
//         ticket.document.write('<br/>TABLE # ', tableNumber, '<br/><br/>');
//         //loop thorugh and create new line fore each product in order
//         for (let i = 0; i<order.products.length; i++){
//             let product = order.products[i]

//             ticket.document.write(product.productName, '<br/>');
//             //new print line for each selected option
//             if(product.selectedOptions && product.selectedOptions.length > 0){
//                 for (let j = 0; j<product.selectedOptions.length; j++){
//                     let option = product.selectedOptions[j]
//                     ticket.document.write('- ', option.selectedOption, '<br/>');
//                 }
//             }
//             ticket.document.write('<br/>');
//         }

//         ticket.document.write('<br/><br/>');
//         ticket.document.write('Total Cost: $', order.totalCost.toFixed(2));
//         ticket.document.write('<br/>');
//     ticket.document.write('</body></html>');

//     ticket.print();
//     ticket.close();

//     //after printing update to printedTicekt so we dont print again
//     var orderRef = db.collection("orders").doc(order.id);
//     // Set the "capital" field of the city 'DC'
//     return orderRef.update({printedTicket: true})
//     .then(()=> {
//         console.log("updated to printed true!");
//     })
//     .catch((error)=> {
//         console.error("Error updating document: ", error);
//     });
// }


export default class OrdersTableView extends Component {

    state={
        page: 0,
        rowsPerPage: 25,
        commandsToPrint: this.props.commandsToPrint
    }
   
    componentDidMount(){
        console.log('loaded view ORDERS for staff -->', this.props)
        let yesterdayStart = new Date(moment().subtract(1, 'day').startOf('day').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
        
        let orders = []
        let unfulfilledOrders = []
        let otherOrders = [] 
        let audio = new Audio(soundfile);

        this.realTimeOrders = db.collection("orders").where('businessId', '==', this.props.user.data.businessId)
        .where("timestampCreated", ">=", yesterdayStart)
        .orderBy('timestampCreated', 'desc')
        .onSnapshot((querySnapshot)=> {
            if(querySnapshot.size === 0){
                return
            }else {
                querySnapshot.forEach(doc => {
                    let order = doc.data()
                    order.id = doc.id
                    order.timestampForDisplay = order.timestampCreated.toDate()
                    order.timestampForDisplay = `${order.timestampForDisplay.getMonth()+1}/${order.timestampForDisplay.getDate()}/${order.timestampForDisplay.getFullYear()} ${order.timestampForDisplay.getHours()}:${order.timestampForDisplay.getMinutes()} `
                    order.timestampForDisplay = moment(order.timestampForDisplay).format('hh:mm a')
                    // console.log('--->', order.timestampForDisplay)
                    
                    if(!order.fulfilled && !order.cancelled){
                        unfulfilledOrders.push(order) 
                    }else{
                        otherOrders.push(order)
                    }
                    // console.log(order)

                    //if logged in staff has auto print then add print logic here - only print if not printed & not cancelled & not fulfilled
                    if(this.props.user.data.printTickets && !order.printedTicket && !order.cancelled && !order.fulfilled){
                        // autoPrintTicket(order)
                    }
                })

                orders = unfulfilledOrders.concat(otherOrders) // concat everything else at bottom - this keeps timestamp order

                this.setState({orders: orders})
                         
                orders = [] //set back to empty for new orders coming in
                unfulfilledOrders = []
                otherOrders = [] 
            }
        })

        //new orders check to make sound alert
        if(this.props.user.data.orderNotificationSound){
            this.newOrdersCheck = db.collection("orders").where('businessId', '==', this.props.user.data.businessId)
            .where("timestampCreated", ">=", yesterdayStart)
            .orderBy('timestampCreated', 'desc')
            .onSnapshot((snapshot)=> {
                snapshot.docChanges().forEach((change)=>{
                    if (change.type === "added") {
                        let order = change.doc.data()
                        if (order.fulfilled || order.cancelled || order.processing || order.ready){
                            // console.log('do nothing')
                        }else{
                            return console.log("New order so make sound!!!!: ", audio.play());
                        }
                    }
                });
            });
        }
    }

    componentWillUnmount(){
        if(this.realTimeOrders){
            this.realTimeOrders() 
            console.log('unmount component and unsubscribe realtime lister')
        }
        if(this.newOrdersCheck){
            this.realTimeOrders() 
            console.log('unmount component and unsubscribe realtime lister')
        }
    }

    concatOrder = (products) => {
        let out = {};
        for (let i=0; i<products.length; i++) {
          if (Object.keys(out).includes(products[i].productName)) {
            let opt = products[i].selectedOptions;
            let index = -1;
            for (let j=0; j<out[products[i].productName].length; j++) {
              if (out[products[i].productName][j]) {
                if (isEqual(out[products[i].productName][j][0], opt)) {
                  index = j;
                }
              }
            }
            if (index !== -1) {
              out[products[i].productName][index][1].total += 1;
            } else {
              out[products[i].productName].push([products[i].selectedOptions, {total: 1}]);
            }
          } else {
            out[products[i].productName] = [[products[i].selectedOptions, {total: 1}]];
          }
        }
        return out;
    }

    //this updates to processing prints for now
    confirm = (order) => {
        var orderRef = db.collection("orders").doc(order.id);
        return orderRef.update({processing:true})
        .then(()=> {
            console.log("updated doc to processing!");
            this.printTicket(order)
            this.setState({clickedOrderOptions:false, clickedOrder: null})
        })
        .catch((error)=>{
            console.error("Error updating document: ", error);
            this.printTicket(order)
            this.setState({clickedOrderOptions:false, clickedOrder: null})
        });
    };

    fulfilled = (order) => {
        console.log('clicked fulfill order', order.id)
        let orderRef = db.collection("orders").doc(order.id)
        orderRef.update({fulfilled:true})
        this.setState({clickedOrderOptions:false, clickedOrder: null})
    };

    cancelOrder = (order) => {
        console.log('clicked cancel order', order)
        let orderRef = db.collection("orders").doc(order.id)
        orderRef.update({cancelled:true })
        this.setState({clickedOrderOptions:false, clickedOrder: null, clickedCancel:false})
    };
    outForDelivery = (order) => {
        console.log('clicked order out for delivery', order)
        let orderRef = db.collection("orders").doc(order.id)
        orderRef.update({ outForDelivery: true })
        this.setState({clickedOrderOptions:false, clickedOrder: null})
    };

    removeItemFromOrder = (itemIndex) => {
        console.log('clicked order removeItemFromOrder', this.state.clickedOrder, itemIndex)
        if( this.state.clickedOrder.products.length === 1){
            return alert ('Order has 1 item. Please cancel order for proper analytics.')
        }
        var orderRef = db.collection("orders").doc(this.state.clickedOrder.id);
        orderRef.update({
            totalCost: this.state.clickedOrder.totalCost - this.state.clickedOrder.products[itemIndex].productPrice,
            products: firebase.firestore.FieldValue.arrayRemove(this.state.clickedOrder.products[itemIndex])
        })
        .then(()=> {
            console.log("Order successfully updated!");
            axios({
                method: 'post',
                url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/saveCompedItem',
                data: {
                  orderId: this.state.clickedOrder.id,
                  itemIndex: itemIndex,
                  businessId: this.state.businessId,
                  product: this.state.clickedOrder.products[itemIndex]
                }
              }).then((response) =>{
                console.log('posted to cloud function to save removed item', response)
              })
            this.setState({clickedViewOrder: false, clickedOrder: null})  
        })
        .catch((error)=>{
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
            this.setState({clickedViewOrder: false, clickedOrder: null})  
        });
    }

    printTicket = (order) =>{
        var orderRef = db.collection("orders").doc(order.id);
        var commandsToPrint =
            "<BIG><BOLD><CENTER>" + order.businessName + "<BR>\n" +
            "<BIG><BOLD><CENTER>" + order.selectedOrderConfirmationMethod + "<BR>\n" +
            "<LINE>" + "<BR>\n" +
            "<LEFT>Customer Name: "+ order.username + "<BR>\n" +
            "<LEFT>Customer Phone: " + order.userPhone + "<BR>\n";

        if (order.selectedOrderConfirmationMethod === "dineIn") {
            commandsToPrint += 
            "<MEDIUM1><CENTER>" + order.selectedOrderConfirmationMethod + "<BR>\n" +
            "<LEFT>Table Number: " + order.tableNumber + "<BR>\n";
        }
        if (order.selectedOrderConfirmationMethod === "carryOut") {
            commandsToPrint += 
            "<MEDIUM1><CENTER>" + order.selectedOrderConfirmationMethod + "<BR>\n"; 
        }
        if (order.selectedOrderConfirmationMethod === "delivery") {
            commandsToPrint += 
            "<MEDIUM1><CENTER>" + order.selectedOrderConfirmationMethod + "<BR>\n"; 
        } 

        commandsToPrint += 
            "<LEFT>Total Cost: $" + order.totalCost.toFixed(2) + "<BR>\n" +
            "<LEFT>Tip Included: $" + order.tip.toFixed(2) + "<BR>\n" +
            "<MEDIUM1><CENTER>Products:<BR>\n";

    // Sort order based on productName and selectedOptions
    // TO DO: So it looks like something here/below is messing up how the ticket prints. NEED TO FIX. 
          //Work with Brandon to do test prints and trail/error
    order.products.sort(function(a, b) {
        let nameA = a.productName.toUpperCase();
        let nameB = b.productName.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        let selectedOptionsA = a.selectedOptions;
        let selectedOptionsB = b.selectedOptions;
        if (selectedOptionsA && selectedOptionsB) {
            if (selectedOptionsA.length < selectedOptionsB.length) {
                return -1;
            }
            if (selectedOptionsA.length > selectedOptionsB.length) {
                return 1;
            }

            for (let i=0; i<selectedOptionsA.length; i++) {
                let priceA = selectedOptionsA[i].additionalCost;
                let priceB = selectedOptionsB[i].additionalCost;
                if (priceA < priceB) {
                    return -1;
                }
                if (priceA > priceB) {
                    return 1;
                }
                let sampleOptionA = selectedOptionsA[i].selectedOption;
                let sampleOptionB = selectedOptionsB[i].selectedOption;
                if (sampleOptionA < sampleOptionB) {
                    return -1;
                }
                if (sampleOptionA > sampleOptionB) {
                    return 1;
                }
            }
        } else if (selectedOptionsA || selectedOptionsB) {
            if (selectedOptionsA && !selectedOptionsB) {
                return 1;
            }
            if (!selectedOptionsA && selectedOptionsB) {
                return -1;
            }
        }
            // names must be equal
            return 0;
        })

        let concatProducts = this.concatOrder(order.products);

        // let linesPrinted = 0;
        // let passedFirstPrint = false;

        for (let i=0; i<order.products.length; i++) {
            let productName = order.products[i].productName;
            let selectedOptions = order.products[i].selectedOptions;

            
            if (Object.keys(concatProducts).includes(productName)) {
                for (let j=0; j<concatProducts[productName].length; j++) {
                    if (isEqual(concatProducts[productName][j][0], selectedOptions)) {
                        let total = concatProducts[productName][j][1].total;
                        if (total > 1) {
                            commandsToPrint += total + "X " + productName + "<BR>\n";
                            i += (total - 1)
                        } 
                        else {
                            commandsToPrint += productName + "<BR>\n";
                        }
                    }
                }
            }
            else {
                commandsToPrint += productName + "<BR>\n";
            }

            // // Print of product
            // linesPrinted += 1;
            // if (!passedFirstPrint) {
            //   if (linesPrinted === 2) {
            //     linesPrinted = 0;
            //     commandsToPrint += "<CUT>\n"
            //     passedFirstPrint = true;
            //   }
            // } else {
            //   if (linesPrinted === 8) {
            //     linesPrinted = 0;
            //     commandsToPrint += "<CUT>\n";
            //   }
            // }

            if (selectedOptions) {
                for(let k = 0; k<selectedOptions.length; k++) {
                  commandsToPrint += "     >>" + selectedOptions[k].selectedOption + "<BR>\n";

                  // // Print of selected option
                  // linesPrinted += 1;
                  // if (!passedFirstPrint) {
                  //   if (linesPrinted === 2) {
                  //     linesPrinted = 0;
                  //     commandsToPrint += "<CUT>\n";
                  //     passedFirstPrint = true;
                  //   }
                  // } else {
                  //   if (linesPrinted === 8) {
                  //     linesPrinted = 0;
                  //     commandsToPrint += "<CUT>\n";
                  //   }
                  // }
                }
            }

          }

          // if ((linesPrinted - 4) >= 8) commandsToPrint += "<CUT>\n";

          commandsToPrint += 
              "<LINE>" + "<BR>\n" +
              "<LEFT>Time Received: " + order.timestampCreated.toDate() + "<BR>\n" +
              "<LEFT>Order Number: " + order.orderNumber + "<BR>\n" +
              "<BR><BR><BR><BR><BR><BR><BR><BR>\n";

          commandsToPrint += 
              "<CUT>\n" + "<BR><BR><BR><BR><CUT>\n" + "<DRAWER>\n";

        console.log("Printing: ", commandsToPrint);

        var textEncoded = encodeURI(commandsToPrint);
    window["printNewReceipt"](textEncoded);

        if(!order.printedTicket){
            return orderRef.update({printedTicket: true})
            .then(()=> {
                console.log("updated doc to printed!");
                this.setState({clickedOrderOptions:false, clickedOrder: null})
            })
            .catch((error)=>{
                console.error("Error updating document: ", error);
                this.setState({clickedOrderOptions:false, clickedOrder: null})
            });
        }else{
            this.setState({clickedOrderOptions:false, clickedOrder: null})
        }
    }

    viewCustomer = (order)=>{
        this.setState({clickedViewCustomer: true, clickedOrder: order})
        // console.log('clicked view customer', order)
    }

    viewOrderDetails = (order)=>{
        this.setState({clickedViewOrder: true, clickedOrder: order})
        // console.log('clicked view order', order)
    }

    handleChangePage = (event, newPage) => {
        console.log('change page', newPage)
        this.setState({page: newPage})
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: +event.target.value, page: 0})
    };

    handleClose = () =>{
        this.setState({clickedOrderOptions: false, clickedViewCustomer:false, clickedOrder: null, clickedViewOrder: false, clickedCancel:false})
    }

    render() {
        return (
            <div style={{marginTop: '8em', marginLeft:'2em', marginRight:'2em', marginBottom: '2em'}}>
                
                <Dialog onClose={this.handleClose} aria-labelledby="order-status" open={this.state.clickedViewCustomer}>
                    <DialogTitle id="order-statuse">Customer Info</DialogTitle>
                        {this.state.clickedOrder &&
                        <List style={{padding:'1em'}}>
                            <p>Name: {this.state.clickedOrder.username}</p>
                            <p>Phone: {this.state.clickedOrder.userPhone}</p>
                            <p>Last 4 Card # {this.state.clickedOrder.last4}</p>
                        </List>
                        }
                </Dialog>

                {/* click view order */}
                <Dialog style={{textAlign: 'center'}} fullWidth={true} maxWidth={'md'} onClose={this.handleClose} open={this.state.clickedViewOrder}>
                    <DialogTitle id="order-statuse" >Order Details</DialogTitle>
                        {this.state.clickedOrder &&
                        <div style={{padding:'1em'}}>
                            <p>Order # {this.state.clickedOrder.orderNumber}</p>
                            <br/>

                            <Grid container spacing={1}>
                                {this.state.clickedOrder.products.map((product, index) => (
                                    <div key={index}>            
                                    <Grid item xs={12}>
                                        <Card style={{padding:'1em', margin:'1em'}}>
                                            <div component="h2" style={{fontWeight:"Bold"}}> {product.productName} </div>
                                            <div style={{paddingBottom:"5%"}}>
                                                {product.selectedOptions && product.selectedOptions.map((selectedOption, index) => ( 
                                                    <Chip variant="outlined" label={<span style={{color:'grey', fontSize: '15px'}} > {selectedOption.option}: {selectedOption.selectedOption}</span>} key={index}/> 
                                                ))}
                                                <p>Price: ${product.productPrice.toFixed(2)}</p>
                                                <Button onClick={() => this.removeItemFromOrder(index)} variant='contained' style={{backgroundColor:' #ff3333', color:'white'}}>Remove Item from order</Button>
                                            </div> 
                                        </Card>
                                    </Grid>
                                    </div>
                                ))}
                            </Grid>

                            <hr/>
                            <p>Total: ${(this.state.clickedOrder.totalCost - this.state.clickedOrder.tip).toFixed(2)}</p>
                            <p>Tip: ${this.state.clickedOrder.tip.toFixed(2)}</p>
                            <p>Total + Tip: ${this.state.clickedOrder.totalCost.toFixed(2)}</p>
                        </div>
                        }
                </Dialog>

                <Dialog onClose={this.handleClose} aria-labelledby="order-status" open={this.state.clickedOrderOptions}>
                    <DialogTitle id="order-statuse">Order Options</DialogTitle>
                        <List>
                            <ListItem button onClick={() => this.printTicket(this.state.clickedOrder)}>
                                <ListItemAvatar>
                                    <Avatar> <PrintRoundedIcon/></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Print Ticket" />
                            </ListItem>
                            <br/>
                            {this.state.clickedOrder && this.state.clickedOrder.selectedOrderConfirmationMethod === 'delivery' &&
                            <ListItem button onClick={() => this.outForDelivery(this.state.clickedOrder)}>
                                <ListItemAvatar>
                                    <Avatar> <DirectionsBikeIcon/></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Out For Delivery" />
                            </ListItem>
                            }
                            <br/>

                            <ListItem button onClick={() => this.setState({clickedCancel:true})}>
                                <ListItemAvatar>
                                    <Avatar> <BlockIcon/></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Cancel Order" />
                            </ListItem>  

                        </List>
                </Dialog>

                {/* are you sure cancel? */}
                <Dialog onClose={this.handleClose} open={this.state.clickedCancel}>
                    <DialogTitle id="order-statuse">Are you sure you want to cancel?</DialogTitle>
                    <p style={{padding:'1em'}}>The user will not be charged and will be notified their order is cancelled</p> 
                    <DialogActions>
                    <Button style={{marginRight:'1em'}} onClick={this.handleClose} color="primary" variant='contained'> No</Button>
                    <Button style={{marginLeft:'1em'}} onClick={() => this.cancelOrder(this.state.clickedOrder)} variant='contained' color="primary" autoFocus>
                        Yes, Cancel
                    </Button>
                    </DialogActions> 
                </Dialog>

                <Paper elevation={3}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{fontWeight:'bold', minWidth: column.minWidth}} >
                                    {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>

                            {this.state.orders &&
                                <TableBody>
                                    {this.state.orders.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                        let p = row.products;
                                        return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{backgroundColor: row.fulfilled || row.cancelled || row.outForDelivery ? '#F0F0F0' : 'transparent'}}>                                        
                                            <TableCell onClick={()=>this.viewCustomer(row)} style={{fontSize: '1.25em', cursor:'pointer'}} align="center" component="th" scope="row">
                                                <span>{row.username}</span>
                                                <br/><br/>
                                                {row.customerArrived && <span style={{color:'red', fontWeight:'bold'}}>*CUSTOMER ARRIVED*</span> }
                                            </TableCell>
                                            <TableCell align="left">
                                                <p onClick={()=>this.viewOrderDetails(row)}>
                                                    {p.map((product, index) => (
                                                        <div variant="outlined" key={index} style={{cursor:'pointer', marginLeft:'1%', marginRight:'1%'}}>
                                                            <span style={{fontSize: '15px'}}>
                                                                <div component="h2" style={{fontWeight:"Bold"}}> {product.productName} </div>
                                                                <div style={{paddingBottom:"5%"}}>
                                                                    {product.selectedOptions && product.selectedOptions.map((selectedOption, index) => ( 
                                                                        <Chip variant="outlined" label={<span style={{color:'grey', fontSize: '15px'}} > {selectedOption.option}: {selectedOption.selectedOption}</span>} key={index}/> 
                                                                        ))}
                                                                </div>                                             
                                                            </span>
                                                        </div>
                                                    ))}
                                                    {row.instructionsForBusiness &&
                                                    <div>
                                                        <span style={{color:'red'}}>Instructions: </span><span style={{fontStyle:'italic'}}>{row.instructionsForBusiness}</span>
                                                    </div>
                                                    }   
                                                </p>
                                            </TableCell>
                                            <TableCell align="center" style={{fontSize: '1.25em'}}> {row.selectedOrderConfirmationMethod === 'dineIn' && <span>{row.tableNumber} </span>}{row.selectedOrderConfirmationMethod === 'carryOut' && <span>Carry Out</span>} {row.selectedOrderConfirmationMethod === 'bar' && <span>Bar</span>}
                                            {row.selectedOrderConfirmationMethod === 'delivery' && <span style={{fontSize:'1em'}}><span style={{color:'red'}}>Delivery: </span>{row.userAddress}</span>}
                                            </TableCell>                                
                                            <TableCell align="center" style={{fontSize: '1.25em'}}>{row.timestampForDisplay}</TableCell>
                                            {/* <TableCell align="center" style={{fontSize: '1.25em'}}>${row.totalCost.toFixed(2)}</TableCell> */}
                                            <TableCell align="right" style={{fontSize: '1.25em'}}>
                                                <div style={{display:'inline'}}>
                                                    {row.cancelled ?
                                                        <Button disabled variant="contained" color="default">Cancelled</Button> 
                                                        :
                                                        <span>
                                                            <div style={{marginBottom:'1em', marginRight:'.5em'}}>
                                                            {row.processing ? 
                                                                <Button disabled variant="contained" color="default">Confirmed</Button> 
                                                                : 
                                                                <Button style={{backgroundColor:'#ff3333', color:'white'}} variant="contained" onClick={()=>this.confirm(row)}>Confirm</Button>          
                                                            }
                                                            </div>
                                                          

                                                            <div style={{marginTop:'.5em', marginRight:'.5em'}}>
                                                            {row.fulfilled || row.outForDelivery ? 
                                                                <Button disabled variant="contained" color="default">Fulfilled</Button> 
                                                                : 
                                                                <Button variant="contained" color="primary" onClick={()=>this.fulfilled(row)}>Fulfill</Button>          
                                                            }
                                                            </div>
                                                        </span> 
                                                    }

                                                    <IconButton onClick={()=>this.setState({clickedOrderOptions:true, clickedOrder:row})} aria-label="clickOptions"> <MoreHorizIcon /></IconButton> 
                                                </div>                                           
                                            </TableCell>
                                                
                                        </TableRow>
                                    )})}
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={this.state.orders && this.state.orders.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>

            </div>
        )
    }
}
