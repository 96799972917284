import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import moment from  'moment'
import Avatar from '@material-ui/core/Avatar';
import BlockIcon from '@material-ui/icons/Block';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import soundfile from '../../Assets/alert.mp3'
import { isEqual } from 'lodash';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import TocIcon from '@material-ui/icons/Toc';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import OrdersTableView from './PartyOrdersTableView';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import styles from '../Orders/animations.module.css'

import firebase from '../../Config/firebase'
const db = firebase.firestore();

const columns = [
    { 
        id: 'customerName', 
        label: 'Name', 
        minWidth: 120,
        align: 'center',
    },
    {
        id: 'time',
        label: 'Time',
        minWidth: 120,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    }
  ];

const emptyColumns = [
    { 
        id: 'Completed orders', 
        label: 'Name', 
        minWidth: 120,
        align: 'center',
    },
    {
        id: '',
        label: 'Time',
        minWidth: 120,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    }
  ];

export default class PartyOrdersRestaurantView extends Component {

    state={
        page: 0,
        rowsPerPage: 8, 
        commandsToPrint: this.props.commandsToPrint,
        clickedOrder: null,
        clickedCancelledViewOrder: false,
        view: ['restaurant'],
        discount: '',
        showDiscount: false,
        showRemoveItem: false,
        showModificationMessage: false,
        selectedProduct:null,
        fulfilledOrdersLength:0,
        unFulfilledOrdersLength:0,
        repeatAlert: false
    }

    componentDidMount(){
        let yesterdayStart = new Date(moment().subtract(1, 'day').startOf('day').add(4, 'hours').format('YYYY-MM-DD HH:mm:ss'));
        let orders = []
        let unfulfilledOrders = []
        let otherOrders = [] 
        let audio = new Audio(soundfile);

        this.realTimeOrders = db.collection("orders").where('businessId', '==', this.props.business.user.id)
        .where("timestampCreated", ">=", yesterdayStart)
        .orderBy('timestampCreated', 'desc')
        .onSnapshot((querySnapshot)=> {
            if(querySnapshot.size === 0){
                return
            }else {
                querySnapshot.forEach(doc => {
                  let order = doc.data()
                  if(!order.partyOrder){
                    return console.log('dont normal order in party view')
                  }
                  // If not available add the drinks fulfilled parameter
                  if (!order.drinksFulfilled) {
                    order.drinksFulfilled = false
                  }

                  if(this.props.isSchedule){
                       //calc all tax in order
                       let taxInOrder = []
                       let subTotalInOrder = []
                       for (let i=0; i < order.products.length; i ++){
                           taxInOrder.push(order.products[i].taxApplied)
                           subTotalInOrder.push(order.products[i].productPrice)
                       }
                       order.taxInOrder = taxInOrder.reduce((a, b) => a + b, 0)
                       order.subTotal = subTotalInOrder.reduce((a, b) => a + b, 0)

                    if(order.deliveryType == "scheduled"){
                      order.id = doc.id
                      order.timestampForDisplay = order.timestampCreated.seconds;
                      // order.timestampForDisplay = order.timestampCreated.toDate()
                      // order.timestampForDisplay = `${order.timestampForDisplay.getMonth()+1}/${order.timestampForDisplay.getDate()}/${order.timestampForDisplay.getFullYear()} ${order.timestampForDisplay.getHours()}:${order.timestampForDisplay.getMinutes()} `
                      // order.timestampForDisplay = moment(order.timestampForDisplay).format('hh:mm a')
  
                      if(!order.fulfilled && !order.cancelled){
                          unfulfilledOrders.push(order) 
                      }else{
                          otherOrders.push(order)
                      }
                    }
                  }else{
                    let taxInOrder = []
                    let subTotalInOrder = []
                    for (let i=0; i < order.products.length; i ++){
                        taxInOrder.push(order.products[i].taxApplied)
                        subTotalInOrder.push(order.products[i].productPrice)
                    }
                    order.taxInOrder = taxInOrder.reduce((a, b) => a + b, 0)
                    order.subTotal = subTotalInOrder.reduce((a, b) => a + b, 0)
                    if(order.deliveryType != "scheduled"){
                      order.id = doc.id
                      order.timestampForDisplay = order.timestampCreated.seconds;
                      // order.timestampForDisplay = order.timestampCreated.toDate()
                      // order.timestampForDisplay = `${order.timestampForDisplay.getMonth()+1}/${order.timestampForDisplay.getDate()}/${order.timestampForDisplay.getFullYear()} ${order.timestampForDisplay.getHours()}:${order.timestampForDisplay.getMinutes()} `
                      // order.timestampForDisplay = moment(order.timestampForDisplay).format('hh:mm a')
  
                      if(!order.fulfilled && !order.cancelled){
                          unfulfilledOrders.push(order) 
                      }else{
                          otherOrders.push(order)
                      }
                    }
                  }
                  if(this.state.clickedOrder && this.state.clickedOrder.id == order.id){
                    this.setState({clickedOrder:order})
                  }
                })

                orders = unfulfilledOrders.concat(otherOrders) // concat everything else at bottom - this keeps timestamp order

                let fulfilled = orders;
                fulfilled = fulfilled.filter(i => i.fulfilled || i.cancelled);

                let unfulfilled = orders;
                unfulfilled = unfulfilled.filter(i => !i.fulfilled && !i.cancelled);


                fulfilled.sort(function(a, b) {
                    let aTime = a.timestampCreated.seconds;
                    let bTime = b.timestampCreated.seconds;

                    if (aTime < bTime) {
                       return 1;
                    } else {
                       return -1;
                    }
                    return 0;
                  })

                unfulfilled.sort(function(a, b) {
                    let aTime = a.timestampCreated.seconds;
                    let bTime = b.timestampCreated.seconds;

                    if (aTime < bTime) {
                       return -1;
                    } else {
                       return 1;
                    }
                    return 0;
                  });
                orders = unfulfilled.concat(fulfilled);

                orders.map(i => i.duration = this.showTimeByDuration(i.timestampCreated.seconds))
                this.setState({
                  fulfilledOrdersLength:fulfilled.length,
                  unFulfilledOrdersLength:unfulfilled.length
                })
                this.setState({ orders: orders, ordersCreated: true })
                if (!this.state.clickedOrder) {
                  if(orders.length >  0){
                    this.viewCustomer(orders[0], orders[0].id)
                    // console.log("Orders to show: ", orders);
                    // this.setState({ clickedOrder: orders[0] })
                  }
                }else{
                  this.viewCustomer(this.state.clickedOrder, this.state.clickedOrder.id)
                }

                //if business wants repeat sound alert
                //start with no repeat
                if(this.state.repeatAlert){
                    console.log('turn off sound alert interval', this.state.repeatAlert)
                    clearInterval(this.state.repeatAlert); // clear the repeat sound alert
                    this.setState({repeatAlert: false})
                }
                if(orders.length && this.props.business.user.data.orderNotificationRepeat){
                    if (orders[0].fulfilled || orders[0].cancelled || orders[0].processing || orders[0].ready || orders[0].printedTicket){
                        console.log('top order has been addressed')
                    }else{
                        console.log('top order has not been addressed')
                        this.state.repeatAlert = setInterval(() => console.log("repeat sound!!!!: ", audio.play()), 10000); //after first sound ring every 10 seconds 
                    }
                }
                if(orders.length){
                  if (orders[0].fulfilled || orders[0].cancelled || orders[0].processing || orders[0].ready || orders[0].printedTicket){
                    this.setState({newOrder: false})
                  }
                }
             
                
                orders = [] //set back to empty for new orders coming in
                unfulfilledOrders = []
                otherOrders = [] 
            }
        })
 
        //new orders check to make sound alert
        if(this.props.business.user.data.orderNotificationSound){
            this.newOrdersCheck = db.collection("orders").where('businessId', '==', this.props.business.user.id)
            .where("timestampCreated", ">=", yesterdayStart)
            .orderBy('timestampCreated', 'desc')
            .onSnapshot((snapshot)=> {
                snapshot.docChanges().forEach((change)=>{
                    if (change.type === "added") {
                        console.log('order', change.doc.data())
                        let order = change.doc.data()
                        if (order.fulfilled || order.cancelled || order.processing || order.ready || !order.partyOrder){
                            // console.log('do nothing')
                        }else{
                          this.setState({newOrder: true})
                          console.log("New order so make sound!!!!: ", audio.play());
                          var docRef = db.collection("parties").doc(order.partyId);
                          docRef.get().then((doc)=> {
                              if (doc.exists) {
                                  console.log("Document data:", doc.data());
                                  let currentParty = doc.data()
                                  currentParty.id = doc.id
                                  this.setState({currentParty: currentParty})
                              } else {
                                  // doc.data() will be undefined in this case
                                  console.log("No such document!");
                              }
                          }).catch((error)=>{
                              console.log("Error getting document:", error);
                          });
                        }
                    }
                });
            });
        }

        // Update the time every 10 sec
    
        this.interval = setInterval(() => {
          let orders = this.state.orders;
          if( orders && orders.length > 0){
            orders.map(i => i.duration = this.showTimeByDuration(i.timestampCreated.seconds))
            this.setState({
              orders: orders
            })
          }
         
          if (!orders) {
            clearInterval(this.interval)
          }
        }, 10000)
    }

    componentWillUnmount(){
      if(this.state.repeatAlert){
        console.log('turn off sound alert interval', this.state.repeatAlert)
        clearInterval(this.state.repeatAlert); // clear the repeat sound alert
      }
        if(this.realTimeOrders){
            this.realTimeOrders() 
            console.log('unmount component and unsubscribe realtime lister, display order restaurant view')
        }
        if(this.newOrdersCheck){
            this.realTimeOrders() 
            console.log('unmount check new orders and unsubscribe realtime lister, alerts on restaurant view')
        }
        clearInterval(this.interval);
    }

    fulfilled = (order) => {
        console.log('clicked fulfill order', order.id)
        let orderRef = db.collection("orders").doc(order.id)
      orderRef.update({fulfilled: !this.state.clickedOrder.fulfilled,
                      drinksFulfilled: true })
        this.setState({ clickedOrder: {...this.state.clickedOrder, fulfilled: !this.state.clickedOrder.fulfilled, drinksFulfilled: true }}, () => console.log("Fulfilled is set to: ", this.state.clickedOrder.fulfilled))
        // this.setState({clickedOrderOptions:false, clickedOrder: null})
    };


    fulfillDrinks = (order) => {
      let orderRef = db.collection("orders").doc(order.id)
      orderRef.update({ drinksFulfilled: !this.state.clickedOrder.drinksFulfilled })
      this.setState({
        clickedOrder: {...this.state.clickedOrder,
        drinksFulfilled: !this.state.clickedOrder.drinksFulfilled
      }}, () => console.log("Drinks fulfilled is set to: ", this.state.clickedOrder.drinksFulfilled))
      console.log("Drinks are fulfilled.")
    }

    confirm = (order) => {
        var orderRef = db.collection("orders").doc(order.id);
        return orderRef.update({processing:true})
        .then(()=> {
            console.log("updated doc to processing!");
            this.setState({ clickedOrder: {...this.state.clickedOrder, processing: true}})
            this.printTicket(order)
            // this.setState({clickedOrderOptions:false, clickedOrder: null})
        })
        .catch((error)=>{
            console.error("Error updating document: ", error);
            this.printTicket(order)
            // this.setState({clickedOrderOptions:false, clickedOrder: null})
        });
    };

    concatOrder = (products) => {
      let out = {};
      for (let i=0; i<products.length; i++) {
        // Product is not in out
        if (Object.keys(out).includes(products[i].productName)) {
          let opt = products[i].selectedOptions;
          let inst = products[i].productInstructions || null;
          let index = -1;
          for (let j=0; j<out[products[i].productName].length; j++) {
            if (out[products[i].productName][j]) {
              if (isEqual(out[products[i].productName][j][0], opt) && isEqual(out[products[i].productName][j][2], inst)) {
                index = j;
              }
            }
          }
          // Product is in out but doesn't have the same product options
          if (index !== -1) {
            out[products[i].productName][index][1].total += 1;
            out[products[i].productName][index][1].price  += products[i].productPrice;
            if (products[i].discount) {
              out[products[i].productName][index][1].discountedPrice += products[i].productPrice * (1 - products[i].discount);
            } else {
              out[products[i].productName][index][1].discountedPrice += products[i].productPrice;
            }
          } else {
            if (products[i].discount) {
              out[products[i].productName].push([products[i].selectedOptions, {total: 1, price: products[i].productPrice, discountedPrice: products[i].productPrice * (1 - products[i].discount)}, products[i].productInstructions || null]);
            } else {
              out[products[i].productName].push([products[i].selectedOptions, {total: 1, price: products[i].productPrice, discountedPrice: products[i].productPrice}, products[i].productInstructions || null]);

            }
          }
        } else {
          if (products[i].discount) {
            out[products[i].productName] = [[products[i].selectedOptions, {total: 1, price: products[i].productPrice, discountedPrice: products[i].productPrice * (1 - products[i].discount)}, products[i].productInstructions || null]];
          } else {
            out[products[i].productName] = [[products[i].selectedOptions, {total: 1, price: products[i].productPrice, discountedPrice: products[i].productPrice}, products[i].productInstructions || null]];
          }
        }
      }
      return out;
    }

    //  instructionsToObject = (instruction) => {
    //      let texts = instruction.split(",")
    //      let instructions = {}
    //      for (let i=0; i<texts.length; i++) {
    //          let text = texts[i];
    //          text = text.split(":");
    //          if (text[0] in instructions) {
    //              instructions[text[0]].push(text[1]);
    //          } else {
    //              instructions[text[0]] = [text[1]];
    //          }
    //      }
    //      console.log("Instruction: ", instructions);
    //      return instructions;
    //  }

    printTicket = (order) =>{
        var orderRef = db.collection("orders").doc(order.id);
        var commandsToPrint =
          "<BIG><BOLD><CENTER>" + order.businessName + "<BR>\n" +
          "<BIG><BOLD><CENTER>" + order.selectedOrderConfirmationMethod + "<BR>\n" +
          "<LINE>" + "<BR>\n" +
          "<CENTER><UNDERLINE>Customer Info: "+ "<BR>\n" +
          "<CENTER>" + order.username + "<BR>\n" +
          "<CENTER>"+ order.userPhone + "<BR>\n";

        if (order.selectedOrderConfirmationMethod === "dineIn") {
          commandsToPrint += 
          "<BOLD><LEFT>Table Number: " +"#"+order.tableNumber + "<BR>\n"+
          "<BR>" +
          "<MEDIUM1><LEFT>Order Details:" + "<BR>\n";
        }

        if (order.selectedOrderConfirmationMethod === "delivery") {
          commandsToPrint += 
          "<BOLD><CENTER>Delivery Address: " + order.userAddress + "<BR>\n" +
          "<BR>" +
          "<MEDIUM1><LEFT>Order Details:" + "<BR>\n";
        }

        if (order.selectedOrderConfirmationMethod === "carryOut") {
          commandsToPrint += 
          "<MEDIUM1><LEFT>Order Details:" + "<BR>\n";
        }
        

        // let instructions = null;
        // console.log("Order: ", order);

        // if (order.instructionsForBusiness) {
        //   instructions = this.instructionsToObject(order.instructionsForBusiness);
        //   console.log("Instructions: ", instructions);
    
        //   commandsToPrint += 
        //   "<LINE>" + "<BR>\n" + 
        //   "<LEFT>*Special Instructions*" + "<BR>\n" +
        //   "<LEFT>" + order.instructionsForBusiness + "<BR>\n"+
        //   "<LINE>" + "<BR>\n";  
        // } 

        // Sort order based on productName and selectedOptions
        order.products.sort(function(a, b) {
          let nameA = a.productName.toUpperCase();
          let nameB = b.productName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          let selectedOptionsA = a.selectedOptions;
          let selectedOptionsB = b.selectedOptions;
          if (selectedOptionsA && selectedOptionsB) {
            if (selectedOptionsA.length < selectedOptionsB.length) {
              return -1;
            }
            if (selectedOptionsA.length > selectedOptionsB.length) {
              return 1;
            }

            for (let i=0; i<selectedOptionsA.length; i++) {
              let priceA = selectedOptionsA[i].additionalCost;
              let priceB = selectedOptionsB[i].additionalCost;
              if (priceA < priceB) {
                return -1;
              }
              if (priceA > priceB) {
                return 1;
              }
              let sampleOptionA = selectedOptionsA[i].selectedOption;
              let sampleOptionB = selectedOptionsB[i].selectedOption;
              if (sampleOptionA < sampleOptionB) {
                return -1;
              }
              if (sampleOptionA > sampleOptionB) {
                return 1;
              }
            }
          } else if (selectedOptionsA || selectedOptionsB) {
            if (selectedOptionsA && !selectedOptionsB) {
              return 1;
            }
            if (!selectedOptionsA && selectedOptionsB) {
              return -1;
            }
          }

          // names must be equal
          return 0;
        })

        let concatProducts = this.concatOrder(order.products);
        console.log("concatProducts: ", concatProducts)

        for (let i=0; i<order.products.length; i++) {
          let productName = order.products[i].productName;
          let selectedOptions = order.products[i].selectedOptions;
          let inst = order.products[i].productInstructions || null;
          if (Object.keys(concatProducts).includes(productName)) {
            for (let j=0; j<concatProducts[productName].length; j++) {
              if (isEqual(concatProducts[productName][j][0], selectedOptions) && isEqual(concatProducts[productName][j][2], inst)) {
                let total = concatProducts[productName][j][1].total;
                if (total > 1) {
                  commandsToPrint += "<LEFT>" + total + "X " + productName + "<BR>\n";
                  i += (total - 1)
                } else {
                  commandsToPrint += "<LEFT>" + total + "X " + productName + "<BR>\n";
                }
              }
            }
          } else {
            commandsToPrint += productName + "<BR>\n";
          }
          if (selectedOptions) {
            for(let k = 0; k<selectedOptions.length; k++) {
              commandsToPrint += "     >> " + selectedOptions[k].selectedOption + "<BR>\n";
            }
          }
            if (inst) {
              commandsToPrint += "     Instruction: " + inst + "<BR>\n";
            }  
        }

        commandsToPrint += 
            "<LINE>" + "<BR>\n" +
            "<LEFT>Total Cost: $" + order.totalCost.toFixed(2) + "<BR>\n" +
            "<LEFT>Tip Included: $" + order.tip.toFixed(2) + "<BR>\n";

        commandsToPrint += 
            "<LINE>" + "<BR>\n" +
            "<LEFT>Time Received: " + order.timestampCreated.toDate() + "<BR>\n" +
            "<LEFT>Order Number: " + order.orderNumber + "<BR>\n" +
            "<BR\n>";
            
        commandsToPrint += 
            "<CUT>\n" + "<DRAWER>\n";

        console.log("Printing: ", commandsToPrint);

        var textEncoded = encodeURI(commandsToPrint);
        window["printNewReceipt"](textEncoded);

        return orderRef.update({printedTicket: true})
        .then(()=> {
            console.log("updated doc to printed!");
            // this.setState({clickedOrderOptions:false, clickedOrder: null})
        })
        .catch((error)=>{
            console.error("Error updating document: ", error);
            // this.setState({clickedOrderOptions:false, clickedOrder: null})
        });
    }

    cancelOrder = (order) => {
        // console.log('clicked cancel order', order)
        let orderRef = db.collection("orders").doc(order.id)
        orderRef.update({ processing:false, ready:false, fulfilled:false, cancelled:true })
        this.setState({clickedOrderOptions:false, clickedCancel:false})
    };

    // ARE WE USING THIS???
    cancel = (order) => {
      console.log('clicked fulfill order', order.id)
      let orderRef = db.collection("orders").doc(order.id)
      this.setState({
        clickedOrder: {...this.state.clickedOrder, cancelled: true}})
        orderRef.update({cancelled :true, processing:false, ready:false, fulfilled:false})
      // this.setState({clickedOrderOptions:false, clickedOrder: null})
    };
    outForDelivery = (order) => {
        console.log('clicked order out for delivery', order)
        let orderRef = db.collection("orders").doc(order.id)
        orderRef.update({ outForDelivery: true, fulfilled: true })
        this.setState({ clickedOrder: {...this.state.clickedOrder, outForDelivery: true, fulfilled: true }})
        // this.setState({clickedOrderOptions:false, clickedOrder: null})
    };

    showTimeByDuration = (seconds) => {
      let currentTime = new Date();
      let time = new Date(seconds * 1000);

      let diff = (currentTime - time) / 1000;
      let dayDiff = Math.floor(diff / (60 * 60 * 24));
      let hourDiff = Math.floor(diff / (60 * 60));
      let minuteDiff = Math.floor(diff / 60);
      let secondDiff = Math.floor(diff / 60);

      if (dayDiff > 0) {
        return [String(dayDiff) + 'd ago', true];
      }

      if (hourDiff > 0) {
        return [String(hourDiff) + 'h ago', true];
      } else {
        if (minuteDiff < 1) {
          return ['Just now', false];
        } else {
          if (minuteDiff < 5) {
            return [String(minuteDiff) + 'm ago', false];
          } else {
            return [String(minuteDiff) + 'm ago', true];

          }
        }
      }

      return [String(seconds) + 's ago', false];


    }

    convertSecondsToDate = (seconds) => {
      let date = new Date(seconds * 1000);
      return date.toDateString();
    }

    countTotalPrice = () => {
      const {clickedProducts, totalTax}= this.state
      let total = 0;
      clickedProducts.map((product) => {
        total = total + product[3]
      })
      console.log(this.state.clickedOrder)
    //   for(let i = 0; i <order.products.length; i++){
    //     taxAppliedToProduct.push(order.products[i].taxApplied)
    //     taxRateInOrder = order.products[i].taxApplied / order.products[i].productPrice
    // }


    // let totalTaxInOrder = taxAppliedToProduct.reduce((a, b) => a + b, 0) || 0
      this.setState({
        totalPriceBeforeTax: total,
        // totalPriceAfterTax: total+totalTax
        totalPriceAfterTax: total+ this.state.totalTaxInOrder
      })
    }

    discountItemFromOrder = (chosenProduct, discount, index) => {
      
      const clickedProducts = [...this.state.clickedProducts]
      // NOTE: Discount is from 0 to 1.
   
      let chosenProductId = chosenProduct.itemIdForOrder;
      let chosenProductName = chosenProduct.productName;
      let orderRef = db.collection("orders").doc(this.state.clickedOrder.id);
      let newTotalCost;
      console.log(this.state.clickedOrder.totalCost)
      console.log(chosenProduct.productPrice)
      console.log(chosenProduct.discount)
      console.log(discount)
      if(discount){
        newTotalCost =  this.state.clickedOrder.totalCost - (chosenProduct.productPrice * discount);

      }else{
        console.log( this.state.clickedOrder.totalCost )
        console.log(chosenProduct)
        newTotalCost =  this.state.clickedOrder.totalCost + (chosenProduct.productPrice  * chosenProduct.discount)
      }

      let newProducts = [];
      let message = 'Successfully applied ' + String(discount * 100) + '% discount to ' + chosenProductName;
      orderRef
      .get().then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            newProducts = data.products;
            let totalTaxInOrder;
            let taxAppliedToProduct = []
            
            for (let i=0; i<newProducts.length; i++) {
              // let price = newProducts[i].productPrice - newProducts[i].taxApplied
              // let taxRate = newProducts[i].taxApplied / newProducts[i].productPrice
              if (chosenProductId) {
                if (newProducts[i].itemIdForOrder === chosenProductId) {
                  newProducts[i].discount = discount;
                  // newProducts[i].taxApplied = price * taxRate
                }
              } else {
                if (newProducts[i].productName === chosenProductName) {
                  newProducts[i].discount = discount;
                  // newProducts[i].taxApplied = price * taxRate
                }
              }
              let  price
              if(newProducts[i].discount){
                price = newProducts[i].productPrice - (newProducts[i].productPrice * newProducts[i].discount)
              }else{
                price = newProducts[i].productPrice 
              }
              console.log("newProducts[i].productPrice", newProducts[i].productPrice)
              console.log("newProducts[i].discount",newProducts[i].discount)
              newProducts[i].taxApplied = (price * 7)/100
              taxAppliedToProduct.push((price * 7)/100)
              console.log(taxAppliedToProduct)
                totalTaxInOrder = taxAppliedToProduct.reduce((a, b) => a + b, 0) || 0
            }
           
            this.setState({totalTaxInOrder: totalTaxInOrder})

            console.log("New info: ", newProducts);
          } else {
              console.log("No such document!");
          }
      })
      .then(() => {
        let discountedPrice = chosenProduct.productPrice * discount

        clickedProducts.map((item)=>{
          if(item[1] == chosenProduct.productName){
            item[3] = chosenProduct.productPrice - discountedPrice
          }
          return item
        })
        
        //
        let total = 0;
        this.state.clickedProducts.map((product) => {
          total = total + product[3]
        })
        console.log(this.state.clickedOrder)
      //   for(let i = 0; i <order.products.length; i++){
      //     taxAppliedToProduct.push(order.products[i].taxApplied)
      //     taxRateInOrder = order.products[i].taxApplied / order.products[i].productPrice
      // }
  
  
      // let totalTaxInOrder = taxAppliedToProduct.reduce((a, b) => a + b, 0) || 0
        this.setState({
          totalPriceBeforeTax: total,
          // totalPriceAfterTax: total+totalTax
          totalPriceAfterTax: total+ this.state.totalTaxInOrder
        })
        orderRef.update({
          totalCost: parseFloat((total+ this.state.totalTaxInOrder+this.state.clickedOrder.tip).toFixed(2)),
          products: newProducts
        })
        //
        this.setState({ clickedOrder: {...this.state.clickedOrder, products: newProducts, totalCost:newTotalCost},clickedProducts }, () => {
          this.setState({showModificationMessage: true, message: message}, () => this.handleCancelledClose())
        })
      })
      .catch(function(error) {
          console.log("Error getting document:", error);
      });
    }


    removeItemFromOrder = (productClicked, itemIndex) => {   
        if( this.state.clickedOrder.products.length === 1){
            return alert ('Order has 1 item. Please cancel order for proper analytics.')
        }
        let chosenProduct = productClicked.productName;
        var orderRef = db.collection("orders").doc(this.state.clickedOrder.id);
        orderRef.update({
          products: firebase.firestore.FieldValue.arrayRemove(productClicked)
      })
        .then(()=> {
            console.log("Order successfully updated!");
            axios({
                method: 'post',
                url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/saveCompedItem',
                data: {
                  orderId: this.state.clickedOrder.id,
                  itemIndex: itemIndex,
                  businessId: this.state.businessId,
                  product: productClicked
                }
              }).then((response) =>{
                console.log('posted to cloud function to save removed item', response);
                this.setState({
                  clickedOrder: {...this.state.clickedOrder,
                                products: this.state.clickedOrder.products.filter(i => i !== productClicked)},
                  loading: true
                }, () => {
                  let message = "Successfully removed " + chosenProduct + " from order."
                  this.setState({showModificationMessage: true, message: message}, () => this.handleCancelledClose())
                  let products = this.state.clickedProducts;
                  let taxRate = this.state.clickedOrder.taxRate || 0;
                  let totalProducts = 0;
                  let totalPriceBeforeTax = 0;
                  let totalTax = 0;
                  let totalPriceAfterTax = 0;
                  let i = 0;
                  let totalTaxInOrder;
                  let taxAppliedToProduct = []
                  const orderProducts = [...this.state.clickedOrder.products]
                  for(let i = 0; i < orderProducts.length; i++){
                    let  price
                    if(orderProducts[i].discount){
                      price = orderProducts[i].productPrice - (orderProducts[i].productPrice * orderProducts[i].discount)
                    }else{
                      price = orderProducts[i].productPrice 
                    }
           
                    taxAppliedToProduct.push((price * 7)/100)
                    totalProducts += 1
                    totalPriceBeforeTax += orderProducts[i].productPrice
                  }

                  while (i < products.length) {
                    // for (let i=0; i<products.length; i++) {
                      if (products[i][1] === chosenProduct) {
                        let price = products[i][3] / products[i][0];
                        products[i][0] -= 1;
                        products[i][3] -= price;
                        if (products[i][0] === 0) {
                          products.splice(i, 1)
                        } else {
                
                        }
                      }
                      i++;
                    }
                  totalTaxInOrder = taxAppliedToProduct.reduce((a, b) => a + b, 0) || 0                 
                  this.setState({totalTaxInOrder: totalTaxInOrder})
  
                  // totalTax = totalPriceBeforeTax * taxRate;
                  totalPriceAfterTax = totalPriceBeforeTax + totalTaxInOrder;
                  orderRef.update({
                    totalCost: totalPriceAfterTax+this.state.clickedOrder.tip,
                })
                  
                  this.setState({ clickedProducts: products, totalProducts: totalProducts, 
                                  totalPriceBeforeTax: totalPriceBeforeTax, 
                                  totalPriceAfterTax: totalPriceAfterTax, totalTax: totalTaxInOrder, loading: false }, () => console.log("Prices have been updated..."));
                            })
                          })
                        // this.setState({clickedViewOrder: false, clickedOrder: null})  
                    })
                    .catch((error)=>{
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                        // this.setState({clickedViewOrder: false, clickedOrder: null})  
                    });
    }

    viewCustomer = (order, id)=>{
        let finalOrder = order;
        let sortedProducts = order.products;
        let taxAppliedToProduct = []
        let taxRateInOrder;
        finalOrder.id = id;

        for(let i = 0; i <order.products.length; i++){
            taxAppliedToProduct.push(order.products[i].taxApplied)
            taxRateInOrder = order.products[i].taxApplied / order.products[i].productPrice
        }
        let totalTaxInOrder = taxAppliedToProduct.reduce((a, b) => a + b, 0) || 0
        console.log('TAX RATE IN ORDER -->', taxRateInOrder)

        sortedProducts.sort(function(a, b) {
          let nameA = a.productName.toUpperCase();
          let nameB = b.productName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          let selectedOptionsA = a.selectedOptions;
          let selectedOptionsB = b.selectedOptions;
          if (selectedOptionsA && selectedOptionsB) {
            if (selectedOptionsA.length < selectedOptionsB.length) {
              return -1;
            }
            if (selectedOptionsA.length > selectedOptionsB.length) {
              return 1;
            }

            for (let i=0; i<selectedOptionsA.length; i++) {
              let priceA = selectedOptionsA[i].additionalCost;
              let priceB = selectedOptionsB[i].additionalCost;
              if (priceA < priceB) {
                return -1;
              }
              if (priceA > priceB) {
                return 1;
              }
              let sampleOptionA = selectedOptionsA[i].selectedOption;
              let sampleOptionB = selectedOptionsB[i].selectedOption;
              if (sampleOptionA < sampleOptionB) {
                return -1;
              }
              if (sampleOptionA > sampleOptionB) {
                return 1;
              }
            }
          } else if (selectedOptionsA || selectedOptionsB) {
            if (selectedOptionsA && !selectedOptionsB) {
              return 1;
            }
            if (!selectedOptionsA && selectedOptionsB) {
              return -1;
            }
          }

          // names must be equal
          return 0;
        })

        let concatProducts = this.concatOrder(sortedProducts);

        let clickedProducts = [];
        let totalProducts = order.products.length;
        let totalPriceBeforeTax = 0;
        let totalPriceAfterTax = 0;
        for (let i=0; i<sortedProducts.length; i++) {
          let productName = sortedProducts[i].productName;
          let selectedOptions = sortedProducts[i].selectedOptions;
          let inst = sortedProducts[i].productInstructions || null;
          let productTotal = 1;
          let productPrice = 0;
          let nonDiscountProductPrice = 0;
          let allSelectedOptions = []
          for (let j=0; j<concatProducts[productName].length; j++) {
            if (isEqual(concatProducts[productName][j][0], selectedOptions) && isEqual(concatProducts[productName][j][2], inst) && !inst) {
              let total = concatProducts[productName][j][1].total;
              let price = concatProducts[productName][j][1].price;
              let discountedPrice = concatProducts[productName][j][1].discountedPrice;
              productPrice += discountedPrice;
              nonDiscountProductPrice += price;
              totalPriceBeforeTax += discountedPrice;
              if (total > 1) {
                productTotal = total;
                // totalProducts += total;
                i += (total - 1)
              }
            }
          }
          if (selectedOptions) {
              for(let k = 0; k<selectedOptions.length; k++) {
                allSelectedOptions.push({optionType: selectedOptions[k].option, optionChoice: selectedOptions[k].selectedOption})
              }
          }
          clickedProducts.push([productTotal, productName, allSelectedOptions, productPrice, nonDiscountProductPrice, sortedProducts[i].productType, inst]);
        }
        totalPriceAfterTax = totalPriceBeforeTax + totalTaxInOrder;

        console.log("Final order: ", finalOrder);

        clickedProducts.sort(function(a, b) {
          if (a[5] < b[5]) {
            return 1;
          } else if (a[5] > b[5]) {
            return -1;
          }
          return 0;
        })

        this.setState({clickedViewCustomer: true, clickedOrder: finalOrder, clickedProducts: clickedProducts, totalProducts: totalProducts, totalPriceBeforeTax: totalPriceBeforeTax, totalPriceAfterTax: totalPriceAfterTax, totalTaxInOrder: totalTaxInOrder})
        // console.log('clicked view customer', order)
    }
        
    viewOrderDetails = ()=>{
        this.setState({clickedCancelledViewOrder: true})
        // console.log('clicked view order', order)
    }

    handleCancelledClose = () => {
      this.setState({ clickedCancelledViewOrder: false })
    }

    handleChangePage = (event, newPage) => {
        console.log('change page', newPage)
        this.setState({page: newPage})
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: +event.target.value, page: 0})
    };

    handleFormat = (event, newFormats) => {
      if (newFormats.length > 0) {
        this.setState({
          view: [newFormats[newFormats.length - 1]]
        }, () => console.log("Format changed to: ", this.state.view));
      }
    };

    handleDiscountChange = (event) => {
      this.setState({discount: event.target.value})
    }

    handleDiscount = (product, index) => {
      if (this.state.discount.match(/^[0-9]+$/) === null || this.state.discount.length <= 0) {
        return alert("Please use only numbers from 0-100");
      }
      let discount = parseInt(this.state.discount) / 100;
      if (discount < 0 || discount > 1) {
        return alert("Please use only numbers from 0-100");
      }

      if (discount < 0.009) {
        this.discountItemFromOrder(product, null, index);
      } else {
        this.discountItemFromOrder(product, discount, index);
      }

      this.setState({ discount: '', showDiscount: false})
    }

    handleClose = () =>{
        this.setState({clickedOrderOptions: false, clickedCancel:false})
    }

    compDiscountRemoveItem = (item, updateMethod, productId) => {
      
      if(updateMethod === 'remove' && this.state.clickedOrder.products.length === 1){
          return alert ('Order has 1 item. Please cancel order for proper analytics.')
      }
  // [productTotal, productName, allSelectedOptions, productPrice, nonDiscountProductPrice, sortedProducts[i].productType]
      // 0: 1,         1: "Espresso" ,   2: [],            3: 0,         4: 0 ,                  5: "Coffee / Tea"
      this.setState({loadingCompDisountRemove: true})

      axios({
          method: 'post',
          url: 'https://us-central1-tango-pwa-7ad3e.cloudfunctions.net/compDiscountRemoveItem',
          data: {
            orderId: this.state.clickedOrder.id,
            businessId: this.state.businessId,
            orderTotalCost: this.state.clickedOrder.totalCost,
            updateMethod: updateMethod,
            product: item,
            taxInOrder: this.state.clickedOrder.taxInOrder
          }
      }).then((response) =>{
          console.log('posted to cloud function to comp, discount or remove', response)
          //close modal on response
          this.setState({ loadingCompDisountRemove: false}) 
      })
      .catch((error)=>{
          console.error("Error posting to cloud function ", error);
          alert('something went wrong with your request')
          this.setState({ loadingCompDisountRemove: false})  
      });
  }

    enableParty = () =>{
      console.log('clicked enable party', this.state.currentParty)
          return db.collection("parties").doc(this.state.currentParty.id).update({enabled: true})
          .then(()=> {
              console.log("Document successfully updated!");
              this.setState({openPartyDialog:false})
              window.location.reload()
          })
          .catch((error)=> {
              console.error("Error updating document: ", error);
          });
    }
    disableParty = () =>{
        console.log('clicked disable party', this.state.currentParty)
        return db.collection("parties").doc(this.state.currentParty.id).update({enabled: false})
        .then(()=> {
            console.log("Document successfully updated!");
            this.setState({openPartyDialog:false})
            window.location.reload()
        })
        .catch((error)=> {
            console.error("Error updating document: ", error);
        });
    }
    render() {

      if (this.state.view[0] === 'table') {
        return <OrdersTableView business={this.props.business} commandsToPrint={this.props.commandsToPrint}  />
      }
        return (
            <div style={{marginTop:'2em'}}>
                {/* click elipses */}
                <Dialog onClose={this.handleClose} aria-labelledby="order-status" open={this.state.clickedOrderOptions}>
                    <DialogTitle id="order-statuse">Order Options</DialogTitle>
                        <List>
                            <ListItem button onClick={() => this.setState({clickedCancel:true})}>
                                <ListItemAvatar>
                                    <Avatar> <BlockIcon/></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Cancel Order" />
                            </ListItem>
                        </List>
                </Dialog>

                {/* are you sure cancel? */}
                <Dialog onClose={this.handleClose} open={this.state.clickedCancel}>
                    <DialogTitle id="order-statuse">Are you sure you want to cancel?</DialogTitle>
                    <p style={{padding:'1em'}}>The user will not be charged and will be notified their order is cancelled</p> 
                    <DialogActions>
                    <Button style={{marginRight:'1em'}} onClick={this.handleClose} color="primary" variant='contained'> No</Button>
                    <Button style={{marginLeft:'1em'}} onClick={() => this.cancelOrder(this.state.clickedOrder)} variant='contained' color="primary" autoFocus>
                        Yes, Cancel
                    </Button>
                    </DialogActions> 
                </Dialog>

                {/* Show loading */}
                {this.state.loading && 
                  <Dialog open={this.state.loading} onClose={() => this.setState({ loading: false })}>
                    <CircularProgress />
                  </Dialog>
                }

                {this.state.currentParty &&
                <Dialog onClose={()=>this.setState({openPartyDialog:false})} open={this.state.openPartyDialog}>
                    <DialogTitle id="order-statuse">Current Party: {this.state.currentParty.name}</DialogTitle>
                    <span style={{padding:'.5em'}}>
                        <p>Entry Code: {this.state.currentParty.entryCode}</p> 
                        <br/>
                        <p>Current Item Count: {this.state.currentParty.itemCount}</p>
                        <p>Max Item Count: {this.state.currentParty.maxItemCount}</p> 
                        <br/>
                        <p>Current Dollar Count: ${this.state.currentParty.dollarCount.toFixed(2)}</p>
                        <p>Max Dollar Amount: ${this.state.currentParty.maxDollarAmount.toFixed(2)}</p> 
                        <br/>
                    </span>
                    <DialogActions>
                    {/* <Button style={{marginRight:'1em'}} onClick={()=>this.setState({openPartyDialog:false})} color="primary" variant='contained'> No</Button> */}
                    {!this.state.currentParty.enabled && <Button style={{marginLeft:'1em'}} variant='contained' color="primary" autoFocus onClick={this.enableParty}>Enable Party</Button>}
                    {this.state.currentParty.enabled && <Button style={{marginLeft:'1em'}} variant='contained' color="secondary" autoFocus onClick={this.disableParty}>Disable Party</Button>}
                    </DialogActions> 
                </Dialog>
                }

                {/* MAIN - Page Container */}
                <Grid container spacing={1}>
                    {/* LEFT - Table Panel */}
                    <Grid xs={4}>
                        <Paper elevation={3}>
                          {this.state.currentParty &&
                              <div style={{float:'left', marginTop:'.5em'}}>
                              <span style={{color:'black', padding:'.5em'}}><span>🎉</span> Party Orders</span>
                              <Button variant='outlined' color='primary' style={{padding:'.5em'}} onClick={()=>this.setState({openPartyDialog:true})}>
                                  <span style={{color:'black', fontWeight:'bold'}}><span>{this.state.currentParty.itemCount}/{this.state.currentParty.maxItemCount}</span></span>
                              </Button>
                              </div>
                            }
                            {this.state.newOrder &&
                              <NotificationsActiveRoundedIcon className={styles.notificationBell} color="secondary" fontSize="large" />
                            }
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} align={column.align} style={{fontWeight:'bold', minWidth: column.minWidth}} >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>

                                    {this.state.orders && this.state.clickedOrder && 
                                        <TableBody>
                                            {this.state.orders.filter(i => !i.fulfilled && !i.cancelled).slice(this.state.page * Math.min(this.state.rowsPerPage, 5), this.state.page * Math.min(this.state.rowsPerPage, 5) + Math.min(this.state.rowsPerPage, 5)).map((row) => {
                                                let p = row.products;
                                                return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{backgroundColor: row.id === this.state.clickedOrder.id ? '#E3EFFF' : row.fulfilled || row.cancelled || row.outForDelivery  ? '#F0F0F0' : 'transparent'}} onClick={()=>this.viewCustomer(row, row.id)}>
                                                    <TableCell style={{fontSize: '1.25em', alignItems:"center", justifyContent:"center", height:"40px"}} align="center" component="th" scope="row">
                                                      <span style={{ cursor:'pointer'}} onClick={()=>this.viewCustomer(row, row.id)}>{row.username}</span>
                                                      {row.drinksFulfilled && <span style={{ paddingLeft: 5 }}><RadioButtonCheckedRoundedIcon /></span>}
                                                        {row.customerArrived && <span style={{color:'red', fontWeight:'bold'}}>*CUSTOMER ARRIVED*</span> }
                                                    </TableCell>
                                                    <TableCell align="center" style={{fontSize: '1.25em'}}>
                                                        <span style={{ cursor:'pointer'}} onClick={()=>this.viewCustomer(row, row.id)}>{
                                                          row.duration[1] ? 
                                                          row.fulfilled || row.cancelled || row.outForDelivery ? 
                                                          <p>{row.duration[0]}</p> :
                                                          <b style={{ color: "#ff0000" }}>{row.duration[0]}</b> :
                                                          <p>{row.duration[0]}</p>
                                                        }</span>
                                                    </TableCell>
                                                    {/* <TableCell align="center"><Button onClick={()=>this.viewOrderDetails(row.id)}>View</Button></TableCell> */}
                                                </TableRow>
                                            )})}
                                        </TableBody>
                                    }
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={this.state.unFulfilledOrdersLength}
                                rowsPerPage={Math.min(this.state.rowsPerPage, 5)}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </Paper><br /><br />

                        <Paper elevation={3}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                    {emptyColumns.map((column) => (
                                        <TableCell key={column.id} align={column.align} style={{fontWeight:'bold', minWidth: column.minWidth}} >
                                        {column.id}
                                        </TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>

                                    {this.state.orders && this.state.clickedOrder && 
                                        <TableBody>
                                            {this.state.orders.filter(i => i.fulfilled || i.cancelled).slice(this.state.page * Math.min(this.state.rowsPerPage, 3), this.state.page * Math.min(this.state.rowsPerPage, 3) + Math.min(this.state.rowsPerPage, 3)).map((row) => {
                                                let p = row.products;
                                                return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={{backgroundColor: row.id === this.state.clickedOrder.id ? '#E3EFFF' : row.fulfilled || row.cancelled || row.outForDelivery  ? '#F0F0F0' : 'transparent'}} onClick={()=>this.viewCustomer(row, row.id)}>
                                                    <TableCell style={{fontSize: '1.25em', alignItems:"center", justifyContent:"center", height:"40px"}} align="center" component="th" scope="row">
                                                        <span style={{ cursor:'pointer'}} onClick={()=>this.viewCustomer(row, row.id)}>{row.username}</span>
                                                        {row.customerArrived && <span style={{color:'red', fontWeight:'bold'}}>*CUSTOMER ARRIVED*</span> }
                                                    </TableCell>
                                
                                                    <TableCell align="center" style={{fontSize: '1.25em'}}>
                                                        <span style={{ cursor:'pointer'}} onClick={()=>this.viewCustomer(row, row.id)}>{
                                                          row.duration[1] ? 
                                                          row.fulfilled || row.cancelled || row.outForDelivery ? 
                                                          <p>{row.duration[0]}</p> :
                                                          <b style={{ color: "#ff0000" }}>{row.duration[0]}</b> :
                                                          <p>{row.duration[0]}</p>
                                                        }</span>
                                                    </TableCell>
                                                        
                                                    {/* <TableCell align="center"><Button onClick={()=>this.viewOrderDetails(row.id)}>View</Button></TableCell> */}
                                                </TableRow>
                                            )})}
                                        </TableBody>
                                    }
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={this.state.fulfilledOrdersLength}
                                rowsPerPage={Math.min(this.state.rowsPerPage, 3)}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </Paper>

                    </Grid>

                    {/* CLICKED - "Problem with Order" */}
                    {this.state.clickedOrder &&
                    <Dialog style={{textAlign: 'center'}} fullWidth={true} maxWidth={'md'} onClose={this.handleCancelledClose} open={this.state.clickedCancelledViewOrder}>
                    <div id="order-statuse" style={{fontWeight:"bold", fontSize:"130%", paddingTop:"5%"}}>
                        Edit Order
                    </div>
                    <div style={{fontWeight:"600", paddingTop:"1%"}}>Order #{this.state.clickedOrder.orderNumber}</div>
                    <Grid xs={12} 
                    style={{paddingTop:"5%", paddingBottom:"5%"}}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    >
                        {this.state.clickedOrder.products.map((product, index) => (
                           
                          
                                <Paper id={product.id} elevation={6} style={{padding:"5%",width:"85%", marginBottom:20}} >
                                    <div  style={{fontWeight:"bold", textAlign:"left", fontSize:"130%"}}> 
                                        {product.productName}
                                    </div>
                                    <div  style={{marginTop:8, textAlign:"left"}}>
                                        {product.selectedOptions && product.selectedOptions.map((selectedOption, indexTwo) => (
                                            <div style={{ borderStyle:"solid", borderRight:"0px", borderBottom:"0px", borderTop:"0px", borderColor:"grey"}}>
                                                <div style={{paddingLeft:10, fontSize:"115%"}}>
                                                    {selectedOption.selectedOption}
                                                </div>
                                            </div>
                                        ))}
                                        {product.discount ?
                                          <div  style={{fontSize:"115%", paddingTop:10, paddingBottom:20}}>Price: <strike style={{ color: "#808080" }}>${product.productPrice.toFixed(2)}</strike> ${(product.productPrice - (product.productPrice * product.discount)).toFixed(2)}</div> :
                                          <div  style={{fontSize:"115%", paddingTop:10, paddingBottom:20}}>Price: ${product.productPrice.toFixed(2)}                                       {product.comped &&<span style={{fontStyle:'italic', color:'red'}}>*Product Comped</span>}
                                          </div>
                                        }

                                    </div>
                                    <Grid xs={12}
                                    container
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    >
                                        <Grid>
                                        {!product.comped &&<Button disabled={this.state.loadingCompDisountRemove}onClick={() => this.compDiscountRemoveItem(product, 'comp')} style={{width:"250px",backgroundColor: this.state.loadingCompDisountRemove?'grey':'green', color: 'white', marginRight:"20px"}}>Comp Item</Button>}

                                        </Grid>
                                        <Grid>
                                        <Button disabled={this.state.loadingCompDisountRemove} onClick={() => this.compDiscountRemoveItem(product, 'remove')} style={{width:"250px",backgroundColor: this.state.loadingCompDisountRemove?'grey':'#ff3333', color:'white'}}>Remove Item from order</Button>
                                        </Grid>
                                    </Grid>

                                    {/* This is the discount dialog */}
                                    {this.state.showDiscount && 
                                      <Dialog
                                        open={this.state.showDiscount}
                                        onClose={() => this.setState({ showDiscount: false,selectedProduct:null, selectedIndex:null})}>
                                        <Paper style={{ padding: 20 }}>
                                          <OutlinedInput
                                            id= {`${product.productName} outlined-adornment-weight`}
                                            value={this.state.discount}
                                            type="number"
                                            onChange={this.handleDiscountChange}
                                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                            aria-describedby="outlined-weight-helper-text"
                                            inputProps={{
                                              'aria-label': `${product.productName} + discount`,
                                            }}
                                            labelWidth={0}
                                          />
                                          <Button onClick={() => this.handleDiscount(this.state.selectedProduct, index)}variant='contained' style={{paddingTop: 10, paddingBottom: 10, backgroundColor:' blue', color:'white', width:"95%", height:"50px"}}>
                                            Apply Discount
                                          </Button>
                                        </Paper>
                                      </Dialog>
                                      }

                                    {/* Dialog before removing order */}
                                    
                                    {this.state.showRemoveItem && 
                                      <Dialog
                                        open={this.state.showRemoveItem}
                                        onClose={() => this.setState({ showRemoveItem: false, selectedProduct:null, selectedIndex:null})}>
                                        <Paper style={{ padding: 20 }}>
                                          <div style={{paddingBottom:20, fontWeight:"bold", fontSize:"130%"}}>
                                            Are you sure you want to cancel this item?
                                          </div>
                                          <div style={{marginBottom:15}}>
                                            <Button onClick={() => {
                                              this.removeItemFromOrder(this.state.selectedProduct, this.state.selectedIndex)
                                              this.setState({ showRemoveItem: !this.state.showRemoveItem})
                                              }} 
                                              variant='contained' 
                                              style={{paddingTop: 10, paddingBottom: 10, backgroundColor:'#ff3333', color:'white', width:"95%", height:"50px"}}
                                            >
                                              Yes - Cancel Item
                                            </Button>
                                          </div>
                                          <Button onClick={() => this.setState({ showRemoveItem: !this.state.showRemoveItem})} variant='contained' style={{paddingTop: 10, paddingBottom: 10, backgroundColor:' #cacccb', color:'white', width:"95%", height:"50px"}}>
                                          No
                                          </Button>


                                        </Paper>
                                      </Dialog>
                                    }

                             
                                </Paper>
 
                              
                        ))}
                        </Grid>

                        
                        {this.state.loadingCompDisountRemove && <LinearProgress/>}
                       
                </Dialog>
                }

                {/* This is the modification message dialog */}
                {this.state.showModificationMessage && 
                  <Dialog 
                    open={this.state.showModificationMessage}
                    onClose={() => this.setState({ showModificationMessage: false, message: '' })}>
                      <Paper style={{ padding: 20 }}>
                        <div style={{paddingBottom:20, fontWeight:"bold", fontSize:"130%"}}>
                          {console.log("Modification dialog is showing...")}
                          {this.state.message}
                        </div>
                        <Button onClick={() => {
                          this.setState({ showModificationMessage: false, message: '' })
                          }}
                          variant='contained' 
                          style={{paddingTop: 10, paddingBottom: 10, backgroundColor:'#ff3333', color:'white', width:"95%", height:"50px"}}
                        >
                         Okay
                        </Button>
                      </Paper>
                  </Dialog>
                  }
                    {/* RIGHT - View Order Panel */}
                    {this.state.clickedProducts && 
                    <Grid xs={8}>
                        <Paper elevation={3} style={{marginLeft:"5%"}}>
                        {this.state.clickedOrder && 
                        <div>
                          <Grid xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            style={{height:"70px"}}
                          >
                            <Grid xs={7} style={{alignItems:"left"}}>
                                <div style={{textAlign:"left"}}>
                                <span style={{paddingLeft:"5%", fontWeight:"bold", textAlign:"left"}}>
                                    Order #{this.state.clickedOrder.orderNumber}
                                </span>
                                <span style={{paddingLeft:"5%"}}>
                                    {this.state.totalProducts < 1 ?
                                        <span>1 item</span> :
                                        <span>
                                            {this.state.totalProducts} items
                                        </span>
                                    }
                                </span>

                       
                                </div>
                            </Grid>
                            <Grid xs={2}>
                                <div>Customer</div>
                                <div style={{fontWeight:"bold"}}>{this.state.clickedOrder.username}</div>
                            </Grid>
                            <Grid xs={2}>
                                {this.state.clickedOrder.fulfilled ?
                                    <div>Order Status: 
                                        <div style={{fontWeight:"bold", color:"green"}}>Fulfilled</div>
                                    </div> :
                                    this.state.clickedOrder.cancelled ?
                                    <div>Order Status: 
                                        <div style={{fontWeight:"bold", color:"red"}}>Cancelled</div>
                                    </div> :
                                    this.state.clickedOrder.ready ?
                                    <div>Order Status: Ready</div> :
                                    <div>Order Status: 
                                        <div div style={{fontWeight:"bold", color:"orange"}}>Preparing</div>
                                    </div>
                                }                            
                            </Grid>
                            <Grid xs={1}>
                            <IconButton onClick={()=>this.setState({clickedOrderOptions: true})}> <MoreHorizIcon /></IconButton> 
                            
                                {/* ... */}
                            </Grid>
                            {/* {this.convertSecondsToDate(this.state.clickedOrder.timestampCreated.seconds)}<br /><br /> */}
                          </Grid>
                          <Divider style={{paddingBottom:"2px"}}/>

                          {/* THIS SHOWS IF ORDER IS CANCELLED */}
                          {this.state.clickedOrder.cancelled &&
                            <Grid xs={12} style={{padding:20}}>
                                <Paper elevation={2} style={{backgroundColor:"#FCF98C"}}>
                                    <div style={{paddingTop:"2%", paddingBottom:"2%"}}>
                                        <div style={{fontSize:"130%", fontWeight:"bold"}}>Canceled Order</div>
                                        <div style={{fontWeight:"600", paddingTop:"1%"}}>This order has been cancelled.</div>
                                    </div>
                                </Paper>
                            </Grid>
                          }

                          {/* ORDER TYPE SECION */}
                          <Grid xs={12}
                            container
                            direction="row"
                            style={{paddingBottom:"3%", paddingTop:"3%", }}
                          >
                            <Grid xs={9}>
                                <div style={{paddingLeft: 20, textAlign:"left"}}>
                                    <div>Order Type</div>
                                        {this.state.clickedOrder.selectedOrderConfirmationMethod == "delivery" &&
                                        <div style={{fontWeight:"bold", fontSize:"150%"}}>
                                            Delivery: {this.state.clickedOrder.userAddress}
                                        </div>
                                        }
                                        {this.state.clickedOrder.selectedOrderConfirmationMethod == "carryOut" &&
                                        <div style={{fontWeight:"bold", fontSize:"150%"}}>
                                            Carry Out
                                        </div>
                                        }
                                        {this.state.clickedOrder.selectedOrderConfirmationMethod == "dineIn" &&
                                        <div style={{fontWeight:"bold", fontSize:"150%"}}>
                                            Dine-In
                                        </div>
                                        }
                                </div>
                                <br/>
                            </Grid>
                            <Grid xs={6} style={{padding: '.25em'}}>
                                {this.state.clickedOrder.selectedOrderConfirmationMethod == "dineIn" &&
                                    <React.Fragment>
                                    <div style={{textAlign:"left", paddingLeft: 20}}>
                                        Table Number
                                    </div>
                                    <div style={{fontWeight:"bold", fontSize:"150%", textAlign:"left", paddingLeft: 20}}>
                                        Table {this.state.clickedOrder.tableNumber}
                                    </div>
                                    </React.Fragment>
                                }
                                {
                                  this.state.clickedOrder.deliveryType == "scheduled" && <React.Fragment>
                                  <div style={{textAlign:"left"}}>
                                      Deliver at
                                  </div>
                                  <div style={{fontWeight:"bold", fontSize:"150%", textAlign:"left"}}>
                                    {moment.unix(this.state.clickedOrder.deliveryTime.seconds).format("MM/DD/YYYY HH:mm:ss")}
                                  </div>
                                  </React.Fragment>
                                }
                            </Grid>                            
                          </Grid>
                          <Divider/>

                          {/* ITEMS SECTION */}
                          <Grid xs={12} style={{paddingTop:"2%", paddingBottom:"5%"}}>
                          {this.state.clickedProducts.filter(i => i[5] !== "Food").length > 0 && 
                            <div style={{fontWeight:"bold", fontSize:"100%", paddingBottom: 10, textAlign: "left", paddingLeft: 20, color: "grey", display: "flex", justifyContent: "space-between"}}>
                              DRINKS
                              {this.state.clickedProducts.filter(i => i[5] !== "Food").length > 0 && (!this.state.clickedOrder.fulfilled) &&
                              <Button style={{marginRight:'1em'}} onClick={() => this.fulfillDrinks(this.state.clickedOrder)} color={this.state.clickedOrder.drinksFulfilled ? "secondary" : "primary"} variant='contained'>Fulfilled Drinks</Button>
                            }
                            </div>
                          }
                          <div>
                            </div>
                            {this.state.clickedProducts.filter(i => i[5] !== "Food").map((i, j) => {
                                return (
                                    <div className="orders" key={j} style={{ textAlign: "left", paddingLeft: 20 }}>
                                        {/* SPLIT THE SECTION HERE SO DRINKS IS ALWAYS ON TOP */}
                                        <span style={{width:"100%", marginBottom:"3%", color: (this.state.clickedOrder.drinksFulfilled && !this.state.clickedOrder.fulfilled) ? "lightgray" : "black"}}>
                                            <span style={{fontWeight:"bold"}}>
                                                {i[0]}x
                                            </span>
                                            <span style={{fontWeight:"bold", paddingLeft:"3%"}}>
                                                {i[1]}
                                            </span>
                                            <span style={{textAlign:"right", paddingLeft:20}}> 

                                            {i[3] === i[4] ?
                                                <NumberFormat value={i[3].toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : 
                                                <>
                                                  <NumberFormat value={i[4].toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} style={{ textDecoration: 'line-through', color: "#808080", paddingRight: 5 }} />
                                                  <NumberFormat value={i[3].toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} style={{fontWeight:"bold", color:"blue"}} />
                                                </>
                                            }
                                            {
                                              i[4] == 0 && <span style={{fontStyle:'italic', color:'red', marginLeft:"10px"}}>*Product Comped</span>
                                            }
                                            </span>
                                        </span>
                                        <div style={{marginTop:".5%", marginBottom:"3%", borderStyle:"solid", borderRight:"0px", borderBottom:"0px", borderTop:"0px", borderColor:"grey"}}>
                                            <div style={{paddingLeft:"2%"}}>
                                            {i[2].map((k, v) => {
                                                return(
                                                    <div key={'suborder_' + v} style={{fontColor:"grey"}}>
                                                        {/* {k.optionType + ': ' + k.optionChoice}  */}
                                                        {k.optionChoice} 
                                                    </div>
                                                )})
                                            }
                                            </div>
                                            {i[6] &&
                                                <div style={{paddingLeft:"2%"}}>
                                                    <div key={'suborder_instructions'} style={{fontColor:"grey"}}>
                                                        {"Instructions: " + i[6]} 
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    )})
                            }
                            {this.state.clickedProducts.filter(i => i[5] === "Food").length > 0 && 
                              <div style={{fontWeight:"bold", fontSize:"100%", paddingBottom: 10, textAlign: "left", paddingLeft: 20, color: "grey"}}>
                                FOOD
                              </div>
                            }
                            {this.state.clickedProducts.filter(i => i[5] === "Food").map((i, j) => {
                                return (
                                    <div className="orders" key={j} style={{ textAlign: "left", paddingLeft: 20 }}>
                                        {/* SPLIT THE SECTION HERE SO DRINKS IS ALWAYS ON TOP */}
                                        <span style={{width:"100%", marginBottom:"3%"}}>
                                            <span style={{fontWeight:"bold"}}>
                                                {i[0]}x
                                            </span>
                                            <span style={{fontWeight:"bold", paddingLeft:"3%"}}>
                                                {i[1]}
                                            </span>
                                            <span style={{textAlign:"right", paddingLeft:20}}> 
                                            {i[3] === i[4] ?
                                                <NumberFormat value={i[3].toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : 
                                                <>
                                                  <NumberFormat value={i[4].toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} style={{ textDecoration: 'line-through', color: "#808080", paddingRight: 5 }} />
                                                  <NumberFormat value={i[3].toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} style={{fontWeight:"bold", color:"blue"}}/>
                                                </>
                                            }
                                            </span>
                                        </span>
                                        <div style={{marginTop:".5%", marginBottom:"3%", borderStyle:"solid", borderRight:"0px", borderBottom:"0px", borderTop:"0px", borderColor:"grey"}}>
                                            <div style={{paddingLeft:"2%"}}>
                                            {i[2].map((k, v) => {
                                                return(
                                                    <div key={'suborder_' + v} style={{fontColor:"grey"}}>
                                                        {/* {k.optionType + ': ' + k.optionChoice}  */}
                                                        {k.optionChoice} 
                                                    </div>
                                                )})
                                            }
                                            </div>
                                            {i[6] &&
                                                <div style={{paddingLeft:"2%"}}>
                                                    <div key={'suborder_instructions'} style={{fontColor:"grey"}}>
                                                        {"Instructions: " + i[6]} 
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    )})
                            }
                            <div style={{textAlign:"left", color:"red", fontWeight:"600", paddingLeft: 20, paddingTop:"5%", justifyContent:"center"}}>
                                <span style={{ cursor:'pointer'}} onClick={()=>this.viewOrderDetails()}>
                                    Any problems with this order?
                                </span>
                                {/* <span style={{ cursor:'pointer'}} onClick={()=>this.viewCustomer(row, row.id)}>
                                    Any problems with this order?
                                </span> */}
                                {/* <span>
                                    <ChevronRightIcon/>
                                </span> */}
                            </div>
                          </Grid>
                          <Divider/>
                          
                          <Grid xs={12}
                            container
                            direction="row"
                            style={{paddingBottom:"3%", paddingTop:"3%"}}
                          >
                              <Grid xs={7}>
                                  <div style={{fontWeight:"bold", textAlign:"left", paddingLeft: 20, paddingTop:"3%"}}>
                                    {this.state.totalProducts < 1 ?
                                        <div>1 total item</div> :
                                        <div>
                                            {this.state.totalProducts} total items
                                        </div>
                                        }
                                    </div>
                              </Grid>
                              <Grid xs={3}>
                                <div style={{fontWeight:"bold", textAlign:"left", paddingTop:"3%"}}>
                                    <div>Subtotal:</div>
                                    <div>Tip: </div>
                                    <div>Tax:</div>
                                    <div>Total:</div>
                                </div>
                              </Grid>
                              <Grid xs={2}>
                                  <div style={{fontWeight:"bold", textAlign:"left", paddingTop:"3%"}}>
                                      <div>
                                        <NumberFormat value={(this.state.clickedOrder.totalCost - this.state.clickedOrder.tip - this.state.clickedOrder.taxInOrder).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      </div>
                                      <div>
                                        <NumberFormat value={this.state.clickedOrder.tip.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      </div>
                                      <div>
                                        <NumberFormat value={this.state.clickedOrder.taxInOrder? this.state.clickedOrder.taxInOrder.toFixed(2):0.00} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      </div>
                                      <div>
                                        <NumberFormat value={this.state.clickedOrder.totalCost.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                      </div>
                                </div>
                              </Grid>
                          </Grid>
                          <Divider/>

                          <Grid xs={12}
                            container
                            direction="row"
                            style={{paddingBottom:"3%", paddingTop:"3%"}}
                          >
                            {this.state.clickedOrder && this.state.clickedOrder.selectedOrderConfirmationMethod === 'delivery' ?
                                <React.Fragment>
                                  <Grid xs={6}>
                                    {this.state.clickedOrder.processing ? 
                                      <Button style={{width:"90%", height:"60px", backgroundColor:"#cacccb", color:"white"}} variant="contained" onClick={()=>this.confirm(this.state.clickedOrder)}>Reprint Ticket</Button> 
                                      : 
                                      <Button style={{backgroundColor:'orange', color:'white', width:"90%", height:"60px", fontWeight:"600"}} variant="contained" onClick={()=>this.confirm(this.state.clickedOrder)}>Print Ticket</Button>          
                                    }
                                  </Grid>
                                  <Grid xs={6}>
                                    {this.state.clickedOrder.outForDelivery ? 
                                      <Button style={{width:"90%", height:"60px", backgroundColor:"#cacccb", color:"white"}} variant="contained" color="default">Out for Delivery</Button> 
                                      : 
                                      <Button style={{backgroundColor:'#1bcc52', color:"white", width:"90%", height:"60px", fontWeight:"600"}} variant="contained" onClick={() => this.outForDelivery(this.state.clickedOrder)}>
                                          Out for Delivery
                                      </Button>
                                    }
                                  </Grid>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  <Grid xs={6}>
                                      {this.state.clickedOrder.processing ? 
                                          <Button style={{width:"90%", height:"60px", backgroundColor:"#cacccb", color:"white"}} variant="contained" onClick={()=>this.confirm(this.state.clickedOrder)}>Reprint Ticket</Button> 
                                          : 
                                          <Button style={{backgroundColor:'orange', color:'white', width:"90%", height:"60px", fontWeight:"600"}} variant="contained" onClick={()=>this.confirm(this.state.clickedOrder)}>Print Ticket</Button>          
                                      }
                                  </Grid>
                                  <Grid xs={6}>
                                      {this.state.clickedOrder.fulfilled ? 
                                          <Button style={{width:"90%", height:"60px", backgroundColor:"#cacccb", color:"white"}} variant="contained" color="default" onClick={()=>this.fulfilled(this.state.clickedOrder)}>Mark as Ready (UNDO)</Button> 
                                          : 
                                          <Button style={{backgroundColor:'#1bcc52', color:"white", width:"90%", height:"60px", fontWeight:"600"}} variant="contained" onClick={()=>this.fulfilled(this.state.clickedOrder)}>
                                              Mark as Ready
                                          </Button>
                                      }
                                  </Grid>
                                </React.Fragment>
                            }                       
   

                            {/* {this.state.clickedProducts.cancelled ?
                                <Button disabled variant="contained" color="default">Cancelled</Button> 
                                :
                                <span>
                                    <div style={{marginBottom:'1em', marginRight:'.5em'}}>
                                    {this.state.clickedProducts.processing ? 
                                        <Button disabled variant="contained" color="default">Confirmed</Button> 
                                        : 
                                        <Button style={{backgroundColor:'#ff3333', color:'white'}} variant="contained" onClick={()=>this.confirm(this.state.clickedOrder)}>Print Ticket</Button>          
                                    }
                                    </div>
                                    <div style={{marginTop:'.5em', marginRight:'.5em'}}>
                                    {this.state.clickedOrder.fulfilled || this.state.clickedOrder.outForDelivery ? 
                                        <Button disabled variant="contained" color="default">Fulfilled</Button> 
                                        : 
                                        <Button variant="contained" color="primary" onClick={()=>this.fulfilled(this.state.clickedOrder)}>Fulfill</Button>
                                    }
                                    </div>
                                </span>
                            } */}
                            
                                {/* {this.state.clickedOrder && this.state.clickedOrder.selectedOrderConfirmationMethod === 'delivery' &&
                                <ListItem button onClick={() => this.outForDelivery(this.state.clickedOrder)}>
                                    <ListItemAvatar>
                                        <Avatar> <DirectionsBikeIcon/></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Out For Delivery" />
                                </ListItem>
                                }
                                <br/> */}

                                {/* <ListItem button onClick={() => this.cancel(this.state.clickedOrder,this.state.clickedCancel)}>
                                    <ListItemAvatar>
                                        <Avatar> <BlockIcon/></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Cancel Order" />
                                </ListItem> */}
                          </Grid>

                            </div>
                            }
                        </Paper>
                    </Grid>
                    }
                </Grid>
            </div>
        )
    }
}
